import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../axios';
import {
	getAllLocationsEndPoint,
	locationByIdEndPoint,
	locationsEndPoint,
} from '../Utils/Constants/endPoints';

const initialState = {
	Location: { locationsList: [] },
	SelectedLocation: { location: null },
	LocationByLocationFor: { locationList: [] },
};

export const getAllLocation = createAsyncThunk('Location/getAllLocation', async () => {
	try {
		const response = await axios.get(getAllLocationsEndPoint);
		return response.data;
	} catch (error) {
		const err = {
			name: error.name,
			message: error.response.data.message,
		};
		throw err;
	}
});

export const createLocation = createAsyncThunk('Location/createLocation', async (params) => {
	const data = { ...params.location };
	try {
		const response = await axios.post(locationsEndPoint, data);
		return response.data;
	} catch (error) {
		const err = {
			name: error.name,
			message: error.response.data.message,
		};
		throw err;
	}
});

export const getLocationById = createAsyncThunk('Location/getLocationById', async (params) => {
	try {
		const response = await axios.get(locationByIdEndPoint(params.locationId));
		return response.data;
	} catch (error) {
		const err = {
			name: error.name,
			message: error.response.data.message,
		};
		throw err;
	}
});

export const deleteLocation = createAsyncThunk('Location/deleteLocation', async (params) => {
	try {
		await axios.delete(locationByIdEndPoint(params.locationId));
		return params.locationId;
	} catch (error) {
		const err = {
			name: error.name,
			message: error.response.data.message,
		};
		throw err;
	}
});

export const updateLocation = createAsyncThunk('Location/updateVehicleType', async (params) => {
	const data = { ...params.location };
	console.log(data);
	try {
		const response = await axios.put(locationByIdEndPoint(data.id), data);
		return response.data;
	} catch (error) {
		const err = {
			name: error.name,
			message: error.response.data.message,
		};
		throw err;
	}
});

export const locationSlice = createSlice({
	name: 'Location',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getAllLocation.fulfilled, (state, action) => {
				state.Location.locationsList = Object.values(action.payload);
			})
			.addCase(createLocation.fulfilled, (state, action) => {
				state.Location.locationsList.push(action.payload);
			})
			.addCase(getLocationById.fulfilled, (state, action) => {
				state.SelectedLocation.location = action.payload;
			})
			.addCase(deleteLocation.fulfilled, (state, action) => {
				state.Location.locationsList = state.Location.locationsList.filter(
					(item) => item.id !== action.payload,
				);
			})
			.addCase(updateLocation.fulfilled, (state, action) => {
				state.Location.locationsList = state.Location.locationsList.map((item) => {
					if (item.id === action.payload.id) {
						item = action.payload;
						return item;
					}
					return item;
				});
			})
	},
});

export default locationSlice.reducer;
