/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/no-named-as-default */
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';
import persistStore from 'redux-persist/es/persistStore';
import driverSlice from './driverSlice';
import driversVehiclesSlice from './driversVehiclesSlice';
import locationSlice from './locationSlice';
import restrictedAreaSlice from './restrictedAreaSlice';
import riderSlice from './riderSlice';
import vehicleTypeSlice from './vehicleTypeSlice';
import KmRangeFareSlice from './KmRangeFareSlice';
import LocationWiseFareSlice from './LocationWiseFareSlice';
import authSlice from './authSlice';
import userSlice from './userSlice';
import bookingSlice from './bookingSlice';
import googleMapsKeySlice from './googleMapsKeySlice';
import settingsSlice from './settingsSlice';
import fileSlice from './fileSlice';
import fleetOperatorSlice from './fleetOperatorSlice';
import dashboardSlice from './dashboardSlice';
import godsViewSlice from './godsViewSlice';
import CancellationPolicySlice from './CancellationPolicySlice';
import brandSlice from './tenantBrandSlice';
import WayPointSlice from './WayPointSlice';
import auditLogSlice from './auditLogSlice';
import tenantFeatureSlice from './tenantFeatureSlice';
import makeSlice from './makeSlice';
import modelSlice from './modelSlice';
import carPoolingSettingSlice from './carPoolingSettingSlice';
import bookingPoolSlice from './bookingPoolSlice';
import corporateSlice from './corporateSlice';
import bundleSlice from './bundleSlice';
import purchasedBundleSlice from './purchasedBundleSlice';
import refundSlice from './refundSlice';
import ReviewSlice from './reviewSlice';

const persistConfig = {
	key: 'auth',
	storage,
	whitelist: ['auth'],
};

const rootReducer = combineReducers({
	auth: authSlice,
	drivers: driverSlice,
	riders: riderSlice,
	driversVehicle: driversVehiclesSlice,
	vehiclesType: vehicleTypeSlice,
	locations: locationSlice,
	restrictedArea: restrictedAreaSlice,
	kmRangeFare: KmRangeFareSlice,
	locationsWiseFare: LocationWiseFareSlice,
	users: userSlice,
	booking: bookingSlice,
	googleMapsKey: googleMapsKeySlice,
	settings: settingsSlice,
	files: fileSlice,
	fleetOperator: fleetOperatorSlice,
	dashboard: dashboardSlice,
	godsView: godsViewSlice,
	cancellationPolicy: CancellationPolicySlice,
	brand: brandSlice,
	wayPoint: WayPointSlice,
	auditLog: auditLogSlice,
	tenantFeatures: tenantFeatureSlice,
	make: makeSlice,
	model: modelSlice,
	carPoolingSetting: carPoolingSettingSlice,
	bookingPool: bookingPoolSlice,
	corporates: corporateSlice,
	bundles: bundleSlice,
	purchasedBundles: purchasedBundleSlice,
	refunds: refundSlice,
	reviews: ReviewSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	// other store configuration options
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});
export const persistor = persistStore(store);
