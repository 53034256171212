/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
/* eslint-disable no-template-curly-in-string */
import { Button, Divider, Form, Input, message } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

const validateMessages = {
	types: {
		email: '${label} is not a valid email',
	},
};

const KmRangeFareForm = ({ kmRangeFare, unsavedChanges, onDirty, onSave, onClose }) => {
	const { Settings } = useSelector((state) => state.settings);
	const [form] = Form.useForm();
	const onCheckForm = (values) => {
		form.validateFields()
			.then(() => {
				onSave(values);
			})
			.catch(() => {
				message.error('Please fill the required fields');
			});
	};

	return (
		<Form
			name='locationWiseFare'
			layout='vertical'
			initialValues={kmRangeFare}
			validateMessages={validateMessages}
			// disable the submit button if the form is invalid
			// or if there are no unsaved changes
			onFinish={onCheckForm}
			autoComplete='off'
			onValuesChange={() => onDirty()}>
			<Form.Item
				label='From KM'
				name='fromKm'
				rules={[
					{ required: true, message: 'Please fill the from Km' },
					{
						type: 'number',
						min: 0,
						transform: (value) => parseFloat(value),
						message: 'Please enter a number greater than or equal to 0',
					},
				]}>
				<Input type='number' step='0.01' />
			</Form.Item>

			<Form.Item
				label='To KM'
				name='toKm'
				rules={[
					{ required: true, message: 'Please fill the to Km' },
					{
						type: 'number',
						min: 0,
						transform: (value) => parseFloat(value),
						message: 'Please enter a number greater than or equal to 0',
					},
				]}>
				<Input type='number' step='0.01' />
			</Form.Item>

			<Form.Item
				label={`Price (${Settings?.settingsData?.currency || '€'})`}
				name='price'
				rules={[
					{ required: true, message: 'Please fill the price' },
					{
						type: 'number',
						min: 0,
						transform: (value) => parseFloat(value),
						message: 'Please enter a number greater than or equal to 0',
					},
				]}>
				<Input type='number' step='0.01' />
			</Form.Item>
			<Divider />
			<Form.Item style={{ float: 'right' }}>
				<Button
					type='default'
					style={{ marginBottom: 32, marginRight: 8 }}
					onClick={onClose}>
					Cancel
				</Button>
				<Button
					type='primary'
					style={{ marginBottom: 32 }}
					htmlType='submit'
					// disable the button if there is no unsaved changes and if the form is not valid
					disabled={!unsavedChanges}>
					{kmRangeFare ? 'Update' : 'Save'}
				</Button>
			</Form.Item>
		</Form>
	);
};

export default KmRangeFareForm;
