/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-shadow */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { GoogleMap, DirectionsRenderer } from '@react-google-maps/api';
import { useSelector } from 'react-redux';

const BookingPoolMaps = () => {
	const { driverNavigation } = useSelector((state) => state.bookingPool);
	const [directions, setDirections] = useState(null);
	const [center, setCenter] = useState({
		lat: 37.17130611530913,
		lng: 9.772111191511911,
	});

	useEffect(() => {
		setCenter(generateCenterFromNavigation());
		if (driverNavigation?.driverNavigationList?.length >= 2) {
			getDirectionsFromStops(driverNavigation?.driverNavigationList);
		}
	}, [driverNavigation?.driverNavigationList]);

	const onLoad = () => {
		console.log('OnLoad: loaded');
	};

	const generateCenterFromNavigation = () => {
		if (
			!driverNavigation?.driverNavigationList ||
			driverNavigation?.driverNavigationList.length === 0
		) {
			return { lat: 0, lng: 0 }; // Default center if there are no stops
		}

		const totalStops = driverNavigation?.driverNavigationList.length;
		const sumLat = driverNavigation?.driverNavigationList.reduce(
			(acc, stop) => acc + stop.latitude,
			0,
		);
		const sumLng = driverNavigation?.driverNavigationList.reduce(
			(acc, stop) => acc + stop.longitude,
			0,
		);

		const lat = sumLat / totalStops;
		const lng = sumLng / totalStops;

		return { lat, lng };
	};

	const getDirectionsFromStops = (stops) => {
		const directionsService = new window.google.maps.DirectionsService();
		const waypoints = stops.map((stop) => ({
			location: new window.google.maps.LatLng(stop.latitude, stop.longitude),
			stopover: true,
		}));

		const origin = waypoints.shift().location;
		const destination = waypoints.pop().location;

		const request = {
			origin,
			destination,
			waypoints,
			travelMode: 'DRIVING',
		};

		directionsService.route(request, (response, status) => {
			if (status === 'OK') {
				setDirections(response);
			}
		});
	};

	return (
		<GoogleMap
			id='circle-example'
			mapContainerStyle={{
				borderRadius: '16px',
				height: '410px',
				width: '99%',
			}}
			zoom={7}
			center={center}
			options={{
				disableDefaultUI: true,
				zoomControl: true,
			}}>
			{directions && (
				<>
					<DirectionsRenderer
						options={{
							directions,
							polylineOptions: {
								strokeColor: '#2979FF',
								strokeOpacity: 0.8,
								strokeWeight: 5,
							},
							markerOptions: {
								visible: true,
								animation: window.google.maps.Animation.DROP,
							},
							suppressMarkers: false,
						}}
						onLoad={onLoad}
					/>
					{/* {driverNavigation?.driverNavigationList.map((stop, index) => (
						<Marker
							key={stop?.id}
							position={{ lat: stop.latitude, lng: stop.longitude }}
							label={{
								text: `${index + 1}`,
								color: 'white', // Set text color to white
								fontWeight: 'bold', // Make text bold
							}}
						/>
					))} */}
				</>
			)}
		</GoogleMap>
	);
};

export default BookingPoolMaps;
