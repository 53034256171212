/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
import { Button, Divider, Form, Select } from 'antd';
import React from 'react';

const restrictArea = ['Pick Up', 'Drop Off'];

const RestrictedAreaForm = ({
	restrictedArea,
	locationsFor,
	onSave,
	onSaveFailed,
	unsavedChanges,
	onDirty,
	onCancel,
}) => {
	const initialValues = {
		locationName: restrictedArea?.locationName,
		restrictArea: restrictedArea?.restrictArea?.split(','),
	};
	return (
		<Form
			name='restrictedArea'
			layout='vertical'
			autoComplete='off'
			initialValues={initialValues}
			onFinish={onSave}
			onFinishFailed={onSaveFailed}
			onValuesChange={() => onDirty()}>
			<Form.Item
				label='Geofence Location'
				name='locationName'
				rules={[{ required: true, message: 'Please select Geofence Location' }]}>
				<Select
					size='medium'
					showArrow
					showSearch
					optionFilterProp='filterBy'
					allowClear
					optionLabelProp='label'
					popupMatchSelectWidth={false}>
					{locationsFor?.map((item) => (
						<Select.Option key={item.id} label={item.locationName}>
							{item.locationName}
						</Select.Option>
					))}
				</Select>
			</Form.Item>
			<Form.Item
				label='Restrict Area'
				name='restrictArea'
				rules={[{ required: true, message: 'Please select Restrict Area' }]}>
				<Select
					size='medium'
					showArrow
					showSearch
					optionFilterProp='filterBy'
					allowClear
					optionLabelProp='label'
					mode='multiple'
					popupMatchSelectWidth={false}>
					{restrictArea.map((item) => (
						<Select.Option key={item} label={item}>
							{item}
						</Select.Option>
					))}
				</Select>
			</Form.Item>

			<Divider />
			<Form.Item style={{ float: 'right' }}>
				<Button
					type='default'
					style={{ marginBottom: 32, marginRight: 8 }}
					onClick={() => onCancel(unsavedChanges)}>
					Cancel
				</Button>
				<Button
					type='primary'
					style={{ marginBottom: 32 }}
					htmlType='submit'
					disabled={!unsavedChanges}>
					{!restrictedArea ? 'Save' : 'Update'}
				</Button>
			</Form.Item>
		</Form>
	);
};

export default RestrictedAreaForm;
