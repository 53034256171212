/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { Form, Input, Select, Button, Divider, Tag, message, Row, Col, DatePicker } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { useDispatch, useSelector } from 'react-redux';
import { FileTextOutlined } from '@ant-design/icons';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import { getAllDrivers } from '../../redux/driverSlice';
import { getAllVehicleType } from '../../redux/vehicleTypeSlice';
import { getAllFleetOperators } from '../../redux/fleetOperatorSlice';
import { style } from '../../Styles';
import { getAllMakes } from '../../redux/makeSlice';
import { getAllModels } from '../../redux/modelSlice';

import UploadDocumentsModal from '../Global/UploadDocumentsModal';
import {
	downloadImage,
	getDriverVehicleDocuments,
	setVehicleInsuranceDocuments,
	setVehicleLicenceDiscDocuments,
	setVehicleLicencePlateDocuments,
	uploadMultiDocuments,
} from '../../redux/fileSlice';

const { useForm } = Form;
const validateMessages = {
	types: {
		email: '${label} is not a valid email',
	},
};

const dateFormat = 'YYYY/MM/DD';

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(utc);

const tomorrow = dayjs().add(1, 'day');
const tagRender = (props) => {
	const { label, value, closable, onClose } = props;
	const onPreventMouseDown = (event) => {
		event.preventDefault();
		event.stopPropagation();
	};
	return (
		<Tag
			color={value}
			onMouseDown={onPreventMouseDown}
			closable={closable}
			onClose={onClose}
			style={{
				marginRight: 3,
				background: '#e6f7ff',
				color: '#1890ff',
				fontWeight: 'bold',
				borderColor: '#91d5ff',
			}}>
			{label}
		</Tag>
	);
};

const DriverVehicleForm = ({
	onSave,
	onSaveFailed,
	driverVehicle,

	onCancel,
	onDirty,
	unsavedChanges,
}) => {
	const { vehicleFetchedDocuments } = useSelector((state) => state.files);
	const [openUploadModal, setOpenUploadModal] = useState(false);
	const [modalTitle, setModalTitle] = useState('');
	const [isUploadingMultiDocuments, setIsUploadingMultiDocuments] = useState(false);
	const [documentList, setDocumentList] = useState([
		vehicleFetchedDocuments?.vehicleFetchedDocumentsList || [],
	]);
	const openUploadModalHandler = (title) => {
		setModalTitle(title);
		setOpenUploadModal(true);
	};
	const [form] = useForm();
	const [selectedMake, setSelectedMake] = useState([]);
	const onSelectMake = (makeId) => {
		const foundedMake = Makes.makeList.filter((p) => p.id === makeId);
		const selectedModels = foundedMake[0]?.models || [];
		// Use form.setFieldsValue to update the model field in the form
		form.setFieldsValue({
			model: undefined, // Set model to undefined to clear the selected value
		});

		setSelectedMake(selectedModels);
	};

	const dispatch = useDispatch();
	const { Drivers } = useSelector((state) => state.drivers);
	const { VehicleType } = useSelector((state) => state.vehiclesType);
	const { Makes } = useSelector((state) => state.make);

	const fetchAllDriverVehicleDocuments = async () => {
		const fetchDocumentsCalls = [];
		const fetchedDocuemnts = [];
		driverVehicle?.documents?.forEach((document) => {
			fetchDocumentsCalls.push(
				dispatch(downloadImage(document?.fileId))
					.unwrap()
					.then((data) => {
						const imgUrl = URL.createObjectURL(data);

						fetchedDocuemnts.push({
							uid: document.fileId,
							id: document.fileId,
							name: document.originalFileName,
							status: 'done',
							url: imgUrl,
							type: document.documentType,
							oldFile: true,
						});
					})
					.catch((error) => {
						throw error;
					}),
			);
		});

		Promise.all(fetchDocumentsCalls)
			.then(() => {
				dispatch(getDriverVehicleDocuments(fetchedDocuemnts));
			})
			.catch((error) => {
				console.log('error', error);
			});
	};

	useEffect(() => {
		fetchAllDriverVehicleDocuments();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		Promise.all([
			dispatch(getAllDrivers()),
			dispatch(getAllVehicleType()),
			dispatch(getAllFleetOperators()),
			dispatch(getAllMakes()),
			dispatch(getAllModels()),
		])
			.then(() => {})
			.catch(() => {
				message.error('Error while fetching drivers');
			});
	}, [dispatch]);

	const getExpiryDateValue = (type) => {
		if (driverVehicle?.documents) {
			const document = driverVehicle?.documents.find((doc) => doc.documentType === type);
			if (document) {
				return dayjs(document?.expiryDate);
			}
		}
		return undefined;
	};
	const initialValues = {
		...driverVehicle,
		vehicleColor: driverVehicle?.vehicleColor || '#FFD854',
		vehicleType: driverVehicle?.driverVehicleType?.map((type) => type?.vehicleType.id),
		fleetOperatorId: driverVehicle?.fleetOperator?.id,
		drivers: driverVehicle?.assignedDriverVehicles?.map((item) => item?.driver?.id),
		make: driverVehicle?.make.deleted ? null : driverVehicle?.make.id,
		model: driverVehicle?.model.deleted ? null : driverVehicle?.model.id,
		insuranceExpiryDate: getExpiryDateValue('VEHICLE_INSURANCE_DOCUMENT'),
	};
	const { fleetOperator } = useSelector((state) => state.fleetOperator);
	useEffect(() => {
		if (driverVehicle?.make) setSelectedMake(driverVehicle?.make?.models);
	}, [driverVehicle?.make]);

	const handleVehicleTypeChange = (selectedValues) => {
		// Calculate the maximum available seats from the selected vehicle types
		let maxSeats = 0;
		selectedValues.forEach((selectedValue) => {
			const selectedVehicleType = VehicleType?.vehicleTypeList.find(
				(item) => item.id === selectedValue,
			);
			if (selectedVehicleType && selectedVehicleType.availableSeat > maxSeats) {
				maxSeats = selectedVehicleType.availableSeat;
			}
		});
		// Use form.setFieldsValue to update the value of the "availableSeats" field
		form.setFieldsValue({
			availableSeats: maxSeats,
		});
	};

	const uploadDocuments = (data) => {
		const files = data?.files;
		const type = data?.type;
		setIsUploadingMultiDocuments(true);
		const newFiles = files?.filter((file) => !file.oldFile);

		dispatch(uploadMultiDocuments({ files: newFiles, type, component: 'VEHICLE' }))
			.unwrap()
			.then((response) => {
				message.success('Documents uploaded successfully');
				if (response?.type === 'VEHICLE_LICENSE_PLATE') {
					dispatch(setVehicleLicencePlateDocuments(response?.data));
				}
				if (response?.type === 'VEHICLE_LICENSE_DISK') {
					dispatch(setVehicleLicenceDiscDocuments(response?.data));
				}
				if (response?.type === 'VEHICLE_INSURANCE_DOCUMENT') {
					dispatch(setVehicleInsuranceDocuments(response?.data));
				}
				setOpenUploadModal(false);
				setDocumentList([]);
			})
			.catch((e) => {
				console.error(e);
				message.error('Error while uploading documents, please try again later');
			})
			.finally(() => {
				setIsUploadingMultiDocuments(false);
			});
	};

	return (
		<Form
			form={form}
			name='DriverVehicle'
			layout='vertical'
			autoComplete='off'
			initialValues={initialValues}
			validateMessages={validateMessages}
			onFinish={onSave}
			onFinishFailed={onSaveFailed}
			onValuesChange={() => onDirty()}>
			<Form.Item
				label='Make'
				name='make'
				rules={[{ required: true, message: 'Please select Vehicle' }]}>
				<Select
					size='medium'
					showArrow
					showSearch
					optionFilterProp='filterBy'
					allowClear
					optionLabelProp='label'
					onChange={onSelectMake}>
					{Makes?.makeList
						.filter((p) => !p.deleted)
						?.map((p) => (
							<Select.Option key={p.id} filterBy={p.name} label={p.name} value={p.id}>
								{p.name}
							</Select.Option>
						))}
				</Select>
			</Form.Item>

			<Form.Item
				label='Model'
				name='model'
				rules={[{ required: true, message: 'Please select Vehicle Model' }]}>
				<Select
					size='medium'
					showArrow
					showSearch
					optionFilterProp='filterBy'
					allowClear
					value={initialValues?.model}
					optionLabelProp='label'>
					{selectedMake &&
						selectedMake
							.filter((p) => !p.deleted)
							?.map((p) => (
								<Select.Option
									key={p.id}
									filterBy={`${p.name}`}
									label={p.name}
									value={p.id}>
									{p.name}
								</Select.Option>
							))}
				</Select>
			</Form.Item>
			<Form.Item
				label='Year'
				name='year'
				rules={[
					{ required: true, message: 'Please input the year of vehicle' },
					{
						type: 'number',
						min: 0,
						transform: (value) => parseFloat(value),
						message: 'Please enter a number greater than or equal to 0',
					},
				]}>
				<Input type='number' step='10' />
			</Form.Item>
			<Form.Item
				label='Licence plate'
				name='licencePlate'
				rules={[{ required: true, message: 'Please input the Licence Plate' }]}>
				<Input />
			</Form.Item>
			<FormItem
				label='Vehicle Type'
				name='vehicleType'
				/*  value={driverVehicle?.driverVehicleType?.map(
            (type) => type.vehicleType.id
          )} */
				rules={[{ required: true, message: 'Please select Vehicle Type' }]}>
				<Select
					mode='multiple'
					showArrow
					tagRender={tagRender}
					style={{
						width: '100%',
					}}
					optionFilterProp='filterBy'
					allowClear
					optionLabelProp='label'
					popupMatchSelectWidth={false}
					onChange={handleVehicleTypeChange}>
					{VehicleType?.vehicleTypeList?.map((item) => (
						<Select.Option key={item.id} value={item.id} label={item.vehicleType}>
							{item.vehicleType}
						</Select.Option>
					))}
				</Select>
			</FormItem>
			<Form.Item
				label='Available seats'
				tooltip='The populated value is the maximum number of seat from the selected vehicle types.'
				name='availableSeats'
				rules={[
					{ required: true, message: 'Please input the number of available seats' },
					{
						type: 'number',
						min: 1,
						transform: (value) => parseFloat(value),
						message: 'Please enter a number greater than 0',
					},
				]}>
				<Input type='number' step='1' readOnly />
			</Form.Item>
			<Form.Item
				label='Driver'
				name='drivers'
				rules={[{ required: true, message: 'Please select Vehicle Model' }]}>
				<Select
					mode='multiple'
					size='medium'
					showArrow
					showSearch
					optionFilterProp='filterBy'
					allowClear
					initialvalues={driverVehicle?.userId}
					optionLabelProp='label'
					popupMatchSelectWidth={false}>
					{Drivers.driversList?.map((p) => (
						<Select.Option
							key={p.id}
							filterBy={`${p.name}`}
							label={`${p.name} ${p.surname}`}
							disabled={!p.active}
							value={p.id}>
							<div>{` ${p.name}  ${p.surname}`}</div>
							<span style={{ color: 'gray' }}>{p.email}</span>
						</Select.Option>
					))}
				</Select>
			</Form.Item>
			<Form.Item
				label='Fleet Operator'
				name='fleetOperatorId'
				rules={[{ required: true, message: 'Please select fleet operator' }]}>
				<Select
					size='medium'
					showArrow
					showSearch
					optionFilterProp='filterBy'
					allowClear
					optionLabelProp='label'>
					{fleetOperator?.fleetOperatorList?.map((fo) => (
						<Select.Option
							key={fo.id}
							filterBy={fo.name}
							value={fo.id}
							label={fo.name}
							disabled={!fo.active}>
							<div style={style.inlineContent}>
								<span
									style={{
										display: 'flex',
										alignContent: 'center',
										alignItems: 'center',
										float: 'left',
									}}>
									{fo.name}
								</span>
								{fo.active ? (
									<Tag color='green'>Active</Tag>
								) : (
									<Tag color='red'>Inactive</Tag>
								)}
							</div>
						</Select.Option>
					))}
				</Select>
			</Form.Item>
			<Form.Item
				label='Vehicle Color'
				name='vehicleColor'
				rules={[{ message: 'Please input vehicle Color' }]}>
				<Input type='color' defaultValue='#FFD854' />
			</Form.Item>

			<Divider orientation='left' orientationMargin='0'>
				Licence Plate
			</Divider>
			<Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
				<Col span={16} />
				<Col span={8}>
					<span style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
						<Row>
							<Button
								icon={<FileTextOutlined />}
								shape='square'
								onClick={() =>
									openUploadModalHandler('Upload Vehicle Licence Plate Documents')
								}>
								{
									vehicleFetchedDocuments?.vehicleFetchedDocumentsList.filter(
										(doc) =>
											doc.type === 'VEHICLE_LICENSE_PLATE' ||
											doc.documentType === 'VEHICLE_LICENSE_PLATE',
									).length
								}{' '}
								Files
							</Button>
						</Row>
					</span>
				</Col>
			</Row>

			<Divider orientation='left' orientationMargin='0'>
				Licence Disk
			</Divider>
			<Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
				<Col span={16} />

				<Col span={8}>
					<span style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
						<Row>
							<Button
								icon={<FileTextOutlined />}
								shape='square'
								onClick={() =>
									openUploadModalHandler('Upload Vehicle Licence Disc Documents')
								}>
								{
									vehicleFetchedDocuments?.vehicleFetchedDocumentsList.filter(
										(doc) =>
											doc.type === 'VEHICLE_LICENSE_DISK' ||
											doc.documentType === 'VEHICLE_LICENSE_DISK',
									).length
								}{' '}
								Files
							</Button>
						</Row>
					</span>
				</Col>
			</Row>

			<Divider orientation='left' orientationMargin='0'>
				Police Conduct
			</Divider>
			<Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
				<Col span={16}>
					<Form.Item
						label='Expiry date'
						name='insuranceExpiryDate'
						rules={[
							{
								required:
									vehicleFetchedDocuments?.vehicleFetchedDocumentsList.filter(
										(doc) =>
											doc.type === 'VEHICLE_INSURANCE_DOCUMENT' ||
											doc.documentType === 'VEHICLE_INSURANCE_DOCUMENT',
									).length > 0,
								message: 'Please Choose Expiry Date for the insurance',
							},
						]}>
						<DatePicker
							format={dateFormat}
							style={{ width: '100%' }}
							disabledDate={(current) => {
								return current && current < tomorrow;
							}}
						/>
					</Form.Item>
				</Col>
				<Col span={8}>
					<span style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
						<Row>
							<Button
								icon={<FileTextOutlined />}
								shape='square'
								onClick={() =>
									openUploadModalHandler('Upload Vehicle Insurance Doc Documents')
								}>
								{
									vehicleFetchedDocuments?.vehicleFetchedDocumentsList.filter(
										(doc) =>
											doc.type === 'VEHICLE_INSURANCE_DOCUMENT' ||
											doc.documentType === 'VEHICLE_INSURANCE_DOCUMENT',
									).length
								}{' '}
								Files
							</Button>
						</Row>
					</span>
				</Col>
			</Row>
			<UploadDocumentsModal
				title={modalTitle}
				visible={openUploadModal}
				setVisible={setOpenUploadModal}
				onUpload={uploadDocuments}
				isUploadingMultiDocuments={isUploadingMultiDocuments}
				documentList={documentList}
				setDocumentList={setDocumentList}
				setTitle={setModalTitle}
				componentType='VEHICLE'
			/>
			<Divider />
			<Form.Item style={{ float: 'right' }}>
				<Button
					type='default'
					style={{ marginBottom: 32, marginRight: 8 }}
					onClick={() => onCancel(unsavedChanges)}>
					Cancel
				</Button>
				<Button
					type='primary'
					style={{ marginBottom: 32 }}
					htmlType='submit'
					disabled={
						!unsavedChanges &&
						vehicleFetchedDocuments?.vehicleFetchedDocumentsList?.filter(
							(doc) => !doc.oldFile,
						).length === 0 &&
						driverVehicle?.documents?.length ===
							vehicleFetchedDocuments?.vehicleFetchedDocumentsList
					}>
					{!driverVehicle ? 'Save' : 'Update'}
				</Button>
			</Form.Item>
		</Form>
	);
};

export default DriverVehicleForm;
