/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-unused-vars */

/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable import/prefer-default-export */
import { Card, Col, Form, InputNumber, Select, TimePicker } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { daysOfWeek } from '../../Utils/Constants/utils';

export const CardFareSurcharge = ({
	index,
	fareSurcharge,
	faresSurcharges,
	updateFaresSurcharge,
}) => {
	const [formValues, setFormValues] = useState({});
	const [startTime, setStartTime] = useState('');
	const [endTime, setEndTime] = useState('');
	const [day, setDay] = useState('');
	const [surcharge, setSurcharge] = useState('');

	useEffect(() => {
		if (fareSurcharge?.startTime) setStartTime(dayjs(fareSurcharge.startTime));
		if (fareSurcharge?.endTime) setEndTime(dayjs(fareSurcharge.endTime));
		setDay(fareSurcharge?.day);
		setSurcharge(fareSurcharge?.surcharge);
		setFormValues(fareSurcharge);
	}, [fareSurcharge?.id]);

	const handleInputChange = (value) => {
		setSurcharge(value);
		setFormValues((current) => {
			const newData = { ...current, surcharge: value };
			handleChangeFormsData(newData);
			return newData;
		});
	};
	// handle datetime change
	const onChange = (value, timeString, name) => {
		if (name === 'day') {
			setDay(daysOfWeek[value]);
			setFormValues((current) => {
				const newData = { ...current, [name]: daysOfWeek[value] };
				handleChangeFormsData(newData);
				return newData;
			});
		} else
			setFormValues((current) => {
				const newData = { ...current, [name]: timeString };
				handleChangeFormsData(newData);
				return newData;
			});
		if (name === 'startTime') setStartTime(dayjs(value));
		if (name === 'endTime') setEndTime(dayjs(value));
	};

	const handleChangeFormsData = (data) => {
		if (data) {
			const updatedObject = {
				...faresSurcharges[index],
				day: data.day,
				startTime: data.startTime,
				endTime: data.endTime,
				surcharge: data.surcharge,
			};
			const newFaresSurcharges = [
				...faresSurcharges.slice(0, index),
				updatedObject,
				...faresSurcharges.slice(index + 1),
			];
			updateFaresSurcharge(newFaresSurcharges);
		}
	};
	return (
		<Col style={{ margin: 8 }}>
			<Card>
				<Form name='fare_surcharge' layout='vertical' autoComplete='off'>
					<Form.Item label='Day'>
						<Select
							size='medium'
							showArrow
							allowClear
							optionLabelProp='label'
							popupMatchSelectWidth={false}
							name='day'
							onChange={(value) => onChange(value, '', 'day')}
							value={day}>
							{daysOfWeek.map((p, index) => (
								<Select.Option key={index} label={p}>
									{p}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item label='Start date time'>
						<TimePicker
							showTime={{ format: 'HH:mm' }}
							onChange={(time, timeString) => onChange(time, timeString, 'startTime')}
							value={startTime}
							format='HH:mm'
						/>
					</Form.Item>
					<Form.Item label='End date time'>
						<TimePicker
							showTime={{ format: 'HH:mm' }}
							onChange={(time, timeString) => onChange(time, timeString, 'endTime')}
							value={endTime}
							format='HH:mm'
						/>
					</Form.Item>
					<Form.Item label='Surcharge'>
						<InputNumber
							name='surcharge'
							min={1} // Set the minimum value to 1
							step={0.1} // Set the step to allow decimal values
							onChange={(e) => handleInputChange(e)}
							value={surcharge}
						/>
					</Form.Item>
				</Form>
			</Card>
		</Col>
	);
};
