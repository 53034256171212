/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-nested-ternary */

/* eslint-disable no-shadow */
import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Modal, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import { getBase64 } from '../../../Utils/ArrayToObject';

const allowedImageTypes = 'image/png,image/jpeg,image/jpg,image/svg+xml';

const uploadButton = (
	<div>
		<PlusOutlined />
		<div style={{ marginTop: 8 }}>Upload</div>
	</div>
);
const UploadComponent = ({ fileList, setFileList, setIsFileChosen, allowedTypes, withoutCrop }) => {
	const [previewOpen, setPreviewOpen] = useState(false);
	const [previewImage, setPreviewImage] = useState('');
	const [previewTitle, setPreviewTitle] = useState('');
	const handlePreview = async (file) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj);
		}
		setPreviewImage(file.url || file.preview);
		setPreviewOpen(true);
		setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
	};
	const handleUploadChange = ({ fileList: newFileList }) => {
		setFileList(newFileList);
	};
	const [previewUrl, setPreviewUrl] = useState('');

	const dummyRequest = ({ onSuccess }) => {
		setTimeout(() => {
			onSuccess('ok');
		}, 0);
	};
	const handleDownload = (file) => {
		// Create a virtual anchor element
		const link = document.createElement('a');
		link.href = file.url;
		link.target = '_blank'; // Open the link in a new tab
		link.download = file.name; // Set the filename for the downloaded file
		document.body.appendChild(link);

		// Trigger a click event on the anchor element
		link.click();

		// Clean up by removing the anchor element
		document.body.removeChild(link);
	};
	const props = {
		onRemove: (file) => {
			const newFileList = fileList.filter((item) => item.uid !== file.uid);
			setFileList(newFileList);
		},
		onPreview: handlePreview,
		accept: 'image/*',
		beforeUpload: (file) => {
			// Read the file and set the preview URL
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => {
				setPreviewUrl(reader.result);
			};
			return false; // Prevent default upload behavior
		},

		listType: 'picture-card',
		fileList,
		onChange: (fileList) => {
			setIsFileChosen(true);
			handleUploadChange(fileList);
		},
		maxCount: 1,
		showPreview: true,
		onDownload: handleDownload,
		showUploadList: {
			showDownloadIcon: true,
		},
	};

	const handleCancel = () => setPreviewOpen(false);
	return (
		<>
			{withoutCrop ? (
				<Upload {...props} listType='picture-card' customRequest={dummyRequest}>
					{fileList.length === 0 ? uploadButton : null}
				</Upload>
			) : (
				<ImgCrop showGrid rotationSlider aspectSlider showReset>
					<Upload
						{...props}
						listType='picture-card'
						customRequest={dummyRequest}
						accept={allowedTypes || allowedImageTypes}>
						{fileList.length === 0 ? uploadButton : null}
					</Upload>
					{allowedTypes === 'image/svg+xml' && previewUrl && (
						<div style={{ marginTop: '10px' }}>
							<h4>Image Preview:</h4>
							<img src={previewUrl} alt='Preview' style={{ maxWidth: '100%' }} />
						</div>
					)}
				</ImgCrop>
			)}
			<Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
				<img
					alt='example'
					style={{
						width: '100%',
					}}
					src={previewImage}
				/>
			</Modal>
		</>
	);
};

export default UploadComponent;
