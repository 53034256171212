import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Breadcrumb, Button, Input, Typography, message } from 'antd';
import { MinusOutlined, ReloadOutlined } from '@ant-design/icons';
import { deletePurchasedBundle, getAllPurchasedBundles } from '../../../redux/purchasedBundleSlice';
import { style } from '../../../Styles';
import { formatEnumValue } from '../../../Utils/ArrayToObject';
import ActionButton from '../../Global/ActionsButton';
import CustomTableComponents from '../../Global/CustomTableComponents';
import PurchasedBundleDrawer from './PurchasedBundleDrawer';
import { formatDateAndTime } from '../../../Utils/date-utils';
import ImageComponent from '../../Global/ImageComponent/ImageComponent';
import CustomAvatar from '../../Global/CustomAvatar/CustomAvatar';

const { Text } = Typography;

const PurchasedBundle = () => {
	const { purchasedBundles } = useSelector((state) => state.purchasedBundles);
	const { bundles } = useSelector((state) => state.bundles);
	const [filter, setFilter] = useState('');
	const data = purchasedBundles?.purchasedBundlesList
		? Object.values(purchasedBundles?.purchasedBundlesList)
		: [];
	const filtredData = data.filter((item) => {
		return (
			item?.code.toLowerCase()?.indexOf(filter.toLowerCase()) !== -1 ||
			item?.buyerEmail.toLowerCase()?.indexOf(filter.toLowerCase()) !== -1 ||
			item?.buyerName.toLowerCase()?.indexOf(filter.toLowerCase()) !== -1 ||
			item?.buyerSurname.toLowerCase()?.indexOf(filter.toLowerCase()) !== -1 ||
			item?.status.toLowerCase()?.indexOf(filter.toLowerCase()) !== -1
		);
	});

	const [isDrawerOpened, setIsDrawerOpened] = useState(false);
	const [purchasedBundleId, setPurchasedBundleId] = useState(null);
	const dispatch = useDispatch();
	const fetchPurchasedBundles = () => {
		dispatch(getAllPurchasedBundles());
	};

	const closeDrawer = () => {
		setIsDrawerOpened(false);
		setPurchasedBundleId(null);
	};

	const openDrawer = (record) => {
		setIsDrawerOpened(true);
		if (record) setPurchasedBundleId(record.id);
	};

	const handleDeletePurchasedBundle = (id) => {
		dispatch(deletePurchasedBundle(id))
			.unwrap()
			.then(() => {
				message.success('Purchased Bundle deleted successfully');
			})
			.catch(() => {
				message.error('Error occurred while deleting Purchased bundle');
			});
	};

	// eslint-disable-next-line consistent-return
	const getSpanStyle = (status) => {
		switch (status) {
			case 'USED':
				return style.statusCompleted;
			case 'CODE_EXPIRED':
				return style.statusCancelled;
			case 'PENDING':
				return style.statusPending;
			case 'IN_PROGRESS':
				return style.statusInProgress;
			case 'ACCEPTED':
				return style.statusAccepted;
			default:
				break;
		}
	};

	const getBundleName = (id) => {
		const bundle = bundles?.bundleList.find((item) => item.id === id);
		return bundle?.name;
	};

	const columns = [
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id',
		},
		{
			title: 'Code',
			key: 'code',
			render: (record) => {
				return (
					<Text copyable code>
						{record?.code}
					</Text>
				);
			},
		},
		{
			title: 'Code valid until',
			key: 'codeValideUntil',
			render: (record) => {
				return record.codeValidUntil ? (
					<span style={style.dataContainer}>
						{formatDateAndTime(record.codeValidUntil)}
					</span>
				) : (
					<MinusOutlined />
				);
			},
		},
		{
			title: 'Start Date',
			key: 'startDate',
			render: (record) => {
				return (
					<span style={style.dataContainer}>{formatDateAndTime(record.startDate)}</span>
				);
			},
		},
		{
			title: 'End Date',
			key: 'endDate',
			render: (record) => {
				return (
					<span style={style.dataContainer}>{formatDateAndTime(record?.endDate)}</span>
				);
			},
		},
		{
			title: 'Status',
			key: 'status',
			render: (record) => {
				return (
					<span style={style.dataContainer}>
						<div style={getSpanStyle(record?.status)}>
							{formatEnumValue(record.status)}
						</div>
					</span>
				);
			},

			width: 90,
		},

		{
			title: 'Bundle Name',
			key: 'bundle',
			render: (record) => {
				// value.allowedDays is a string separated by comma, i want to convert it to a list of days of the week
				const bundleName = getBundleName(record?.bundleId);
				return bundleName ? (
					<span style={style.tags}>{formatEnumValue(bundleName)}</span>
				) : (
					<MinusOutlined />
				);
			},
		},
		{
			title: 'Rider',
			key: 'rider',
			render: (record) => {
				const fullName = `${record?.user?.name} ${record?.user?.surname}`;
				return record?.user ? (
					<div style={{ display: 'flex', alignItems: 'center' }}>
						{record?.user?.profilePicture ? (
							<ImageComponent size={40} imageId={record?.user?.profilePicture} />
						) : (
							<CustomAvatar name={fullName} type='initials' />
						)}
						<span>{`${fullName} `}</span>{' '}
						{!record.user?.active ? (
							<span
								style={{ color: 'GrayText', fontSize: '12px', marginLeft: '2px' }}>
								{' '}
								(Inactive)
							</span>
						) : null}
					</div>
				) : (
					<MinusOutlined />
				);
			},
		},
		{
			title: 'Buyer Email',
			key: 'buyerEmail',
			render: (record) => {
				return record?.buyerEmail ? (
					<Text copyable code>
						{record?.buyerEmail}
					</Text>
				) : (
					<MinusOutlined />
				);
			},
		},
		{
			title: 'Buyer Full Name',
			key: 'buyerFullName',
			render: (record) => {
				return record?.buyerName && record?.buyerName ? (
					<span>
						{record?.buyerName} {record?.buyerSurname}
					</span>
				) : (
					<MinusOutlined />
				);
			},
		},
		{
			title: 'Buyer Phone',
			key: 'buyerPhone',
			render: (record) => {
				return record?.buyerPhone ? (
					<Text copyable code>
						{record?.buyerPhone}
					</Text>
				) : (
					<MinusOutlined />
				);
			},
		},
		{
			title: 'Actions',
			key: 'actions',
			render: (text, record) => (
				<ActionButton
					record={record}
					onDelete={() => handleDeletePurchasedBundle(record.id)}
					onEdit={() => openDrawer(record)}
				/>
			),
		
		},
	];

	useEffect(() => {
		fetchPurchasedBundles();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	return (
		<>
			<div style={style.inlineContent}>
				<div
					style={{
						display: 'flex',
						alignContent: 'center',
						alignItems: 'center',
						float: 'left',
						marginBottom: 8,
					}}>
					<Breadcrumb
						style={{ margin: '0 3em 0 0', fontSize: '1.1em' }}
						items={[
							{
								title: 'Purchased Bundles',
							},
						]}
					/>
					<Button type='default' size='small' onClick={() => fetchPurchasedBundles()}>
						<ReloadOutlined />
					</Button>
				</div>

				<div
					style={{
						...style.inlineContent,
						float: 'right',
						marginBottom: 8,
					}}>
					<Input.Search
						placeholder='Search'
						style={{ margin: '0 8px 8px 0' }}
						value={filter}
						onChange={(e) => setFilter(e.target.value)}
					/>

					<Button
						type='default'
						style={{ float: 'right', marginBottom: 8 }}
						onClick={() => openDrawer()}>
						Add purchased Bundle
					</Button>
				</div>
			</div>
			<CustomTableComponents
				isMakingApiCall={purchasedBundles?.loading}
				columns={columns}
				dataArray={filtredData}
				handleDoubleClickRow={(record) => {
					openDrawer(record);
				}}
				handleSelectedRow={() => {}}
			/>
			{isDrawerOpened && (
				<PurchasedBundleDrawer
					onClose={closeDrawer}
					purchasedBundleId={purchasedBundleId}
					dispatch={dispatch}
					setPurchasedBundleId={setPurchasedBundleId}
				/>
			)}
		</>
	);
};

export default PurchasedBundle;
