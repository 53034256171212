/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */

/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
import { Breadcrumb, Button, Card, Input, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReloadOutlined } from '@ant-design/icons';
import {
	deleteRestrictedArea,
	getAllRestrictedArea,
	updateRestrictedArea,
} from '../../redux/restrictedAreaSlice';
import { style } from '../../Styles';
import CustomTableComponents from '../Global/CustomTableComponents';
import RestrictedAreaDrawer from './RestrictedAreaDrawer';
import ActionButtonWithChangeStatus from '../Global/ActionButtonWithChangeStatus';

const RestrictedArea = ({ role }) => {
	const { auth } = useSelector((state) => state.auth);
	const [filter, setfilter] = useState('');
	const columns = [
		{
			title: 'Geofence Location',
			dataIndex: 'locationName',
			sorter: {
				compare: (a, b) => a.locationName - b.locationName,
				multiple: 5,
			},
		},
		{
			title: 'Restrict Area',
			key: 'restrictArea',
			render: (record) => {
				// get restrcied area separated by comma from record.restrictArea
				const restrciedArea = record.restrictArea.split(',');
				// then show them in tags depending on the value
				return (
					<span style={style.dataContainer}>
						{restrciedArea.map((item) => {
							return (
								<div
									key={item}
									style={{
										...(item === 'Pick Up'
											? style.statusActif
											: style.statusInactif),
										marginRight: 5,
									}}>
									{item}
								</div>
							);
						})}
					</span>
				);
			},
			sorter: {
				compare: (a, b) => a.restrictArea - b.restrictArea,
				multiple: 4,
			},
		},

		{
			title: 'Status',
			key: 'active',
			render: (record) => {
				return (
					<span style={style.dataContainer}>
						{record.active ? (
							<div style={style.statusActif}>ACTIVE</div>
						) : (
							<div style={style.statusInactif}>INACTIVE</div>
						)}
					</span>
				);
			},
			sorter: {
				compare: (a, b) => a.status - b.status,
				multiple: 1,
			},
			width: 150,
		},
		{
			title: 'Actions',
			key: 'actions',
			render: (text, record) => (
				<ActionButtonWithChangeStatus
					record={record}
					onDelete={handleDlete}
					onRowClick={() => handleSelectedRestrictedArea(record)}
					onChangeStatus={handleChangeStatus}
					recordName={record?.locationName}
				/>
			),

			disabled: !role?.includes('SUPER_ADMIN'),
		},
	];

	const handleChangeStatus = (record) => {
		setMakingApiCall(true);
		dispatch(
			updateRestrictedArea({
				restrictedAreaId: record.id,
				data: record,
			}),
		)
			.unwrap()
			.then(() => {
				message.success('Restricted Area updated successfully');
			})
			.catch(() => {
				message.error('Restricted Area updated failed');
			})
			.finally(() => {
				setMakingApiCall(false);
			});
	};

	const handleDlete = (id, calbback) => {
		setMakingApiCall(true);
		dispatch(
			deleteRestrictedArea({
				restrictedAreaId: id,
			}),
		)
			.then(() => {
				calbback();
				message.success('Restricted Ared deleted successfully');
			})
			.catch(() => {
				message.error('Restricted Ared deleted failed');
			})
			.finally(() => {
				onCloseDrawer();
				setMakingApiCall(false);
			});
	};

	const [isMakingApiCall, setMakingApiCall] = useState(true);
	const [displayDrawer, setDisplayDrawer] = useState(false);
	const [restrictedAreaId, setRestrictedAreaId] = useState(null);
	const dispatch = useDispatch();

	const { RestrictedArea } = useSelector((state) => state.restrictedArea);

	const handleSelectedRestrictedArea = (selectedRestrictedArea) => {
		setRestrictedAreaId(selectedRestrictedArea.id);
		showDrawer();
	};

	const onCloseDrawer = () => {
		setDisplayDrawer(false);
		setRestrictedAreaId(null);
	};

	const showDrawer = () => {
		setDisplayDrawer(true);
	};

	const fetchAll = () => {
		dispatch(getAllRestrictedArea())
			.unwrap()
			.then()
			.catch(() => {
				message.error('Restricted Area fetch failed');
			})
			.finally(() => {
				setMakingApiCall(false);
			});
	};
	useEffect(() => {
		fetchAll();
	}, [dispatch, auth.token]);

	let restrictedAreaArray = Object.values(RestrictedArea.restrictedAreaList);
	restrictedAreaArray = restrictedAreaArray.map((item) => {
		return { ...item, key: item.id };
	});
	const filtredData = restrictedAreaArray.filter((item) => {
		return (
			item.id.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
			item.restrictArea.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
			item.restrictType.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
			item.locationName.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1
		);
	});

	return (
		<>
			<Card
				style={{
					margin: 16,
					maxHeight: '90%', // Set maximum width
					height: 'auto', // Allow width to be dynamic
				}}>
				<div style={style.inlineContent}>
					<div
						style={{
							display: 'flex',
							alignContent: 'center',
							alignItems: 'center',
							float: 'left',
							marginBottom: 8,
						}}>
						<Breadcrumb
							style={{ margin: '0 3em 0 0', fontSize: '1.1em' }}
							items={[
								{
									title: 'Locations',
								},
								{
									title: 'Restricted Areas',
								},
							]}
						/>
						<Button
							type='default'
							size='small'
							// style={{ margin: '0 8px 8px 0' }}
							onClick={() => fetchAll()}>
							<ReloadOutlined />
						</Button>
					</div>

					<div
						style={{
							...style.inlineContent,
							float: 'right',
							marginBottom: 8,
						}}>
						<Input.Search
							placeholder='Search'
							style={{ margin: '0 8px 8px 0' }}
							value={filter}
							onChange={(e) => setfilter(e.target.value)}
						/>
						{role?.includes('SUPER_ADMIN') && (
							<Button
								type='default'
								style={{ float: 'right', marginBottom: 8 }}
								onClick={showDrawer}>
								Add Restricted Area
							</Button>
						)}
					</div>
				</div>
				<CustomTableComponents
					isMakingApiCall={isMakingApiCall}
					columns={columns.filter((item) => {
						return !item.disabled;
					})}
					dataArray={filtredData}
					handleSelectedRow={() => {}}
					handleDoubleClickRow={(record) =>
						role?.includes('SUPER_ADMIN') ? handleSelectedRestrictedArea(record) : {}
					}
				/>
			</Card>
			{displayDrawer && (
				<RestrictedAreaDrawer
					onClose={onCloseDrawer}
					restrictedAreaId={restrictedAreaId}
					handleDlete={handleDlete}
				/>
			)}
		</>
	);
};

export default RestrictedArea;
