/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { MinusOutlined, ReloadOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Card, Input, Typography, message } from 'antd';
import { useLoadScript } from '@react-google-maps/api';
import { getAllRefunds } from '../../redux/refundSlice';
import { formatDateAndTime } from '../../Utils/date-utils';
import ImageComponent from '../Global/ImageComponent/ImageComponent';
import CustomAvatar from '../Global/CustomAvatar/CustomAvatar';
import { style } from '../../Styles';
import CustomTableComponents from '../Global/CustomTableComponents';
import { formatEnumValue } from '../../Utils/ArrayToObject';
import { getSpanStyle, refundStatusStyle } from '../../Utils/style-depending-on-status';
import { refundReasonsLabels } from '../../Utils/AuditLogsDescriptions';
import DetailsModal from '../Booking/DetailsModal';
import { getBookingById, setSelectedBooking } from '../../redux/bookingSlice';
import { getRandomGoogleMapsKey } from '../../redux/googleMapsKeySlice';

const { Link } = Typography;
const libraries = ['core', 'places', 'geocoding', 'routes', 'geometry'];

const Refunds = ({ role }) => {
	const { Settings } = useSelector((state) => state.settings);

	const currencyRender = () => {
		return <span>{Settings?.settingsData?.currency || '€'}</span>;
	};
	const { googleMaps } = useSelector((state) => state.googleMapsKey);

	const { isLoaded } = useLoadScript({
		googleMapsApiKey: googleMaps?.googleMapsKey?.key,
		libraries,
	});
	const dispatch = useDispatch();
	const { refunds } = useSelector((state) => state.refunds);
	const [isModalBookingOpen, setIsModalBookingOpen] = useState(false);
	const { selectedBooking } = useSelector((state) => state.booking);
	// eslint-disable-next-line no-unused-vars
	const [selectedBookingId, setSelectedBookingId] = useState(null);
	const [filter, setFilter] = useState('');
	const fetchAllRefunds = () => {
		dispatch(getAllRefunds());
	};

	const fetchBookingDetailsById = (bookingId) => {
		dispatch(getBookingById(bookingId))
			.unwrap()
			.then(() => {
				setIsModalBookingOpen(true);
			})
			.catch((error) => {
				console.log('Error', error);
			});
	};

	const columns = [
		{
			title: 'Booking ID',
			key: 'bookingId',
			render: (record) => {
				return record?.bookingId ? (
					// eslint-disable-next-line jsx-a11y/anchor-is-valid
					<Link
						style={{ cursor: 'pointer' }}
						onClick={() => {
							fetchBookingDetailsById(record.bookingId);
						}}>
						{record?.bookingId}
					</Link>
				) : (
					<MinusOutlined />
				);
			},

			sorter: (a, b) => a.bookingId - b.bookingId,
		},
		{
			title: 'Amount',
			key: 'amountToRefund',
			render: (record) => {
				return record?.amountToRefund ? (
					<span>
						{currencyRender()}
						{`${record?.amountToRefund?.toFixed(2)}`}
					</span>
				) : (
					<MinusOutlined />
				);
			},
			sorter: (a, b) => a.amount - b.amount,
		},
		{
			title: 'Reason',
			key: 'refundReason',
			render: (record) => {
				return record?.refundReason ? (
					refundReasonsLabels(record?.refundReason)
				) : (
					<MinusOutlined />
				);
			},

			sorter: (a, b) => a.refundReason - b.refundReason,
		},
		{
			title: 'Description',
			key: 'description',
			render: (record) => {
				return record?.refundReasonDescription ? (
					record.refundReasonDescription
				) : (
					<MinusOutlined />
				);
			},

			sorter: (a, b) => a.refundReasonDescription - b.refundReasonDescription,
		},

		{
			title: 'Status',
			key: 'status',
			render: (record) => {
				return (
					<span style={style.dataContainer}>
						<div style={refundStatusStyle(record?.status)}>
							{formatEnumValue(record?.status)}
						</div>
					</span>
				);
			},
			sorter: {
				compare: (a, b) => a.status.localeCompare(b.status),
				multiple: 1,
			},
		},
		{
			title: 'Refunded On',
			key: 'date',
			render: (record) => {
				return record?.created ? formatDateAndTime(record?.created) : <MinusOutlined />;
			},
			sorter: (a, b) => {
				const dateA = new Date(a.created);
				const dateB = new Date(b.created);
				return dateA.getTime() - dateB.getTime();
			},
			width: '12%',
		},
		{
			title: 'Refunded By',
			key: 'refundedBy',
			render: (record) => {
				const fullName = `${record?.refundedBy?.name} ${record?.refundedBy?.surname} `;
				return record?.refundedBy ? (
					<div style={{ display: 'flex', alignItems: 'center' }}>
						{record?.refundedBy?.profilePicture ? (
							<ImageComponent
								size={40}
								imageId={record?.refundedBy?.profilePicture}
							/>
						) : (
							<CustomAvatar name={fullName} type='initials' />
						)}
						<span>{fullName}</span>
					</div>
				) : (
					<MinusOutlined />
				);
			},
		},
	];

	const fetchGoogleMapsKey = () => {
		dispatch(getRandomGoogleMapsKey())
			.then(() => {})
			.catch(() => {
				message.error('Error getting google maps key');
			});
	};

	useEffect(() => {
		fetchAllRefunds();
		fetchGoogleMapsKey();
	}, [dispatch]);

	const closeModalBooking = () => {
		dispatch(setSelectedBooking());
		setIsModalBookingOpen(false);
		setSelectedBookingId(null);
	};

	const filtredData = refunds?.refundList?.filter((item) => {
		return item.bookingId.toString().includes(filter);
	});

	return (
		<Card
			style={{
				margin: 16,
				maxHeight: '90%', // Set maximum width
				height: 'auto', // Allow width to be dynamic
			}}>
			<div
				style={{
					borderRadius: '17px',
					background: '#ffffff',
					marginTop: '1em',
				}}>
				<div style={style.inlineContent}>
					<div
						style={{
							display: 'flex',
							alignContent: 'center',
							alignItems: 'center',
							float: 'left',
							marginBottom: 8,
						}}>
						<Breadcrumb
							style={{ margin: '0 3em 0 0', fontSize: '1.1em' }}
							items={[
								{
									title: 'Refunds',
								},
							]}
						/>
						<Button type='default' size='small' onClick={() => fetchAllRefunds()}>
							<ReloadOutlined />
						</Button>
					</div>
					<div
						style={{
							...style.inlineContent,
							float: 'right',
							marginBottom: 8,
						}}>
						<Input.Search
							placeholder='Search with booking ID'
							style={{ margin: '0 8px 8px 0' }}
							value={filter}
							onChange={(e) => setFilter(e.target.value)}
						/>
					</div>
				</div>
				<CustomTableComponents
					isMakingApiCall={refunds?.loading}
					loading={refunds?.loading}
					columns={columns}
					dataArray={filtredData}
					handleDoubleClickRow={() => {}}
					handleSelectedRow={() => {}}
				/>
			</div>

			{isModalBookingOpen && (
				<DetailsModal
					isModalOpen={isModalBookingOpen}
					closeModal={closeModalBooking}
					bookingId={selectedBooking?.booking?.id}
					getSpanStyle={getSpanStyle()}
					isLoaded={isLoaded}
					role={role}
				/>
			)}
		</Card>
	);
};

export default Refunds;
