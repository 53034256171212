import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../axios';

const initialState = {
	tenantFeatures: [],
	globalFeatures: [],
};

export const fetchGlobalFeatures = createAsyncThunk(
	'TenantFeature/fetchGlobalFeatures',
	async () => {
		const featuresURL = `features`;
		try {
			const response = await axiosInstance.get(featuresURL);
			return response.data;
		} catch (error) {
			const customError = {
				name: 'Custom axios error',
				message: error.response.data.message,
			};
			throw customError;
		}
	},
);

export const fetchCurrentTenantFeatures = createAsyncThunk(
	'TenantFeature/fetchCurrentTenantFeatures',
	async () => {
		const featuresURL = `tenant_feature`;
		try {
			const response = await axiosInstance.get(featuresURL);
			return response.data;
		} catch (error) {
			const customError = {
				name: 'Custom axios error',
				message: error.response.data.message,
			};
			throw customError;
		}
	},
);

export const enableFeature = createAsyncThunk('TenantFeature/enableFeature', async (feature) => {
	const enableFeatureURL = `tenant_feature/${feature.id}`;
	try {
		await axiosInstance.post(enableFeatureURL);
		return feature;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const disableFeature = createAsyncThunk('TenantFeature/disableFeature', async (feature) => {
	const disableFeatureURL = `tenant_feature/${feature.id}`;
	try {
		await axiosInstance.delete(disableFeatureURL);
		return feature;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
		};
		throw customError;
	}
});

const tenantFeatureSlice = createSlice({
	name: 'TenantFeature',
	initialState,
	reducers: {},
	extraReducers: {
		[fetchCurrentTenantFeatures.fulfilled]: (state, action) => {
			state.tenantFeatures = action.payload;
		},
		[enableFeature.fulfilled]: (state, action) => {
			const foundFeature = state.tenantFeatures.find(
				(f) => f.feature.id === action.payload.id,
			);
			if (!foundFeature) {
				state.tenantFeatures.push(action.payload);
			}
		},
		[disableFeature.fulfilled]: (state, action) => {
			const foundFeature = state.tenantFeatures.find(
				(f) => f.feature.id === action.payload.id,
			);
			if (foundFeature) {
				const index = state.tenantFeatures.indexOf(foundFeature);
				state.tenantFeatures.splice(index, 1);
			}
		},
		[fetchGlobalFeatures.fulfilled]: (state, action) => {
			state.globalFeatures = Object.values(action.payload);
		},
	},
});

export default tenantFeatureSlice.reducer;
