/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import { Drawer, message, Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createLocation, getLocationById, updateLocation } from '../../redux/locationSlice';
import LocationForm from './LocationForm';
import ShowConfirmClose from '../Modals/ShowConfirmClose';

const LocationDrawer = ({ onClose, locationId, handleDeleteLocation }) => {
	const [unsavedChanges, setUnsavedChanges] = useState(false);
	const [makingApiCall, setMakingApiCall] = useState(0);
	const dispatch = useDispatch();
	const [location, setLocation] = useState(undefined);

	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const handleClose = useCallback(
		(hasUnsavedChanges) => {
			if (hasUnsavedChanges && !confirmationOpen) {
				setConfirmationOpen(true); // Mark that the confirmation modal is open
				ShowConfirmClose(() => {
					setConfirmationOpen(false); // Reset when the confirmation modal is closed
					onClose();
				});
			} else {
				onClose();
			}
		},
		[onClose, confirmationOpen],
	);

	const onSave = (values, coordinatesLocation) => {
		const coordinates =
			coordinatesLocation?.points !== undefined
				? coordinatesLocation?.points.map((item, index) => ({
						lat: item[0],
						lng: item[1],
						point_order: index + 1,
				  }))
				: coordinatesLocation;
		const data = { ...values, points: coordinates };

		handleSaveLocation(data);
	};

	const handleSaveLocation = (data) => {
		setMakingApiCall(true);
		data = { ...data, id: locationId };
		const action = locationId
			? updateLocation({
					location: data,
			  })
			: createLocation({
					location: data,
			  });
		dispatch(action)
			.unwrap()
			.then(() => {
				message.success('Geofence Location saved successfully');
				setUnsavedChanges(false);
			})
			.catch((e) => {
				if (e.message === 'LOCATION_NAME_ALREADY_EXISTS') {
					message.error('Location name already exists');
				} else message.error('Error saving Geofence Location');
			})
			.finally(() => {
				onClose();
				setMakingApiCall(false);
			});
	};
	const onSaveFailed = () => {};

	const handleDirty = useCallback(() => {
		setUnsavedChanges(true);
	}, []);

	const fetchLocation = () => {
		if (locationId) {
			setMakingApiCall(1);
			dispatch(
				getLocationById({
					locationId,
				}),
			)
				.unwrap()
				.then((res) => {
					if (locationId) {
						setLocation(res);
					}
				})
				.catch(() => {
					onClose();
				})
				.finally(() => {
					setMakingApiCall(2);
				});
		}
	};

	useEffect(() => {
		const abortController = new AbortController();
		if (locationId) fetchLocation();
		else setMakingApiCall(2);

		return () => abortController.abort();
	}, [locationId, dispatch]);

	return (
		<Drawer
			title={!locationId ? 'Add Geofence Location' : 'Update Geofence Location'}
			placement='right'
			onClose={() => handleClose(unsavedChanges)}
			open
			width={600}>
			<Spin size='large' spinning={makingApiCall === 1} style={{ marginTop: 64 }} delay={500}>
				{makingApiCall === 2 && (
					<LocationForm
						location={location}
						onSave={onSave}
						onSaveFailed={onSaveFailed}
						onDelete={handleDeleteLocation}
						onCancel={handleClose}
						onDirty={handleDirty}
						unsavedChanges={unsavedChanges}
						setUnsavedChanges={setUnsavedChanges}
					/>
				)}
			</Spin>
		</Drawer>
	);
};

export default LocationDrawer;
