/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReloadOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Card, Input, message } from 'antd';
import { deleteModel, getAllModels } from '../../redux/modelSlice';
import ActionButton from '../Global/ActionsButton';
import { style } from '../../Styles';
import CustomTableComponents from '../Global/CustomTableComponents';
import DrawerModel from './DrawerModel';

const Model = () => {
	const [filter, setfilter] = useState('');
	const [isMakingApiCall, setMakingApiCall] = useState(true);
	const [displayDrawer, setDisplayDrawer] = useState(false);

	const [selectedModel, setSelectedModel] = useState(null);

	const dispatch = useDispatch();

	const { Models } = useSelector((state) => state.model);
	const fetchAll = () => {
		dispatch(getAllModels())
			.unwrap()
			.then()
			.catch(() => {
				message.error('Error fetching models');
			})
			.finally(() => {
				setMakingApiCall(false);
			});
	};

	const showDrawer = () => {
		setDisplayDrawer(true);
	};
	const closeDrawer = () => {
		setSelectedModel(null);
		setDisplayDrawer(false);
	};

	const handleSelectedMake = (record) => {
		setSelectedModel(record);
		showDrawer();
	};
	const handleDelete = (id, calbback) => {
		setMakingApiCall(true);
		dispatch(deleteModel(id))
			.unwrap()
			.then(() => {
				message.success('Model deleted successfully');
				calbback();
			})
			.catch(() => {})
			.finally(() => {
				setMakingApiCall(false);
			});
	};

	useEffect(() => {
		fetchAll();
	}, [dispatch]);

	let makesArray = Object.values(Models.modelList);
	makesArray = makesArray.map((item) => {
		return { ...item, key: item.id };
	});

	const filtredData = makesArray.filter((item) => {
		return (
			item.id.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
			item.name.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1
		);
	});

	const columns = [
		{
			title: 'ID',
			dataIndex: 'id',
			sorter: {
				compare: (a, b) => a.id - b.id,
				multiple: 4,
			},
		},
		{
			title: 'Name',
			key: 'name',
			render: (record) => {
				return <span>{`${record.name}`}</span>;
			},
			sorter: {
				compare: (a, b) => a.name - b.name,
				multiple: 5,
			},
		},
		{
			title: 'Make',
			key: 'make',
			render: (record) => {
				return <span>{`${record?.make?.name}`}</span>;
			},
			sorter: {
				compare: (a, b) => a.make.name - b.make.name,
				multiple: 5,
			},
		},

		{
			title: 'Actions',
			key: 'actions',
			render: (text, record) => (
				<ActionButton
					record={record}
					onDelete={() => handleDelete(record.id)}
					onEdit={() => handleSelectedMake(record)}
				/>
			),
		},
	];
	return (
		<Card
			style={{
				margin: 16,
				maxHeight: '90%', // Set maximum width
				height: 'auto', // Allow width to be dynamic
			}}>
			<div style={style.inlineContent}>
				<div
					style={{
						display: 'flex',
						alignContent: 'center',
						alignItems: 'center',
						float: 'left',
						marginBottom: 8,
					}}>
					<Breadcrumb
						style={{ margin: '0 3em 0 0', fontSize: '1.1em' }}
						items={[
							{
								title: 'Vehicles',
							},
							{
								title: 'Vehicle Model',
							},
						]}
					/>

					<Button
						type='default'
						size='small'
						// style={{ margin: '0 8px 8px 0' }}
						onClick={() => fetchAll()}>
						<ReloadOutlined />
					</Button>
				</div>

				<div
					style={{
						...style.inlineContent,
						float: 'right',
						marginBottom: 8,
					}}>
					<Input.Search
						placeholder='Search'
						style={{ margin: '0 8px 8px 0' }}
						value={filter}
						onChange={(e) => setfilter(e.target.value)}
					/>
					<Button
						type='default'
						style={{ float: 'right', marginBottom: 8 }}
						onClick={showDrawer}>
						Add Model
					</Button>
				</div>
			</div>
			<CustomTableComponents
				isMakingApiCall={isMakingApiCall}
				columns={columns.filter((item) => {
					return !item.disabled;
				})}
				dataArray={filtredData}
				handleSelectedRow={() => {}}
				handleDoubleClickRow={(record) => handleSelectedMake(record)}
			/>
			{displayDrawer && <DrawerModel onClose={closeDrawer} modelId={selectedModel?.id} />}
		</Card>
	);
};

export default Model;
