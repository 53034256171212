/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Spin } from 'antd';
import { useSelector } from 'react-redux';
import AssigneCorporateAdmin from './AssigneCorporateAdmin';
import RidersList from './RidersList';
import DiscountComponent from './DiscountComponent';
import AllowedArea from './AllowedArea';
import Schedule from './Schedule';

const CorporateModal = ({ isModalOpen, title, onModalClose }) => {
	const { selectedCorporate } = useSelector((state) => state.corporates);
	const [modalWidth, setModalWidth] = useState('800px');
	let modalMaxWidth = 600; // Default width

	useEffect(() => {
		setModalWidth(modalWidth);
	}, [modalMaxWidth]); // Only re-run the effect if modalWidth changes
	const componentDependingOnTitle = () => {
		switch (title) {
			case 'Admins List':
				return <AssigneCorporateAdmin onModalClose={onModalClose} />;
			case 'Riders List':
				return <RidersList onModalClose={onModalClose} />;
			case 'Discount':
				return <DiscountComponent onModalClose={onModalClose} />;
			case 'Allowed Areas':
				return <AllowedArea onModalClose={onModalClose} />;
			case 'Schedule':
				modalMaxWidth = '1000px';
				return (
					<Schedule
						onModalClose={onModalClose}
						selectedCorporateId={selectedCorporate?.id}
					/>
				);
			default:
				setModalWidth(modalWidth); // Set modal width for default case
				return <p>Modal Content</p>;
		}
	};
	return (
		<Modal
			style={{ top: 10, width: modalWidth }}
			title={`${selectedCorporate?.corporate?.name} - ${title}`}
			onCancel={onModalClose}
			footer={null}
			width={modalWidth}
			open={isModalOpen}>
			<div style={{ width: '100%' }}>
				<Spin spinning={selectedCorporate?.loading}>{componentDependingOnTitle()}</Spin>
			</div>
		</Modal>
	);
};

CorporateModal.propTypes = {
	isModalOpen: PropTypes.bool.isRequired,
	title: PropTypes.string.isRequired,
	onModalClose: PropTypes.func.isRequired,
};

export default CorporateModal;
