/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
/* eslint-disable import/no-extraneous-dependencies */
import { Breadcrumb, Button, Card, Input, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReloadOutlined } from '@ant-design/icons';
import { deleteLocation, getAllLocation } from '../../redux/locationSlice';
import { style } from '../../Styles';
import ActionButton from '../Global/ActionsButton';
import CustomTableComponents from '../Global/CustomTableComponents';
import LocationDrawer from './LocationDrawer';

const Location = ({ role }) => {
	const [filter, setfilter] = useState('');
	const columns = [
		{
			title: 'Location Name',
			dataIndex: 'locationName',
			sorter: {
				compare: (a, b) => a.locationName - b.locationName,
				multiple: 5,
			},
		},
		{
			title: 'Country',
			dataIndex: 'country',
			sorter: {
				compare: (a, b) => a.country - b.country,
				multiple: 4,
			},
		},
		{
			title: 'Actions',
			key: 'actions',
			render: (text, record) => (
				<ActionButton
					record={record}
					onDelete={() => handleDeleteLocation(record?.id, () => {})}
					onEdit={() => handleSelectedLocation(record)}
					recordName={record.locationName}
				/>
			),
			
			disabled: !role?.includes('SUPER_ADMIN'),
		},
	];

	const handleDeleteLocation = (id, callback) => {
		setMakingApiCall(true);
		dispatch(
			deleteLocation({
				locationId: id,
			}),
		)
			.unwrap()
			.then(() => {
				callback();
				message.success('Location deleted successfully');
			})
			.catch(() => {})
			.finally(() => {
				onCloseDrawer();
				setMakingApiCall(false);
			});
	};
	const [isMakingApiCall, setMakingApiCall] = useState(true);
	const [displayDrawer, setDisplayDrawer] = useState(false);
	const [locationId, setLocationId] = useState(null);
	const dispatch = useDispatch();

	const { Location } = useSelector((state) => state.locations);

	const handleSelectedLocation = (selectedLocation) => {
		setLocationId(selectedLocation.id);
		showDrawer();
	};

	const onCloseDrawer = () => {
		setDisplayDrawer(false);
		setLocationId(null);
	};

	const showDrawer = () => {
		setDisplayDrawer(true);
	};

	const fetchAll = () => {
		setMakingApiCall(true);
		dispatch(getAllLocation())
			.unwrap()
			.then()
			.catch(() => {
				message.error('Error fetching locations');
			})
			.finally(() => {
				setMakingApiCall(false);
			});
	};

	useEffect(() => {
		fetchAll();
	}, [dispatch]);

	let locationArray = Object.values(Location.locationsList);
	locationArray = locationArray.map((item) => {
		return { ...item, key: item.id };
	});

	const filtredData = locationArray.filter((item) => {
		return (
			item.id.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
			item.country.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
			item.locationFor.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
			item.locationName.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1
		);
	});

	return (
		<>
			<Card style={{
					margin: 16,
					maxHeight: '90%', // Set maximum width
					height: 'auto', // Allow width to be dynamic
				}}>
				<div style={style.inlineContent}>
					<div
						style={{
							display: 'flex',
							alignContent: 'center',
							alignItems: 'center',
							float: 'left',
							marginBottom: 8,
						}}>
						<Breadcrumb
							style={{ margin: '0 3em 0 0', fontSize: '1.1em' }}
							items={[
								{
									title: 'Locations',
								},
								{
									title: 'Geofence Locations',
								},
							]}
						/>
						<Button
							type='default'
							size='small'
							// style={{ margin: '0 8px 8px 0' }}
							onClick={() => fetchAll()}>
							<ReloadOutlined />
						</Button>
					</div>

					<div
						style={{
							...style.inlineContent,
							float: 'right',
							marginBottom: 8,
						}}>
						<Input.Search
							placeholder='Search'
							style={{ margin: '0 8px 8px 0' }}
							value={filter}
							onChange={(e) => setfilter(e.target.value)}
						/>
						{role?.includes('SUPER_ADMIN') && (
							<Button
								type='default'
								style={{ float: 'right', marginBottom: 8 }}
								onClick={showDrawer}>
								Add Geofence Location
							</Button>
						)}
					</div>
				</div>
				<CustomTableComponents
					isMakingApiCall={isMakingApiCall}
					columns={columns.filter((item) => {
						return item.disabled !== true;
					})}
					dataArray={filtredData}
					handleSelectedRow={() => {}}
					handleDoubleClickRow={(record) =>
						role?.includes('SUPER_ADMIN') ? handleSelectedLocation(record) : {}
					}
				/>
			</Card>
			{displayDrawer && (
				<LocationDrawer
					onClose={onCloseDrawer}
					locationId={locationId}
					handleDeleteLocation={handleDeleteLocation}
				/>
			)}
		</>
	);
};

export default Location;
