/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
import React, { useContext, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router';
import Dashboard from './Dashboard/Dashboard';
import Driver from './Driver/Driver';
import Rider from './Rider/Rider';
import DriversVehicles from './DriversVehicles/DriversVehicles';
import VehicleType from './VehiclesType/VehicleType';
import Location from './Location/Location';
import RestrictedArea from './RestrictedArea/RestrictedArea';
import KmRangeFare from './KmRangeFare/KmRangeFare';
import LocationWiseFare from './LocationWiseFare/LocationWiseFare';
import WayPoints from './WayPoints/WayPoints';
import Profile from './Profile/Profile';
import Users from './Users/Users';
import SettingsScreen from './Settings/Settings';
import Booking from './Booking/Booking';
import FleetOperator from './FleetOperators/FleetOperator';
import NotFound from './notFound/NotFound';
import NavBar from './NavBar';
import GodsView from './GodsView/GodsView';
import Make from './Mark/Make';
import Model from './Model/Model';
import BookingPool from './BookingPool/BookingPool';
import { TenantFeaturesContext } from '../Context/TenantFeatureContext';
import Corporate from './Corporate/Corporate';
import BundleMultiTab from './Bundle/BundleMultiTab';
import Refunds from './Refunds/Refunds';
import Reviews from './Reviews/Reviews';

const LoggedInArea = ({ roles }) => {
	const { fetchCurrentTenantFeatures } = useContext(TenantFeaturesContext);
	// eslint-disable-next-line no-unused-vars
	const navigate = useNavigate();
	useEffect(() => {
		fetchCurrentTenantFeatures();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<NavBar role={roles} />
			<Routes>
				<Route index element={<Dashboard role={roles} />} />
				<Route path='/driver' element={<Driver role={roles} />} />
				<Route path='/rider' element={<Rider />} />
				<Route path='/driversVehicles' element={<DriversVehicles />} />
				<Route path='/vehiclesType' element={<VehicleType role={roles} />} />
				<Route path='/vehicles/make' element={<Make role={roles} />} />
				<Route path='/vehicles/model' element={<Model role={roles} />} />
				<Route path='/location' element={<Location role={roles} />} />
				<Route path='/restrictedArea' element={<RestrictedArea role={roles} />} />
				<Route path='/km-range-fare' element={<KmRangeFare role={roles} />} />
				<Route path='/location-wise-fare' element={<LocationWiseFare role={roles} />} />
				<Route path='/way-points' element={<WayPoints role={roles} />} />
				<Route path='/profile' element={<Profile role={roles} />} />
				<Route path='/users' element={<Users role={roles} />} />
				<Route path='/settings' element={<SettingsScreen role={roles} />} />
				<Route path='/booking' element={<Booking role={roles} />} />
				<Route path='/booking/:id' element={<Booking role={roles} />} />

				<Route path='/booking-pool' element={<BookingPool role={roles} />} />
				<Route path='/fleet-operators' element={<FleetOperator role={roles} />} />
				<Route path='/*' element={<NotFound role={roles} />} />
				<Route path='/gods-view' element={<GodsView role={roles} />} />
				<Route path='/corporates' element={<Corporate role={roles} />} />
				<Route path='/bundles' element={<BundleMultiTab role={roles} />} />
				<Route path='/refunds' element={<Refunds role={roles} />} />
				<Route path='/reviews' element={<Reviews role={roles} />} />
			</Routes>
		</>
	);
};

export default LoggedInArea;
