/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React, { useState, useRef } from 'react';
import { CSVLink } from 'react-csv';
import { message, Modal, Upload, Button, Table, Empty, Spin } from 'antd';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { bulkUploadWayPoints, getAllWayPoints } from '../../redux/WayPointSlice';

const ImportCsvsDialog = ({ setVisible, name_exempleFile, name_file_error }) => {
	const [uploadedFiles, setUploadedFiles] = useState([]);
	const [uploading, setUploading] = useState(false);
	const dispatch = useDispatch();
	const headers = useRef([
		{ label: 'Line', key: 'line' },
		{ label: 'Error', key: 'error' },
	]);

	const props = {
		name: 'csvFile',
		action: '',
		accept: '.csv',
		showUploadList: false,
		onChange(info) {
			const { file } = info;
			if (file.status === 'done') {
				setUploading(true);
				dispatch(bulkUploadWayPoints(file.originFileObj))
					.unwrap()
					.then((result) => {
						const item = {
							file: file.originFileObj,
							status: file.status,
							name: file.name,
							response: result,
						};

						if (result?.processedRecordsCount === result?.importedCount) {
							message.success(`${file.name} file uploaded successfully.`);
							dispatch(getAllWayPoints());
						} else {
							message.warning(`${file.name} file uploaded with errors.`);
						}
						setUploadedFiles([...uploadedFiles, item]);
					})
					.catch((error) => {
						if (error === 'INVALID_CSV_FILE_HEADER') {
							message.error(`${file.name} is not in a valid format.`);
						} else {
							message.error(`${file.name} file upload failed. Please try again.`);
						}
					})
					.finally(() => {
						setUploading(false);
					});
			}
		},
	};

	const columns = [
		{
			title: 'Filename',
			key: 'name',
			render: (text, record) => {
				let color;
				if (record.status === 'error') {
					color = 'red';
				} else {
					const { response } = record;
					if (response?.processedRecordsCount === response?.importedCount) {
						color = 'green';
					} else {
						color = 'orange';
					}
				}
				return <span style={{ color }}>{record.name}</span>;
			},
		},
		{
			title: 'Records',
			render: (text, record) => {
				const { response } = record;
				return <span>{response?.processedRecordsCount}</span>;
			},
		},
		{
			title: 'Imported',
			render(text, record) {
				const { response } = record;
				return <span>{response?.importedCount}</span>;
			},
		},
		{
			title: 'Invalid',
			render(text, record) {
				const { response } = record;
				return <span>{response?.invalidCount}</span>;
			},
		},

		{
			title: 'Errors',
			key: 'errors',
			render: (text, record) => {
				if (!record?.response?.lineErrors) return null;
				const lineErrors = Object.keys(record.response.lineErrors).map((key) => ({
					line: key,
					error: record.response.lineErrors[key],
				}));
				const hasErrors = lineErrors.length > 0;
				if (hasErrors) {
					return (
						<div style={{ textAlign: 'center' }}>
							<CSVLink
								data={lineErrors}
								headers={headers.current}
								asyncOnClick
								filename={`${name_file_error}-import-errors-${new Date()
									.toISOString()
									.slice(0, 30)}.csv`}>
								<DownloadOutlined />
							</CSVLink>
						</div>
					);
				}
				return (
					<div style={{ textAlign: 'center' }}>
						<Button
							type='link'
							onClick={() => {
								message.info('No errors were encountered.');
							}}>
							<DownloadOutlined />
						</Button>
					</div>
				);
			},
		},
	];

	const dummyRequest = ({ onSuccess }) => {
		setTimeout(() => {
			onSuccess('ok');
		}, 0);
	};
	return (
		<Modal
			closable={false}
			onOk={() => {
				setVisible(false);
			}}
			okText='Done'
			cancelButtonProps={{ style: { display: 'none' } }}
			visible
			width={uploadedFiles?.length > 0 ? 720 : 720}>
			<Spin
				size='large'
				indicator={<LoadingOutlined style={{ fontSize: 54 }} spin />}
				tip={
					<div>
						<h2>Loading...</h2>
					</div>
				}
				spinning={uploading}>
				<span style={{ float: 'right' }}>
					<a href={`${process.env.PUBLIC_URL}/${name_exempleFile}`}>
						Download sample file
					</a>
				</span>
				<Upload {...props} customRequest={dummyRequest}>
					<Button loading={uploading}>Upload CSV File</Button>
				</Upload>
				<Table
					loading={uploading}
					dataSource={uploadedFiles}
					columns={columns}
					rowKey='uid'
					pagination={false}
					style={{ marginTop: 10 }}
					title={() => 'Uploaded files:'}
					locale={{ emptyText: <Empty description='No uploaded files' /> }}
				/>
			</Spin>
		</Modal>
	);
};

export default ImportCsvsDialog;
