/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */

import { Breadcrumb, Button, Card, Input, message } from 'antd';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReloadOutlined } from '@ant-design/icons';
import { style } from '../../Styles';
import CustomTableComponents from '../Global/CustomTableComponents';
import { deleteMake, getAllMakes } from '../../redux/makeSlice';
import DrawerMark from './DrawerMake';
import ActionButton from '../Global/ActionsButton';

const Make = () => {
	const [filter, setfilter] = useState('');
	const [isMakingApiCall, setMakingApiCall] = useState(true);
	const [displayDrawer, setDisplayDrawer] = useState(false);

	const [selectedMake, setSelectedMake] = useState(null);

	const dispatch = useDispatch();

	const { Makes } = useSelector((state) => state.make);
	const fetchAll = () => {
		dispatch(getAllMakes())
			.unwrap()
			.then()
			.catch(() => {
				message.error('Error fetching makes');
			})
			.finally(() => {
				setMakingApiCall(false);
			});
	};

	const showDrawer = () => {
		setDisplayDrawer(true);
	};
	const closeDrawer = () => {
		setSelectedMake(null);
		setDisplayDrawer(false);
	};

	const handleSelectedMake = (record) => {
		setSelectedMake(record);
		showDrawer();
	};
	const handleDelete = (id, calbback) => {
		setMakingApiCall(true);
		dispatch(deleteMake(id))
			.unwrap()
			.then(() => {
				message.success('Make deleted successfully');
				calbback();
			})
			.catch(() => {})
			.finally(() => {
				setMakingApiCall(false);
			});
	};

	useEffect(() => {
		fetchAll();
	}, [dispatch]);

	let makesArray = Object.values(Makes.makeList);
	makesArray = makesArray.map((item) => {
		return { ...item, key: item.id };
	});

	const filtredData = makesArray.filter((item) => {
		return (
			item.id.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
			item.name.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1
		);
	});

	const columns = [
		{
			title: 'ID',
			dataIndex: 'id',
			sorter: {
				compare: (a, b) => a.id - b.id,
				multiple: 4,
			},
		},
		{
			title: 'Name',
			key: 'name',
			render: (record) => {
				return <span>{`${record.name}`}</span>;
			},
			sorter: {
				compare: (a, b) => a.name - b.name,
				multiple: 5,
			},
		},

		{
			title: 'Actions',
			key: 'actions',
			render: (text, record) => (
				<ActionButton
					record={record}
					onDelete={() => handleDelete(record.id)}
					onEdit={() => handleSelectedMake(record)}
				/>
			),
		},
	];
	return (
		<Card
			style={{
				margin: 16,
				maxHeight: '90%', // Set maximum width
				height: 'auto', // Allow width to be dynamic
			}}>
			<div style={style.inlineContent}>
				<div
					style={{
						display: 'flex',
						alignContent: 'center',
						alignItems: 'center',
						float: 'left',
						marginBottom: 8,
					}}>
					<Breadcrumb
						style={{ margin: '0 3em 0 0', fontSize: '1.1em' }}
						items={[
							{
								title: 'Vehicles',
							},
							{
								title: 'Vehicle Make',
							},
						]}
					/>

					<Button
						type='default'
						size='small'
						// style={{ margin: '0 8px 8px 0' }}
						onClick={() => fetchAll()}>
						<ReloadOutlined />
					</Button>
				</div>

				<div
					style={{
						...style.inlineContent,
						float: 'right',
						marginBottom: 8,
					}}>
					<Input.Search
						placeholder='Search'
						style={{ margin: '0 8px 8px 0' }}
						value={filter}
						onChange={(e) => setfilter(e.target.value)}
					/>
					<Button
						type='default'
						style={{ float: 'right', marginBottom: 8 }}
						onClick={showDrawer}>
						Add Make
					</Button>
				</div>
			</div>
			<CustomTableComponents
				isMakingApiCall={isMakingApiCall}
				columns={columns.filter((item) => {
					return !item.disabled;
				})}
				dataArray={filtredData}
				handleSelectedRow={() => {}}
				handleDoubleClickRow={(record) => handleSelectedMake(record)}
			/>
			{displayDrawer && <DrawerMark onClose={closeDrawer} makeId={selectedMake?.id} />}
		</Card>
	);
};

export default Make;
