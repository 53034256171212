/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
import { Button, Form, Input, Select } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

const ModelForm = ({ onSave, onSaveFailed, model, onCancel, onDirty, unsavedChanges }) => {
	const { Makes } = useSelector((state) => state.make);
	const initialValues = {
		...model,
		makeId: model?.make?.id,
	};
	return (
		<Form
			name='Model'
			layout='vertical'
			autoComplete='off'
			initialValues={initialValues}
			onFinish={onSave}
			onFinishFailed={onSaveFailed}
			onValuesChange={() => onDirty()}>
			<Form.Item
				label='Name'
				name='name'
				rules={[{ required: true, message: 'Please input model name' }]}>
				<Input />
			</Form.Item>
			<Form.Item
				label='Make'
				name='makeId'
				rules={[{ required: true, message: 'Please select Vehicle Make' }]}>
				<Select
					size='medium'
					showArrow
					showSearch
					optionFilterProp='filterBy'
                    initialvalues={model?.make?.id}
					allowClear
					optionLabelProp='label'
					popupMatchSelectWidth={false}>
					{Makes.makeList?.map((make) => (
						<Select.Option
							key={make.id}
							filterBy={`${make.name}`}
							label={`${make.name}`}
							value={make.id}>
							{make.name}
						</Select.Option>
					))}
				</Select>
			</Form.Item>
			<Form.Item style={{ float: 'right' }}>
				<Button
					type='default'
					style={{ marginBottom: 32, marginRight: 8 }}
					onClick={() => onCancel(unsavedChanges)}>
					Cancel
				</Button>
				<Button
					type='primary'
					style={{ marginBottom: 32 }}
					htmlType='submit'
					disabled={!unsavedChanges}>
					{!model ? 'Save' : 'Update'}
				</Button>
			</Form.Item>
		</Form>
	);
};

export default ModelForm;
