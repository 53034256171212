import { Button, Card, Col, Row, Spin, Tooltip } from 'antd';
import React, { useState } from 'react';
import {
	BankOutlined,
	CarOutlined,
	TeamOutlined,
	InfoCircleOutlined,
	LoadingOutlined,
	ReloadOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import CustomCountingCard from './CustomCountingCard';
import { getSiteStatistics } from '../../redux/dashboardSlice';

const SiteStatistics = () => {
	const { dashboard } = useSelector((state) => state.dashboard);
	const { Settings } = useSelector((state) => state.settings);
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();

	const reload = () => {
		setLoading(true);
		dispatch(getSiteStatistics())
			.then(() => {})
			.catch((error) => {
				console.log('error', error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const currencyRender = () => {
		return <span>{Settings?.settingsData?.currency || '€'}</span>;
	};

	const navigate = useNavigate();

	return (
		<Spin
			size='large'
			indicator={<LoadingOutlined style={{ fontSize: 54 }} spin />}
			tip={
				<div>
					<h2>Loading...</h2>
				</div>
			}
			spinning={loading}>
			<Card
				type='inner'
				title='Site Statistics'
				extra={
					<div>
						<Tooltip title='This widget displays site statistics including the count of drivers, riders, bookings, and earnings.'>
							<InfoCircleOutlined />
						</Tooltip>

						<Button
							style={{
								marginLeft: '1em',
							}}
							type='default'
							size='small'
							onClick={() => reload()}>
							<ReloadOutlined />
						</Button>
					</div>
				}
				bordered={false}
				style={{
					width: '100%',
				}}>
				<Row gutter={16}>
					<Col
						style={{
							cursor: 'pointer',
						}}
						onClick={() => {
							navigate('/app/main/rider');
						}}
						span={12}>
						<CustomCountingCard
							title='Total Riders'
							count={dashboard?.siteStatistics?.numberOfRiders}
							currency=''
							icon={<TeamOutlined />}
						/>
					</Col>
					<Col
						style={{
							cursor: 'pointer',
						}}
						onClick={() => {
							navigate('/app/main/driver');
						}}
						span={12}>
						<CustomCountingCard
							title='Total Drivers'
							count={dashboard?.siteStatistics?.numberOfDrivers}
							currency=''
							icon={<CarOutlined />}
						/>
					</Col>
					<Col
						style={{
							cursor: 'pointer',
							marginTop: '0.5em',
						}}
						onClick={() => {
							navigate(
								'/app/main/gods-view',
								{ state: { selectedOption: 'AVAILABLE' } },
								{ replace: true },
							);
						}}
						span={12}>
						<CustomCountingCard
							title='Total Online Drivers'
							count={dashboard?.siteStatistics?.onlineDrivers}
							currency=''
							icon={<BankOutlined />}
						/>
					</Col>
					<Col
						style={{
							cursor: 'pointer',
							marginTop: '0.5em',
						}}
						onClick={() => {
							navigate('/app/main/booking');
						}}
						span={12}>
						<CustomCountingCard
							title='Total Earnings'
							count={dashboard?.siteStatistics?.totalEarnings}
							currency={currencyRender()}
							showPrecision
							icon={<BankOutlined />}
						/>
					</Col>
				</Row>
			</Card>
		</Spin>
	);
};

export default SiteStatistics;
