/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
import { Empty } from 'antd';
import React from 'react';

export const NoData = ({ height }) => {
	return (
		<div
			style={{
				height: height || '100%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}>
			<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
		</div>
	);
};

export default NoData;
