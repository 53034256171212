/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */

import React, { useEffect } from 'react';
import { Modal, Upload, message, Button, Spin } from 'antd';
import {
	InboxOutlined,
	EyeOutlined,
	DeleteOutlined,
	PaperClipOutlined,
	LoadingOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import ModalShowPicture from './ImageComponent/ModalShowPicture';
import {
	downloadImage,
	removeFromDriverDocuments,
	removeFromVehicleDocuments,
	resetDocuments,
	setDriverIdDocuments,
	setDriverLicenceDocuments,
	setDriverPoliceConductDocuments,
	setDriverTagDocuments,
	setVehicleInsuranceDocuments,
	setVehicleLicenceDiscDocuments,
	setVehicleLicencePlateDocuments,
} from '../../redux/fileSlice';

const { Dragger } = Upload;

const UploadDocumentsModal = ({
	visible,
	setVisible,
	title,
	onUpload,
	isUploadingMultiDocuments,
	documentList,
	setDocumentList,
	setTitle,
	componentType,
}) => {
	const { Brand } = useSelector((state) => state.brand);
	const { driverFetchedDocuments, vehicleFetchedDocuments } = useSelector((state) => state.files);

	const dispatch = useDispatch();
	const filterDriverDocuments = () => {
		let documents;
		if (title === 'Upload ID card Documents') {
			documents =
				driverFetchedDocuments?.driverDocuments?.filter(
					(doc) => doc.type === 'ID_CARD' || doc.documentType === 'ID_CARD',
				) || [];
			setDocumentList(documents);
			dispatch(setDriverIdDocuments(documents));
		}
		if (title === 'Upload Tag Documents') {
			documents =
				driverFetchedDocuments?.driverDocuments?.filter(
					(doc) => doc.type === 'TAG_DOCUMENT' || doc.documentType === 'TAG_DOCUMENT',
				) || [];
			setDocumentList(documents);
			dispatch(setDriverTagDocuments(documents));
		}
		if (title === 'Upload Driving Licence Documents') {
			documents =
				driverFetchedDocuments?.driverDocuments.filter(
					(doc) =>
						doc.type === 'DRIVER_LICENSE_DOCUMENT' ||
						doc.documentType === 'DRIVER_LICENSE_DOCUMENT',
				) || [];
			setDocumentList(documents);
			dispatch(setDriverLicenceDocuments(documents));
		}
		if (title === 'Upload Police Conduct Documents') {
			documents =
				driverFetchedDocuments?.driverDocuments.filter(
					(doc) =>
						doc.type === 'POLICE_CONDUCT_DOCUMENT' ||
						doc.documentType === 'POLICE_CONDUCT_DOCUMENT',
				) || [];
			setDocumentList(documents);
			dispatch(setDriverPoliceConductDocuments(documents));
		}
	};
	const filterVehicleDocuments = () => {
		let documents;
		if (title === 'Upload Vehicle Licence Plate Documents') {
			documents =
				vehicleFetchedDocuments?.vehicleFetchedDocumentsList?.filter(
					(doc) =>
						doc.type === 'VEHICLE_LICENSE_PLATE' ||
						doc.documentType === 'VEHICLE_LICENSE_PLATE',
				) || [];
			setDocumentList(documents);
			dispatch(setVehicleLicencePlateDocuments(documents));
		}
		if (title === 'Upload Vehicle Licence Disc Documents') {
			documents =
				vehicleFetchedDocuments?.vehicleFetchedDocumentsList.filter(
					(doc) =>
						doc.type === 'VEHICLE_LICENSE_DISK' ||
						doc.documentType === 'VEHICLE_LICENSE_DISK',
				) || [];
			setDocumentList(documents);
			dispatch(setVehicleLicenceDiscDocuments(documents));
		}
		if (title === 'Upload Vehicle Insurance Doc Documents') {
			documents =
				vehicleFetchedDocuments?.vehicleFetchedDocumentsList.filter(
					(doc) =>
						doc.type === 'VEHICLE_INSURANCE_DOCUMENT' ||
						doc.documentType === 'VEHICLE_INSURANCE_DOCUMENT',
				) || [];
			setDocumentList(documents);
			dispatch(setVehicleInsuranceDocuments(documents));
		}
	};

	useEffect(() => {
		if (componentType === 'VEHICLE') {
			filterVehicleDocuments();
		} else {
			filterDriverDocuments();
		}
		/* return () => {
			// Any clean-up code you want to run when the modal is closed
			console.log('ModalComponent is unmounting...');
			setDocumentList([]);
			dispatch(resetDocuments());
			setTitle('');
		}; */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, title, componentType, visible]);

	const handleUpload = (files) => {
		// Iterate over each selected file
		files.forEach((file) => {
			// Check file type
			const isImage = file.type.startsWith('image/');
			if (!isImage) {
				message.error('Only image files are allowed!');
				return;
			}

			// Check file size
			const isSizeValid = file.size / 1024 / 1024 < 2;
			if (!isSizeValid) {
				message.error('Maximum file size is 2MB!');
				return;
			}

			setDocumentList((prevFileList) => [...prevFileList, file]);
		});
	};

	const handleRemove = (file) => {
		const updatedFileList = documentList.filter((f) => f.uid !== file.uid);
		const filesToDelete = documentList.filter((f) => f.uid === file.uid);
		setDocumentList(updatedFileList);
		if (componentType === 'VEHICLE') {
			dispatch(removeFromVehicleDocuments(filesToDelete));
		} else {
			dispatch(removeFromDriverDocuments(filesToDelete));
		}
	};

	const handleCancel = () => {
		setVisible(false);
		setDocumentList([]);
		dispatch(resetDocuments());
		setTitle('');
	};

	const [previewImage, setPreviewImage] = React.useState(null);

	const handleView = (file) => {
		if (!file.oldFile) {
			setPreviewImage(URL.createObjectURL(file));
		} else {
			dispatch(downloadImage(file?.uid))
				.unwrap()
				.then((data) => {
					const imgUrl = URL.createObjectURL(data);
					setPreviewImage(imgUrl);
				});
		}
	};

	const handleUploadButtonClick = () => {
		const newDocumentList = documentList.filter((doc) => !doc.oldFile);
		if (newDocumentList.length === 0) {
			handleCancel();
			return;
		}
		const type = getTypeDependingOnTheComponent();
		onUpload({
			files: newDocumentList,
			type,
		});
	};

	const getTypeDependingOnTheComponent = () => {
		let type;

		switch (title) {
			case 'Upload ID card Documents':
				type = 'ID_CARD';
				break;
			case 'Upload Tag Documents':
				type = 'TAG_DOCUMENT';
				break;
			case 'Upload Driving Licence Documents':
				type = 'DRIVER_LICENSE_DOCUMENT';
				break;
			case 'Upload Police Conduct Documents':
				type = 'POLICE_CONDUCT_DOCUMENT';
				break;
			case 'Upload Vehicle Licence Plate Documents':
				type = 'VEHICLE_LICENSE_PLATE';
				break;
			case 'Upload Vehicle Licence Disc Documents':
				type = 'VEHICLE_LICENSE_DISK';
				break;
			default:
				type = 'VEHICLE_INSURANCE_DOCUMENT';
		}
		return type;
	};

	return (
		<Modal
			title={title}
			visible={visible}
			afterClose={() => {
				setDocumentList([]);
			}}
			onCancel={handleCancel}
			destroyOnClose
			footer={[
				<Button key='cancel' type='secondary' onClick={handleCancel}>
					Cancel
				</Button>,
				<Button
					key='upload'
					type='primary'
					disabled={isUploadingMultiDocuments}
					onClick={handleUploadButtonClick}>
					{documentList.filter((doc) => !doc.oldFile)?.length === 0
						? 'Save'
						: isUploadingMultiDocuments
						? 'Uploading'
						: 'Upload'}
				</Button>,
			]}>
			<Spin
				size='large'
				indicator={<LoadingOutlined style={{ fontSize: 54 }} spin />}
				tip={
					<div>
						<h2>Uploading in process...</h2>
					</div>
				}
				spinning={isUploadingMultiDocuments}>
				<Dragger
					accept='image/*'
					multiple
					beforeUpload={(files) => {
						handleUpload([files]);
						return false;
					}}
					showUploadList={false} // Add this line to hide the default preview
				>
					{/* Your custom preview */}
					<div className='custom-dragger-preview'>
						<p className='ant-upload-drag-icon'>
							<InboxOutlined />
						</p>
						<p className='ant-upload-text'>
							Click or drag image files to this area to upload
						</p>
						<p className='ant-upload-hint'>Support for a single or bulk upload.</p>
					</div>
				</Dragger>
			</Spin>
			{documentList?.map((file) => (
				<div key={file.uid} style={{ display: 'flex', alignItems: 'center' }}>
					<span
						style={{
							maxWidth: '70%',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							whiteSpace: 'wrap',
							marginRight: 'auto',
							color: Brand?.brandData?.webPrimary || '#ffa500',
						}}>
						<PaperClipOutlined style={{ marginRight: '4px' }} />
						{file.name || file.filename}
					</span>
					<Button
						type='link'
						icon={
							<EyeOutlined
								style={{ color: Brand?.brandData?.webPrimary || '#ffa500' }}
							/>
						}
						onClick={() => handleView(file)}
					/>
					<Button
						type='link'
						icon={
							<DeleteOutlined
								style={{ color: Brand?.brandData?.webPrimary || '#ffa500' }}
							/>
						}
						onClick={() => handleRemove(file)}
					/>
				</div>
			))}
			<ModalShowPicture
				open={previewImage}
				setOpen={setPreviewImage}
				imageURL={previewImage}
			/>
		</Modal>
	);
};

export default UploadDocumentsModal;
