// eslint-disable-next-line import/prefer-default-export
export const style = {
	tagsDriver: {
		display: 'inline-block',
		margin: '4px', // Adjust the margin to control the spacing between tags.
		padding: 4,
		backgroundColor: '#e6f7ff',
		borderColor: ' #91d5ff',
		borderStyle: 'solid',
		borderWidth: 1,
		color: '#1890ff',
		fontSize: 11,
		fontWeight: 'bold',
		borderRadius: 10,
	},
	dataContainer: {
		display: 'flex',
	},
	btnView: {
		display: 'flex',
		justifyContent: 'center',
	},
	statusActif: {
		padding: 4,
		width: 80,
		fontSize: 11,
		fontWeight: 'bold',
		borderRadius: 10,
		textAlign: 'center',
		color: '#13c2c2',
		backgroundColor: '#e6fffb',
		borderColor: '#87e8de',
		borderWidth: 1,
		borderStyle: 'solid',
	},
	statusInactif: {
		padding: 4,
		width: 80,
		fontSize: 11,
		fontWeight: 'bold',
		borderRadius: 10,
		textAlign: 'center',
		color: '#fa541c',
		backgroundColor: '#fff2e8',
		borderColor: ' #ffbb96',
		borderWidth: 1,
		borderStyle: 'solid',
	},
	tags: {
		padding: 4,
		width: 80,
		fontSize: 11,
		fontWeight: 'bold',
		borderRadius: 10,
		textAlign: 'center',
		margin: 4,
		paddingTop: 5,
		paddingLeft: 12,
		paddingBottom: 5,
		paddingRight: 12,
		color: '#1890ff',
		backgroundColor: '#e6f7ff',
		borderColor: ' #91d5ff',
		textTransform: 'uppercase',
		borderStyle: 'solid',
		borderWidth: 1,
	},
	headerModal: {
		marginTop: 16,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	inlineContent: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	centredContent: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	pageTitle: {
		fontSize: 18,
		marginBottom: 32,
		letterSpacing: 1.5,
	},
	// eslint-disable-next-line no-dupe-keys
	headerModal: {
		marginTop: 16,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	statusPending: {
		padding: 4,
		width: 80,
		fontSize: 11,
		fontWeight: 'bold',
		borderRadius: 10,
		textAlign: 'center',
		color: '#333333',
		backgroundColor: '#f9c74f',
		borderColor: '#f3722c',
		borderWidth: 1,
		borderStyle: 'solid',
	},
	statusInProgress: {
		padding: 4,
		width: 80,
		fontSize: 11,
		fontWeight: 'bold',
		borderRadius: 10,
		textAlign: 'center',
		color: '#333333',
		backgroundColor: '#90e0ef',
		borderColor: '#4E9AF1',
		borderWidth: 1,
		borderStyle: 'solid',
	},
	statusAccepted: {
		padding: 4,
		width: 80,
		fontSize: 11,
		fontWeight: 'bold',
		borderRadius: 10,
		textAlign: 'center',
		color: '#333333',
		backgroundColor: '#D5E8D4',
		borderColor: '#5CB85C',
		borderWidth: 1,
		borderStyle: 'solid',
	},
	statusCompleted: {
		padding: 4,
		width: 80,
		fontSize: 11,
		fontWeight: 'bold',
		borderRadius: 10,
		textAlign: 'center',
		color: '#333333',
		backgroundColor: '#80ed99',
		borderColor: '#4CAF50',
		borderWidth: 1,
		borderStyle: 'solid',
	},
	statusCancelled: {
		padding: 4,
		width: 80,
		fontSize: 11,
		fontWeight: 'bold',
		borderRadius: 10,
		textAlign: 'center',
		color: '#333333',
		backgroundColor: '#F2DEDE',
		borderColor: '#D9534F',
		borderWidth: 1,
		borderStyle: 'solid',
	},
	statusRequested: {
		padding: 4,
		width: 80,
		fontSize: 11,
		fontWeight: 'bold',
		borderRadius: 10,
		textAlign: 'center',
		color: '#333333',
		backgroundColor: '#E8D6EA',
		borderColor: '#B088C4',
		borderWidth: 1,
		borderStyle: 'solid',
	},

	statusTimedOut: {
		padding: 4,
		width: 80,
		fontSize: 11,
		fontWeight: 'bold',
		borderRadius: 10,
		textAlign: 'center',
		color: '#333333',
		backgroundColor: '#F78E69',
		borderColor: '#5D675B',
		borderWidth: 1,
		borderStyle: 'solid',
	},
	statusRefunded: {
		padding: 4,
		width: 80,
		fontSize: 11,
		fontWeight: 'bold',
		borderRadius: 10,
		textAlign: 'center',
		color: '#333333',
		backgroundColor: '#f0f0f0', // Light gray background
		borderColor: '#999999', // Dark gray border
		borderWidth: 1,
		borderStyle: 'solid',
	},
	CompanyMode: {
		padding: 4,
		width: 80,
		fontSize: 11,
		fontWeight: 'bold',
		textAlign: 'center',
		color: '#333333',
		backgroundColor: '#E8D6EA',
		borderColor: '#B088C4',
		borderWidth: 1,
		borderStyle: 'solid',
	},

	selfEmplyedMode: {
		padding: 4,
		width: 80,
		fontSize: 11,
		fontWeight: 'bold',
		textAlign: 'center',
		color: '#333333',
		backgroundColor: '#F78E69',
		borderColor: '#5D675B',
		borderWidth: 1,
		borderStyle: 'solid',
	},

	boxShadow: {
		borderRadius: '16px',
		background: '#ffffff',
		boxShadow: '-6px 6px 38px #cfcfcf,6px -6px 38px #ffffff',
	},
};
