import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../axios';

const initialState = {
	LocationsWiseFare: { loading: false, locationsWiseFareList: [] },
	SelectedLocationWiseFare: { loading: false, locationWiseFare: null },
};

export const getAllLocationsWiseFare = createAsyncThunk(
	'LocationWiseFare/getAllLcoationsWiseFare',
	async () => {
		try {
			const response = await axios.get(`locationsWiseFare`);
			return response.data;
		} catch (error) {
			const customError = {
				name: 'Custom axios error',
				message: error.response.data.message,
				/* data: error.response.data, */
			};
			throw customError;
		}
	},
);

export const getLocationWiseFareById = createAsyncThunk(
	'LocationWiseFare/getLocationWiseFareById',
	async (data) => {
		const { id } = data;
		try {
			const response = await axios.get(`locationWiseFare/${id}`);
			return response.data;
		} catch (error) {
			const customError = {
				name: 'Custom axios error',
				message: error.response.data.message,
				/* data: error.response.data, */
			};
			throw customError;
		}
	},
);

export const addNewLocationWiseFare = createAsyncThunk(
	'LocationWiseFare/addNewLocationWiseFare',
	async (data) => {
		const { locationWiseFare } = data;
		try {
			const response = await axios.post(`locationWiseFare`, {
				...locationWiseFare,
			});
			return response.data;
		} catch (error) {
			const customError = {
				name: 'Custom axios error',
				message: error.response.data.message,
				/* data: error.response.data, */
			};
			throw customError;
		}
	},
);

export const updateLocationWiseFare = createAsyncThunk(
	'LocationWiseFare/updateLocationWiseFare',
	async (data) => {
		const { locationWiseFare } = data;
		try {
			const response = await axios.put(`locationWiseFare/${data.id}`, {
				...locationWiseFare,
			});
			return response.data;
		} catch (error) {
			const customError = {
				name: 'Custom axios error',
				message: error.response.data.message,
				/* data: error.response.data, */
			};
			throw customError;
		}
	},
);

export const deleteLocationWiseFare = createAsyncThunk(
	'LocationWiseFare/deleteLocationWiseFare',
	async (data) => {
		const { id } = data;
		try {
			await axios.delete(`locationWiseFare/${id}`);
			return data.id;
		} catch (error) {
			const customError = {
				name: 'Custom axios error',
				message: error.response.data.message,
				/* data: error.response.data, */
			};
			throw customError;
		}
	},
);

const LocationWiseFareSlice = createSlice({
	name: 'LocationWiseFare',
	initialState,
	reducers: {},
	extraReducers: {
		[getAllLocationsWiseFare.pending]: (state) => {
			state.LocationsWiseFare.loading = true;
		},
		[getAllLocationsWiseFare.fulfilled]: (state, action) => {
			state.LocationsWiseFare.loading = false;
			state.LocationsWiseFare.locationsWiseFareList = Object.values(action.payload);
		},
		[getAllLocationsWiseFare.rejected]: (state) => {
			state.LocationsWiseFare.loading = false;
		},
		[getLocationWiseFareById.pending]: (state) => {
			state.SelectedLocationWiseFare.loading = true;
		},
		[getLocationWiseFareById.fulfilled]: (state, action) => {
			state.SelectedLocationWiseFare.loading = false;
			state.SelectedLocationWiseFare.locationWiseFare = action.payload;
		},
		[getLocationWiseFareById.rejected]: (state) => {
			state.SelectedLocationWiseFare.loading = false;
		},
		[addNewLocationWiseFare.pending]: (state) => {
			state.SelectedLocationWiseFare.loading = true;
		},
		[addNewLocationWiseFare.fulfilled]: (state, action) => {
			state.SelectedLocationWiseFare.loading = false;
			state.LocationsWiseFare.locationsWiseFareList.push(action.payload);
		},
		[addNewLocationWiseFare.rejected]: (state) => {
			state.SelectedLocationWiseFare.loading = false;
		},
		[updateLocationWiseFare.pending]: (state) => {
			state.SelectedLocationWiseFare.loading = true;
		},
		[updateLocationWiseFare.fulfilled]: (state, action) => {
			state.SelectedLocationWiseFare.loading = false;
			const index = state.LocationsWiseFare.locationsWiseFareList.findIndex(
				(locationWiseFare) => locationWiseFare.id === action.payload.id,
			);
			state.LocationsWiseFare.locationsWiseFareList[index] = action.payload;
		},
		[updateLocationWiseFare.rejected]: (state) => {
			state.SelectedLocationWiseFare.loading = false;
		},
		[deleteLocationWiseFare.pending]: (state) => {
			state.SelectedLocationWiseFare.loading = true;
		},
		[deleteLocationWiseFare.fulfilled]: (state, action) => {
			state.SelectedLocationWiseFare.loading = false;
			const index = state.LocationsWiseFare.locationsWiseFareList.findIndex(
				(locationWiseFare) => locationWiseFare.id === action.payload,
			);
			state.LocationsWiseFare.locationsWiseFareList.splice(index, 1);
		},
		[deleteLocationWiseFare.rejected]: (state) => {
			state.SelectedLocationWiseFare.loading = false;
		},
	},
});

export default LocationWiseFareSlice.reducer;
