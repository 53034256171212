import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../axios';

const initialState = {
	CarPoolingSetting: { loading: false, settingsData: null },
};

export const getCarPoolingSetting = createAsyncThunk(
	'CarPoolingSetting/getCarPoolingSetting',
	async () => {
		try {
			const response = await axios.get(`/car_pooling_setting`);
			return response.data;
		} catch (error) {
			if (
				error.response.status === 404 ||
				error.response.data.message === 'CAR_POOLING_SETTING_NOT_FOUND_FOR_THIS_TENANT'
			) {
				const customError = {
					name: 'CAR_POOLING_SETTING_NOT_FOUND_FOR_THIS_TENANT',
					message: error.response.data.message,
					data: error.response.data,
				};
				throw customError;
			} else {
				const customError = {
					name: 'Custom axios error',
					message: error.response.data.message,
					data: error.response.data,
				};
				throw customError;
			}
		}
	},
);

export const updateCarPoolingSetting = createAsyncThunk(
	'CarPoolingSetting/updateCarPoolingSetting',
	async (data) => {
		try {
			const response = await axios.post(`/car_pooling_setting`, data);
			return response.data;
		} catch (error) {
			const customError = {
				name: 'Custom axios error',
				message: error.response.data.message,
				/* data: error.response.data, */
			};
			throw customError;
		}
	},
);

export const carPoolingSlice = createSlice({
	name: 'CarPoolingSetting',
	initialState,
	reducers: {
		resetCarPoolingSettingsData: (state) => {
			state.CarPoolingSetting.settingsData = null;
		},
	},
	extraReducers: {
		[getCarPoolingSetting.pending]: (state) => {
			state.CarPoolingSetting.loading = true;
		},
		[getCarPoolingSetting.rejected]: (state) => {
			state.CarPoolingSetting.loading = false;
			// state.Settings.settingsData = null;
		},
		[getCarPoolingSetting.fulfilled]: (state, action) => {
			state.CarPoolingSetting.loading = false;
			state.CarPoolingSetting.settingsData = action.payload;
		},
		[updateCarPoolingSetting.pending]: (state) => {
			state.CarPoolingSetting.loading = true;
		},
		[updateCarPoolingSetting.rejected]: (state) => {
			state.CarPoolingSetting.loading = false;
		},
		[updateCarPoolingSetting.fulfilled]: (state, action) => {
			state.CarPoolingSetting.loading = false;
			state.CarPoolingSetting.settingsData = action.payload;
		},
	},
});
export const { resetSettingsData } = carPoolingSlice.actions;
export default carPoolingSlice.reducer;
