// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import { Button, Dropdown, Menu } from 'antd';
import React, { useState } from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import { MoreOutlined, EyeOutlined, RollbackOutlined } from '@ant-design/icons';

const ActionButtonWithRefund = ({ record, onSeeDetails, setSelectedBooking, onRefund }) => {
	const [menuVisible, setMenuVisible] = useState(false);
	const bookingStatus = record?.bookingStatus?.id;
	const arrayOfStatusAvailableForRefund = [4, 5];

	const handleMenuClick = ({ key }) => {
		// switch case depending on the key
		switch (key) {
			case 'details':
				setSelectedBooking(record);
				onSeeDetails(record);
				break;
			case 'refund':
				setSelectedBooking(record);
				if (arrayOfStatusAvailableForRefund.includes(bookingStatus)) {
					onRefund();
				}
				break;

			default:
				break;
		}
		setMenuVisible(false);
	};

	const menu = (
		<Menu onClick={handleMenuClick}>
			<Menu.Item key='details' icon={<EyeOutlined />}>
				Details
			</Menu.Item>
			{arrayOfStatusAvailableForRefund.includes(bookingStatus) &&
				!record?.bookingRefundHistories && (
					<Menu.Item key='refund' icon={<RollbackOutlined />}>
						Refund
					</Menu.Item>
				)}
		</Menu>
	);

	return (
		<Dropdown
			overlay={menu}
			trigger={['click', 'hover']}
			visible={menuVisible}
			onVisibleChange={(visible) => {
				setMenuVisible(visible);
			}}>
			<Button
				type='text'
				icon={<MoreOutlined />}
				onClick={(event) => event.stopPropagation()}
			/>
		</Dropdown>
	);
};

export default ActionButtonWithRefund;
