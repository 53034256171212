/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-unused-vars */

import { Avatar, Button, Divider, Form, Input, message, Select, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import {
	CheckOutlined,
	MinusOutlined,
	PhoneOutlined,
	MailOutlined,
	UserOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'antd/es/form/Form';
import {
	assigneUserToCorporate,
	checkIfUserExistByEmail,
	createCorporateAdmin,
} from '../../redux/userSlice';
import { countryPhoneCode } from '../../Utils/country-phone-codes';

import getCountryFromIP from '../../Utils/GettingCountryFromIP';
import ImageComponent from '../Global/ImageComponent/ImageComponent';
import CustomAvatar from '../Global/CustomAvatar/CustomAvatar';

const AssigneCorporateAdmin = ({ onModalClose }) => {
	const dispatch = useDispatch();
	const [userExist, setUserExist] = useState(false);
	const [userEmail, setUserEmail] = useState('');
	const [countryFromIp, setCountryFromIp] = useState('Malta');
	const [phoneCodeFromIp, setPhoneCodeFromIp] = useState('356');
	const { selectedCorporate } = useSelector((state) => state.corporates);
	const checkUserExist = () => {
		const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		if (!emailPattern.test(userEmail)) {
			message.error('Please enter a valid email address');
			return;
		}
		const encodedEmail = encodeURIComponent(userEmail); // encode the email because the value is sent in query param and it should be encoded to accept the symbols in email
		dispatch(checkIfUserExistByEmail(encodedEmail))
			.unwrap()
			.then((data) => {
				setUserExist(data);
			})
			.catch((error) => {
				if (error.message === 'USER_DOES_NOT_EXIST')
					message.warning(
						'User does not exist, you can add this user as a corporate admin',
					);
				setUserExist(null);
			});
	};

	const onEmailChange = (e) => {
		setUserEmail(e.target.value);
	};
	const [form] = useForm();

	useEffect(() => {
		getCountryFromIP().then((country) => {
			setCountryFromIp(country);
			// Find the corresponding phone code for the country
			const foundCountry = countryPhoneCode.find((c) => c.en === country);
			if (foundCountry) {
				form.setFieldsValue({
					phoneCountryCode: foundCountry.phoneCode,
					country: foundCountry.en,
				});
				setPhoneCodeFromIp(foundCountry.phoneCode);
				setCountryFromIp(foundCountry.en);
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onSave = () => {
		if (
			form.validateFields(['phoneCountryCode', 'country', 'name', 'surname', 'phone']) ===
			false
		) {
			form.validateFields(['phoneCountryCode', 'country', 'name', 'surname', 'phone']);
		}
		const values = {
			...form.getFieldsValue(),
			email: userEmail,
			corporateId: selectedCorporate?.corporate?.id,
		};

		const action =
			userExist == null ? createCorporateAdmin(values) : assigneUserToCorporate(values);
		dispatch(action)
			.unwrap()
			.then(() => {
				message.success('Corporate admin saved successfully');
				form.resetFields();
				setUserExist(false);
				setUserEmail('');
				onModalClose();
			})
			.catch(() => {
				message.error('Error saving corporate admin');
			});
	};

	return (
		<>
			<Divider orientation='left' orientationMargin='0'>
				List of assigned Corporate Admins
			</Divider>
			{selectedCorporate?.corporate?.admins?.length > 0 ? (
				<Avatar.Group
					maxCount={10}
					maxPopoverTrigger='hover'
					size='large'
					maxStyle={{
						color: '#f56a00',
						backgroundColor: '#fde3cf',
						cursor: 'pointer',
					}}
					style={{ display: 'flex', flexWrap: 'wrap' }}>
					{selectedCorporate?.corporate?.admins?.map((admin) => (
						<Tooltip
							key={admin.id} // Assuming admin has an unique identifier
							title={
								<div>
									<ul style={{ listStyleType: 'none', padding: 0 }}>
										<li style={{ display: 'flex', alignItems: 'center' }}>
											<UserOutlined style={{ marginRight: '8px' }} />
											{`${admin.name} ${admin.surname}`}
										</li>
										<li style={{ display: 'flex', alignItems: 'center' }}>
											<MailOutlined style={{ marginRight: '8px' }} />
											<span style={{ whiteSpace: 'pre-wrap', width: '90%' }}>
												{admin.email}
											</span>
										</li>
										<li style={{ display: 'flex', alignItems: 'center' }}>
											<PhoneOutlined style={{ marginRight: '8px' }} />
											{`+(${admin.phoneCountryCode}) ${admin.phone}`}
										</li>
									</ul>
								</div>
							}
							placement='top'>
							<>
								{admin.profilePicture ? (
									<ImageComponent size={35} imageId={admin?.profilePicture} />
								) : (
									<CustomAvatar
										name={`${admin.name} ${admin.surname}`}
										type='initials'
									/>
								)}
							</>
						</Tooltip>
					))}
				</Avatar.Group>
			) : (
				<MinusOutlined />
			)}

			<Divider orientation='left' orientationMargin='0'>
				Assign Corporate Admin
			</Divider>
			<Form
				form={form}
				name='corporate_admins'
				layout='vertical'
				autoComplete='off'
				onFinish={onSave}
				style={{ width: '100%', marginBottom: '10%' }}>
				<Form.Item
					label='Email'
					name='email'
					rules={[
						{
							required: true,
							message: 'Please input email address',
						},
					]}>
					<Input onChange={onEmailChange} style={{ width: '89%' }} />
					<Button
						type='primary'
						onClick={(values) => checkUserExist(values)}
						icon={<CheckOutlined />}
						style={{ width: '7%', marginLeft: '10px' }}
					/>
				</Form.Item>
				{userExist === false ? (
					<></>
				) : userExist ? (
					<Form.Item label='User Details'>
						<p>
							<strong>Name:</strong> {userExist?.name} {userExist?.surname}
						</p>
						<p>
							<strong>Email:</strong> {userExist?.email}
						</p>
						<p>
							<strong>Phone:</strong> {userExist?.phone}
						</p>
					</Form.Item>
				) : (
					<>
						<Form.Item
							label='Name'
							name='name'
							rules={[
								{
									required: true,
									message: 'Please input name',
								},
							]}>
							<Input />
						</Form.Item>
						<Form.Item
							label='Surname'
							name='surname'
							rules={[
								{
									required: true,
									message: 'Please input surname',
								},
							]}>
							<Input />
						</Form.Item>
						<Form.Item
							label='Country'
							name='country'
							initialValue={countryFromIp}
							rules={[{ required: true, message: 'Please select driver country' }]}>
							<Select
								size='medium'
								showArrow
								showSearch
								optionFilterProp='filterBy'
								allowClear
								onChange={(country) => {
									const foundCountry = countryPhoneCode.find(
										(c) => c.en === country,
									);
									if (foundCountry) {
										form.setFieldsValue({
											phoneCountryCode: foundCountry.phoneCode,
										});
										setPhoneCodeFromIp(foundCountry.phoneCode);
										setCountryFromIp(foundCountry.en);
									}
								}}
								optionLabelProp='label'>
								{countryPhoneCode.map((p) => (
									<Select.Option
										key={p.en}
										filterBy={`${p.en} +${p.phoneCode}`}
										value={p.en}
										label={
											<>
												<span role='img'>{p.emoji}</span>
												{`  ${p.en}`}
											</>
										}>
										<span role='img'>{p.emoji}</span>
										{`  ${p.en} `}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item
							label='Phone Country Code'
							name='phoneCountryCode'
							defaultValue={phoneCodeFromIp}
							rules={[
								{ required: true, message: 'Please select phone country code' },
							]}>
							<Select
								size='medium'
								showArrow
								showSearch
								optionFilterProp='filterBy'
								allowClear
								optionLabelProp='label'>
								{countryPhoneCode.map((p) => (
									<Select.Option
										key={p.en}
										filterBy={`${p.en} +${p.phoneCode}`}
										value={p.phoneCode}
										label={
											<>
												<span role='img'>{p.emoji}</span>
												{` +(${p.phoneCode})`}
											</>
										}>
										<span role='img'>{p.emoji}</span>{' '}
										<span style={{ color: 'gray' }}>{`+(${p.phoneCode})`}</span>
									</Select.Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item
							label='Phone'
							name='phone'
							rules={[{ required: true, message: 'Please input  phone number' }]}>
							<Input />
						</Form.Item>
					</>
				)}

				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
					<Button key='back' onClick={onModalClose}>
						Close
					</Button>
					<Button
						key='submit'
						type='primary'
						disabled={userExist === false}
						onClick={onSave}
						style={{ marginLeft: '10px' }}>
						Save
					</Button>
				</div>
			</Form>
		</>
	);
};

export default AssigneCorporateAdmin;
