/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
import { Button, Form, Input, Select, Spin } from 'antd';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { daysOfWeekWithCapitalValue } from '../../Utils/Constants/utils';

const BundleForm = ({
	onSave,
	bundle,
	bundleId,
	onSaveFailed,
	onCancel,
	onDirty,
	unsavedChanges,
}) => {
	const { selectedBundle } = useSelector((state) => state.bundles);
	const [form] = Form.useForm();
	useEffect(() => {
		if (selectedBundle?.bundle) {
			form.setFieldValue('allowedDays', selectedBundle.bundle.allowedDays.split(','));
			form.setFieldValue('name', selectedBundle.bundle.name);
			form.setFieldValue('numberOfRides', selectedBundle.bundle.numberOfRides);
			form.setFieldValue('numberOfDays', selectedBundle.bundle.numberOfDays);
			form.setFieldValue('price', selectedBundle.bundle.price);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bundle]);

	return bundleId && !bundle ? (
		<Spin spinning />
	) : (
		<Form
			form={form}
			name='corporate'
			layout='vertical'
			autoComplete='off'
			initialValues={bundle}
			onFinish={onSave}
			onFinishFailed={onSaveFailed}
			onValuesChange={() => onDirty()}>
			<Spin spinning={selectedBundle?.loading}>
				<Form.Item
					label='Name'
					name='name'
					rules={[{ required: true, message: 'Please input bundle name' }]}>
					<Input />
				</Form.Item>
				<Form.Item
					label='Number of Rides'
					name='numberOfRides'
					rules={[
						{ required: true, message: 'Please input number of rides' },

						{
							pattern: /^[1-9]\d*$/,
							message: 'Number of rides should be a whole number',
						},
					]}>
					<Input />
				</Form.Item>
				<Form.Item
					label='Number of Days'
					name='numberOfDays'
					rules={[
						{ required: true, message: 'Please input number of rides' },

						{
							pattern: /^[1-9]\d*$/,
							message: 'Number of rides should be a whole number',
						},
					]}>
					<Input />
				</Form.Item>
				<Form.Item
					label='Price'
					name='price'
					rules={[
						{
							required: true,
							message: 'Please input bundle price',
						},
						{
							pattern: /^(?:\d+(?:\.\d*)?|\.\d+)$/,
							message: 'Price must be a number',
						},
					]}>
					<Input />
				</Form.Item>
				<Form.Item
					label='Allowed Days'
					name='allowedDays'
					rules={[{ required: true, message: 'Please select days of the week' }]}>
					<Select mode='multiple' placeholder='Please select days of the week'>
						{daysOfWeekWithCapitalValue.map((day) => (
							<Select.Option key={day.value} value={day.value}>
								{day.label}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item style={{ float: 'right' }}>
					<Button
						type='default'
						style={{ marginBottom: 32, marginRight: 8 }}
						onClick={() => onCancel(unsavedChanges)}>
						Cancel
					</Button>
					<Button
						type='primary'
						style={{ marginBottom: 32 }}
						htmlType='submit'
						disabled={!unsavedChanges}>
						{!selectedBundle?.bundle ? 'Save' : 'Update'}
					</Button>
				</Form.Item>
			</Spin>
		</Form>
	);
};

export default BundleForm;
