// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, Col, Form, Input, Row, Spin, message } from 'antd';
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SaveOutlined, LoadingOutlined } from '@ant-design/icons';
import UploadComponent from '../Global/CustomAvatar/UploadComponent';
import { getTenantBrand, updateTenantBrand } from '../../redux/tenantBrandSlice';
import { downloadImage, uploadBrandLogo } from '../../redux/fileSlice';

const allowedSVGTypes = 'image/svg+xml';
const allowedFaviconTypes = 'image/x-icon,image/vnd.microsoft.icon';

const TenantBrandForm = () => {
	const { Brand } = useSelector((state) => state.brand);

	const [makingApiCall, setMakingApiCall] = useState(false);
	const [unsavedChanges, setUnsavedChanges] = useState(false);

	const [imageLists, setImageLists] = useState([
		{ type: 'webLogoList', list: [], uploadedId: null, isChosen: false },
		{ type: 'webIconList', list: [], uploadedId: null, isChosen: false },
		{ type: 'driverLogoList', list: [], uploadedId: null, isChosen: false },
		{ type: 'driverIconList', list: [], uploadedId: null, isChosen: false },
		{ type: 'riderLogoList', list: [], uploadedId: null, isChosen: false },
		{ type: 'riderIconList', list: [], uploadedId: null, isChosen: false },
	]);

	const updateChosenStateForType = (type) => {
		setImageLists((prevChosenStates) =>
			prevChosenStates.map((imageState) =>
				imageState.type === type ? { ...imageState, isChosen: true } : imageState,
			),
		);
	};

	const updateImageListForType = (type, newValue) => {
		setImageLists((previmageList) =>
			previmageList.map((imageList) =>
				imageList.type === type ? { ...imageList, list: newValue } : imageList,
			),
		);
	};

	const [uploading, setUploading] = useState(false);

	const handleUpload = (type) => {
		const fileList = imageLists.find((item) => item.type === type).list;
		const isChosen = imageLists.find((item) => item.type === type)?.isChosen;

		if (fileList.length !== 0 && isChosen) {
			return new Promise((resolve, reject) => {
				setUploading(true);
				setMakingApiCall(true);
				dispatch(uploadBrandLogo(fileList[0]?.originFileObj))
					.unwrap()
					.then((res) => {
						const findedItem = imageLists.find((item) => item.type === type);
						// change the uploadedId of the finded item
						findedItem.uploadedId = res.id;
						// now make clone of the imageLists
						const cloneImageLists = [...imageLists];
						// now find the index of the finded item
						const index = cloneImageLists.findIndex((item) => item.type === type);
						// now replace the finded item with the new finded item
						cloneImageLists[index] = findedItem;
						// now set the imageLists with the cloneImageLists
						setImageLists(cloneImageLists);
						setUploading(false);
						resolve(); // Resolve the promise when upload is successful
					})
					.catch((error) => {
						console.error('Upload failed:', error);
						setUploading(false);
						reject(error); // Reject the promise on upload failure
					})
					.finally(() => {
						setMakingApiCall(false);
					});
			});
		}
		// If no upload is needed, resolve the promise immediately
		return Promise.resolve();
	};
	const dispatch = useDispatch();
	const fetchTenantBrand = () => {
		setMakingApiCall(true);
		dispatch(getTenantBrand())
			.unwrap()
			.then(() => {})
			.catch(() => {
				message.error('An error occurred while fetching Tenant Brand');
			})
			.finally(() => {
				setMakingApiCall(false);
			});
	};

	const handleSaveTenantBrand = async (values) => {
		const uploadedPromises = imageLists.map((item) => handleUpload(item.type));
		setMakingApiCall(true);
		await Promise.all(uploadedPromises)
			.then(() => {
				saveTenantBrand(values);
			})
			.catch(() => {
				message.error('An error occurred while uploading images');
			})
			.finally(() => {
				setMakingApiCall(false);
			});
	};

	useEffect(() => {
		fetchTenantBrand();
	}, [dispatch]);

	const onSaveFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};
	const saveTenantBrand = async (values) => {
		const action = updateTenantBrand({
			...values,
			webLogo: imageLists.find((item) => item.type === 'webLogoList').isChosen
				? imageLists.find((item) => item.type === 'webLogoList').uploadedId
				: Brand.brandData.webLogo,
			webIcon: imageLists.find((item) => item.type === 'webIconList').isChosen
				? imageLists.find((item) => item.type === 'webIconList').uploadedId
				: Brand.brandData.webIcon,
			driverLogo: imageLists.find((item) => item.type === 'driverLogoList').isChosen
				? imageLists.find((item) => item.type === 'driverLogoList').uploadedId
				: Brand.brandData.driverLogo,

			driverIcon: imageLists.find((item) => item.type === 'driverIconList').isChosen
				? imageLists.find((item) => item.type === 'driverIconList').uploadedId
				: Brand.brandData.driverIcon,
			riderLogo: imageLists.find((item) => item.type === 'riderLogoList').isChosen
				? imageLists.find((item) => item.type === 'riderLogoList').uploadedId
				: Brand.brandData.riderLogo,
			riderIcon: imageLists.find((item) => item.type === 'riderIconList').isChosen
				? imageLists.find((item) => item.type === 'riderIconList').uploadedId
				: Brand.brandData.riderIcon,
		});

		await dispatch(action)
			.unwrap()
			.then(() => {
				message.success('Tenant Brand saved successfully');
				setUnsavedChanges(false);
			})
			.catch(() => {
				message.error('An error occurred while saving Tenant Brand');
			})
			.finally(() => {
				setMakingApiCall(false);
			});
	};
	const fetchImage = async (imageId) => {
		setMakingApiCall(true);

		return dispatch(downloadImage(imageId))
			.unwrap()
			.then((data) => {
				const imgUrl = URL.createObjectURL(data);
				setMakingApiCall(false);
				return imgUrl;
			})
			.catch((error) => {
				setMakingApiCall(false);
				throw error;
			});
	};

	const handleDirty = useCallback(() => {
		setUnsavedChanges(true);
	}, []);
	const onSave = async (values) => {
		await handleSaveTenantBrand(values);
	};
	const typeToFieldMap = {
		driverLogoList: 'driverLogo',
		driverIconList: 'driverIcon',
		riderLogoList: 'riderLogo',
		riderIconList: 'riderIcon',
		webLogoList: 'webLogo',
		webIconList: 'webIcon',
	};

	const fetchTenantBrandImages = () => {
		// Loop through each type in the mapping
		Object.keys(typeToFieldMap).forEach((type) => {
			const field = typeToFieldMap[type];
			const imageId = Brand?.brandData[field];
			if (imageId && imageId !== 0) {
				fetchImage(imageId).then((imgUrl) => {
					setImageLists((previmageList) => {
						const listIndex = previmageList.findIndex((item) => item.type === type);
						if (listIndex !== -1) {
							const updatedList = [...previmageList];
							updatedList[listIndex] = {
								...updatedList[listIndex],
								list: [
									{
										uid: '-1',
										name: 'image.png',
										status: 'done',
										url: imgUrl,
									},
								],
							};
							return updatedList;
						}
						return previmageList;
					});
				});
			}
		});
	};

	useEffect(() => {
		if (Brand.brandData) fetchTenantBrandImages();
	}, []);

	return (
		<Spin
			size='large'
			indicator={<LoadingOutlined style={{ fontSize: 54 }} spin />}
			tip={
				<div>
					<h2>Loading...</h2>
				</div>
			}
			spinning={makingApiCall}>
			<Form
				name='driver'
				layout='horizontal'
				labelCol={{ flex: '110px' }}
				labelAlign='left'
				labelWrap
				wrapperCol={{ flex: 1 }}
				colon={false}
				initialValues={Brand.brandData}
				onFinish={onSave}
				onFinishFailed={onSaveFailed}
				autoComplete='off'
				onValuesChange={() => handleDirty()}>
				<Row gutter={16}>
					<Col xs={24} sm={12} md={8} lg={8} xl={8}>
						<Card title='Web App' bordered={false}>
							<Form.Item
								name='webPrimary'
								label='Primary color'
								rules={[{ required: true, message: 'color is required!' }]}>
								<Input type='color' defaultValue='#FFAD01' />
							</Form.Item>
							<Form.Item
								name='webSecondary'
								label='Secondary color'
								rules={[{ required: true, message: 'color is required!' }]}>
								<Input type='color' defaultValue='#FFAD01' />
							</Form.Item>
							<div style={{ display: 'flex', justifyContent: 'space-around' }}>
								<div>
									<Form.Item
										label='Web Logo'
										name='webLogo'
										rules={[
											{ required: true, message: 'web logo is required!' },
										]}
										style={{ marginRight: '10px' }}>
										<Spin spinning={false}>
											<UploadComponent
												fileList={
													imageLists.find(
														(item) => item.type === 'webLogoList',
													).list
												}
												setFileList={(e) =>
													updateImageListForType('webLogoList', e)
												}
												handleUpload={() => handleUpload('webLogoList')}
												uploading={uploading}
												setIsFileChosen={() =>
													updateChosenStateForType('webLogoList', true)
												}
												allowedTypes={allowedSVGTypes}
											/>
										</Spin>
									</Form.Item>
								</div>
								<div>
									<Form.Item
										label='Web Icon'
										name='webIcon'
										rules={[
											{ required: true, message: 'web icon is required!' },
										]}
										style={{ marginLeft: '10px' }}>
										<Spin spinning={false}>
											<UploadComponent
												fileList={
													imageLists.find(
														(item) => item.type === 'webIconList',
													).list
												}
												setFileList={(e) =>
													updateImageListForType('webIconList', e)
												}
												handleUpload={() => handleUpload('webIconList')}
												uploading={uploading}
												setIsFileChosen={() =>
													updateChosenStateForType('webIconList', true)
												}
												allowedTypes={allowedFaviconTypes}
											/>
										</Spin>
									</Form.Item>
								</div>
							</div>
						</Card>
					</Col>
					<Col xs={24} sm={12} md={8} lg={8} xl={8}>
						<Card title='Driver App' bordered={false}>
							<Form.Item
								name='driverPrimary'
								label='Primary color'
								rules={[{ required: true, message: 'color is required!' }]}>
								<Input type='color' defaultValue='#FFAD01' />
							</Form.Item>
							<Form.Item
								name='driverSecondary'
								label='Secondary color'
								rules={[{ required: true, message: 'color is required!' }]}>
								<Input type='color' defaultValue='#FFAD01' />
							</Form.Item>
							<div style={{ display: 'flex', justifyContent: 'space-around' }}>
								<div>
									<Form.Item
										label='Driver Logo'
										name='driverLogo'
										rules={[
											{ required: true, message: 'driver logo is required!' },
										]}
										style={{ marginRight: '10px' }}>
										<Spin spinning={false}>
											<UploadComponent
												fileList={
													imageLists.find(
														(item) => item.type === 'driverLogoList',
													).list
												}
												setFileList={(e) =>
													updateImageListForType('driverLogoList', e)
												}
												handleUpload={() => handleUpload('driverLogoList')}
												uploading={uploading}
												setIsFileChosen={() =>
													updateChosenStateForType('driverLogoList', true)
												}
												allowedTypes={allowedSVGTypes}
											/>
										</Spin>
									</Form.Item>
								</div>
								<div>
									<Form.Item
										label='Driver Icon'
										name='driverIcon'
										rules={[
											{ required: true, message: 'driver icon is required!' },
										]}
										style={{ marginLeft: '10px' }}>
										<Spin spinning={false}>
											<UploadComponent
												fileList={
													imageLists.find(
														(item) => item.type === 'driverIconList',
													).list
												}
												setFileList={(e) =>
													updateImageListForType('driverIconList', e)
												}
												handleUpload={() => handleUpload('driverIconList')}
												uploading={uploading}
												setIsFileChosen={() =>
													updateChosenStateForType('driverIconList', true)
												}
												allowedTypes={allowedSVGTypes}
											/>
										</Spin>
									</Form.Item>
								</div>
							</div>
						</Card>
					</Col>
					<Col xs={24} sm={12} md={8} lg={8} xl={8}>
						<Card title='Rider App' bordered={false}>
							<Form.Item
								name='riderPrimary'
								label='Primary color'
								rules={[{ required: true, message: 'color is required!' }]}>
								<Input type='color' defaultValue='#FFAD01' />
							</Form.Item>
							<Form.Item
								name='riderSecondary'
								label='Secondary color'
								rules={[{ required: true, message: 'color is required!' }]}>
								<Input type='color' defaultValue='#FFAD01' />
							</Form.Item>
							<div style={{ display: 'flex', justifyContent: 'space-around' }}>
								<div>
									<Form.Item
										label='Rider Logo'
										name='riderLogo'
										rules={[
											{ required: true, message: 'rider logo is required!' },
										]}
										style={{ marginRight: '10px' }}>
										<Spin spinning={false}>
											<UploadComponent
												fileList={
													imageLists.find(
														(item) => item.type === 'riderLogoList',
													).list
												}
												setFileList={(e) =>
													updateImageListForType('riderLogoList', e)
												}
												handleUpload={() => handleUpload('riderLogoList')}
												uploading={uploading}
												setIsFileChosen={() =>
													updateChosenStateForType('riderLogoList', true)
												}
												allowedTypes={allowedSVGTypes}
											/>
										</Spin>
									</Form.Item>
								</div>
								<div>
									<Form.Item
										label='Rider Icon'
										name='riderIcon'
										rules={[
											{ required: true, message: 'rider icon is required!' },
										]}
										style={{ marginLeft: '10px' }}>
										<Spin spinning={false}>
											<UploadComponent
												fileList={
													imageLists.find(
														(item) => item.type === 'riderIconList',
													).list
												}
												setFileList={(e) =>
													updateImageListForType('riderIconList', e)
												}
												handleUpload={() => handleUpload('riderIconList')}
												uploading={uploading}
												setIsFileChosen={() =>
													updateChosenStateForType('riderIconList', true)
												}
												allowedTypes={allowedSVGTypes}
											/>
										</Spin>
									</Form.Item>
								</div>
							</div>
						</Card>
					</Col>
				</Row>
				<Row
					gutter={16}
					style={{
						margin: '15px 0 32px 32px ',
						display: 'flex',
						justifyContent: 'end',
						alignItems: 'center',
					}}>
					<Button
						disabled={!unsavedChanges}
						type='primary'
						style={{
							margin: '15px 0 32px 32px ',
							width: '8em',
						}}
						icon={<SaveOutlined />}
						htmlType='submit'>
						Save
					</Button>
				</Row>
			</Form>
		</Spin>
	);
};

export default TenantBrandForm;
