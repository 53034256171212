/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
import React from 'react';
import { Card, Badge, Typography } from 'antd';
import { PicCenterOutlined, MinusOutlined, UserOutlined } from '@ant-design/icons';
import { style } from '../../Styles';
import ImageComponent from '../Global/ImageComponent/ImageComponent';
import CustomAvatar from '../Global/CustomAvatar/CustomAvatar';

const { Meta } = Card;
const { Paragraph, Text } = Typography;

const DriverCard = ({ driver, width, setSelectedDriver, setZoom, zoom }) => {
	const onDriverClick = () => {
		console.log('Zoom', zoom);
		if (driver && setSelectedDriver && setZoom) {
			setSelectedDriver(driver);
			setZoom(15);
		}
	};
	return (
		<div
			style={{
				...style.boxShadow,
				width: width || '90%',
				margin: '0.5em',
				marginTop: '1em',
				cursor: 'pointer', // Add a pointer cursor to indicate it's clickable
			}}>
			<Meta
				onClick={onDriverClick}
				style={{ padding: '0.2em' }}
				avatar={
					<div style={{ margin: '40% 0 0 20%' }}>
						<Badge
							dot
							size='large'
							style={{
								fontSize: '15px',
								width: '15px',
								height: '15px',
								marginTop: '5px',
								marginRight: '12px',
							}}
							status={driver?.user?.available ? 'success' : 'error'}>
							{driver?.user?.profilePicture ? (
								<ImageComponent size={40} imageId={driver?.user?.profilePicture} />
							) : (
								<CustomAvatar
									size={40}
									name={`${driver?.user?.name} ${driver?.user?.surname}`}
									type='initials'
								/>
							)}
						</Badge>
					</div>
				}
				// title={`${driver?.user?.name} ${driver?.user?.surname}`}
				description={
					<div>
						<Text
							strong
							style={{
								fontSize: 13,
								marginBottom: '3px',
							}}>
							<UserOutlined className='site-result-demo-error-icon' />{' '}
							{`${driver?.user?.name} ${driver?.user?.surname}`}
						</Text>
						<p style={{ marginBottom: '3px', marginTop: '3px' }}>
							{driver?.user?.email}
						</p>
						<Paragraph style={{ marginTop: '3px' }}>
							<Text
								strong
								style={{
									fontSize: 13,
								}}>
								<PicCenterOutlined className='site-result-demo-error-icon' />{' '}
								Licence Plate:{' '}
							</Text>
							<Text
								code
								style={{
									fontSize: 16,
								}}>
								{driver?.vehicle ? (
									driver?.vehicle?.licencePlate
								) : (
									<MinusOutlined />
								)}
							</Text>
						</Paragraph>
					</div>
				}
			/>
		</div>
	);
};

export default DriverCard;
