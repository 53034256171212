/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react-hooks/exhaustive-deps */
import { Breadcrumb, Button, Input, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { ReloadOutlined, MinusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { style } from '../../Styles';
import CustomTableComponents from '../Global/CustomTableComponents';
import ActionButton from '../Global/ActionsButton';
import { deleteBundle, getAllBundles } from '../../redux/bundleSlice';
import BundleDrawer from './BundleDrawer';
import { formatEnumValue } from '../../Utils/ArrayToObject';

const Bundle = () => {
	const { bundles } = useSelector((state) => state.bundles);
	const [filter, setFilter] = useState('');
	const [isDrawerOpened, setIsDrawerOpened] = useState(false);
	const [bundleId, setBundleId] = useState(null);
	const dispatch = useDispatch();
	const fetchBundles = () => {
		dispatch(getAllBundles());
	};

	const closeDrawer = () => {
		setIsDrawerOpened(false);
		setBundleId(null);
	};

	const openDrawer = (record) => {
		setIsDrawerOpened(true);
		if (record) setBundleId(record.id);
	};

	const data = bundles?.bundleList ? Object.values(bundles?.bundleList) : [];
	const filtredData = data.filter((item) => {
		return item?.name.toLowerCase()?.indexOf(filter.toLowerCase()) !== -1;
	});
	const { Settings } = useSelector((state) => state.settings);

	const handleDelete = (id) => {
		dispatch(deleteBundle(id))
			.unwrap()
			.then(() => {
				message.success('Bundle deleted successfully');
			})
			.catch(() => {
				message.error('Error occurred while deleting bundle');
			});
	};
	const currencyRender = () => {
		return <span>{Settings?.settingsData?.currency || '€'}</span>;
	};
	const columns = [
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id',
		},
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Number of Rides',
			dataIndex: 'numberOfRides',
			key: 'numberOfRides',
		},
		{
			title: 'Number of Days',
			dataIndex: 'numberOfDays',
			key: 'numberOfDays',
		},
		{
			title: 'Price',

			key: 'price',
			render: (record) => {
				return (
					<div>
						{currencyRender()}
						{record.price}
					</div>
				);
			},
		},
		{
			title: 'Allowed Days',
			key: 'allowedDays',
			render: (record) => {
				// value.allowedDays is a string separated by comma, i want to convert it to a list of days of the week
				const allowedDaysList = record?.allowedDays?.split(',');
				return allowedDaysList?.length > 0 ? (
					<span>
						{allowedDaysList?.map((item) => {
							return (
								<span key={item} style={style.tags}>
									{formatEnumValue(item)}
								</span>
							);
						})}
					</span>
				) : (
					<MinusOutlined />
				);
			},
		},
		{
			title: 'Actions',
			key: 'actions',
			render: (text, record) => (
				<ActionButton
					record={record}
					onDelete={() => handleDelete(record.id)}
					onEdit={() => openDrawer(record)}
				/>
			),
		
		},
	];

	useEffect(() => {
		fetchBundles();
	}, []);

	return (
		<>
			<div style={style.inlineContent}>
				<div
					style={{
						display: 'flex',
						alignContent: 'center',
						alignItems: 'center',
						float: 'left',
						marginBottom: 8,
					}}>
					<Breadcrumb
						style={{ margin: '0 3em 0 0', fontSize: '1.1em' }}
						items={[
							{
								title: 'Bundles',
							},
						]}
					/>
					<Button type='default' size='small' onClick={() => fetchBundles()}>
						<ReloadOutlined />
					</Button>
				</div>

				<div
					style={{
						...style.inlineContent,
						float: 'right',
						marginBottom: 8,
					}}>
					<Input.Search
						placeholder='Search'
						style={{ margin: '0 8px 8px 0' }}
						value={filter}
						onChange={(e) => setFilter(e.target.value)}
					/>

					<Button
						type='default'
						style={{ float: 'right', marginBottom: 8 }}
						onClick={() => openDrawer()}>
						Add Bundle
					</Button>
				</div>
			</div>
			<CustomTableComponents
				isMakingApiCall={bundles?.loading}
				columns={columns}
				dataArray={filtredData}
				handleDoubleClickRow={(record) => {
					openDrawer(record);
				}}
				handleSelectedRow={() => {}}
			/>
			{isDrawerOpened && (
				<BundleDrawer onClose={closeDrawer} bundleId={bundleId} dispatch={dispatch} />
			)}
		</>
	);
};

export default Bundle;
