/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, Spin, message } from 'antd';
import { addNewUser, getUserById, updateUser } from '../../redux/userSlice';
import ShowConfirmClose from '../Modals/ShowConfirmClose';
import UserForm from './UserForm';
import { downloadImage, uploadProfilPicture } from '../../redux/fileSlice';
import { profile } from '../../redux/authSlice';

const DrawerUser = ({ onClose, userId, handleDeleteUser }) => {
	const [unsavedChanges, setUnsavedChanges] = useState(false);
	const dispatch = useDispatch();
	const [makingApiCall, setMakingApiCall] = useState(false);
	const [user, setUser] = useState(undefined);
	const [fileList, setFileList] = useState([]);
	const [uploading, setUploading] = useState(false);
	const [isFileChosen, setIsFileChosen] = useState(false);
	const { auth } = useSelector((state) => state.auth);
	const handleUpload = (callback, values) => {
		if (fileList.length !== 0 && isFileChosen) {
			setUploading(true);
			dispatch(uploadProfilPicture(fileList[0]?.originFileObj))
				.unwrap()
				.then((res) => {
					setFileList([]);
					callback(values, res.id);
				})
				.catch(() => {
					message.error('upload failed.');
				})
				.finally(() => {
					setUploading(false);
				});
		} else callback(values, null);
	};
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const handleClose = useCallback(
		(hasUnsavedChanges) => {
			if (hasUnsavedChanges && !confirmationOpen) {
				setConfirmationOpen(true); // Mark that the confirmation modal is open
				ShowConfirmClose(() => {
					setConfirmationOpen(false); // Reset when the confirmation modal is closed
					onClose();
				});
			} else {
				onClose();
			}
		},
		[onClose, confirmationOpen],
	);

	const handleDirty = useCallback(() => {
		setUnsavedChanges(true);
	}, []);

	const onSave = (values) => {
		handleSaveUser(values);
	};

	const handleSaveUser = async (values) => {
		setMakingApiCall(true);
		await handleUpload(saveUser, values); // Wait for the upload to complete and update the pictureId
	};

	const saveUser = (values, profilePictureId) => {
		const action = userId
			? updateUser({
					userId: user.id,
					user: {
						...values,
						active: user.active,
						email: values.email.trim().toLowerCase(),
						profilePhoto: isFileChosen ? profilePictureId : user.profilePicture,
					},
			  })
			: addNewUser({
					user: {
						...values,
						email: values.email.trim().toLowerCase(),
						profilePhoto: profilePictureId,
						active: true,
					},
			  });
		dispatch(action)
			.unwrap()
			.then((res) => {
				if (auth?.profile?.id === res.id) {
					dispatch(profile());
				}
				message.success('User saved successfully');
				onClose();
				setUnsavedChanges(false);
			})
			.catch((e) => {
				if (e.message === 'USER_ALREADY_EXIST') {
					message.error('Could not save user, it is already exist');
				} else {
					message.error('Error occurred while saving user');
				}
			})
			.finally(() => {
				onClose();
				setMakingApiCall(false);
			});
	};
	const onSaveFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};

	const fetchImage = async (imageId) => {
		setMakingApiCall(true);

		return dispatch(downloadImage(imageId))
			.unwrap()
			.then((data) => {
				const imgUrl = URL.createObjectURL(data);
				setMakingApiCall(false);
				return imgUrl;
			})
			.catch((error) => {
				setMakingApiCall(false);
				throw error;
			});
	};
	useEffect(() => {
		const abortController = new AbortController();

		if (userId) {
			setMakingApiCall(true);
			const calls = [
				dispatch(
					getUserById({
						userId,
					}),
				),
			];
			Promise.all(calls)
				.then((res) => {
					if (userId) {
						setUser(res[0].payload);
						if (
							res[0].payload?.profilePicture &&
							res[0].payload?.profilePicture !== 0
						) {
							fetchImage(res[0].payload?.profilePicture).then((imgUrl) => {
								setFileList([
									{
										uid: '-1',
										name: 'image.png',
										status: 'done',
										url: imgUrl,
									},
								]);
							});
						}
					}
				})
				.catch(() => {
					onClose();
				})
				.finally(() => {
					setMakingApiCall(false);
				});
		}
		return () => abortController.abort();
	}, [userId, dispatch]);

	return (
		<Drawer
			width={500}
			title={!userId ? 'Add User' : 'Update User'}
			placement='right'
			onClose={() => handleClose(unsavedChanges)}
			open>
			<Spin size='large' spinning={makingApiCall} style={{ marginTop: 64 }} delay={500}>
				{!makingApiCall && (
					<UserForm
						user={user}
						onSave={onSave}
						onSaveFailed={onSaveFailed}
						onDeleteUser={handleDeleteUser}
						onCancel={handleClose}
						onDirty={handleDirty}
						unsavedChanges={unsavedChanges}
						setUnsavedChanges={setUnsavedChanges}
						fileList={fileList}
						setFileList={setFileList}
						handleUpload={handleUpload}
						uploading={uploading}
						setIsFileChosen={setIsFileChosen}
					/>
				)}
			</Spin>
		</Drawer>
	);
};

export default DrawerUser;
