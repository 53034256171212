import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../axios';

// Define the initial state for the bundle entity
const initialState = {
	purchasedBundles: { loading: false, purchasedBundlesList: [] },
	selectedPurchasedBundle: { loading: false, purchasedBundle: null },
};

export const getAllPurchasedBundles = createAsyncThunk(
	'purchasedBundles/getAllPurchasedBundles',
	async () => {
		try {
			const response = await axiosInstance.get(`/purchased-bundles`);
			return response.data;
		} catch (error) {
			const customError = {
				name: 'Custom axios error',
				message: error.response.data.message,
				/* data: error.response.data, */
			};
			throw customError;
		}
	},
);

export const getPurchasedBundleById = createAsyncThunk(
	'purchasedBundles/getPurchasedBundleById',
	async (id) => {
		try {
			const response = await axiosInstance.get(`/purchased-bundle/${id}`);
			return response.data;
		} catch (error) {
			const customError = {
				name: 'Custom axios error',
				message: error.response.data.message,
				/* data: error.response.data, */
			};
			throw customError;
		}
	},
);

export const createPurchasedBundle = createAsyncThunk(
	'purchasedBundles/createPurchasedBundle',
	async (data) => {
		try {
			const response = await axiosInstance.post(`/purchased-bundle`, data);
			return response.data;
		} catch (error) {
			const customError = {
				name: 'Custom axios error',
				message: error.response.data.message,
				/* data: error.response.data, */
			};
			throw customError;
		}
	},
);

export const updatePurchasedBundle = createAsyncThunk(
	'purchasedBundles/updatePurchasedBundle',
	async (data) => {
		try {
			const response = await axiosInstance.put(`/purchased-bundle`, data);
			return response.data;
		} catch (error) {
			const customError = {
				name: 'Custom axios error',
				message: error.response.data.message,
				/* data: error.response.data, */
			};
			throw customError;
		}
	},
);

export const deletePurchasedBundle = createAsyncThunk(
	'purchasedBundles/deletePurchasedBundle',
	async (id) => {
		try {
			await axiosInstance.delete(`/purchased-bundle/${id}`);
			return id;
		} catch (error) {
			const customError = {
				name: 'Custom axios error',
				message: error.response.data.message,
				/* data: error.response.data, */
			};
			throw customError;
		}
	},
);

// Define the bundle slice
const bundleSlice = createSlice({
	name: 'purchasedBundles',
	initialState,
	reducers: {
		resetSelectedPurchasedBundle: (state) => {
			state.selectedPurchasedBundle = { loading: false, purchasedBundle: null };
		},
	},
	extraReducers: {
		[getAllPurchasedBundles.pending]: (state) => {
			state.purchasedBundles.loading = true;
		},
		[getAllPurchasedBundles.fulfilled]: (state, action) => {
			state.purchasedBundles.loading = false;
			state.purchasedBundles.purchasedBundlesList = action.payload;
		},
		[getAllPurchasedBundles.rejected]: (state) => {
			state.purchasedBundles.loading = false;
		},
		[getPurchasedBundleById.pending]: (state) => {
			state.selectedPurchasedBundle.loading = true;
		},
		[getPurchasedBundleById.fulfilled]: (state, action) => {
			state.selectedPurchasedBundle.loading = false;
			state.selectedPurchasedBundle.purchasedBundle = action.payload;
		},
		[getPurchasedBundleById.rejected]: (state) => {
			state.selectedPurchasedBundle.loading = false;
		},
		[createPurchasedBundle.pending]: (state) => {
			state.purchasedBundles.loading = true;
		},
		[createPurchasedBundle.fulfilled]: (state, action) => {
			state.purchasedBundles.loading = false;
			state.purchasedBundles.purchasedBundlesList.push(action.payload);
		},
		[createPurchasedBundle.rejected]: (state) => {
			state.purchasedBundles.loading = false;
		},
		[updatePurchasedBundle.pending]: (state) => {
			state.purchasedBundles.loading = true;
		},
		[updatePurchasedBundle.fulfilled]: (state, action) => {
			state.purchasedBundles.loading = false;
			state.purchasedBundles.purchasedBundlesList =
				state.purchasedBundles.purchasedBundlesList.map((item) => {
					if (item.id === action.payload.id) {
						item = action.payload;
						return item;
					}
					return item;
				});
		},
		[updatePurchasedBundle.rejected]: (state) => {
			state.purchasedBundles.loading = false;
		},
		[deletePurchasedBundle.pending]: (state) => {
			state.purchasedBundles.loading = true;
		},
		[deletePurchasedBundle.fulfilled]: (state, action) => {
			state.purchasedBundles.loading = false;
			state.purchasedBundles.purchasedBundlesList =
				state.purchasedBundles.purchasedBundlesList.filter((item) => {
					return item.id !== action.payload;
				});
		},
		[deletePurchasedBundle.rejected]: (state) => {
			state.purchasedBundles.loading = false;
		},
	},
});

export const { resetSelectedPurchasedBundle } = bundleSlice.actions;

export default bundleSlice.reducer;
