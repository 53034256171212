/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/order */
import { Button, Divider, Form, Input, Select, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { countryPhoneCode } from '../../Utils/country-phone-codes';
import L from 'leaflet';
import {
	FeatureGroup,
	MapContainer,
	Polygon,
	TileLayer,
	Tooltip,
	ZoomControl,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import { EditControl } from 'react-leaflet-draw';

const defaultBounds = [
	{
		lat: 35.90066951,
		lng: 14.42897017,
	},
	{
		lat: 35.80066951,
		lng: 14.32897017,
	},
	{
		lat: 35.99066951,
		lng: 14.52897017,
	},
];
const LocationForm = ({ location, onSave, onSaveFailed, unsavedChanges, onDirty, onCancel }) => {
	const [coordinatesLocation, setCoordinatesLocation] = useState([]);
	useEffect(() => {
		if (location?.points) setCoordinatesLocation(location.points);
	}, [location]);

	const handleCompleteDraw = (e) => {
		onDirty();
		const { layer } = e;
		const coords = layer?.getLatLngs();
		if (coords) {
			const coordsValue = coords && Object.values(coords[0]);
			const coordsArray = coordsValue.map((item) => Object.values(item));
			setCoordinatesLocation({ points: coordsArray });
		}
	};

	const handleUpdateDraw = (e) => {
		onDirty();
		let coords;
		e.layers.eachLayer((a) => {
			coords = a.getLatLngs();
		});
		const coordsValue = coords && Object.values(coords[0]);
		if (coordsValue) {
			const coordsArray = coordsValue?.map((item) => Object.values(item));
			setCoordinatesLocation({ points: coordsArray });
		}
	};
	const handleDeleteteDraw = () => {
		// onDirty();
		setCoordinatesLocation([]);
	};
	const orderPoints = (points) => {
		const obj = Object.values(points).sort((a, b) => a.point_order - b.point_order);
		return obj;
	};

	const saveLocation = (values, coordinatesLocations) => {
		if (coordinatesLocations.length === 0) {
			message.error('Please draw the location on the map');
			return;
		}
		onSave(values, coordinatesLocations);
	};
	return (
		<Form
			name='location'
			layout='vertical'
			autoComplete='off'
			initialValues={location}
			onFinish={(values) => saveLocation(values, coordinatesLocation)}
			onFinishFailed={onSaveFailed}
			onValuesChange={() => onDirty()}>
			<Form.Item
				label='Location Name'
				name='locationName'
				rules={[{ required: true, message: 'Location name is required' }]}>
				<Input />
			</Form.Item>
			<Form.Item
				label='Country'
				name='country'
				rules={[{ required: true, message: 'Please select country' }]}>
				<Select
					size='medium'
					showArrow
					showSearch
					optionFilterProp='filterBy'
					allowClear
					optionLabelProp='label'
					popupMatchSelectWidth={false}>
					{countryPhoneCode.map((p) => (
						<Select.Option
							key={p.en}
							filterBy={`${p.en}`}
							label={
								<>
									<span role='img'>{p.emoji}</span>
									{p.en}
								</>
							}>
							<span role='img'>{p.emoji}</span>
							{p.en} <span style={{ color: 'gray' }} />
						</Select.Option>
					))}
				</Select>
			</Form.Item>

			<Form.Item label='Draw The Location' name='points'>
				<MapContainer
					bounds={
						location?.points
							? new L.LatLngBounds(location?.points)
							: new L.LatLngBounds(defaultBounds)
					}
					zoom={1}
					scrollWheelZoom
					style={{ height: '300px' }}
					zoomControl={false}
					radius={200}>
					<TileLayer
						attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
						url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
					/>
					<FeatureGroup>
						<EditControl
							position='topleft'
							onEdited={(e) => handleUpdateDraw(e)}
							onCreated={(e) => handleCompleteDraw(e)}
							onDeleted={(e) => handleDeleteteDraw(e)}
							draw={{
								toolbar: {
									buttons: {
										polygon: 'Draw an awesome polygon',
									},
								},
								polygon: {
									icon: new L.DivIcon({
										iconSize: new L.Point(8, 8),
										className: 'leaflet-div-icon leaflet-editing-icon',
									}),
									shapeOptions: {
										guidelineDistance: 10,
										color: 'navy',
										weight: 3,
									},
								},
								rectangle: false,
								circle: false,
								marker: false,
								circlemarker: false,
								polyline: false,
							}}
						/>
						{location?.points && location?.points.length > 0 && (
							<Polygon positions={orderPoints(location?.points)}>
								<Tooltip sticky>{location?.locationName}</Tooltip>
							</Polygon>
						)}
					</FeatureGroup>
					<ZoomControl position='bottomleft' />
				</MapContainer>
			</Form.Item>
			<Divider />
			<Form.Item style={{ float: 'right' }}>
				<Button
					type='default'
					style={{ marginBottom: 32, marginRight: 8 }}
					onClick={() => onCancel(unsavedChanges)}>
					Cancel
				</Button>
				<Button
					type='primary'
					style={{ marginBottom: 32 }}
					htmlType='submit'
					disabled={!unsavedChanges || !coordinatesLocation}>
					{!location ? 'Save' : 'Update'}
				</Button>
			</Form.Item>
		</Form>
	);
};

export default LocationForm;
