// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import { Button, Dropdown, Menu } from 'antd';
import React, { useState } from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import { MoreOutlined, EyeOutlined } from '@ant-design/icons';

const ActionButton = ({ record, onSeeDetails, setSelectedBooking }) => {
	const [menuVisible, setMenuVisible] = useState(false);

	const handleMenuClick = ({ key }) => {
		// switch case depending on the key
		switch (key) {
			case 'details':
				setSelectedBooking(record);
				onSeeDetails(record);
				break;

			default:
				break;
		}
		setMenuVisible(false);
	};

	const menu = (
		<Menu onClick={handleMenuClick}>
			<Menu.Item key='details' icon={<EyeOutlined />}>
				Details
			</Menu.Item>
		</Menu>
	);

	return (
		<Dropdown
			overlay={menu}
			trigger={['click']}
			visible={menuVisible}
			onVisibleChange={(visible) => {
				setMenuVisible(visible);
			}}>
			<Button
				type='text'
				icon={<MoreOutlined />}
				onClick={(event) => event.stopPropagation()}
			/>
		</Dropdown>
	);
};

export default ActionButton;
