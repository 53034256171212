/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */

import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import axiosInstance from '../axios';
import { getTenantBrand } from '../redux/tenantBrandSlice';

export const TenantFeaturesContext = createContext();

const publicRoutes = [
	'/app/forget-password',
	'/app/login',
	'/app/check-reset-code',
	'/app/reset-password',
];

const TenantFeaturesContextProvider = ({ children }) => {
	const [tenantFeatures, setTenantFeatures] = useState([]);
	const [tenantBrand, setTenantBrand] = useState(null);
	const location = useLocation();
	const isPublicRoute = publicRoutes.includes(location.pathname);
	const { auth } = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	const fetchCurrentTenantFeatures = useCallback(async () => {
		if (!isPublicRoute) {
			const featuresURL = `tenant_feature`;

			await axiosInstance
				.get(featuresURL)
				.then((r) => {
					setTenantFeatures(r.data);
				})
				.catch((error) => {
					console.log('Error', error);
				});
		}
	}, []);

	const fetchCurrentTenantBrand = useCallback(async () => {
		const brandURL = `tenant-brand`;

		dispatch(getTenantBrand())
			.then((response) => {
				setTenantBrand(response);
			})
			.catch((error) => {
				console.log('Error', error);
			});
	}, []);
	useEffect(() => {
		fetchCurrentTenantFeatures();
		fetchCurrentTenantBrand();
	}, []);

	// Memoize the value object to prevent it from changing on every render
	const contextValue = useMemo(() => {
		return {
			tenantFeatures,
			fetchCurrentTenantFeatures,
			tenantBrand,
			fetchCurrentTenantBrand,
			setTenantBrand,
		};
	}, [tenantFeatures, fetchCurrentTenantFeatures, tenantBrand, fetchCurrentTenantBrand]);

	return (
		<TenantFeaturesContext.Provider value={contextValue}>
			{children}
		</TenantFeaturesContext.Provider>
	);
};

export default TenantFeaturesContextProvider;
