/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Drawer, Spin, message } from 'antd';
import ShowConfirmClose from '../Modals/ShowConfirmClose';
import { createMake, getMakeById, updateMake } from '../../redux/makeSlice';
import MarkForm from './MakeForm';

const DrawerMake = ({ onClose, makeId }) => {
	const [unsavedChanges, setUnsavedChanges] = useState(false);
	const [makingApiCall, setMakingApiCall] = useState(false);
	const [make, setMake] = useState(null);
	const [confirmationOpen, setConfirmationOpen] = useState(false);

	const dispatch = useDispatch();

	const handleClose = useCallback(
		(hasUnsavedChanges) => {
			setMake(null);
			if (hasUnsavedChanges && !confirmationOpen) {
				setConfirmationOpen(true); // Mark that the confirmation modal is open
				ShowConfirmClose(() => {
					setConfirmationOpen(false); // Reset when the confirmation modal is closed

					onClose();
				});
			} else {
				onClose();
			}
		},
		[onClose, confirmationOpen],
	);

	const handleDirty = useCallback(() => {
		setUnsavedChanges(true);
	}, []);

	const saveMake = async (values) => {
		const action = makeId
			? updateMake({
					...values,
					id: makeId,
			  })
			: createMake(values);
		dispatch(action)
			.unwrap()
			.then(() => {
				message.success('Make saved successfully');
				setUnsavedChanges(false);
			})
			.catch((e) => {
				if (e.message === 'MAKE_ALREADY_EXIST') {
					message.error('Could not save make, it is already exist');
				} else {
					message.error(e.message);
				}
			})
			.finally(() => {
				onClose();
				setMakingApiCall(false);
			});
	};

	const onSaveFailed = (error) => {
		console.log('Failed:', error);
	};

	useEffect(() => {
		const abortController = new AbortController();

		if (makeId) {
			setMakingApiCall(true);
			const calls = [dispatch(getMakeById(makeId))];
			Promise.all(calls)
				.then((res) => {
					setMake(res[0].payload);
				})
				.catch(() => {
					onClose();
				})
				.finally(() => {
					setMakingApiCall(false);
				});
		}
		return () => abortController.abort();
	}, [makeId, dispatch]);

	return (
		<Drawer
			width={500}
			title={!make ? 'Add Make' : 'Update Make'}
			placement='right'
			onClose={() => handleClose(unsavedChanges)}
			open>
			<Spin size='large' spinning={makingApiCall} style={{ marginTop: 64 }} delay={500}>
				{!makingApiCall && (
					<MarkForm
						make={make}
						onSave={saveMake}
						onSaveFailed={onSaveFailed}
						onCancel={handleClose}
						onDirty={handleDirty}
						unsavedChanges={unsavedChanges}
						setUnsavedChanges={setUnsavedChanges}
					/>
				)}
			</Spin>
		</Drawer>
	);
};

export default DrawerMake;
