/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/anchor-is-valid */

/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react';
import { GoogleMap, Marker, LoadScript, useGoogleMap, InfoWindow } from '@react-google-maps/api';

import { useEffect } from 'react';
import { Skeleton, Timeline, Typography, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { UserOutlined, CarOutlined, PicCenterOutlined } from '@ant-design/icons';
import DriverCard from './DriverCard';
import { getRandomGoogleMapsKey } from '../../redux/googleMapsKeySlice';
import { defaultCenterMap } from '../../Utils/staticData';

const { Paragraph, Text } = Typography;

const GodsViewMap = ({ drivers, selectedDriver, setSelectedDriver, zoom, setZoom }) => {
	const { googleMaps } = useSelector((state) => state.googleMapsKey);

	const [center, setCenter] = useState({
		lat: 37.17130611530913,
		lng: 9.772111191511911,
	});

	const [error, setError] = useState(null);
	const [apiKey, setApiKey] = useState(null);

	// Function to calculate the average center
	const calculateAverageCenter = (data) => {
		if (data?.length === 0) {
			return defaultCenterMap;
		}

		const sumLat = data?.reduce((acc, driver) => acc + driver.latitude, 0);
		const sumLng = data?.reduce((acc, driver) => acc + driver.longitude, 0);

		const averageLat = sumLat / data?.length;
		const averageLng = sumLng / data?.length;

		return { lat: averageLat, lng: averageLng };
	};

	const dispatch = useDispatch();

	const fetchGoogleMapsKey = () => {
		dispatch(getRandomGoogleMapsKey())
			.then(() => {})
			.catch(() => {
				message.error('Error getting google maps key');
			});
	};

	useEffect(() => {
		fetchGoogleMapsKey();
		// Calculate the average center
		const newCenter = calculateAverageCenter(drivers);
		// Update the map center
		setCenter(newCenter);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [isMapLoaded, setIsMapLoaded] = useState(false);
	const markerIcons = {
		url: '/images/taxi.png',
		scaledSize: {
			width: 37,
			height: 40,
		},
	};
	const handleMapLoad = () => {
		setIsMapLoaded(true);
	};
	useEffect(() => {
		if (apiKey) {
			setIsMapLoaded(true);
		}
	}, [apiKey]);

	useEffect(() => {
		if (googleMaps) {
			setApiKey(googleMaps?.googleMapsKey?.key);
		}
	}, [googleMaps]);

	if (!apiKey) {
		return <div>API key not found. Unable to load the map we're trying again</div>;
	}

	if (error) {
		return <div>Error while loading the google map</div>;
	}

	return (
		<LoadScript
			googleMapsApiKey={apiKey}
			// eslint-disable-next-line no-return-assign
			onLoad={handleMapLoad}
			libraries={['places']}
			onUnmount={() => {
				setIsMapLoaded(false);
			}}>
			{isMapLoaded ? (
				<GoogleMap
					id='GodsViewMap'
					mapContainerStyle={{
						borderRadius: '16px',
						height: '100%',
						width: '100%',
					}}
					zoom={zoom}
					defaultZoom={7}
					center={
						selectedDriver
							? {
									lat: selectedDriver.latitude,
									lng: selectedDriver.longitude,
							  }
							: center
					}
					options={{
						disableDefaultUI: true,
						zoomControl: true,
						// panControl: true,
						disableDoubleClickZoom: true,

						// zoom,
						isFractionalZoomEnabled: false,
						// enable the following options to allow the user to see the satellite view
						mapTypeControl: true,
					}}>
					{drivers?.map((driver) => (
						<Marker
							key={driver.user.id}
							position={{
								lat: driver.latitude,
								lng: driver.longitude,
							}}
							onClick={() => {
								setSelectedDriver(driver);
							}}
							icon={markerIcons}
						/>
					))}
					{drivers?.map((driver) => (
						<Marker
							key={driver.user.id} // Use a unique key for each marker
							position={{
								lat: driver.latitude,
								lng: driver.longitude,
							}}
							onClick={() => {
								setSelectedDriver(driver);
								setZoom(15);
							}}
							icon={markerIcons}
						/>
					))}
					{selectedDriver && (
						<InfoWindow
							position={{
								lat: selectedDriver.latitude + 0.0012,
								lng: selectedDriver.longitude + 0.00019,
							}}
							onCloseClick={() => {
								setSelectedDriver(null);
								setZoom(7);
							}}>
							<div className='desc'>
								<Paragraph>
									<Text
										strong
										style={{
											fontSize: 13,
										}}>
										<UserOutlined className='site-result-demo-error-icon' />{' '}
										Driver name:{' '}
									</Text>
									<Text
										code
										style={{
											fontSize: 16,
										}}>
										{`${selectedDriver.user.name} ${selectedDriver.user.surname}`}{' '}
									</Text>
								</Paragraph>
								{selectedDriver?.vehicle && (
									<>
										<Paragraph>
											<Text
												strong
												style={{
													fontSize: 13,
												}}>
												<PicCenterOutlined className='site-result-demo-error-icon' />{' '}
												Licence Plate:{' '}
											</Text>
											<Text
												code
												style={{
													fontSize: 16,
												}}>
												{` ${selectedDriver?.vehicle?.licencePlate}`}{' '}
											</Text>
										</Paragraph>
										<Paragraph>
											<Text
												strong
												style={{
													fontSize: 13,
												}}>
												<CarOutlined className='site-result-demo-error-icon' />{' '}
												Make & Model:{' '}
											</Text>
											<Text
												code
												style={{
													fontSize: 16,
												}}>
												{`${selectedDriver?.vehicle?.make?.name} ${selectedDriver?.vehicle?.model?.name} ${selectedDriver?.vehicle?.year} `}
											</Text>
										</Paragraph>
									</>
								)}
							</div>
						</InfoWindow>
					)}
				</GoogleMap>
			) : (
				<Skeleton />
			)}
		</LoadScript>
	);
};

export default GodsViewMap;
