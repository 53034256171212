/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Input, Spin, Select } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { PRIVATE, SHARED_PRIVATE, SHARED } from '../../Utils/Constants/utils';

const CorporateForm = ({
	onSave,
	corporate,
	corporateId,
	onSaveFailed,
	onCancel,
	onDirty,
	unsavedChanges,
}) => {
	const { selectedCorporate } = useSelector((state) => state.corporates);

	return corporateId && !corporate ? (
		<Spin spinning />
	) : (
		<Form
			name='corporate'
			layout='vertical'
			autoComplete='off'
			initialValues={corporate}
			onFinish={onSave}
			onFinishFailed={onSaveFailed}
			onValuesChange={() => onDirty()}>
			<Spin spinning={selectedCorporate?.loading}>
				<Form.Item
					label='Name'
					name='name'
					rules={[{ required: true, message: 'Please input corporate name' }]}>
					<Input />
				</Form.Item>
				<Form.Item
					label='Applied For'
					name='appliedFor'
					rules={[{ required: true, message: 'Please select applied for' }]}>
					<Select
						id='appliedFor_Ref'
						size='medium'
						optionLabelProp='label'
						defaultValue={SHARED_PRIVATE}
						getPopupContainer={(trigger) => trigger.parentNode}>
						<Select.Option key='shared' label='SHARED' value={SHARED}>
							Shared
						</Select.Option>
						<Select.Option key='private' label='PRIVATE' value={PRIVATE}>
							Private
						</Select.Option>
						<Select.Option
							key='shared_private'
							label='SHARED & PRIVATE'
							value={SHARED_PRIVATE}>
							Shared & Private
						</Select.Option>
					</Select>
				</Form.Item>
				<Form.Item style={{ float: 'right' }}>
					<Button
						type='default'
						style={{ marginBottom: 32, marginRight: 8 }}
						onClick={() => onCancel(unsavedChanges)}>
						Cancel
					</Button>
					<Button
						type='primary'
						style={{ marginBottom: 32 }}
						htmlType='submit'
						disabled={!unsavedChanges}>
						{!selectedCorporate?.corporate ? 'Save' : 'Update'}
					</Button>
				</Form.Item>
			</Spin>
		</Form>
	);
};

export default CorporateForm;
