import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../axios/index';

const initialState = {
	refunds: {
		loading: false,
		refundList: [],
	},
};

export const getAllRefunds = createAsyncThunk('Refunds/getAllRefunds', async () => {
	try {
		const response = await axiosInstance.get(`booking-refund`);
		return response.data;
	} catch (error) {
		console.log(error);
		const customError = {
			name: 'Error login',
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const refundSlice = createSlice({
	name: 'Refunds',
	initialState,
	reducers: {},
	extraReducers: {
		[getAllRefunds.pending]: (state) => {
			state.refunds.loading = true;
		},
		[getAllRefunds.fulfilled]: (state, action) => {
			state.refunds.loading = false;
			state.refunds.refundList = action.payload;
		},
		[getAllRefunds.rejected]: (state) => {
			state.refunds.loading = false;
		},
	},
});

export default refundSlice.reducer;
