import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../axios';

const initialState = {
	booking: { loading: false, bookingList: null },
	selectedBooking: { loading: false, booking: null },
};

export const getAllBookings = createAsyncThunk('booking/getAllBookings', async (data) => {
	try {
		const response = await axios.patch(`/bookings`, data);
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
			/* data: error.response.data, */
		};
		throw customError;
	}
});

export const getAllBookingsWithCretaria = createAsyncThunk(
	'booking/getAllBookingsWithCretaria',
	async (filterData) => {
		try {
			const response = await axios.patch(`/bookings/filter`, filterData);
			return response.data;
		} catch (error) {
			const customError = {
				name: 'Custom axios error',
				message: error.response.data.message,
				/* data: error.response.data, */
			};
			throw customError;
		}
	},
);

export const refundBooking = createAsyncThunk('booking/refundBooking', async (data) => {
	try {
		const response = await axios.post(`/booking-refund`, data);
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
			/* data: error.response.data, */
		};
		throw customError;
	}
});

export const getBookingRefundHistoryByBookingId = createAsyncThunk(
	'booking/getBookingRefundHistoryByBookingId',
	async (bookingId) => {
		try {
			const response = await axios.get(`/booking-refund/${bookingId}`);
			return response.data;
		} catch (error) {
			const customError = {
				name: 'Custom axios error',
				message: error.response.data.message,
				/* data: error.response.data, */
			};
			throw customError;
		}
	},
);

export const getBookingById = createAsyncThunk('booking/getBookingById', async (bookingId) => {
	try {
		const response = await axios.get(`/bookings/${bookingId}/getBookingDetails`);
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
			/* data: error.response.data, */
		};
		throw customError;
	}
});

export const bookingSlice = createSlice({
	name: 'booking',
	initialState,
	reducers: {
		setSelectedBooking: (state) => {
			state.selectedBooking = { loading: false, booking: null };
		},
	},
	extraReducers: {
		[getAllBookings.pending]: (state) => {
			state.booking.loading = true;
		},
		[getAllBookings.rejected]: (state) => {
			state.booking.loading = false;
		},
		[getAllBookings.fulfilled]: (state, action) => {
			state.booking.loading = false;
			state.booking.bookingList = Object.values(action.payload);
		},
		[getAllBookingsWithCretaria.pending]: (state) => {
			state.booking.loading = true;
		},
		[getAllBookingsWithCretaria.rejected]: (state) => {
			state.booking.loading = false;
		},
		[getAllBookingsWithCretaria.fulfilled]: (state, action) => {
			state.booking.loading = false;
			state.booking.bookingList = Object.values(action.payload);
		},
		[refundBooking.pending]: (state) => {
			state.booking.loading = true;
		},
		[refundBooking.rejected]: (state) => {
			state.booking.loading = false;
		},
		[refundBooking.fulfilled]: (state, action) => {
			state.booking.loading = false;
			state.booking.bookingList = state.booking.bookingList.map((booking) => {
				if (booking.id === action.payload.bookingId) {
					const refundedAmount = action.payload.amountToRefund;
					// ? if the initial amount is equal or greater than the refunded amount then set the paid amount to initial amount - refunded amount
					if (booking.priceBreakdown.paidAmount >= refundedAmount) {
						booking.priceBreakdown = {
							...booking.priceBreakdown,
							paidAmount: booking.priceBreakdown.paidAmount - refundedAmount,
						};
					}
					// ? set the refund history
					const refundHistory = [action.payload];
					booking.bookingRefundHistories = refundHistory;
				}

				return booking;
			});
		},
		[getBookingById.pending]: (state) => {
			state.selectedBooking.loading = true;
		},
		[getBookingById.rejected]: (state) => {
			state.selectedBooking.loading = false;
		},
		[getBookingById.fulfilled]: (state, action) => {
			state.selectedBooking.loading = false;
			state.selectedBooking.booking = action.payload;
		},
	},
});

export const { setSelectedBooking } = bookingSlice.actions;

export default bookingSlice.reducer;
