const refundReasons = [
	{ value: 'SERVICE_QUALITY', label: 'Service Quality' },
	{ value: 'FAILURE_TO_PROVIDE_SERVICE', label: 'Failure to provide service' },
	{ value: 'PAYMENT_ISSUES', label: 'Payment Issues' },
	{ value: 'SAFETY_CONCERNS', label: 'Safety Concerns' },
	{ value: 'UNEXPECTED_CIRCUMSTANCES', label: 'Unexpected Circumstances' },
	{ value: 'OTHER', label: 'Other' },
];

export default refundReasons;
