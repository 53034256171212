/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import { Drawer, message, Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	addNewKmRangeFare,
	getKmRangeFareById,
	updateKmRangeFare,
} from '../../redux/KmRangeFareSlice';
import ShowConfirmClose from '../Modals/ShowConfirmClose';
import KmRangeFareForm from './KmRangeFareForm';

const KmRangeFareDrawer = ({ kmRangeFareId, handleClose }) => {
	const [unsavedChanges, setUnsavedChanges] = useState(false);
	const dispatch = useDispatch();
	const [makingApiCall, setMakingApiCall] = useState(false);
	const [kmRangeFare, setKmRangeFare] = useState(undefined);

	useEffect(() => {
		const abortController = new AbortController();
		if (kmRangeFareId) {
			setMakingApiCall(true);
			const calls = [
				dispatch(
					getKmRangeFareById({
						id: kmRangeFareId,
					}),
				),
			];
			Promise.all(calls)
				.then((res) => {
					if (kmRangeFareId) {
						const response = res[0].payload;
						setKmRangeFare(response);
					}
				})
				.catch(() => {
					handleClose();
				})
				.finally(() => {
					setMakingApiCall(false);
				});
		}
		return () => abortController.abort();
	}, [dispatch]);

	const handleDirty = useCallback(() => {
		setUnsavedChanges(true);
	}, []);

	const saveKmRangeFare = (values) => {
		setMakingApiCall(true);

		const action = !kmRangeFareId
			? addNewKmRangeFare({
					kmRangeFare: values,
			  })
			: updateKmRangeFare({
					id: kmRangeFareId,
					kmRangeFare: values,
			  });

		dispatch(action)
			.unwrap()
			.then(() => {
				message.success('KM range Fare saved successfully');
				setUnsavedChanges(false);
				handleClose();
			})
			.catch((e) => {
				if (e.message === 'KM_RANGE_FARE_OVERLAPPING') {
					message.error('KM Range Fare is overlapping with another range');
				} else {
					message.error('Could not save KM Range Fare, please try again error');
				}
			})
			.finally(() => {
				setMakingApiCall(false);
			});
	};

	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const handleCloseDrawer = useCallback(
		(hasUnsavedChanges) => {
			if (hasUnsavedChanges && !confirmationOpen) {
				setConfirmationOpen(true); // Mark that the confirmation modal is open
				ShowConfirmClose(() => {
					setConfirmationOpen(false); // Reset when the confirmation modal is closed
					handleClose();
				});
			} else {
				handleClose();
			}
		},
		[handleClose, confirmationOpen],
	);

	const onSave = (values) => {
		saveKmRangeFare(values);
	};

	const onSaveFailed = () => {
		message.error('Could not save KM Range Fare, please try again later');
	};
	return (
		<Drawer
			width={500}
			title={!kmRangeFareId ? 'Add KM Range Fare' : 'Update KM Range Fare'}
			closable={false}
			placement='right'
			onClose={() => handleClose()}
			open>
			<Spin size='large' spinning={false} style={{ marginTop: 64 }} delay={500}>
				{!makingApiCall && (
					<KmRangeFareForm
						kmRangeFare={kmRangeFare}
						onDirty={handleDirty}
						unsavedChanges={unsavedChanges}
						onSave={onSave}
						onSaveFailed={onSaveFailed}
						onClose={handleCloseDrawer}
					/>
				)}
			</Spin>
		</Drawer>
	);
};

export default KmRangeFareDrawer;
