/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
import {
	RedEnvelopeOutlined,
	ExclamationCircleFilled,
	DeleteOutlined,
	MoreOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Menu, Modal } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const ActionButtonWithReSendEmail = ({ record, onDelete, recordName, onResend }) => {
	const [menuVisible, setMenuVisible] = useState(false);
	const { Brand } = useSelector((state) => state.brand);

	const handleMenuClick = ({ key }) => {
		// switch case depending on the key
		switch (key) {
			case 'delete':
				Modal.confirm({
					title: (
						<>
							Are you sure you want to delete <b>{recordName || record.name}</b> ?
						</>
					),
					icon: (
						<ExclamationCircleFilled
							style={{ color: Brand?.brandData?.webPrimary || 'orange' }}
						/>
					),
					okText: 'Yes', // Customize OK button text
					cancelText: 'No', // Customize Cancel button text
					cancelButtonProps: {
						style: {
							color: Brand?.brandData?.webPrimary || 'orange',
							backgroundColor: 'white',
							borderColor: Brand?.brandData?.webPrimary || 'orange',
						},
					},
					okButtonProps: {
						style: {
							color: 'white',
							backgroundColor: Brand?.brandData?.webPrimary || 'orange',
						},
					},
					onOk: () => {
						onDelete(record.id, () => {});
					},
					onCancel: () => {},
				});
				break;
			case 're-send-email':
				onResend(record);
				break;

			default:
				break;
		}
		setMenuVisible(false);
	};

	const menu = (
		<Menu onClick={handleMenuClick}>
			{record?.status === 'PENDING' && (
				<>
					<Menu.Item key='re-send-email' icon={<RedEnvelopeOutlined />}>
						Resend email
					</Menu.Item>
					<Menu.Divider />
				</>
			)}

			<Menu.Item key='delete' icon={<DeleteOutlined />} danger>
				Delete
			</Menu.Item>
		</Menu>
	);

	return (
		<Dropdown
			overlay={menu}
			trigger={['click']}
			visible={menuVisible}
			onVisibleChange={(visible) => {
				setMenuVisible(visible);
			}}>
			<Button
				type='text'
				icon={<MoreOutlined />}
				onClick={(event) => event.stopPropagation()}
			/>
		</Dropdown>
	);
};

export default ActionButtonWithReSendEmail;
