/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */

import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { getAllFleetOperators } from '../../redux/fleetOperatorSlice';
import SiteStatistics from './SiteStatistics';
import RideStatistics from './RideStatistics';
import RidesPieChart from './RidesPieChart';
import DriversPieChart from './DriversPieChart';
import CompletedVsCancelledBarChart from './CompletedVsCancelledBarChart';
import RegistredUsersChart from './RegistredUsersChart';
import {
	completedVsCancelled,
	getBookingStatistics,
	getBookingStatisticsByPeriod,
	getDriverStatisticsByPeriod,
	getSiteStatistics,
	registeredUsers,
} from '../../redux/dashboardSlice';
import { TenantFeaturesContext } from '../../Context/TenantFeatureContext';

const Dashboard = () => {
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { auth } = useSelector((state) => state.auth);
	// eslint-disable-next-line no-unused-vars
	const context = useContext(TenantFeaturesContext);
	const fetchDashboardData = async () => {
		setLoading(true);
		await Promise.all([
			dispatch(getAllFleetOperators()),
			dispatch(getSiteStatistics()),
			dispatch(getBookingStatistics()),
			dispatch(getBookingStatisticsByPeriod('1W')),
			dispatch(getDriverStatisticsByPeriod('1W')),
			dispatch(completedVsCancelled('1W')),
			dispatch(registeredUsers('1W')),
		])
			.then(() => {})
			.catch((error) => {
				console.log('error', error);
			})
			.finally(() => {
				setLoading(false);
			});
	};
	useEffect(() => {
		if (!auth.token) navigate('/app/login');
		fetchDashboardData();
	}, [dispatch]);

	return (
		<>
			<Spin
				size='large'
				indicator={<LoadingOutlined style={{ fontSize: 54 }} spin />}
				tip={
					<div>
						<h2>Loading...</h2>
					</div>
				}
				spinning={loading}>
				<Row style={{ margin: 16 }} gutter={16}>
					<Col xs={24} sm={24} md={12} lg={12} xl={12}>
						<SiteStatistics />
					</Col>
					<Col xs={24} sm={24} md={12} lg={12} xl={12}>
						<RideStatistics />
					</Col>
					<Col style={{ marginTop: '0.5em' }} xs={24} sm={24} md={12} lg={12} xl={12}>
						<RidesPieChart />
					</Col>
					<Col style={{ marginTop: '0.5em' }} xs={24} sm={24} md={12} lg={12} xl={12}>
						<DriversPieChart />
					</Col>
					<Col style={{ marginTop: '0.5em' }} xs={24} sm={24} md={12} lg={12} xl={12}>
						<CompletedVsCancelledBarChart />
					</Col>
					<Col style={{ marginTop: '0.5em' }} xs={24} sm={24} md={12} lg={12} xl={12}>
						<RegistredUsersChart />
					</Col>
				</Row>
			</Spin>
		</>
	);
};

export default Dashboard;
