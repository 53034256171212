/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import { Button, Form, Input, Select, Spin, Tooltip, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { InfoCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { saveCorporateDiscount } from '../../redux/corporateSlice';

const { Option } = Select;

const DiscountComponent = ({ onModalClose }) => {
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const [discountType, setDiscountType] = useState('');
	const { selectedCorporate } = useSelector((state) => state.corporates);
	const onChangeDiscountType = (value) => {
		setDiscountType(value);
	};
	const onSaveCorporateDiscount = (values) => {
		dispatch(
			saveCorporateDiscount({
				...values,
				corporateId: selectedCorporate?.corporate?.id,
			}),
		)
			.unwrap()
			.then(() => {
				message.success('Discount saved successfully');
				onModalClose();
			})
			.catch(() => {
				message.error('Error saving discount');
			});
	};

	useEffect(() => {
		if (selectedCorporate.corporate) {
			form.setFieldValue('discountType', selectedCorporate.corporate?.discount?.discountType);

			setDiscountType(selectedCorporate.corporate?.discount?.discountType || '');
			form.setFieldValue('percentage', selectedCorporate.corporate?.discount?.percentage);
			form.setFieldValue('fixedAmount', selectedCorporate.corporate?.discount?.fixedAmount);
			form.setFieldValue('ridesPer', selectedCorporate.corporate?.discount?.ridesPer);
			form.setFieldValue(
				'numberOfRides',
				selectedCorporate.corporate?.discount?.numberOfRides,
			);
		}
		return () => {
			form.resetFields();
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedCorporate?.corporate]);

	return (
		<Spin spinning={selectedCorporate?.loading}>
			<Form
				form={form}
				layout='vertical'
				name='discountForm'
				onFinish={onSaveCorporateDiscount}
				initialValues={{
					discountType: selectedCorporate.corporate?.discount?.discountType,
					percentage: selectedCorporate.corporate?.discount?.percentage,
					fixedAmount: selectedCorporate.corporate?.discount?.fixedAmount,
					ridesPer: selectedCorporate.corporate?.discount?.ridesPer,
					numberOfRides: selectedCorporate.corporate?.discount?.numberOfRides,
				}}>
				<Form.Item
					label='Discount Type'
					initialValue={selectedCorporate?.corporate?.discount?.discountType}
					name='discountType'
					style={{ marginTop: '5%' }}
					rules={[{ required: true, message: 'Please select discount type' }]}>
					<Select
						placeholder='Select a discount type'
						onChange={onChangeDiscountType}
						style={{ width: '100%' }}>
						<Option value='PERCENTAGE'>Percentage</Option>
						<Option value='FIXED'>Fixed</Option>

						<Option value='NUMBER_OF_RIDES'>Number of Rides</Option>
					</Select>
				</Form.Item>
				{discountType === 'PERCENTAGE' && (
					<div>
						<Form.Item
							label='Discount Percentage'
							initialValue={60}
							name='percentage'
							style={{ marginTop: '5%' }}
							rules={[
								{ required: true, message: 'Please input discount percentage!' },

								{
									type: 'number',
									min: 0,
									max: 100,
									transform: (value) => parseFloat(value),
									message: 'Please enter a number greater than or equal to 0',
								},
							]}>
							<Input
								type='number'
								placeholder='Discount Percentage'
								prefix='%'
								max={100}
								suffix={
									<Tooltip title='Enter the percentage of the booking price covered by the corporate account'>
										<InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
									</Tooltip>
								}
								onChange={(e) => {
									const value = parseInt(e.target.value, 10);
									// eslint-disable-next-line no-restricted-globals
									if (isNaN(value) || value > 100) {
										e.preventDefault();
									}
								}}
							/>
						</Form.Item>
					</div>
				)}
				{discountType === 'FIXED' && (
					<div>
						<Form.Item
							name='fixedAmount'
							style={{ marginTop: '5%' }}
							rules={[
								{ required: true, message: 'Please input discount fixed!' },

								{
									type: 'number',
									min: 0,
									max: 100,
									transform: (value) => parseFloat(value),
									message: 'Please enter a number greater than or equal to 0',
								},
							]}>
							<Input
								type='number'
								placeholder='Discount Fixed amount'
								prefix='€'
								max={100}
								suffix={
									<Tooltip title='Enter the fixed amount of money to be deducted per ride'>
										<InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
									</Tooltip>
								}
							/>
						</Form.Item>
					</div>
				)}
				{discountType === 'NUMBER_OF_RIDES' && (
					<div>
						<Form.Item
							label='Ride Per'
							name='ridesPer'
							style={{ marginTop: '5%' }}
							rules={[{ required: true, message: 'Please select ride per' }]}>
							<Select style={{ width: '100%' }}>
								<Option value='DAY'> Day</Option>
								<Option value='WEEK'>Week</Option>
								<Option value='MONTH'>Month</Option>
							</Select>
						</Form.Item>
						<Form.Item
							label='Number Of Rides'
							name='numberOfRides'
							rules={[
								{ required: true, message: 'Please input number Of Rides' },

								{
									type: 'number',
									min: 0,
									max: 100,
									transform: (value) => parseFloat(value),
									message: 'Please enter a number greater than or equal to 0',
								},
							]}>
							<Input
								type='number'
								placeholder='Number Of Rides'
								max={100}
								suffix={
									<Tooltip title='Enter number of free rides during the selected period'>
										<InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
									</Tooltip>
								}
							/>
						</Form.Item>
					</div>
				)}

				<Form.Item>
					<Button type='primary' htmlType='submit' icon={<SaveOutlined />}>
						Save
					</Button>
				</Form.Item>
			</Form>
		</Spin>
	);
};

export default DiscountComponent;
