/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */

import {
	GoogleMap,
	InfoWindow,
	Marker,
	MarkerClusterer,
	useJsApiLoader,
} from '@react-google-maps/api';
import { Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const ViewAllWaypointsWithCluster = ({ isOpen, setIsOpen }) => {
	const [selectedMarker, setSelectedMarker] = useState(null);
	const handleMarkerHover = (marker) => {
		setSelectedMarker(marker);
	};

	const handleMarkerMouseLeave = () => {
		setSelectedMarker(null);
	};
	const [center, setCenter] = useState({
		lat: 37.17130611530913,
		lng: 9.772111191511911,
	});
	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: 'AIzaSyCgTDGF7lq5JJahNIAafAVPhK8Lzr9SkVo',
		libraries: ['places'],
	});
	const { WayPoints } = useSelector((state) => state.wayPoint);

	const closeModal = () => {
		setIsOpen(false);
	};

	const onMarkerClustererClick = (markerClusterer) => {
		console.log('test', markerClusterer);
	};

	console.log('isLoaded', isLoaded);

	return isLoaded ? (
		<Modal
			style={{ top: 200 }}
			width={800}
			title={
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}>
					<div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
							}}>
							<h5
								style={{
									marginRight: '0.5em',
									marginBottom: '0',
									marginTop: '0',
								}}>
								All Waypoints
							</h5>
						</div>
					</div>
					<div
						style={{
							width: '48%',
							height: '50px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'flex-end',
						}}>
						<CloseOutlined
							style={{ fontSize: '1.5em', cursor: 'pointer' }}
							onClick={closeModal}
						/>
					</div>
				</div>
			}
			open={isOpen}
			onOk={closeModal}
			onCancel={closeModal}
			closable={false}
			footer={null}>
			<GoogleMap
				id='circle-example'
				mapContainerStyle={{
					borderRadius: '16px',
					height: '700px',
					width: '99%',
				}}
				zoom={7}
				center={center}
				options={{
					disableDefaultUI: true,
					zoomControl: true,
				}}>
				<MarkerClusterer
					onClick={onMarkerClustererClick}
					averageCenter
					enableRetinaIcons
					gridSize={10}>
					{(clusterer) => (
						<div>
							{WayPoints?.wayPointsList?.map((obj) => (
								<Marker
									key={obj.id}
									position={{ lat: obj.latitude, lng: obj.longitude }}
									animation={window.google.maps.Animation.DROP}
									onClick={() => handleMarkerHover(obj)}
								/>
							))}
						</div>
					)}
				</MarkerClusterer>
				{selectedMarker && (
					<InfoWindow
						position={{ lat: selectedMarker.latitude, lng: selectedMarker.longitude }}
						onCloseClick={handleMarkerMouseLeave}>
						<div>
							<h3>{selectedMarker.id}</h3>
							<p>{selectedMarker.locationName}</p>
						</div>
					</InfoWindow>
				)}
			</GoogleMap>
		</Modal>
	) : (
		'Loading'
	);
};

export default ViewAllWaypointsWithCluster;
