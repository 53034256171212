/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
import { Button, Divider, Form, Input, Select, Switch } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import ImageComponent from '../Global/ImageComponent/ImageComponent';

const VehicletypeForm = ({
	vehicleType,
	onSave,
	onSaveFailed,
	onCancel,
	onDirty,
	unsavedChanges,
	handlePeakSurchageSwitch,
	handleNightShiftSwitch,
}) => {
	const { Location } = useSelector((state) => state.locations);
	const { files } = useSelector((state) => state.files);

	const locationArray = Location.locationsList;
	return (
		<Form
			name='vehicleType'
			layout='vertical'
			autoComplete='off'
			initialValues={vehicleType}
			onFinish={onSave}
			onFinishFailed={onSaveFailed}
			onValuesChange={() => onDirty()}>
			<Form.Item
				label='Image'
				name='imageId'
				rules={[{ required: true, message: 'Please select the image' }]}>
				<Select
					size='medium'
					showArrow
					allowClear
					optionLabelProp='label'
					popupMatchSelectWidth={false}>
					{files?.vehicleTypeFiles?.map((file) => {
						return (
							<Select.Option
								key={file.id}
								label={<ImageComponent size={35} imageId={file.id} />}
								value={file.id}>
								<ImageComponent size={40} imageId={file.id} />
							</Select.Option>
						);
					})}
				</Select>
			</Form.Item>
			<Form.Item
				label='Vehicle Type'
				name='vehicleType'
				rules={[{ required: true, message: '' }]}>
				<Input />
			</Form.Item>

			<Form.Item
				label='Location'
				name='locationId'
				rules={[{ required: true, message: 'Please select the location' }]}>
				<Select
					size='medium'
					showArrow
					showSearch
					optionFilterProp='filterBy'
					allowClear
					optionLabelProp='label'
					popupMatchSelectWidth={false}>
					{locationArray.map((location) => {
						return (
							<Select.Option
								key={location.id}
								filterBy={location.locationName}
								label={<span>{location.locationName}</span>}
								value={location.id}>
								<span>{location.locationName}</span>
							</Select.Option>
						);
					})}
				</Select>
			</Form.Item>
			<Form.Item label='Base Fare' name='baseFare' rules={[{ required: true, message: '' }]}>
				<Input type='number' step='0.01' min={0} />
			</Form.Item>

			<Form.Item
				label='Rider Cancellation Time (In minute)'
				name='riderCancellationTimeLimit'
				rules={[
					{ required: true, message: 'Cancellation Time is required' },

					{
						validator: (_, value) => {
							if (
								(value && value.toString().includes('.')) ||
								value.toString().includes(',')
							) {
								return Promise.reject('Cancellation Time must be an integer');
							}
							return Promise.resolve();
						},
					},
				]}>
				<Input type='number' step='1' min={0} />
			</Form.Item>
			<Form.Item
				label='Rider Cancellation Charge'
				name='riderCancellationCharge'
				rules={[{ required: true, message: '' }]}>
				<Input type='number' step='0.5' min={0} />
			</Form.Item>
			<Form.Item
				label='Available Seat'
				name='availableSeat'
				rules={[
					{ required: true, message: 'Available Seat is required' },

					{
						validator: (_, value) => {
							if (
								(value && value.toString().includes('.')) ||
								value.toString().includes(',')
							) {
								return Promise.reject('Available Seat must be an integer');
							}
							return Promise.resolve();
						},
					},
				]}>
				<Input type='number' step='1' min={1} max={10} />
			</Form.Item>

			<Form.Item
				label='Price (Per KM)'
				name='pricePerKm'
				rules={[{ required: true, message: '' }]}>
				<Input type='number' min={0} step='0.5' />
			</Form.Item>

			<Form.Item label='Peak Time Surcharge' name='peakSurcharge'>
				<Switch
					checkedChildren='ON'
					unCheckedChildren='OFF'
					defaultChecked={vehicleType && vehicleType.peakSurcharge}
					onChange={
						vehicleType
							? (values) => handlePeakSurchageSwitch(values, vehicleType.id)
							: (values) => handlePeakSurchageSwitch(values)
					}
				/>
			</Form.Item>
			<Form.Item label='Night Shift Charge' name='nightShift'>
				<Switch
					checkedChildren='ON'
					unCheckedChildren='OFF'
					defaultChecked={vehicleType && vehicleType.nightShift}
					onChange={
						vehicleType
							? (values) => handleNightShiftSwitch(values, vehicleType.id)
							: (values) => handleNightShiftSwitch(values)
					}
				/>
			</Form.Item>
			<Divider />
			<Form.Item style={{ float: 'right' }}>
				<Button
					type='default'
					style={{ marginBottom: 32, marginRight: 8 }}
					onClick={() => onCancel(unsavedChanges)}>
					Cancel
				</Button>
				<Button
					type='primary'
					style={{ marginBottom: 32 }}
					htmlType='submit'
					disabled={!unsavedChanges}>
					{!vehicleType ? 'Save' : 'Update'}
				</Button>
			</Form.Item>
		</Form>
	);
};

export default VehicletypeForm;
