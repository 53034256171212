/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Breadcrumb, Button, Card, Input, Popconfirm, Table, message } from 'antd';
import {
	ReloadOutlined,
	FileExcelOutlined,
	GlobalOutlined,
	QuestionCircleOutlined,
	DeleteOutlined,
} from '@ant-design/icons';
import { useLoadScript } from '@react-google-maps/api';
import { batchDeleteWayPoints, deleteWayPoint, getAllWayPoints } from '../../redux/WayPointSlice';
import { getRandomGoogleMapsKey } from '../../redux/googleMapsKeySlice';
import { style } from '../../Styles';
import ActionButton from '../Global/ActionsButton';
import DrawerWayPoints from './DrawerWayPoints';
import ImportCsvsDialog from '../Global/ImportCsvsDialog';
import ModalGoogleMaps from '../Modals/ModalGoogleMaps';
import CostumeTableWithSelect from '../Global/CostumeTableWithSelect';
import ViewAllWaypointsWithCluster from './ViewAllWaypointsWithCluster';

const libraries = ['core', 'places', 'geocoding', 'routes', 'geometry'];

const urlAction = 'http://localhost:8080/api/v1/web/way-point/upload/csv?validateOnly=false';
const name_exempleFile = 'sample_way_points_1.csv';

const WayPoints = ({ role }) => {
	const [allPointsModal, setAllPointsModal] = useState(false);
	const { WayPoints } = useSelector((state) => state.wayPoint);
	const [makingApiCall, setMakingApiCall] = useState(false);
	const [filter, setFilter] = useState('');
	const [selectedRecordId, setSelectedRecordId] = useState(null);
	const [isDrawerOpened, setIsDrawerOpened] = useState(false);
	const [importDialogVisible, setImportDialogVisible] = useState(false);
	const [selectedRecord, setSelectedRecord] = useState(null);
	const [isMapModalOpened, setIsMapModalOpened] = useState(false);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const onSelectChange = (newSelectedRowKeys) => {
		console.log('selectedRowKeys changed: ', newSelectedRowKeys);
		setSelectedRowKeys(newSelectedRowKeys);
	};
	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		selections: [
			Table.SELECTION_ALL,
			Table.SELECTION_INVERT,
			Table.SELECTION_NONE,
			{
				key: 'odd',
				text: 'Select Odd Row',
				onSelect: (changeableRowKeys) => {
					let newSelectedRowKeys = [];
					newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
						if (index % 2 !== 0) {
							return false;
						}
						return true;
					});
					setSelectedRowKeys(newSelectedRowKeys);
				},
			},
			{
				key: 'even',
				text: 'Select Even Row',
				onSelect: (changeableRowKeys) => {
					let newSelectedRowKeys = [];
					newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
						if (index % 2 !== 0) {
							return true;
						}
						return false;
					});
					setSelectedRowKeys(newSelectedRowKeys);
				},
			},
		],
	};
	const refreshImportDialogVisible = (value) => {
		setMakingApiCall(false);
		setImportDialogVisible(value);
	};

	const onSelectRecord = (record) => {
		setSelectedRecord(record);
		setIsMapModalOpened(true);
	};
	const handleDrawerClose = () => {
		setIsDrawerOpened(false);
		setSelectedRecordId(null);
	};

	const handleDrawerOpen = (id) => {
		setIsDrawerOpened(true);
		setSelectedRecordId(id);
	};
	const dispatch = useDispatch();
	const fetchAllWayPoints = () => {
		setMakingApiCall(true);
		dispatch(getAllWayPoints())
			.unwrap()
			.then(() => {})
			.catch(() => {})
			.finally(() => {
				setMakingApiCall(false);
			});
	};
	const fetchGoogleMapsKey = () => {
		dispatch(getRandomGoogleMapsKey())
			.then(() => {})
			.catch(() => {});
	};

	useEffect(() => {
		fetchAllWayPoints();
		fetchGoogleMapsKey();
	}, [dispatch]);

	const handleDelete = (id) => {
		setMakingApiCall(true);
		dispatch(deleteWayPoint({ id }))
			.unwrap()
			.then(() => {
				message.success('Waypoint deleted successfully');
			})
			.catch((e) => {
				console.log('Error deleting Waypoint', e);
				message.error('Error deleting Waypoint');
			})
			.finally(() => {
				setMakingApiCall(false);
			});
	};

	const columns = [
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id',
			defaultSortOrder: 'ascend',
			sorter: (a, b) => a.id - b.id,
		},
		{
			title: 'Location Name',
			dataIndex: 'locationName',
			key: 'locationName',
			defaultSortOrder: 'ascend',
			sorter: (a, b) => a.locationName.localeCompare(b.locationName),
		},
		{
			title: 'Coordinates',
			render: (text, record) => (
				<Button type='default' size='small' onClick={() => onSelectRecord(record)}>
					Show Map
				</Button>
			),
			key: 'coordinates',
		},
		{
			title: 'Actions',
			key: 'actions',
			render: (text, record) => (
				<ActionButton
					record={record}
					onDelete={() => handleDelete(record.id)}
					onEdit={() => handleDrawerOpen(record.id)}
				/>
			),

			disabled: !role?.includes('SUPER_ADMIN'),
		},
	];

	const data = WayPoints?.wayPointsList ? Object.values(WayPoints?.wayPointsList) : [];

	const filteredData = data.filter((item) => {
		const lowerFilter = filter.toLowerCase();

		return (
			item?.locationName.toLowerCase().includes(lowerFilter) ||
			item?.latitude.toString().includes(lowerFilter) ||
			item?.longitude.toString().includes(lowerFilter) ||
			item?.id.toString().includes(lowerFilter)
		);
	});
	const { googleMaps } = useSelector((state) => state.googleMapsKey);

	const { isLoaded } = useLoadScript({
		googleMapsApiKey: googleMaps?.googleMapsKey?.key,
		libraries,
	});

	const handleDeleteBatchWaypoint = () => {
		setMakingApiCall(true);
		dispatch(batchDeleteWayPoints({ wayPointIds: selectedRowKeys }))
			.unwrap()
			.then(() => {
				message.success('Waypoints deleted successfully');
			})
			.catch((e) => {
				console.log('Error deleting Waypoint', e);
				message.error('Error deleting Waypoint');
			})
			.finally(() => {
				setMakingApiCall(false);
				setSelectedRowKeys([]);
			});
	};

	return (
		<>
			<Card style={{ margin: 16 }}>
				<div style={style.inlineContent}>
					<div
						style={{
							display: 'flex',
							alignContent: 'center',
							alignItems: 'center',
							float: 'left',
							marginBottom: 8,
						}}>
						<Breadcrumb
							style={{ margin: '0 3em 0 0', fontSize: '1.1em' }}
							items={[
								{
									title: 'Locations',
								},
								{
									title: 'Waypoints',
								},
							]}
						/>
						<Button type='default' size='small' onClick={() => fetchAllWayPoints()}>
							<ReloadOutlined />
						</Button>
					</div>

					<div
						style={{
							...style.inlineContent,
							float: 'right',
							marginBottom: 8,
						}}>
						{role?.includes('SUPER_ADMIN') && (
							<>
								<Popconfirm
									title='Delete Waypoints'
									description={
										<>
											Are you sure you want to delete{' '}
											<b>{selectedRowKeys.length}</b> waypoints ?
										</>
									}
									onConfirm={() => {
										handleDeleteBatchWaypoint();
									}}
									onCancel={() => {
										setSelectedRowKeys([]);
									}}
									icon={<QuestionCircleOutlined style={{ color: 'red' }} />}>
									<Button
										style={{ margin: '0 8px 8px 0' }}
										disabled={selectedRowKeys.length === 0}
										danger>
										Delete
										<DeleteOutlined />
									</Button>
								</Popconfirm>

								<Button
									// type="link"
									style={{ margin: '0 8px 8px 0' }}
									onClick={() => {
										setImportDialogVisible(true);
									}}>
									Import
									<FileExcelOutlined />
								</Button>
							</>
						)}
						<Button
							// type="link"
							style={{ margin: '0 8px 8px 0' }}
							onClick={() => {
								setAllPointsModal(true);
							}}>
							All Waypoints
							<GlobalOutlined />
						</Button>
						<Input.Search
							placeholder='Search'
							style={{ margin: '0 8px 8px 0' }}
							value={filter}
							onChange={(e) => setFilter(e.target.value)}
						/>
						{role?.includes('SUPER_ADMIN') && (
							<Button
								type='default'
								style={{ float: 'right', marginBottom: 8 }}
								onClick={() => setIsDrawerOpened(true)}>
								Add Waypoint
							</Button>
						)}
					</div>
				</div>
				<CostumeTableWithSelect
					isMakingApiCall={makingApiCall}
					columns={columns.filter((item) => {
						return item.disabled !== true;
					})}
					dataArray={filteredData}
					handleDoubleClickRow={(record) =>
						role?.includes('SUPER_ADMIN') ? handleDrawerOpen(record?.id) : {}
					}
					handleSelectedRow={() => {}}
					rowSelection={rowSelection}
				/>
			</Card>
			{isDrawerOpened && (
				<DrawerWayPoints wayPointId={selectedRecordId} onClose={handleDrawerClose} />
			)}
			{importDialogVisible && (
				<ImportCsvsDialog
					setVisible={refreshImportDialogVisible}
					action={urlAction}
					name_exempleFile={name_exempleFile}
					name_file_error='WayPoint'
				/>
			)}
			{isMapModalOpened && (
				<ModalGoogleMaps
					record={selectedRecord}
					setIsMapModalOpened={setIsMapModalOpened}
				/>
			)}
			{isLoaded && allPointsModal && googleMaps?.googleMapsKey?.key && (
				<ViewAllWaypointsWithCluster
					isOpen={allPointsModal}
					setIsOpen={setAllPointsModal}
				/>
			)}
		</>
	);
};

export default WayPoints;
