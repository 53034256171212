/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/default */
/* eslint-disable import/namespace */
import React from 'react';
import { useNavigate, useLocation, Route, Navigate, Routes } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

import env from './Utils/Constants/env';

import LoggedInArea from './components/LoggedInArea';
import NotLoggedInArea from './components/NotLoggedInArea';

const publicRoutes = [
	'/app/forget-password',
	'/app/login',
	'/app/check-reset-code',
	'/app/reset-password',
];

const App = () => {
	const { auth } = useSelector((state) => state.auth);
	const location = useLocation();

	const navigate = useNavigate();
	const { Brand } = useSelector((state) => state.brand);

	const updateFavicon = (newFavicon) => {
		const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
		link.type = 'image/png';
		link.rel = 'icon';
		link.href = newFavicon;
		document.getElementsByTagName('head')[0].appendChild(link);
	};
	useEffect(() => {
		const isPublicRoute = publicRoutes.includes(location.pathname);

		if (!auth.token && !isPublicRoute) {
			navigate('/app/login');
		}

		if (Brand?.brandData?.webIcon)
			updateFavicon(`${env.baseUri}public-file/${Brand?.brandData?.webIcon}`);
	}, [Brand?.brandData?.webIcon, auth.token, location]);
	return (
		<ConfigProvider
			theme={{
				token: {
					colorPrimary: Brand?.brandData?.webPrimary || 'orange',
					colorLink: 'orange',
				},
			}}>
			<Routes>
				{/* Logged-in Area */}
				<Route path='/app/main/*' element={<LoggedInArea roles={auth?.profile?.role} />} />

				{/* Not Logged-in Area */}
				<Route path='/app/*' element={<NotLoggedInArea />} />

				{/* Redirect to the appropriate area */}
				<Route path='/' element={<Navigate to='/app/main' />} />
			</Routes>
		</ConfigProvider>
	);
};

export default App;
