/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-restricted-syntax */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../axios';
import { arrayToObject } from '../Utils/ArrayToObject';

const initialState = {
	Models: {
		loading: false,
		modelList: [],
	},
};

export const getAllModels = createAsyncThunk('Model/getAllModels', async () => {
	try {
		const response = await axios.get(`models`);
		const data = arrayToObject(response.data, 'id');
		return data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const getModelById = createAsyncThunk('Model/getModelById', async (modelId) => {
	try {
		const response = await axios.get(`model/${modelId}`);
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const createModel = createAsyncThunk('Model/createModel', async (requestData) => {
	try {
		const response = await axios.post(`model`, requestData);
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const updateModel = createAsyncThunk('Model/updateModel', async (requestData) => {
	try {
		const response = await axios.put(`model/${requestData.id}`, requestData);
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const deleteModel = createAsyncThunk('Model/deleteModel', async (id) => {
	try {
		await axios.delete(`model/${id}`);
		return id;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const modelSlice = createSlice({
	name: 'Model',
	initialState,
	reducers: {},
	extraReducers: {
		[getAllModels.pending]: (state) => {
			state.Models.loading = true;
		},
		[getAllModels.rejected]: (state) => {
			state.Models.loading = false;
		},
		[getAllModels.fulfilled]: (state, action) => {
			state.Models.loading = false;
			state.Models.modelList = Object.values(action.payload);
		},
		[createModel.pending]: (state) => {
			state.Models.loading = true;
		},
		[createModel.rejected]: (state) => {
			state.Models.loading = false;
		},
		[createModel.fulfilled]: (state, action) => {
			state.Models.loading = false;
			state.Models.modelList.push(action.payload);
		},
		[updateModel.pending]: (state) => {
			state.Models.loading = true;
		},
		[updateModel.rejected]: (state) => {
			state.Models.loading = false;
		},
		[updateModel.fulfilled]: (state, action) => {
			state.Models.loading = false;
			state.Models.modelList = state.Models.modelList.map((item) => {
				if (item.id === action.payload.id) {
					item = action.payload;
					return item;
				}
				return item;
			});
		},
		[deleteModel.pending]: (state) => {
			state.Models.loading = true;
		},
		[deleteModel.rejected]: (state) => {
			state.Models.loading = false;
		},
		[deleteModel.fulfilled]: (state, action) => {
			state.Models.loading = false;
			state.Models.modelList = state.Models.modelList.filter((item) => {
				return item.id !== action.payload;
			});
		},
	},
});

export default modelSlice.reducer;
