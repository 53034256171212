import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../axios';

const initialState = {
	logs: { loading: false, logsList: null },
};

export const getAllLogsByCriteria = createAsyncThunk('logs/getAllLogsByCriteria', async (data) => {
	try {
		const response = await axiosInstance.patch(`/audit-logs`, data);
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
			/* data: error.response.data, */
		};
		throw customError;
	}
});

export const logsSlice = createSlice({
	name: 'logs',
	initialState,
	reducers: {},
	extraReducers: {
		[getAllLogsByCriteria.pending]: (state) => {
			state.logs.loading = true;
		},
		[getAllLogsByCriteria.rejected]: (state) => {
			state.logs.loading = false;
		},
		[getAllLogsByCriteria.fulfilled]: (state, action) => {
			state.logs.loading = false;
			state.logs.logsList = Object.values(action.payload);
		},
	},
});

export default logsSlice.reducer;
