/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export const getAuditDescription = (action, user, date, bookingId) => {
	switch (action) {
		case 'USER_LOGS_IN':
			return `<strong>${user?.name} ${user?.surname}</strong> has successfully logged in.`;
		case 'DRIVER_GOES_OFFLINE':
			return `<strong>${user?.name} ${user?.surname}</strong> has changed their status to offline.`;
		case 'DRIVER_GOES_ONLINE':
			return `<strong>${user?.name} ${user?.surname}</strong> has changed their status to online.`;
		case 'CANCELLATION_OF_BOOKING':
			return `<strong>${user?.name} ${user?.surname}</strong> has cancelled a booking with booking ID: <strong>${bookingId}</strong>.`;
		case 'DECLINE_OF_BOOKING':
			return `<strong>${user?.name} ${user?.surname}</strong> has declined a request for booking.`;
		case 'ACCEPTANCE_OF_BOOKING':
			return `<strong>${user?.name} ${user?.surname}</strong> has accepted a request for booking.`;
		case 'REQUEST_FOR_BOOKING':
			return `<strong>${user?.name} ${user?.surname}</strong> has submitted a request for booking.`;
		case 'DRIVER_REQUESTED_FOR_BOOKING':
			return `<strong>${user?.name} ${user?.surname}</strong> get requested for a booking.`;
		case 'USER_LOGS_OUT':
			return `<strong>${user?.name} ${user?.surname}</strong> has successfully logged out.`;
		case 'REFUND_OF_BOOKING':
			return `<strong>${user?.name} ${user?.surname}</strong> has requested a refund.`;
		default:
			return '';
	}
};

export const getAuditDescriptionWitoutStyle = (action, user) => {
	switch (action) {
		case 'USER_LOGS_IN':
			return `${user?.name} ${user?.surname} has successfully logged in.`;
		case 'DRIVER_GOES_OFFLINE':
			return `${user?.name} ${user?.surname} has changed their status to offline.`;
		case 'DRIVER_GOES_ONLINE':
			return `${user?.name} ${user?.surname} has changed their status to online.`;
		case 'CANCELLATION_OF_BOOKING':
			return `${user?.name} ${user?.surname} has cancelled a booking.`;
		case 'DECLINE_OF_BOOKING':
			return `${user?.name} ${user?.surname} has declined a request for booking.`;
		case 'ACCEPTANCE_OF_BOOKING':
			return `${user?.name} ${user?.surname} has accepted a request for booking.`;
		case 'REQUEST_FOR_BOOKING':
			return `${user?.name} ${user?.surname} has submitted a request for booking.`;
		case 'DRIVER_REQUESTED_FOR_BOOKING':
			return `${user?.name} ${user?.surname} get requested for a booking.`;
		case 'USER_LOGS_OUT':
			return `${user?.name} ${user?.surname} has successfully logged out.`;
		case 'REFUND_OF_BOOKING':
			return `${user?.name} ${user?.surname} has requested a refund.`;
		default:
			return '';
	}
};

export const auditLogsActions = [
	{
		label: 'User logs in',
		value: 'USER_LOGS_IN',
	},
	{
		label: 'Driver goes offline',
		value: 'DRIVER_GOES_OFFLINE',
	},
	{
		label: 'Driver goes online',
		value: 'DRIVER_GOES_ONLINE',
	},
	{
		label: 'Cancellation of booking',
		value: 'CANCELLATION_OF_BOOKING',
	},
	{
		label: 'Decline of booking',
		value: 'DECLINE_OF_BOOKING',
	},
	{
		label: 'Acceptance of booking',
		value: 'ACCEPTANCE_OF_BOOKING',
	},
	{
		label: 'Request for booking',
		value: 'REQUEST_FOR_BOOKING',
	},
	{
		label: 'Refund of booking',
		value: 'REFUND_OF_BOOKING',
	},
	{
		label: 'Driver requested for booking',
		value: 'DRIVER_REQUESTED_FOR_BOOKING',
	},
	{
		label: 'User logs out',
		value: 'USER_LOGS_OUT',
	},
];

export const refundReasonsLabels = (reason) => {
	switch (reason) {
		case 'SERVICE_QUALITY':
			return 'Service quality';
		case 'FAILURE_TO_PROVIDE_SERVICE':
			return 'Failure to provide service';
		case 'PAYMENT_ISSUES':
			return 'Payment issues';
		case 'SAFETY_CONCERNS':
			return 'Safety concerns';
		case 'UNEXPECTED_CIRCUMSTANCES':
			return 'Unexpected circumstances';
		case 'OTHER':
			return 'Other';
		default:
			return '--';
	}
};
