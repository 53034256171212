/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
/* eslint-disable no-template-curly-in-string */
import { Button, DatePicker, Divider, Form, Input, Select, Spin } from 'antd';
import React, { useState } from 'react';
import 'dayjs/locale/en'; // Import the English locale
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import UploadComponent from '../Global/CustomAvatar/UploadComponent';
import { isTodayOrBefore } from '../../Utils/date-utils';

dayjs.extend(weekday);
dayjs.extend(localeData);
const validateMessages = {
	types: {
		email: '${label} is not a valid email',
	},
};
const dateFormat = 'YYYY-MM-DD'; // Format can vary based on your requirements

const FleetOperatorForm = ({
	fleet,
	onSave,
	onSaveFailed,
	uploading,
	fileList,
	setFileList,
	handleUpload,
	setIsFileChosen,
	onDirty,
	onCancel,
	unsavedChanges,
}) => {
	const [form] = Form.useForm();
	const [fleetType, setFleetType] = useState(fleet?.type || null);
	const onTypeChange = (value) => {
		setFleetType(value);
	};

	const initialValues = {
		...fleet,
		expiryDate: fleet?.expiryDate ? dayjs(fleet?.expiryDate) : null,
	};

	const validateIBAN = (rule, value, callback) => {
		if (value && value.length > 34) {
			callback('IBAN must be a maximum of 34 characters.');
		} else {
			callback();
		}
	};

	return (
		<Form
			name='Fleet Operator'
			layout='vertical'
			autoComplete='off'
			initialValues={initialValues}
			validateMessages={validateMessages}
			onFinish={onSave}
			form={form}
			onFinishFailed={onSaveFailed}
			onValuesChange={() => onDirty()}>
			<Form.Item
				label='Fleet Operator Name'
				name='name'
				rules={[{ required: true, message: 'Please input the name' }]}>
				<Input />
			</Form.Item>
			<Form.Item
				label='Type'
				name='type'
				rules={[{ required: true, message: 'Please select the type' }]}>
				<Select
					size='medium'
					showArrow
					showSearch
					optionFilterProp='filterBy'
					allowClear
					optionLabelProp='label'
					popupMatchSelectWidth={false}
					onChange={onTypeChange} // Listen for changes in the 'type' field
				>
					<Select.Option key={1} value='COMPANY' filterBy='Company' label='Company'>
						Company
					</Select.Option>
					<Select.Option
						key={2}
						filterBy='Self employed'
						value='SELF_EMPLOYED'
						label='Self employed'>
						Self employed
					</Select.Option>
				</Select>
			</Form.Item>
			<Form.Item label='Address' name='address'>
				<Input />
			</Form.Item>
			<Form.Item
				label='VAT Number'
				name='vatNumber'
				rules={[{ required: true, message: 'Please input the VAT Number' }]}>
				<Input />
			</Form.Item>
			{fleetType === 'SELF_EMPLOYED' && (
				<Form.Item
					label='ID Card Number'
					name='idCardNumber'
					rules={[{ required: true, message: 'Please input the ID Card Number' }]}>
					<Input />
				</Form.Item>
			)}

			{fleetType === 'COMPANY' && (
				<Form.Item
					label='C Number'
					name='companyNumber'
					rules={[{ required: true, message: 'Please input the C Number' }]}>
					<Input />
				</Form.Item>
			)}
			<Divider orientationMargin={0} orientation='left'>
				Contact details
			</Divider>
			<Form.Item
				label='Name'
				name='contactName'
				rules={[{ required: true, message: 'Please input  Contact Name' }]}>
				<Input />
			</Form.Item>
			<Form.Item
				label='Surname '
				name='contactSurname'
				rules={[{ required: true, message: 'Please input  Contact Surname' }]}>
				<Input />
			</Form.Item>
			<Form.Item
				label='Email'
				name='contactEmail'
				rules={[
					{
						required: true,
						message: 'Please input  Email!',
					},
					{
						pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
						message: 'Please enter a valid email address',
					},
				]}>
				<Input />
			</Form.Item>
			<Form.Item
				label='Phone'
				name='contactPhone'
				rules={[{ required: true, message: 'Please input  phone' }]}>
				<Input />
			</Form.Item>
			<Divider orientationMargin={0} orientation='left'>
				Garage Hire
			</Divider>
			<Form.Item label='LPTS' name='lpts'>
				<Input />
			</Form.Item>
			<Form.Item label='Expiry Date' name='expiryDate'>
				<DatePicker
					style={{ width: '100%' }}
					format={dateFormat}
					disabledDate={isTodayOrBefore} // Call the custom function to disable previous dates
					locale='en' // Apply the English locale to the DatePicker
				/>
			</Form.Item>
			<Form.Item label='Document' name='documentId' style={{ textAlign: 'center' }}>
				<Spin spinning={uploading}>
					<UploadComponent
						fileList={fileList}
						setFileList={setFileList}
						handleUpload={handleUpload}
						uploading={uploading}
						setIsFileChosen={setIsFileChosen}
						withoutCrop
					/>
				</Spin>
			</Form.Item>
			<Divider orientationMargin={0} orientation='left'>
				Bank Details{' '}
			</Divider>
			<Form.Item label='Account Holder Name' name='accountHolderName'>
				<Input />
			</Form.Item>
			<Form.Item
				label='IBAN'
				name='iban'
				rules={[
					{
						validator: validateIBAN,
					},
				]}>
				<Input />
			</Form.Item>
			<Form.Item label='Bank Name' name='bankName'>
				<Input />
			</Form.Item>
			<Form.Item label='BIC/Swift Code' name='bicSwiftCode'>
				<Input />
			</Form.Item>

			<Form.Item style={{ float: 'right' }}>
				<Button
					type='default'
					style={{ marginBottom: 32, marginRight: 8 }}
					onClick={() => onCancel(unsavedChanges)}>
					Cancel
				</Button>
				<Button
					type='primary'
					style={{ marginBottom: 32 }}
					htmlType='submit'
					disabled={!unsavedChanges}>
					{!fleet ? 'Save' : 'Update'}
				</Button>
			</Form.Item>
		</Form>
	);
};

export default FleetOperatorForm;
