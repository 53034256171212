/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
import React from 'react';
import { Card, Statistic } from 'antd';
import { useSelector } from 'react-redux';

const CustomCountingCard = ({ title, count, currency, icon, showPrecision }) => {
	const { Brand } = useSelector((state) => state.brand);

	return (
		<Card style={{ border: '1px solid black' }}>
			<Statistic
				title={title}
				value={count}
				precision={showPrecision ? 2 : 0}
				valueStyle={{
					color: Brand?.brandData?.webPrimary || '#ffa500',
				}}
				prefix={icon}
				suffix={currency}
			/>
		</Card>
	);
};

export default CustomCountingCard;
