import { message } from 'antd';
import lodash from 'lodash';

export const throttledErrorMessage = (errorMessage, type) =>
	lodash.throttle(
		() => {
			message[type || 'error'](errorMessage);
		},
		2000,
		{ trailing: false },
	); // only display message every 2 seconds

export const showConnectivityErrorMessage = throttledErrorMessage(
	'Connectivity problem. Please try again.',
	'error',
);
export const showUnhandledErrorMessage = throttledErrorMessage(
	'An error occurred. Please try again.',
	'error',
);
export const showAuthorizationFailedErrorMessage = throttledErrorMessage(
	'You are not allowed to perform this operation.',
	'error',
);
export const showHasReferenceErrorMessage = throttledErrorMessage(
	'This item has reference and cannot be deleted.',
	'warning',
);
export const showRequestLargerThanMaxSizeErrorMessage = throttledErrorMessage(
	'File size is larger than 2MB.',
	'warning',
);
