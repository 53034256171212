/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
import { Alert } from 'antd';
import React from 'react';

const AlertBanner = ({ title, description, type, showIcon }) => {
	return <Alert message={title} description={description} type={type} showIcon={showIcon} />;
};

export default AlertBanner;
