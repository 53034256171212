/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/jsx-no-useless-fragment */
// eslint-disable-next-line no-unused-vars
import useSelection from 'antd/es/table/hooks/useSelection';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

const NotFound = () => {
	const navigate = useNavigate();
	const { auth } = useSelector((state) => state.auth);

	useEffect(() => {
		if (!auth.token) {
			navigate('/app/login');
		} else {
			navigate('/app/main');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [auth.token]);
	return <p>Not Found</p>;
};

export default NotFound;
