/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Avatar, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { downloadImage } from '../../../redux/fileSlice';
import './style.css';

const ImageComponent = ({ imageId, size, shape }) => {
	const dispatch = useDispatch();
	const [imageUrl, setImageUrl] = useState(null);
	const [makingApiCall, setMakingApiCall] = useState(false);
	const fetchImage = async () => {
		setMakingApiCall(true);
		await dispatch(downloadImage(imageId))
			.unwrap()
			.then((data) => {
				setImageUrl(URL.createObjectURL(data));
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				setMakingApiCall(false);
			});
	};
	useEffect(() => {
		fetchImage();
	}, [dispatch, imageId]);

	return (
		<Spin
			size='large'
			indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
			tip={
				<div>
					<h4>Loading...</h4>
				</div>
			}
			spinning={makingApiCall}>
			{imageUrl && (
				<Avatar
					src={imageUrl}
					style={{ marginRight: '0.5em' }}
					size={size || { xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
					gap={4}
					shape={shape || 'circle'}
					className='custom-avatar'
				/>
			)}
		</Spin>
	);
};

export default ImageComponent;
