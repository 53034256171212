/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Divider, Form, Input, Modal, Row, Select, message } from 'antd';
import L from 'leaflet';
import {
	FeatureGroup,
	MapContainer,
	Polygon,
	TileLayer,
	Tooltip,
	ZoomControl,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import { EditControl } from 'react-leaflet-draw';
import { useDispatch, useSelector } from 'react-redux';
import { EyeOutlined, DeleteOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import {
	deleteAllowedArea,
	getAllAllowedAreasForCorporate,
	resetSelectedAllowedArea,
	saveAllowedArea,
	setSelectedAllowedArea,
} from '../../redux/corporateSlice';
import { style } from '../../Styles';
import CustomTableComponents from '../Global/CustomTableComponents';

const { Option } = Select;

const defaultBounds = [
	{
		lat: 35.90066951,
		lng: 14.42897017,
	},
	{
		lat: 35.80066951,
		lng: 14.32897017,
	},
	{
		lat: 35.99066951,
		lng: 14.52897017,
	},
];

const AllowedArea = ({ onModalClose }) => {
	const [coordinatesLocation, setCoordinatesLocation] = useState([]);
	const { selectedCorporate, corporateAllowedArea } = useSelector((state) => state.corporates);
	const { Brand } = useSelector((state) => state.brand);
	const [selectedAllowedArea, setselectedAllowedArea] = useState(null);
	const [initialValue, setInitialValue] = useState({});
	const [filter, setFilter] = useState('');
	const [loadMap, setLoadMap] = useState(false);
	const [mapBounds, setMapBounds] = useState(defaultBounds);

	const handleMoveToCoordinates = (record) => {
		// Assuming your list of coordinates is an array of [latitude, longitude] pairs
		setCoordinatesLocation(record?.points.map((item) => [item.latitude, item.longitude]));
		const orderedPoints = orderPoints(record?.points || []);
		setInitialValue({
			name: record?.name || '',
			type: record?.type || '',
			allowedAreaPoints: orderedPoints.map((item) => [item.latitude, item.longitude]) || [],
		});
		form.setFieldsValue({
			name: record?.name || '',
			type: record?.type || '',
		});

		if (record?.points?.length > 0) {
			const coordinates = record?.points;
			// Calculate bounds based on coordinates
			const bounds = coordinates.map((coord) => {
				return {
					lat: coord.latitude,
					lng: coord.longitude,
				};
			});
			// Set the map bounds to cover all coordinates
			setMapBounds(bounds);
		}
	};

	const dispatch = useDispatch();
	const [form] = Form.useForm();

	const fetchAllowedAreaForSelectedCorporate = () => {
		dispatch(getAllAllowedAreasForCorporate(selectedCorporate?.corporate?.id));
	};

	useEffect(() => {
		if (selectedCorporate?.corporate?.id) fetchAllowedAreaForSelectedCorporate();
	}, [dispatch, selectedCorporate?.corporate?.id]);

	const handleCompleteDraw = (e) => {
		const { layer } = e;
		const coords = layer?.getLatLngs();
		if (coords) {
			const coordsValue = coords && Object.values(coords[0]);
			const coordsArray = coordsValue.map((item) => Object.values(item));
			setCoordinatesLocation({ points: coordsArray });
		}
	};

	const handleUpdateDraw = (e) => {
		let coords;
		e.layers.eachLayer((a) => {
			coords = a.getLatLngs();
		});
		const coordsValue = coords && Object.values(coords[0]);
		if (coordsValue) {
			const coordsArray = coordsValue?.map((item) => Object.values(item));
			setCoordinatesLocation({ points: coordsArray });
		}
	};
	const handleDeleteteDraw = () => {
		// onDirty();
		setCoordinatesLocation([]);
	};
	const saveLocation = (values, coordinatesLocations) => {
		if (coordinatesLocations.length === 0) {
			message.error('Please draw the Allowed Area on the map');
			return;
		}
		const finalData = {
			...values,
			corporateId: selectedCorporate?.corporate?.id,
			points: coordinatesLocations.points.map((item, index) => {
				return {
					latitude: item[0],
					longitude: item[1],
					pointOrder: index + 1,
				};
			}),
		};
		dispatch(saveAllowedArea(finalData))
			.unwrap()
			.then((err) => {
				message.success('Allowed Area saved successfully');
				form.resetFields();
				setCoordinatesLocation([]);
				// onModalClose();
			})
			.catch((err) => {
				if (err.message === 'CORPORATE_ALLOWED_AREA_ALREADY_EXISTS') {
					message.warning('Allowed Area with same name already exists');
				} else {
					message.error('Error saving Allowed Area');
				}
			});
	};

	// useEffect to load the map
	useEffect(() => {
		setLoadMap(true);
		return () => {
			setLoadMap(false);
		};
	}, []);
	const onDelete = (recordName, id) => {
		Modal.confirm({
			title: (
				<>
					Are you sure you want to delete <b>{recordName}</b> ?
				</>
			),
			icon: (
				<ExclamationCircleFilled
					style={{ color: Brand?.brandData?.webPrimary || 'orange' }}
				/>
			),
			okText: 'Yes', // Customize OK button text
			cancelText: 'No', // Customize Cancel button text
			cancelButtonProps: {
				style: {
					color: Brand?.brandData?.webPrimary || 'orange',
					backgroundColor: 'white',
					borderColor: Brand?.brandData?.webPrimary || 'orange',
				},
			},
			okButtonProps: {
				style: {
					color: 'white',
					backgroundColor: Brand?.brandData?.webPrimary || 'orange',
				},
			},
			onOk: () => {
				handleDelete(id);
			},
			onCancel: () => {},
		});
	};

	const handleDelete = (id) => {
		dispatch(deleteAllowedArea(id))
			.unwrap()
			.then(() => {
				message.success('Allowed Area deleted successfully');
				form.resetFields();
				setCoordinatesLocation([]);
			})
			.catch(() => {
				message.error('Error deleting Allowed Area');
			});
	};

	// eslint-disable-next-line consistent-return
	const getSpanStyle = (status) => {
		switch (status) {
			case 'PICKUP':
				return style.statusPending;
			case 'DROP_OFF':
				return style.statusAccepted;
			case 'BOTH':
				return style.statusRequested;
			default:
				break;
		}
	};

	const columns = [
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Type',
			key: 'type',
			render: (record) => {
				return (
					<span style={style.dataContainer}>
						<div style={getSpanStyle(record?.type)}>{record.type}</div>
					</span>
				);
			},
		},
		{
			title: 'Actions',
			key: 'actions',
			render: (record) => (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-evenly',
					}}>
					<Button
						icon={<EyeOutlined />}
						style={{ height: 30 }}
						onClick={() => {
							form.resetFields();
							setselectedAllowedArea(record);
							handleMoveToCoordinates(record);
						}}
					/>
					<Button
						icon={<DeleteOutlined />}
						style={{ height: 30 }}
						onClick={() => {
							onDelete(record.name, record.id);
						}}
					/>
				</div>
			),
			
		},
	];

	const data = corporateAllowedArea?.allowedArea
		? Object.values(corporateAllowedArea?.allowedArea)
		: [];
	const filtredData = data.filter((item) => {
		return item?.name.toLowerCase()?.indexOf(filter.toLowerCase()) !== -1;
	});

	const orderPoints = (points) => {
		const obj = Object.values(points).sort((a, b) => a.pointOrder - b.pointOrder);
		return obj;
	};

	const resetForm = () => {
		setSelectedAllowedArea(null);
		form.resetFields();
		form.setFieldValue('name', '');
		form.setFieldValue('type', '');
		setCoordinatesLocation([]);
		setInitialValue({});
		setMapBounds(defaultBounds);
	};

	return loadMap ? (
		<>
			<Divider orientation='left' orientationMargin='0'>
				Add/View Allowed Area
			</Divider>
			<Form
				form={form}
				name='location'
				layout='vertical'
				autoComplete='off'
				initialValues={initialValue}
				onFinish={(values) => saveLocation(values, coordinatesLocation)}>
				<Form.Item
					name='name'
					label='Area Name'
					rules={[
						{
							required: true,
						},
					]}
					style={{
						display: 'inline-block',
						width: 'calc(50% - 8px)',
					}}>
					<Input placeholder='Input area name' />
				</Form.Item>
				<Form.Item
					name='type'
					label='Type'
					rules={[
						{
							required: true,
						},
					]}
					style={{
						display: 'inline-block',
						width: 'calc(50% - 8px)',
						margin: '0 8px',
					}}>
					<Select placeholder='Select area type' style={{ width: '100%' }}>
						<Option value='PICKUP'>Pickup</Option>
						<Option value='DROP_OFF'>Drop Off</Option>

						<Option value='BOTH'>Both</Option>
					</Select>
				</Form.Item>

				<Form.Item label='Draw The allowed area' name='allowedAreaPoints'>
					<MapContainer
						bounds={mapBounds}
						zoom={1}
						scrollWheelZoom
						style={{ height: '300px' }}
						zoomControl={false}
						radius={200}>
						<TileLayer
							attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
							url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
						/>
						<FeatureGroup>
							<EditControl
								position='topleft'
								onEdited={(e) => handleUpdateDraw(e)}
								onCreated={(e) => handleCompleteDraw(e)}
								onDeleted={(e) => handleDeleteteDraw(e)}
								draw={{
									toolbar: {
										buttons: {
											polygon: 'Draw an awesome polygon',
										},
									},
									polygon: {
										icon: new L.DivIcon({
											iconSize: new L.Point(8, 8),
											className: 'leaflet-div-icon leaflet-editing-icon',
										}),
										shapeOptions: {
											guidelineDistance: 10,
											color: 'red',
											weight: 3,
										},
									},
									rectangle: false,
									circle: false,
									marker: false,
									circlemarker: false,
									polyline: false,
								}}
							/>
							{initialValue && initialValue?.allowedAreaPoints?.length > 0 && (
								<Polygon positions={initialValue?.allowedAreaPoints}>
									<Tooltip sticky>{initialValue?.name}</Tooltip>
								</Polygon>
							)}
						</FeatureGroup>
						<ZoomControl position='bottomleft' />
					</MapContainer>
				</Form.Item>

				<Form.Item style={{ float: 'right' }}>
					<Button
						type='default'
						style={{ marginBottom: 5, marginRight: 5 }}
						onClick={() => {
							resetForm();
						}}
						htmlType='button'>
						Reset
					</Button>

					<Button
						type='primary'
						style={{ marginBottom: 5, marginRight: 5 }}
						htmlType='submit'
						disabled={coordinatesLocation.length === 0}>
						Save
					</Button>
				</Form.Item>
			</Form>
			<Divider orientation='left' orientationMargin='0'>
				List of Allowed Areas
			</Divider>
			<Row style={{ marginBottom: 8, display: 'flex', justifyContent: 'end' }}>
				<div
					style={{
						...style.inlineContent,
						float: 'right',
						marginBottom: 8,
					}}>
					<Input.Search
						placeholder='Search'
						style={{ float: 'right', margin: '0 8px 8px 0' }}
						value={filter}
						onChange={(e) => setFilter(e.target.value)}
					/>
				</div>
			</Row>
			<CustomTableComponents
				isMakingApiCall={false}
				columns={columns}
				dataArray={filtredData || []}
				handleSelectedRow={() => {}}
				handleDoubleClickRow={() => {}}
				numberOfRows={5}
			/>
		</>
	) : (
		<div>Loading Map...</div>
	);
};

AllowedArea.propTypes = {
	onModalClose: PropTypes.func.isRequired,
};

export default AllowedArea;
