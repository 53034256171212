/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Divider, Form, Input, Row, Spin, message } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

import React, { useState } from 'react';
import { useForm } from 'antd/es/form/Form';
import { useDispatch, useSelector } from 'react-redux';
import CustomTableComponents from '../Global/CustomTableComponents';

import { style } from '../../Styles';
import {
	deleteCorporateRider,
	inviteCorporateRider,
	resendInvitationToRider,
} from '../../redux/corporateSlice';
import ActionButtonWithReSendEmail from '../Global/ActionButtonWithReSendEmail';

const RidersList = () => {
	const [form] = useForm();
	const [riderEmail, setRiderEmail] = useState('');
	const { corporateRiders, selectedCorporate } = useSelector((state) => state.corporates);
	const [filter, setFilter] = useState('');
	const dispatch = useDispatch();

	const onSave = () => {
		const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		if (!emailPattern.test(riderEmail)) {
			message.error('Please enter a valid email address');
			return;
		}
		const encodedEmail = encodeURIComponent(riderEmail); // encode the email because the value is sent in query param and it should be encoded to accept the symbols in email
		dispatch(
			inviteCorporateRider({
				email: encodedEmail,
				corporateId: selectedCorporate?.corporate?.id,
			}),
		)
			.unwrap()
			.then(() => {
				message.success('Rider invited successfully');
				setRiderEmail('');
				form.resetFields();
			})
			.catch((error) => {
				if (error.message === 'CORPORATE_RIDER_ALREADY_INVITED') {
					message.error('Rider already invited');
				} else {
					message.error('Error inviting rider');
				}
			});
	};
	const onEmailChange = (e) => {
		setRiderEmail(e.target.value);
	};

	const deleteRiderAccess = (record) => {
		const encodedEmail = encodeURIComponent(record.email); // encode the email because the value is sent in query param and it should be encoded to accept the symbols in email
		dispatch(
			deleteCorporateRider({
				encodedEmail,
				corporateId: selectedCorporate?.corporate?.id,
				originalEmail: record.email,
			}),
		)
			.unwrap()
			.then(() => {
				message.success('Rider deleted successfully');
			})
			.catch(() => {
				message.error('Error deleting rider');
			});
	};

	// eslint-disable-next-line consistent-return
	const getSpanStyle = (status) => {
		switch (status) {
			case 'PENDING':
				return style.statusPending;
			case 'ACCEPTED':
				return style.statusAccepted;
			default:
				break;
		}
	};

	const handleResendInvitationToRider = (record) => {
		const encodedEmail = encodeURIComponent(record.email); // encode the email because the value is sent in query param and it should be encoded to accept the symbols in email
		dispatch(
			resendInvitationToRider({
				encodedEmail,
				corporateId: selectedCorporate?.corporate?.id,
				email: record.email,
			}),
		)
			.unwrap()
			.then(() => {
				message.success('Rider invited successfully');
			})
			.catch(() => {
				message.error('Error inviting rider');
			});
	};

	const columns = [
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
		},
		{
			title: 'Status',

			key: 'status',
			render: (record) => {
				return (
					<span style={style.dataContainer}>
						<div style={getSpanStyle(record?.status)}>{record.status}</div>
					</span>
				);
			},
		},

		{
			title: 'Actions',
			key: 'actions',
			render: (record) => (
				<ActionButtonWithReSendEmail
					record={record}
					recordName={record.email}
					onDelete={() => deleteRiderAccess(record)}
					onResend={() => handleResendInvitationToRider(record)}
				/>
			),
		},
	];

	const data = corporateRiders?.ridersList ? Object.values(corporateRiders?.ridersList) : [];
	const filtredData = data.filter((item) => {
		return (
			item?.email.toLowerCase()?.indexOf(filter.toLowerCase()) !== -1 ||
			item?.status.toLowerCase()?.indexOf(filter.toLowerCase()) !== -1
		);
	});

	return (
		<Spin spinning={corporateRiders?.loading}>
			<Divider orientation='left' orientationMargin='0'>
				Invite new rider
			</Divider>
			<Form
				form={form}
				name='corporate_riders'
				layout='vertical'
				autoComplete='off'
				onFinish={onSave}
				style={{ width: '100%', marginBottom: '10%' }}>
				<Form.Item
					label='Email'
					rules={[
						{
							required: true,
							message: 'Please input email address',
						},
					]}>
					<Input onChange={onEmailChange} value={riderEmail} style={{ width: '89%' }} />
					<Button
						type='primary'
						onClick={(values) => onSave(values)}
						icon={<CheckOutlined />}
						style={{ width: '7%', marginLeft: '10px' }}
					/>
				</Form.Item>
			</Form>
			<Divider orientation='left' orientationMargin='0'>
				List of riders
			</Divider>
			<Row style={{ marginBottom: 8, display: 'flex', justifyContent: 'end' }}>
				<div
					style={{
						...style.inlineContent,
						float: 'right',
						marginBottom: 8,
					}}>
					<Input.Search
						placeholder='Search'
						style={{ float: 'right', margin: '0 8px 8px 0' }}
						value={filter}
						onChange={(e) => setFilter(e.target.value)}
					/>
				</div>
			</Row>

			<CustomTableComponents
				isMakingApiCall={false}
				columns={columns}
				dataArray={filtredData || []}
				handleDoubleClickRow={(record) => {}}
				handleSelectedRow={() => {}}
				numberOfRows={5}
			/>
		</Spin>
	);
};

export default RidersList;
