/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react';
import { Card, Tabs, message } from 'antd';
import {
	UserOutlined,
	SettingOutlined,
	AuditOutlined,
	BgColorsOutlined,
	BulbOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import Users from '../Users/Users';
import { style } from '../../Styles';
import { getSettings, resetSettingsData } from '../../redux/settingsSlice';
import AuditLogs from './AuditLogs';
import { getTenantBrand } from '../../redux/tenantBrandSlice';
import SettingsForm from './SettingsForm';
import TenantBrandForm from './TenantBrandForm';
import { getCancellationPolicy } from '../../redux/CancellationPolicySlice';
import CancellationPolicyForm from './CancellationPolicyForm';
import TenantFeature from './TenantFeature';
import { fetchGlobalFeatures } from '../../redux/tenantFeatureSlice';
import CarPoolingSettingForm from './CarPoolingSettingForm';
import { getCarPoolingSetting } from '../../redux/carPoolingSettingSlice';
import { TenantFeaturesContext } from '../../Context/TenantFeatureContext';

// import TenantFeature from './TenantFeature';

const SettingsScreen = ({ role }) => {
	const { tenantFeatures, fetchCurrentTenantFeatures } = useContext(TenantFeaturesContext);
	const [activeTab, setActiveTab] = useState('1');
	const [initalValues, setInitialValues] = useState({});
	const [makingApiCall, setMakingApiCall] = useState(false);
	const { Settings } = useSelector((state) => state.settings);
	const { CancellationPolicy } = useSelector((state) => state.cancellationPolicy);

	const { globalFeatures } = useSelector((state) => state.tenantFeatures);
	const dispatch = useDispatch();
	const fetchCancellationPolicy = () => {
		setMakingApiCall(true);
		dispatch(getCancellationPolicy())
			.unwrap()
			.then(() => {})
			.catch(() => {})
			.finally(() => {
				setMakingApiCall(false);
			});
	};
	useEffect(() => {
		fetchCancellationPolicy();
	}, []);
	const fetchSettings = () => {
		setMakingApiCall(true);
		dispatch(getSettings())
			.unwrap()
			.then((res) => {
				setInitialValues(res);
			})
			.catch((e) => {
				if (e.name === 'SETTINGS_NOT_FOUND_FOR_TENANT') {
					dispatch(resetSettingsData());
					message.warning(
						'Settings not found for this tenant. Please fill the form and save.',
					);
				} else {
					message.error('Something went wrong. Please try again later.');
				}
			})
			.finally(() => {
				setMakingApiCall(false);
			});
	};

	const fetchSCarPoolingSettings = () => {
		setMakingApiCall(true);
		dispatch(getCarPoolingSetting())
			.unwrap()
			.then()
			.catch((e) => {
				if (e.name === 'CAR_POOLING_SETTING_NOT_FOUND_FOR_THIS_TENANT') {
					dispatch(resetSettingsData());
					message.warning(
						'Car pooling Settings not found for this tenant. Please fill the form and save.',
					);
				} else {
					message.error('Something went wrong. Please try again later.');
				}
			})
			.finally(() => {
				setMakingApiCall(false);
			});
	};

	const getGlobalFeatures = async () => {
		setMakingApiCall(true);
		dispatch(fetchGlobalFeatures())
			.unwrap()
			.then(fetchCurrentTenantFeatures())
			.catch()
			.finally(() => {
				setMakingApiCall(false);
			});
	};

	useEffect(() => {
		fetchSettings();
		getGlobalFeatures();
		fetchSCarPoolingSettings();
	}, [dispatch, activeTab]);
	const onChange = (key) => {
		setActiveTab(key);
	};
	const fetchTenantBrand = () => {
		dispatch(getTenantBrand());
	};

	useEffect(() => {
		fetchTenantBrand();
		console.log(activeTab);
	}, []);

	const tabItems = [
		{
			label: (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-start',
					}}>
					<UserOutlined />
					<span style={style.navItem}>Users</span>
				</div>
			),
			key: '1',
			children: <Users />,
			show: true,
		},
		{
			label: (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-start',
					}}>
					<SettingOutlined />
					<span style={style.navItem}>Settings</span>
				</div>
			),
			key: '2',
			children: (
				<SettingsForm
					initalValues={initalValues}
					makingApiCall={makingApiCall}
					Settings={Settings}
					dispatch={dispatch}
					setInitialValues={setInitialValues}
					setMakingApiCall={setMakingApiCall}
					role={role}
				/>
			),
			show: true,
		},
		{
			label: (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-start',
					}}>
					<SettingOutlined />
					<span style={style.navItem}>Cancellation policy</span>
				</div>
			),
			key: '3',
			children: (
				<CancellationPolicyForm
					initialValues={CancellationPolicy?.cancellationPolicyData}
					makingApiCall={makingApiCall}
					setMakingApiCall={setMakingApiCall}
				/>
			),
			show: true,
		},
		{
			label: (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-start',
					}}>
					<BgColorsOutlined />
					<span style={style.navItem}>Tenant Brand</span>
				</div>
			),
			key: '4',
			children: <TenantBrandForm />,
			show: true,
		},
		{
			label: (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-start',
					}}>
					<AuditOutlined />
					<span style={style.navItem}>Audit Log</span>
				</div>
			),
			key: '5',
			children: (
				<AuditLogs
					makingApiCall={makingApiCall}
					dispatch={dispatch}
					setMakingApiCall={setMakingApiCall}
					role={role}
				/>
			),
			show: true,
		},
		{
			label: (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-start',
					}}>
					<BulbOutlined />
					<span style={style.navItem}>Tenant Features</span>
				</div>
			),
			key: '6',
			children: <TenantFeature role={role} globalFeatures={globalFeatures} />,
			show: true,
		},
		{
			label: (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-start',
					}}>
					<BulbOutlined />
					<span style={style.navItem}>Car Pooling Settings</span>
				</div>
			),
			key: '7',
			children: (
				<CarPoolingSettingForm
					makingApiCall={makingApiCall}
					dispatch={dispatch}
					setMakingApiCall={setMakingApiCall}
					role={role}
				/>
			),
			show: tenantFeatures.find((f) => f.feature.id === 1),
		},
	];

	return (
		<Card style={{
					margin: 16,
					maxHeight: '90%', // Set maximum width
					height: 'auto', // Allow width to be dynamic
				}}>
			<Tabs
				onChange={onChange}
				type='card'
				items={tabItems.filter((tab) => tab.show)}
				defaultActiveKey={activeTab}
			/>
		</Card>
	);
};

export default SettingsScreen;
