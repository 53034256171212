// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/no-unresolved */
import { useEffect, useState } from 'react';
import EventSource from 'eventsource'; // Use the polyfill for broader browser support
import { useSelector } from 'react-redux';
import env from '../Utils/Constants/env';

const baseURL = `${env.baseUri}`;
const useSSE = (endpoint, eventListener) => {
	// Get the current protocol (http or https)
	const currentProtocol = window.location.protocol;

	// Get the current domain
	const currentDomain = window.location.host;

	const { auth } = useSelector((state) => state.auth);
	const eventSourceURL =
		process.env.NODE_ENV === 'development'
			? `${baseURL}${endpoint}`
			: `${currentProtocol}//${currentDomain}/api/v1/web/${endpoint}`;
	const [sseData, setSSEData] = useState(null);

	useEffect(() => {
		const createEventSourceWithToken = (urlSource, token) => {
			const headers = {
				Authorization: `Bearer ${token}`,
				Accept: '*/*',
				connection: 'keep-alive',
				AcceptEncoding: 'gzip, deflate, br',
			};

			// Create an EventSource with custom headers
			return new EventSource(urlSource, { headers });
		};
		console.log('eventSourceURL', eventSourceURL);
		const eventSourceInstance = createEventSourceWithToken(
			// Create a new URL instance
			eventSourceURL,
			auth.token, // Assuming your Redux state structure includes an auth.token property
		);

		// Add event listeners to handle different types of events
		eventSourceInstance.addEventListener(eventListener, (event) => {
			try {
				const data = JSON.parse(event.data);
				// remove the duplicate drivers by driver.user.id
				const uniqueDrivers = data?.filter(
					(driver, index, self) =>
						index === self.findIndex((t) => t.user.id === driver.user.id),
				);
				setSSEData(uniqueDrivers);
			} catch (error) {
				console.error('Error parsing JSON data:', error);
			}
		});

		// Handle errors
		eventSourceInstance.onerror = (error) => {
			console.error('SSE Error:', error);
			eventSourceInstance.close();
		};

		// Clean up the EventSource when the component unmounts
		return () => {
			eventSourceInstance.close();
		};
	}, [auth.token, eventSourceURL, eventListener]);

	return sseData;
};

export default useSSE;
