/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Drawer, Spin, message } from 'antd';
import {
	createPurchasedBundle,
	getPurchasedBundleById,
	resetSelectedPurchasedBundle,
	updatePurchasedBundle,
} from '../../../redux/purchasedBundleSlice';
import ShowConfirmClose from '../../Modals/ShowConfirmClose';
import PurchasedBundleForm from './PurchasedBundleForm';

const PurchasedBundleDrawer = ({ onClose, purchasedBundleId, dispatch, setPurchasedBundleId }) => {
	const [unsavedChanges, setUnsavedChanges] = useState(false);
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const { selectedPurchasedBundle } = useSelector((state) => state.purchasedBundles);
	const [purchasedBundle, setPurchasedBundle] = useState(null);

	const handleClose = useCallback(
		(hasUnsavedChanges) => {
			if (hasUnsavedChanges && !confirmationOpen) {
				setConfirmationOpen(true); // Mark that the confirmation modal is open
				ShowConfirmClose(() => {
					setConfirmationOpen(false); // Reset when the confirmation modal is closed
					onClose();
					setPurchasedBundleId(null);
				});
			} else {
				onClose();
			}
			dispatch(resetSelectedPurchasedBundle());
		},
		[onClose, confirmationOpen],
	);

	const handleDirty = useCallback(() => {
		console.log('Dirty');
		setUnsavedChanges(true);
	}, []);

	const savePurchasedBundle = async (values) => {
		const payload = purchasedBundleId
			? {
					...values,
					id: purchasedBundleId,
					status: purchasedBundle?.status,
					codeValidUntil: values.codeValidUntil
						? values.codeValidUntil.format('YYYY-MM-DD')
						: null,
					startDate: values.startDate.format('YYYY-MM-DD'),
			  }
			: {
					...values,
					id: null,
					codeValidUntil: values.codeValidUntil
						? values.codeValidUntil.format('YYYY-MM-DD')
						: null,
					startDate: values.startDate.format('YYYY-MM-DD'),
			  };
		const action = purchasedBundleId
			? updatePurchasedBundle(payload)
			: createPurchasedBundle(payload);
		dispatch(action)
			.unwrap()
			.then(() => {
				message.success('Purchased Bundle saved successfully');
				setUnsavedChanges(false);
				onClose();
			})
			.catch((e) => {
				message.error('Error occurred while saving bundle');
				console.log('Error:', e);
			});
	};

	const onSaveFailed = (error) => {
		console.log('Failed:', error);
	};

	useEffect(() => {
		const abortController = new AbortController();
		if (purchasedBundleId) {
			dispatch(getPurchasedBundleById(purchasedBundleId))
				.unwrap()
				.then((data) => {
					setPurchasedBundle(data);
				})
				.catch((error) => console.log(error));
		}
		return () => abortController.abort();
	}, [purchasedBundleId, dispatch]);
	return (
		<Drawer
			width={500}
			title={
				!selectedPurchasedBundle?.purchasedBundle
					? 'Add Purchased Bundle'
					: 'Update Purchased Bundle'
			}
			placement='right'
			onClose={() => handleClose(unsavedChanges)}
			open>
			<Spin
				size='large'
				spinning={selectedPurchasedBundle?.loading}
				style={{ marginTop: 64 }}
				delay={500}>
				<PurchasedBundleForm
					purchasedBundle={purchasedBundle}
					onSave={savePurchasedBundle}
					onSaveFailed={onSaveFailed}
					onCancel={handleClose}
					onDirty={handleDirty}
					unsavedChanges={unsavedChanges}
					setUnsavedChanges={setUnsavedChanges}
					bundleId={purchasedBundleId}
				/>
			</Spin>
		</Drawer>
	);
};

export default PurchasedBundleDrawer;

PurchasedBundleDrawer.propTypes = {
	onClose: PropTypes.func.isRequired,
	purchasedBundleId: PropTypes.string.isRequired,
	dispatch: PropTypes.func.isRequired,
	setPurchasedBundleId: PropTypes.func.isRequired,
};
