import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../axios';
import { arrayToObject } from '../Utils/ArrayToObject';
import { permissionDriver } from '../Utils/Constants/utils';

const initialState = {
	Drivers: { loading: false, driversList: [] },
	SelectedDriver: { loading: false, driver: null },
};

export const getAllDrivers = createAsyncThunk('Driver/getAllDrivers', async () => {
	const body = {
		role: 'DRIVER',
	};
	try {
		const response = await axios.patch(`/drivers`, body);
		return arrayToObject(response.data, 'id');
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
			/* data: error.response.data, */
		};
		throw customError;
	}
});

export const addNewDriver = createAsyncThunk('Driver/addNewDriver', async (data) => {
	const Tmpdriver = data.driver;
	const role = permissionDriver;
	const driver = { ...Tmpdriver, role };
	try {
		const response = await axios.post(`drivers`, driver);
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
			/* data: error.response.data, */
		};
		throw customError;
	}
});

export const getDriverById = createAsyncThunk('Driver/getDriverById', async (data) => {
	try {
		const response = await axios.get(`drivers/${data.driverId}`);
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const deleteDriver = createAsyncThunk('Driver/deleteDriver', async (data) => {
	try {
		await axios.delete(`drivers/${data.driverId}/DRIVER`);
		return data.driverId;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const updateDriver = createAsyncThunk('Driver/updateDriver', async (data) => {
	try {
		const role = permissionDriver;
		const driver = { ...data.driver, role };
		const response = await axios.put(`drivers/${data.driverId}`, driver);
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const changeDriverStatus = createAsyncThunk('Driver/changeDriverStatus', async (data) => {
	try {
		await axios.put(`users/${data.userId}/status`, { active: data.active });
		return data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const changeDriverAllowBookingDecline = createAsyncThunk(
	'User/changeDriverAllowBookingDecline',
	async (data) => {
		try {
			const response = await axios.patch(
				`users/change-allow-booking-decline/${data.driverId}/${data.status}`,
			);
			return response.data;
		} catch (error) {
			const customError = {
				name: 'Error changing user Allow booking decline',
				message: error.response.data.message,
			};
			throw customError;
		}
	},
);
export const driverSlice = createSlice({
	name: 'Driver',
	initialState,
	reducers: {
		resetSelectedDriver: (state) => {
			state.SelectedDriver.driver = null;
		},
	},
	extraReducers: {
		[getAllDrivers.pending]: (state) => {
			state.Drivers.loading = true;
		},
		[getAllDrivers.rejected]: (state) => {
			state.Drivers.loading = false;
		},
		[getAllDrivers.fulfilled]: (state, action) => {
			state.Drivers.loading = false;
			state.Drivers.driversList = Object.values(action.payload);
		},
		[addNewDriver.pending]: (state) => {
			state.Drivers.loading = true;
		},
		[addNewDriver.rejected]: (state) => {
			state.Drivers.loading = false;
		},
		[addNewDriver.fulfilled]: (state, action) => {
			state.Drivers.loading = false;
			state.Drivers.driversList.push(action.payload);
		},
		[getDriverById.pending]: (state) => {
			state.SelectedDriver.loading = true;
		},
		[getDriverById.rejected]: (state) => {
			state.SelectedDriver.loading = false;
		},
		[getDriverById.fulfilled]: (state, action) => {
			state.SelectedDriver.loading = false;
			state.SelectedDriver.driver = action.payload;
		},
		[deleteDriver.pending]: (state) => {
			state.Drivers.loading = true;
		},
		[deleteDriver.rejected]: (state) => {
			state.Drivers.loading = false;
		},
		[deleteDriver.fulfilled]: (state, action) => {
			state.Drivers.loading = false;
			state.Drivers.driversList = state.Drivers.driversList.filter((item) => {
				return item.id !== action.payload;
			});
		},
		[updateDriver.pending]: (state) => {
			state.Drivers.loading = true;
		},
		[updateDriver.rejected]: (state) => {
			state.Drivers.loading = false;
		},
		[updateDriver.fulfilled]: (state, action) => {
			state.Drivers.loading = false;
			state.Drivers.driversList = state.Drivers.driversList.map((item) => {
				if (item.id === action.payload.id) {
					item = action.payload;
					return item;
				}
				return item;
			});
		},
		[changeDriverStatus.pending]: (state) => {
			state.Drivers.loading = true;
		},
		[changeDriverStatus.fulfilled]: (state, action) => {
			state.Drivers.loading = false;
			state.Drivers.driversList = state.Drivers.driversList.map((item) => {
				if (item.id === action.payload.userId) {
					item = { ...item, active: action.payload.active };
					return item;
				}
				return item;
			});
		},
		[changeDriverAllowBookingDecline.pending]: (state) => {
			state.Drivers.loading = true;
		},
		[changeDriverAllowBookingDecline.fulfilled]: (state, action) => {
			state.Drivers.loading = false;
			state.Drivers.driversList = state.Drivers.driversList.map((item) => {
				if (item.id === action.payload.id) {
					item = {
						...action.payload,
						allowBookingDecline: action.payload.allowBookingDecline,
					};
					return item;
				}
				return item;
			});
		},
	},
});

export default driverSlice.reducer;
export const { resetSelectedDriver } = driverSlice.actions;
