import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../axios';

const initialState = {
	Settings: { loading: false, settingsData: null },
};

export const getSettings = createAsyncThunk('Settings/getSettings', async () => {
	try {
		const response = await axios.get(`/settings`);
		return response.data;
	} catch (error) {
		if (
			error.response.status === 404 ||
			error.response.data.message === 'SETTINGS_NOT_FOUND_FOR_TENANT'
		) {
			const customError = {
				name: 'SETTINGS_NOT_FOUND_FOR_TENANT',
				message: error.response.data.message,
				data: error.response.data,
			};
			throw customError;
		} else {
			const customError = {
				name: 'Custom axios error',
				message: error.response.data.message,
				data: error.response.data,
			};
			throw customError;
		}
	}
});

export const updateSettings = createAsyncThunk('Settings/updateSettings', async (data) => {
	try {
		const response = await axios.put(`/settings`, data);
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
			/* data: error.response.data, */
		};
		throw customError;
	}
});

export const settingsSlice = createSlice({
	name: 'Settings',
	initialState,
	reducers: {
		resetSettingsData: (state) => {
			state.Settings.settingsData = null;
		},
	},
	extraReducers: {
		[getSettings.pending]: (state) => {
			state.Settings.loading = true;
		},
		[getSettings.rejected]: (state) => {
			state.Settings.loading = false;
			// state.Settings.settingsData = null;
		},
		[getSettings.fulfilled]: (state, action) => {
			state.Settings.loading = false;
			state.Settings.settingsData = action.payload;
		},
		[updateSettings.pending]: (state) => {
			state.Settings.loading = true;
		},
		[updateSettings.rejected]: (state) => {
			state.Settings.loading = false;
		},
		[updateSettings.fulfilled]: (state, action) => {
			state.Settings.loading = false;
			state.Settings.settingsData = action.payload;
		},
	},
});
export const { resetSettingsData } = settingsSlice.actions;
export default settingsSlice.reducer;
