import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../axios';
import { arrayToObject } from '../Utils/ArrayToObject';
import { permissionAdmin } from '../Utils/Constants/utils';

const initialState = {
	Users: { loading: false, usersList: [] },
	SelectedUser: { loading: false, user: null },
	allUsers: [],
};

export const getAllUsers = createAsyncThunk('User/getAllUsers', async () => {
	const body = {
		role: 'SUPER_ADMIN',
	};
	try {
		const response = await axiosInstance.patch(`/users`, body);
		return arrayToObject(response.data, 'id');
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const getAllUsersForAllRoles = createAsyncThunk('User/getAllUsersForAllRoles', async () => {
	try {
		const response = await axiosInstance.get(`/users/all`);
		return arrayToObject(response.data, 'id');
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const addNewUser = createAsyncThunk('User/addNewUser', async (data) => {
	const Tmpuser = data.user;
	const user = { ...Tmpuser };
	try {
		const response = await axiosInstance.post(`users`, user);
		return response.data.user;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const updateUser = createAsyncThunk('User/updateUser', async (data) => {
	const Tmpuser = data.user;
	const role = permissionAdmin;
	const user = { ...Tmpuser, role };
	try {
		const response = await axiosInstance.put(`users/${data.userId}`, user);
		return response.data.user;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const getUserById = createAsyncThunk('User/getUserById', async (data) => {
	try {
		const response = await axiosInstance.get(`users/${data.userId}`);
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const deleteUser = createAsyncThunk('User/deleteUser', async (data) => {
	try {
		await axiosInstance.delete(`users/${data.userId}/ADMIN`);
		return data.userId;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const changeUserStatus = createAsyncThunk('User/changeUserStatus', async (data) => {
	try {
		await axiosInstance.put(`users/${data.userId}/status`, {
			active: data.active,
		});
		return data;
	} catch (error) {
		const customError = {
			name: 'Error changing user status',
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const checkIfUserExistByEmail = createAsyncThunk(
	'User/checkIfUserExistByEmail',
	async (email) => {
		try {
			const response = await axiosInstance.get(`users/check-email/${email}`);
			return response.data;
		} catch (error) {
			const customError = {
				name: 'Error checking if user exist',
				message: error.response.data.message,
			};
			throw customError;
		}
	},
);

export const createCorporateAdmin = createAsyncThunk('User/createCorporateAdmin', async (data) => {
	try {
		const response = await axiosInstance.post(`users/corporate_admin`, data);
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Error creating corporate admin',
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const assigneUserToCorporate = createAsyncThunk(
	'User/assigneUserToCorporate',
	async (data) => {
		try {
			const response = await axiosInstance.patch(`users/assign_corporate_admin`, data);
			return response.data;
		} catch (error) {
			const customError = {
				name: 'Error assigning user to corporate',
				message: error.response.data.message,
			};
			throw customError;
		}
	},
);

const userSlice = createSlice({
	name: 'User',
	initialState,
	reducers: {},
	extraReducers: {
		[getAllUsers.pending]: (state) => {
			state.Users.loading = true;
		},
		[getAllUsers.fulfilled]: (state, action) => {
			state.Users.loading = false;
			state.Users.usersList = action.payload;
		},
		[getAllUsers.rejected]: (state) => {
			state.Users.loading = false;
		},
		[addNewUser.pending]: (state) => {
			state.Users.loading = true;
		},
		[addNewUser.fulfilled]: (state, action) => {
			state.Users.loading = false;
			state.Users.usersList[action.payload.id] = action.payload;
		},
		[addNewUser.rejected]: (state) => {
			state.Users.loading = false;
		},
		[updateUser.pending]: (state) => {
			state.Users.loading = true;
		},
		[updateUser.fulfilled]: (state, action) => {
			state.Users.loading = false;
			state.Users.usersList[action.payload.id] = action.payload;
		},
		[updateUser.rejected]: (state) => {
			state.Users.loading = false;
		},
		[getUserById.pending]: (state) => {
			state.SelectedUser.loading = true;
		},
		[getUserById.fulfilled]: (state, action) => {
			state.SelectedUser.loading = false;
			state.SelectedUser.user = action.payload;
		},
		[getUserById.rejected]: (state) => {
			state.SelectedUser.loading = false;
		},
		[deleteUser.pending]: (state) => {
			state.Users.loading = true;
		},
		[deleteUser.fulfilled]: (state, action) => {
			state.Users.loading = false;
			delete state.Users.usersList[action.payload];
		},
		[deleteUser.rejected]: (state) => {
			state.Users.loading = false;
		},
		[changeUserStatus.pending]: (state) => {
			state.Users.loading = true;
		},
		[changeUserStatus.fulfilled]: (state, action) => {
			state.Users.loading = false;
			state.Users.usersList[action.payload.userId].active = action.payload.active;
		},
		[changeUserStatus.rejected]: (state) => {
			state.Users.loading = false;
		},
		[getAllUsersForAllRoles.pending]: (state) => {
			state.Users.loading = true;
		},
		[getAllUsersForAllRoles.fulfilled]: (state, action) => {
			state.Users.loading = false;
			state.allUsers = action.payload;
		},
		[getAllUsersForAllRoles.rejected]: (state) => {
			state.Users.loading = false;
		},
		[checkIfUserExistByEmail.pending]: (state) => {
			state.Users.loading = true;
		},
		[checkIfUserExistByEmail.fulfilled]: (state) => {
			state.Users.loading = false;
		},
		[checkIfUserExistByEmail.rejected]: (state) => {
			state.Users.loading = false;
		},
		[createCorporateAdmin.pending]: (state) => {
			state.Users.loading = true;
		},
		[createCorporateAdmin.fulfilled]: (state) => {
			state.Users.loading = false;
		},
		[createCorporateAdmin.rejected]: (state) => {
			state.Users.loading = false;
		},
		[assigneUserToCorporate.pending]: (state) => {
			state.Users.loading = true;
		},
		[assigneUserToCorporate.fulfilled]: (state) => {
			state.Users.loading = false;
		},
		[assigneUserToCorporate.rejected]: (state) => {
			state.Users.loading = false;
		},
	},
});

export default userSlice.reducer;
