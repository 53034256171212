import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../axios';

const initialState = {
	WayPoints: { loading: false, wayPointsList: [] },
	SelectedWayPoint: { loading: false, wayPoint: null },
};

export const getAllWayPoints = createAsyncThunk('WayPoint/getAllWayPoints', async () => {
	try {
		const response = await axiosInstance.get(`/way-point`);
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const addNewWayPoint = createAsyncThunk('WayPoint/addNewWayPoint', async (data) => {
	try {
		const response = await axiosInstance.post(`way-point`, data.wayPoint);
		console.log(response.data);
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const updateWayPoint = createAsyncThunk('WayPoint/updateWayPoint', async (data) => {
	try {
		const response = await axiosInstance.put(`way-point/${data.wayPointId}`, data.wayPoint);
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const deleteWayPoint = createAsyncThunk('WayPoint/deleteWayPoint', async (data) => {
	try {
		await axiosInstance.delete(`way-point/${data.id}`);
		return data.id;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const getWayPointById = createAsyncThunk('WayPoint/getWayPointById', async (data) => {
	try {
		const response = await axiosInstance.get(`way-point/${data.wayPointId}`);
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const bulkUploadWayPoints = createAsyncThunk(
	'WayPoint/bulkUploadWayPoints',
	async (file, { rejectWithValue }) => {
		try {
			const formData = new FormData();
			formData.append('csvFile', file);

			const response = await axiosInstance.post(
				`way-point/upload/csv?validateOnly=false`,
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				},
			);

			return response.data;
		} catch (error) {
			console.log(error);
			return rejectWithValue(error.response.data.message);
		}
	},
);

export const batchDeleteWayPoints = createAsyncThunk(
	'WayPoint/batchDeleteWayPoints',
	async (data, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.patch(`way-point-batch-delete`, data);
			return response.data;
		} catch (error) {
			console.log(error);
			return rejectWithValue(error.response.data.message);
		}
	},
);

const WayPointSlice = createSlice({
	name: 'WayPoint',
	initialState,
	reducers: {},
	extraReducers: {
		[getAllWayPoints.pending]: (state) => {
			state.WayPoints.loading = true;
		},
		[getAllWayPoints.fulfilled]: (state, action) => {
			state.WayPoints.loading = false;
			state.WayPoints.wayPointsList = action.payload;
		},
		[getAllWayPoints.rejected]: (state) => {
			state.WayPoints.loading = false;
		},
		[addNewWayPoint.pending]: (state) => {
			state.WayPoints.loading = true;
		},
		[addNewWayPoint.fulfilled]: (state, action) => {
			state.WayPoints.loading = false;
			state.WayPoints.wayPointsList.push(action.payload);
		},
		[addNewWayPoint.rejected]: (state) => {
			state.WayPoints.loading = false;
		},
		[updateWayPoint.pending]: (state) => {
			state.WayPoints.loading = true;
		},
		[updateWayPoint.fulfilled]: (state, action) => {
			state.WayPoints.loading = false;
			state.WayPoints.wayPointsList = state.WayPoints.wayPointsList.map((item) => {
				if (item.id === action.payload.id) {
					item = action.payload;
					return item;
				}
				return item;
			});
		},
		[updateWayPoint.rejected]: (state) => {
			state.WayPoints.loading = false;
		},
		[deleteWayPoint.pending]: (state) => {
			state.WayPoints.loading = true;
		},
		[deleteWayPoint.fulfilled]: (state, action) => {
			state.WayPoints.loading = false;
			state.WayPoints.wayPointsList = state.WayPoints.wayPointsList.filter((item) => {
				return item.id !== action.payload;
			});
		},
		[deleteWayPoint.rejected]: (state) => {
			state.WayPoints.loading = false;
		},
		[getWayPointById.pending]: (state) => {
			state.SelectedWayPoint.loading = true;
		},
		[getWayPointById.fulfilled]: (state, action) => {
			state.SelectedWayPoint.loading = false;
			state.SelectedWayPoint.wayPoint = action.payload;
		},
		[getWayPointById.rejected]: (state) => {
			state.SelectedWayPoint.loading = false;
		},
		[batchDeleteWayPoints.pending]: (state) => {
			state.WayPoints.loading = true;
		},
		[batchDeleteWayPoints.fulfilled]: (state, action) => {
			state.WayPoints.loading = false;
			const { deletedIds } = action.payload;
			state.WayPoints.wayPointsList = state.WayPoints.wayPointsList.filter((item) => {
				return !deletedIds.includes(item.id);
			});
		},
		[batchDeleteWayPoints.rejected]: (state) => {
			state.WayPoints.loading = false;
		},
	},
});

export default WayPointSlice.reducer;
