/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { Button, Card, Breadcrumb, message, Input, Tooltip, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { ReloadOutlined, MinusOutlined } from '@ant-design/icons';
import DrawerDriversVehicles from './DrawerDriversVehicles';
import { style } from '../../Styles';
import { deleteVehicle, getAllVehicles } from '../../redux/driversVehiclesSlice';
import CustomTableComponents from '../Global/CustomTableComponents';
import ActionButton from '../Global/ActionsButton';
import { formatEnumValue } from '../../Utils/ArrayToObject';
import { getAllDrivers } from '../../redux/driverSlice';

const DriversVehicles = () => {
	const [filter, setfilter] = useState('');
	const [selectedUser, setSelectedUser] = useState(null);
	const { Drivers } = useSelector((state) => state.drivers);
	const columns = [
		{
			title: 'Vehicle',
			key: 'vehicle',
			render: (record) => {
				return (
					<span>{`${record.make?.deleted ? '-' : record.make?.name} ${
						record.model?.deleted ? '-' : record.model?.name
					} ${record.year}`}</span>
				);
			},

			sorter: {
				compare: (a, b) => a.year - b.year,
				multiple: 5,
			},
		},
		{
			title: 'Driver',
			key: 'drivers',
			render: (record) => {
				return record.assignedDriverVehicles?.length > 0 ? (
					<span>
						{record.assignedDriverVehicles?.map((item) => {
							return (
								<span key={item.driver.id} style={style.tagsDriver}>
									{`${item.driver?.name} ${item.driver?.surname}`}
								</span>
							);
						})}
					</span>
				) : (
					<MinusOutlined />
				);
			},

			width: 300,
		},
		{
			title: 'Available Seats',
			key: 'availableSeats',
			render: (record) => {
				return <span>{record.availableSeats}</span>;
			},
			sorter: {
				compare: (a, b) => a.availableSeats - b.availableSeats,
				multiple: 4,
			},
		},
		{
			title: 'Fleet Operator',
			key: 'fleetOperator',
			render: (record) => {
				return record.fleetOperator ? (
					<div>
						<span key={record.fleetOperator.id} style={style.tags}>
							{record?.fleetOperator.name}
						</span>
						{!record.fleetOperator?.active ? (
							<span style={{ color: 'GrayText', fontSize: '12px' }}>(Inactive)</span>
						) : null}
					</div>
				) : (
					<MinusOutlined />
				);
			},
			sorter: {
				compare: (a, b) => a.fleetOperator.name - b.fleetOperator.name,
				multiple: 1,
			},
		},
		{
			title: 'Licence Plate',
			key: 'licencePlate',
			render: (record) => {
				return <span>{record.licencePlate}</span>;
			},
			sorter: {
				compare: (a, b) => a.licencePlate - b.licencePlate,
				multiple: 3,
			},
		},
		{
			title: 'Vehicle Color',
			key: 'vehicleColor',
			render: (record) => {
				return record.vehicleColor ? (
					<Tooltip title={record?.vehicleColor}>
						<div
							style={{
								backgroundColor: record?.vehicleColor,
								borderRadius: '50%',
								width: '1.7em',
								height: '1.7em',
							}}
						/>
					</Tooltip>
				) : (
					'-'
				);
			},
			sorter: {
				compare: (a, b) => a.vehicleColor - b.vehicleColor,
				multiple: 1,
			},
			height: 50,
		},
		{
			title: 'Vehicle Type',
			key: 'vehicleType',
			render: (record) => {
				return record.driverVehicleType?.length > 0 ? (
					<span>
						{record.driverVehicleType?.map((item) => {
							return (
								<span key={item.id} style={style.tags}>
									{formatEnumValue(item.vehicleType?.vehicleType)}
								</span>
							);
						})}
					</span>
				) : (
					<MinusOutlined />
				);
			},
			sorter: {
				compare: (a, b) => a.status - b.status,
				multiple: 1,
			},
		},

		{
			title: 'Actions',
			key: 'actions',
			render: (text, record) => (
				<ActionButton
					record={record}
					onDelete={() => handleDeleteDriverVehicle(record?.id, () => {})}
					onEdit={() => handleSelectedVehicle(record)}
					recordName={`${record?.make?.name} ${record?.model?.name} ${record?.year}`}
				/>
			),
		},
	];

	const handleDeleteDriverVehicle = (id, callback) => {
		setMakingApiCall(true);
		dispatch(
			deleteVehicle({
				vehicleId: id,
			}),
		)
			.unwrap()
			.then(() => {
				message.success('Vehicle deleted successfully');
				callback();
			})
			.catch(() => {})
			.finally(() => {
				onCloseDrawer();
				setMakingApiCall(false);
			});
	};
	const [displayDrawer, setDisplayDrawer] = useState(false);
	const [selectedDriversVehicles, setSelectedDriversVehicles] = useState(null);
	const [isMakingApiCall, setMakingApiCall] = useState(true);
	const showDrawer = () => {
		setDisplayDrawer(true);
	};
	const onCloseDrawer = () => {
		setDisplayDrawer(false);
		setSelectedDriversVehicles({});
	};

	const dispatch = useDispatch();

	const { DriversVehicles } = useSelector((state) => state.driversVehicle);
	const fetchAll = () => {
		setMakingApiCall(true);
		dispatch(getAllVehicles(selectedUser))
			.unwrap()
			.then()
			.catch(() => {
				message.error('Failed to fetch drivers vehicles');
			})
			.finally(() => {
				setMakingApiCall(false);
			});
	};
	const fetchAllDrivers = () => {
		dispatch(getAllDrivers())
			.unwrap()
			.then()
			.catch(() => {
				message.error('Error fetching drivers');
			})
			.finally(() => {
				setMakingApiCall(false);
			});
	};
	useEffect(() => {
		fetchAll();
		fetchAllDrivers();
	}, [dispatch, selectedUser]);

	const handleSelectedVehicle = (selectedDriversVehicles) => {
		setSelectedDriversVehicles(selectedDriversVehicles);
		showDrawer();
	};

	let driversVehiclesArray = Object.values(DriversVehicles.driversVehiclesList);
	driversVehiclesArray = driversVehiclesArray.map((item) => {
		return { ...item, key: item.id };
	});

	const filtredData = driversVehiclesArray.filter((item) => {
		const matchedVehicleTypes = item.driverVehicleType.some((driverVehicleType) =>
			driverVehicleType.vehicleType.vehicleType
				.toString()
				.toLowerCase()
				.includes(filter.toLowerCase()),
		);
		return (
			item?.id?.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
			item?.make?.name?.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
			item?.licencePlate?.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
			item?.model?.name?.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
			item?.year?.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
			item?.availableSeats?.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
			item?.driverVehicleType?.some((driverVehicleType) =>
				driverVehicleType?.vehicleType?.vehicleType
					.toString()
					.toLowerCase()
					.includes(filter.toLowerCase()),
			) ||
			item?.fleetOperator?.name?.toString().toLowerCase().indexOf(filter.toLowerCase()) !==
				-1 ||
			item?.assignedDriverVehicles?.some((assignedDriverVehicles) => {
				const fullName = `${assignedDriverVehicles?.driver?.name} ${assignedDriverVehicles?.driver?.surname}`;
				return fullName.toLowerCase().includes(filter.toLowerCase());
			}) ||
			matchedVehicleTypes
		);
	});

	return (
		<>
			<Card
				style={{
					margin: 16,
					maxHeight: '90%', // Set maximum width
					height: 'auto', // Allow width to be dynamic
				}}>
				<div style={style.inlineContent}>
					<div
						style={{
							display: 'flex',
							alignContent: 'center',
							alignItems: 'center',
							float: 'left',
							marginBottom: 8,
						}}>
						<Breadcrumb
							style={{ margin: '0 3em 0 0', fontSize: '1.1em' }}
							items={[
								{
									title: 'Vehicles',
								},
								{
									title: 'Driver Vehicles',
								},
							]}
						/>
						<Button
							type='default'
							size='small'
							// style={{ margin: '0 8px 8px 0' }}
							onClick={() => {
								fetchAll();
							}}>
							<ReloadOutlined />
						</Button>
					</div>

					<div
						style={{
							...style.inlineContent,
							float: 'right',
							marginBottom: 8,
						}}>
						<Select
							placeholder='Select Driver'
							style={{ margin: '0 8px 8px 0', width: '13em' }}
							allowClear
							showSearch
							optionFilterProp='filterBy'
							optionLabelProp='label'
							onClear={() => setSelectedUser(null)}
							onChange={(value) => {
								setSelectedUser(value);
							}}>
							{Object.values(Drivers?.driversList)?.map((item) => (
								<Select.Option
									key={item.id}
									value={item.id}
									label={
										<div style={style.inlineContent}>
											<span>{`${item.name} ${item.surname}`}</span>
										</div>
									}
									filterBy={`${item.name} +${item.surname}`}>
									<div style={style.inlineContent}>
										<span
											style={{
												marginLeft: '8px',
											}}>{`${item.name} ${item.surname}`}</span>
									</div>
								</Select.Option>
							))}
						</Select>
						<Input.Search
							placeholder='Search'
							style={{ margin: '0 8px 8px 0', width: '13em' }}
							value={filter}
							onChange={(e) => setfilter(e.target.value)}
						/>

						<Button
							type='default'
							style={{ float: 'right', marginBottom: 8 }}
							onClick={showDrawer}>
							Add Driver Vehicles
						</Button>
					</div>
				</div>
				<CustomTableComponents
					isMakingApiCall={isMakingApiCall}
					columns={columns}
					dataArray={filtredData}
					handleDoubleClickRow={(record) => handleSelectedVehicle(record)}
					handleSelectedRow={() => {}}
				/>
			</Card>
			{displayDrawer && (
				<DrawerDriversVehicles
					handleDeleteDriverVehicle={handleDeleteDriverVehicle}
					onClose={onCloseDrawer}
					driversVehiclesId={selectedDriversVehicles?.id}
				/>
			)}
		</>
	);
};
export default DriversVehicles;
