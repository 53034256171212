/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Form, Input, Row, Spin, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { SaveOutlined, LoadingOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import {
	getCancellationPolicy,
	saveCancellationPolicy,
	updateCancellationPolicy,
} from '../../redux/CancellationPolicySlice';

const CancellationPolicyForm = ({ initialValues, makingApiCall, setMakingApiCall }) => {
	const dispatch = useDispatch();
	const [isCancellationPolicyEmpty, setIsCancellationPolicyEmpty] = useState(false);

	const onSave = (values) => {
		setMakingApiCall(true);
		const action = isCancellationPolicyEmpty
			? saveCancellationPolicy(values)
			: updateCancellationPolicy(values);
		dispatch(action)
			.unwrap()
			.then(() => {
				message.success('Cancellation policy updated successfully.');
			})
			.catch(() => {
				message.error('Something went wrong. Please try again later.');
			})
			.finally(() => {
				setMakingApiCall(false);
			});
	};
	const onSaveFailed = (errorInfo) => {
		console.log(errorInfo);
	};
	const onDirty = () => {};

	const fetchCancellationPolicy = () => {
		setMakingApiCall(true);
		dispatch(getCancellationPolicy())
			.unwrap()
			.then(() => {})
			.catch((e) => {
				if (e.name === 'CANCELLATION_POLICY_NOT_FOUND_FOR_TENANT') {
					setIsCancellationPolicyEmpty(true);
					message.warning(
						'Cancellation policy not found for this tenant. Please fill the form and save.',
					);
				} else {
					message.error('Something went wrong. Please try again later.');
				}
			})
			.finally(() => {
				setMakingApiCall(false);
			});
	};
	useEffect(() => {
		fetchCancellationPolicy();
	}, []);

	return (
		<Spin
			size='large'
			indicator={<LoadingOutlined style={{ fontSize: 54 }} spin />}
			tip={
				<div>
					<h2>Loading...</h2>
				</div>
			}
			spinning={makingApiCall}>
			<Form
				name='Cancellation policy Form'
				layout='vertical'
				initialValues={isCancellationPolicyEmpty ? {} : initialValues}
				onFinish={onSave}
				style={{ padding: '20px 40px' }}
				onFinishFailed={onSaveFailed}
				autoComplete='off'
				onValuesChange={() => onDirty()}>
				<Row gutter={16}>
					<Col xs={24} sm={12} md={12} lg={12} xl={12}>
						<Form.Item
							label='Fast Cancellation in seconds'
							name='fastCancellationInSeconds'
							rules={[
								{
									required: true,
									message: 'Please enter Fast Cancellation in seconds',
								},
								{
									pattern: /^[0-9]+$/,
									message: 'Fast Cancellation in seconds must be a number',
								},
							]}>
							<Input />
						</Form.Item>
						<Form.Item
							label='Cancellation Fee'
							name='cancellationFee'
							rules={[
								{
									required: true,
									message: 'Please enter Cancellation Fee',
								},
								{
									pattern: /^(?:\d+(?:\.\d*)?|\.\d+)$/,
									message: 'Cancellation Fee must be a number',
								},
							]}>
							<Input />
						</Form.Item>
						<Form.Item
							label='Rider wait time in seconds'
							name='riderWaitTimeInSeconds'
							rules={[
								{
									required: true,
									message: 'Please enter Rider wait time in seconds',
								},
								{
									pattern: /^[0-9]+$/,
									message: 'Rider wait time in seconds must be a number',
								},
							]}>
							<Input />
						</Form.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={12} xl={12}>
						<Form.Item
							label='Rider no show fee'
							name='riderNoShowFee'
							rules={[
								{
									required: true,
									message: 'Please enter Rider no show fee',
								},
								{
									pattern: /^(?:\d+(?:\.\d*)?|\.\d+)$/,
									message: 'Rider no show fee must be a number',
								},
							]}>
							<Input />
						</Form.Item>
						<Form.Item
							label='Driver wait time in seconds'
							name='driverWaitTimeInSeconds'
							rules={[
								{
									required: true,
									message: 'Please enter Driver wait time in seconds',
								},
								{
									pattern: /^[0-9]+$/,
									message: 'Driver wait time in seconds must be a number',
								},
							]}>
							<Input />
						</Form.Item>
					</Col>
					<Col xs={24}>
						<Form.Item style={{ textAlign: 'right' }}>
							<Button
								type='primary'
								htmlType='submit'
								icon={<SaveOutlined />}
								style={{ width: '8em' }}>
								Save
							</Button>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Spin>
	);
};

export default CancellationPolicyForm;
