import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../axios';

const initialState = {
	CancellationPolicy: { loading: false, cancellationPolicyData: null },
};

export const getCancellationPolicy = createAsyncThunk(
	'CancellationPolicy/getCancellationPolicy',
	async () => {
		try {
			const response = await axios.get(`/cancellation-policies`);
			return response.data;
		} catch (error) {
			if (
				error.response.status === 404 ||
				error.response.data.message === 'CANCELLATION_POLICY_NOT_FOUND_FOR_TENANT'
			) {
				const customError = {
					name: 'CANCELLATION_POLICY_NOT_FOUND_FOR_TENANT',
					message: error.response.data.message,
					data: error.response.data,
				};
				throw customError;
			} else {
				const customError = {
					name: 'Custom axios error',
					message: error.response.data.message,
					data: error.response.data,
				};
				throw customError;
			}
		}
	},
);

export const updateCancellationPolicy = createAsyncThunk(
	'CancellationPolicy/updateCancellationPolicy',
	async (data) => {
		try {
			const response = await axios.put(`/cancellation-policies`, data);
			return response.data;
		} catch (error) {
			const customError = {
				name: 'Custom axios error',
				message: error.response.data.message,
				/* data: error.response.data, */
			};
			throw customError;
		}
	},
);

export const saveCancellationPolicy = createAsyncThunk(
	'CancellationPolicy/saveCancellationPolicy',
	async (data) => {
		try {
			const response = await axios.post(`/cancellation-policies`, data);
			return response.data;
		} catch (error) {
			const customError = {
				name: 'Custom axios error',
				message: error.response.data.message,
				/* data: error.response.data, */
			};
			throw customError;
		}
	},
);

export const cancellationPolicySlice = createSlice({
	name: 'CancellationPolicy',
	initialState,
	reducers: {
		resetCancellationPolicyData: (state) => {
			state.CancellationPolicy.cancellationPolicyData = null;
		},
	},
	extraReducers: {
		[getCancellationPolicy.pending]: (state) => {
			state.CancellationPolicy.loading = true;
		},
		[getCancellationPolicy.rejected]: (state) => {
			state.CancellationPolicy.loading = false;
		},
		[getCancellationPolicy.fulfilled]: (state, action) => {
			state.CancellationPolicy.loading = false;
			state.CancellationPolicy.cancellationPolicyData = action.payload;
		},
		[updateCancellationPolicy.pending]: (state) => {
			state.CancellationPolicy.loading = true;
		},
		[updateCancellationPolicy.rejected]: (state) => {
			state.CancellationPolicy.loading = false;
		},
		[updateCancellationPolicy.fulfilled]: (state, action) => {
			state.CancellationPolicy.loading = false;
			state.CancellationPolicy.cancellationPolicyData = action.payload;
		},
		[saveCancellationPolicy.pending]: (state) => {
			state.CancellationPolicy.loading = true;
		},
		[saveCancellationPolicy.rejected]: (state) => {
			state.CancellationPolicy.loading = false;
		},
		[saveCancellationPolicy.fulfilled]: (state, action) => {
			state.CancellationPolicy.loading = false;
			state.CancellationPolicy.cancellationPolicyData = action.payload;
		},
	},
});

export const { resetCancellationPolicyData } = cancellationPolicySlice.actions;

export default cancellationPolicySlice.reducer;
