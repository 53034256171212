import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../axios';
import {
	getAllNightShiftByVehicleTypeEndPoint,
	peakSurchargeByVehicleTypeEndPoint,
	getAllVehiclesTypesEndPoint,
	vehicleTypeByIdEndPoint,
	vehicleTypeEndPoint,
} from '../Utils/Constants/endPoints';
import { tenantId } from '../Utils/Constants/utils';

const initialState = {
	VehicleType: { vehicleTypeList: [] },
	PeakSurchage: { peakSurchageList: [] },
	NightShift: { nightShiftList: [] },
	SelectedVehicleType: { vehicleType: null },
};

export const getAllVehicleType = createAsyncThunk('VehicleType/getAllVehicleType', async () => {
	try {
		const response = await axios.get(getAllVehiclesTypesEndPoint);
		return response.data;
	} catch (error) {
		const err = {
			name: error.name,
			message: error.response.data.message,
		};
		throw err;
	}
});

export const getPeakSurchargeByVehicleTypeId = createAsyncThunk(
	'VehicleType/getPeakSurchargeByVehicleTypeId',
	async (params) => {
		try {
			const response = await axios.get(
				peakSurchargeByVehicleTypeEndPoint(params.vehicleTypeId),
			);
			return response.data;
		} catch (error) {
			const err = {
				name: error.name,
				message: error.response.data.message,
			};
			throw err;
		}
	},
);

export const getNightShiftByVehicleTypeId = createAsyncThunk(
	'VehicleType/getNightShiftByVehicleTypeId',
	async (params) => {
		try {
			const response = await axios.get(
				getAllNightShiftByVehicleTypeEndPoint(params.vehicleTypeId),
			);
			return response.data;
		} catch (error) {
			const err = {
				name: error.name,
				message: error.response.data.message,
			};
			throw err;
		}
	},
);

export const getVehicleTypeById = createAsyncThunk(
	'VehicleType/getVehicleTypeById',
	async (params) => {
		try {
			const response = await axios.get(vehicleTypeByIdEndPoint(params.vehicleTypeId));
			return response.data;
		} catch (error) {
			const err = {
				name: error.name,
				message: error.response.data.message,
			};
			throw err;
		}
	},
);

export const addVehicleType = createAsyncThunk('VehicleType/addVehicleType', async (params) => {
	const data = { ...params.vehicleType, tenantId };
	try {
		const response = await axios.post(vehicleTypeEndPoint, data);
		return response.data;
	} catch (error) {
		const err = {
			name: error.name,
			message: error.response.data.message,
		};
		throw err;
	}
});
export const deleteVehicleType = createAsyncThunk(
	'VehicleType/deleteVehicleType',
	async (params) => {
		try {
			await axios.delete(vehicleTypeByIdEndPoint(params.vehicleTypeId));
			return params.vehicleTypeId;
		} catch (error) {
			const err = {
				name: error.name,
				message: error.response.data.message,
			};
			throw err;
		}
	},
);

export const updateVehicleType = createAsyncThunk(
	'VehicleType/updateVehicleType',
	async (params) => {
		const data = { ...params.vehicleType, tenantId };
		try {
			const response = await axios.put(vehicleTypeByIdEndPoint(data.id), data);
			return response.data;
		} catch (error) {
			const err = {
				name: error.name,
				message: error.response.data.message,
			};
			throw err;
		}
	},
);

export const updateFareSurcharge = createAsyncThunk(
	'VehicleType/updateFareSurcharge',
	async (params) => {
		console.log(params.data);
		try {
			const response = await axios.put(
				peakSurchargeByVehicleTypeEndPoint(params.id),
				params.data,
			);
			return response.data;
		} catch (error) {
			const err = {
				name: error.name,
				message: error.response.data.message,
			};
			throw err;
		}
	},
);

export const downloadImage = createAsyncThunk('VehicleType/downloadImage', async (fileId) => {
	try {
		const response = await axios.get(`files/${fileId}`, {
			responseType: 'blob',
		});
		return response.data;
	} catch (error) {
		const err = {
			name: error.name,
			message: error.response.data.message,
		};
		throw err;
	}
});

export const vehicleTypeSlice = createSlice({
	name: 'VehicleType',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getAllVehicleType.fulfilled, (state, action) => {
				state.VehicleType.vehicleTypeList = Object.values(action.payload);
			})
			.addCase(getPeakSurchargeByVehicleTypeId.fulfilled, (state, action) => {
				state.PeakSurchage.peakSurchageList = action.payload;
			})
			.addCase(getNightShiftByVehicleTypeId.fulfilled, (state, action) => {
				state.NightShift.nightShiftList = action.payload;
			})
			.addCase(getVehicleTypeById.fulfilled, (state, action) => {
				state.SelectedVehicleType.vehicleType = action.payload;
			})
			.addCase(addVehicleType.fulfilled, (state, action) => {
				state.VehicleType.vehicleTypeList.push(action.payload);
			})
			.addCase(deleteVehicleType.fulfilled, (state, action) => {
				state.VehicleType.vehicleTypeList = state.VehicleType.vehicleTypeList.filter(
					(item) => item.id !== action.payload,
				);
			})
			.addCase(updateVehicleType.fulfilled, (state, action) => {
				state.VehicleType.vehicleTypeList = state.VehicleType.vehicleTypeList.map(
					(item) => {
						if (item.id === action.payload.id) {
							item = action.payload;
							return item;
						}
						return item;
					},
				);
			})
			.addCase(updateFareSurcharge.fulfilled, (state, action) => {
				state.PeakSurchage.peakSurchageList = action.payload;
			});
	},
});

export default vehicleTypeSlice.reducer;
