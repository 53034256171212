/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/namespace */
import React, { useState } from 'react';
import { Button, Checkbox, Form, Input, message, Col, Row } from 'antd';

import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { authentication, profile } from '../../redux/authSlice';
import { getSettings } from '../../redux/settingsSlice';
import LoginSVG from '../../Utils/svgImages/LoginSvg';

const Login = () => {
	const { Brand } = useSelector((state) => state.brand);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [makingApiCall, setMakingApiCall] = useState(false);
	const onFinish = (values) => {
		setMakingApiCall(true);
		dispatch(authentication({ ...values, username: values.username.trim() }))
			.unwrap()
			.then(() => {
				dispatch(profile())
					.unwrap()
					.then((res) => {
						dispatch(getSettings());
						// if user is admin or super admin, redirect to main page, else redirect to corporates page
						if (res?.role?.includes('ADMIN') || res?.role?.includes('SUPER_ADMIN')) {
							navigate('/app/main');
						} else {
							navigate('/app/main/corporates');
						}
					})
					.catch((e) => {
						// eslint-disable-next-line no-console
						console.log(e);
					});
			})
			.catch((e) => {
				// eslint-disable-next-line no-console
				console.log(e);
				switch (e.message) {
					case 'USER_NOT_ACTIVE':
						message.error('Your account is not active. Please contact admin');
						break;
					case 'USER_DO_NOT_HAVE_PERMISSION_TO_ACCESS_TENANT':
						message.error('Invalid credentials');
						break;
					default:
						message.error('Invalid credentials');
						break;
				}
			})
			.finally(() => {
				setMakingApiCall(false);
			});
	};
	const onFinishFailed = (errorInfo) => {
		// eslint-disable-next-line no-console
		console.log('Failed:', errorInfo);
	};

	return (
		<Row type='flex' justify='center' align='middle' style={{ minHeight: '100vh' }}>
			<Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 0 }} lg={{ span: 10 }}>
				<LoginSVG fillColor={Brand?.brandData?.webPrimary || '#ffa500'} />
			</Col>
			<Col xs={{ span: 12, offset: 1 }} lg={{ span: 6, offset: 1 }}>
				<Form
					name='login'
					initialValues={{ remember: true }}
					onFinish={onFinish}
					layout='vertical'
					onFinishFailed={onFinishFailed}
					autoComplete='off'>
					<h1 style={{ textAlign: 'center' }}>Login</h1>

					<Form.Item
						label='Username'
						name='username'
						rules={[{ required: true, message: 'Please input your username' }]}>
						<Input style={{ width: '100%' }} />
					</Form.Item>

					<Form.Item
						label='Password'
						name='password'
						rules={[{ required: true, message: 'Please input your password' }]}>
						<Input.Password style={{ width: '100%' }} />
					</Form.Item>

					<Form.Item name='remember' valuePropName='checked'>
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<Checkbox style={{ alignSelf: 'center' }}>Remember me</Checkbox>
							<Link
								to='/app/forget-password'
								style={{
									alignSelf: 'center',
									color: Brand?.brandData?.webPrimary || '#ffa500',
								}}>
								Forgot Password?
							</Link>
						</div>
					</Form.Item>

					<Form.Item style={{ textAlign: 'center' }}>
						<Button type='primary' htmlType='submit' disabled={makingApiCall}>
							{makingApiCall ? 'logging in...' : 'Login'}
						</Button>
					</Form.Item>
				</Form>
			</Col>
		</Row>
	);
};

export default Login;
