/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import { Drawer, Spin, message } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addNewVehicle, getVehicleById, updateVehicle } from '../../redux/driversVehiclesSlice';
import DriverVehicleForm from './DriverVehicleForm';
import ShowConfirmClose from '../Modals/ShowConfirmClose';
import { resetDocuments, resetFetchedVehicleDocuments } from '../../redux/fileSlice';

const DrawerDriversVehicules = ({ onClose, driversVehiclesId, handleDeleteDriverVehicle }) => {
	const [unsavedChanges, setUnsavedChanges] = useState(false);
	const { vehicleFetchedDocuments } = useSelector((state) => state.files);

	const dispatch = useDispatch();
	const [makingApiCall, setMakingApiCall] = useState(false);
	const [vehicle, setVehicle] = useState(null);

	const onSave = (values) => {
		handleSaveDriverVehicle(values);
	};
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const handleClose = useCallback(
		(hasUnsavedChanges) => {
			if (hasUnsavedChanges && !confirmationOpen) {
				setConfirmationOpen(true); // Mark that the confirmation modal is open
				ShowConfirmClose(() => {
					setConfirmationOpen(false); // Reset when the confirmation modal is closed
					onClose();
				});
			} else {
				onClose();
				dispatch(resetFetchedVehicleDocuments());
				dispatch(resetDocuments());
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[onClose, confirmationOpen],
	);

	const handleDirty = useCallback(() => {
		setUnsavedChanges(true);
	}, []);

	const handleSaveDriverVehicle = (values) => {
		const documents = [];
		const allDocuments = vehicleFetchedDocuments?.vehicleFetchedDocumentsList;
		const vehicleLicencePlateDocuments = allDocuments?.filter(
			(doc) =>
				doc.type === 'VEHICLE_LICENSE_PLATE' ||
				doc.documentType === 'VEHICLE_LICENSE_PLATE',
		);
		const vehicleLicenceDiscDocuments = allDocuments?.filter(
			(doc) =>
				doc.type === 'VEHICLE_LICENSE_DISK' || doc.documentType === 'VEHICLE_LICENSE_DISK',
		);
		const vehicleInsuranceDocuments = allDocuments?.filter(
			(doc) =>
				doc.type === 'VEHICLE_INSURANCE_DOCUMENT' ||
				doc.documentType === 'VEHICLE_INSURANCE_DOCUMENT',
		);
		if (vehicleLicencePlateDocuments?.length > 0) {
			const tagDocumentsPayload = vehicleLicencePlateDocuments.map((item) => {
				return {
					fileId: item?.id,
					type: 'VEHICLE_LICENSE_PLATE',
				};
			});
			documents.push(...tagDocumentsPayload);
		}

		if (vehicleLicenceDiscDocuments?.length > 0) {
			const licencePayload = vehicleLicenceDiscDocuments?.map((item) => {
				return {
					fileId: item.id,
					type: 'VEHICLE_LICENSE_DISK',
				};
			});
			documents.push(...licencePayload);
		}
		if (vehicleInsuranceDocuments?.length > 0) {
			const policePayload = vehicleInsuranceDocuments?.map((item) => {
				return {
					fileId: item.id,
					type: 'VEHICLE_INSURANCE_DOCUMENT',
					expiryDate: values?.insuranceExpiryDate,
				};
			});
			documents.push(...policePayload);
		}
		setMakingApiCall(true);
		const action = driversVehiclesId
			? updateVehicle({
					vehicleId: vehicle.id,
					vehicle: { ...values, documents },
			  })
			: addNewVehicle({
					vehicle: { ...values, documents },
			  });

		dispatch(action)
			.unwrap()
			.then(() => {
				message.success('Vehicle saved successfully');
				setUnsavedChanges(false);
			})
			.catch((e) => {
				if (e.message === 'LICENCE_PLATE_EXIST') {
					message.error('Could not save Vehicle, Licence Plate is already exist');
				} else {
					message.error('Error while saving Vehicle');
				}
			})
			.finally(() => {
				onClose();
				setMakingApiCall(false);
			});
	};

	const onSaveFailed = (error) => {
		console.log('Failed:', error);
	};

	useEffect(() => {
		const abortController = new AbortController();

		if (driversVehiclesId) {
			setMakingApiCall(true);
			const calls = [
				dispatch(
					getVehicleById({
						vehicleId: driversVehiclesId,
					}),
				),
			];
			Promise.all(calls)
				.then((res) => {
					if (driversVehiclesId) {
						setVehicle(res[0].payload);
					}
				})
				.catch(() => {
					onClose();
				})
				.finally(() => {
					setMakingApiCall(false);
				});
		}
		return () => abortController.abort();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [driversVehiclesId, dispatch]);

	return (
		<Drawer
			width={500}
			title={!driversVehiclesId ? 'Add Driver Vehicle' : 'Update Driver Vehicle'}
			placement='right'
			onClose={() => handleClose(unsavedChanges)}
			open>
			<Spin size='large' spinning={makingApiCall} style={{ marginTop: 64 }} delay={500}>
				{!makingApiCall && (
					<DriverVehicleForm
						driverVehicle={vehicle}
						onSave={onSave}
						onSaveFailed={onSaveFailed}
						onDeleteDriverVehicle={handleDeleteDriverVehicle}
						onCancel={handleClose}
						onDirty={handleDirty}
						unsavedChanges={unsavedChanges}
						setMakingApiCall={setMakingApiCall}
						setUnsavedChanges={setUnsavedChanges}
					/>
				)}
			</Spin>
		</Drawer>
	);
};

export default DrawerDriversVehicules;
