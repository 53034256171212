/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-shadow */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { GoogleMap, DirectionsRenderer } from '@react-google-maps/api';

const BookingMaps = ({ pickup, dropOff }) => {
	const [directions, setDirections] = useState(null);
	const [center, setCenter] = useState({
		lat: 37.17130611530913,
		lng: 9.772111191511911,
	});

	useEffect(() => {
		setCenter(generateCenterFromPickupAndDropOff());
		if (pickup && dropOff) {
			getDirectionsBetweenTwoPoints(pickup, dropOff);
		}
	}, [pickup, dropOff]);

	const onLoad = () => {
		console.log('OnLoad: loaded ');
	};
	const generateCenterFromPickupAndDropOff = () => {
		const lat = (pickup?.latitude + dropOff?.latitude) / 2;
		const lng = (pickup?.longitude + dropOff?.longitude) / 2;
		return { lat, lng };
	};
	const getDirectionsBetweenTwoPoints = (pickup, dropOff) => {
		const directionsService = new window.google.maps.DirectionsService();
		const origin = new window.google.maps.LatLng(pickup?.latitude, pickup?.longitude);
		const destination = new window.google.maps.LatLng(dropOff?.latitude, dropOff?.longitude);
		const request = {
			origin,
			destination,
			travelMode: 'DRIVING',
		};
		directionsService.route(request, (response, status) => {
			if (status === 'OK') {
				setDirections(response);
			}
		});
	};

	return (
		<GoogleMap
			id='circle-example'
			mapContainerStyle={{
				borderRadius: '16px',
				height: '450px',
				width: '99%',
			}}
			zoom={7}
			center={center}
			options={{
				disableDefaultUI: true,
				zoomControl: true,
			}}>
			{directions && (
				<DirectionsRenderer
					options={{
						directions,
						polylineOptions: {
							strokeColor: '#2979FF',
							strokeOpacity: 1,
							strokeWeight: 5,
						},
						markerOptions: {
							visible: true,
							animation: window.google.maps.Animation.DROP,
						},
						suppressMarkers: false,
					}}
					onLoad={onLoad}
				/>
			)}
		</GoogleMap>
	);
};

export default BookingMaps;
