import { Button, Col, Form, Input, Row, Spin, message } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import { changePassword } from '../../redux/authSlice';
import LoginSVG from '../../Utils/svgImages/LoginSvg';

const ChangePasswordForm = () => {
	const [makingApiCall, setMakingApiCall] = useState(false);
	const { auth } = useSelector((state) => state.auth);
	const { Brand } = useSelector((state) => state.brand);
	const dispatch = useDispatch();
	const onFinish = (values) => {
		if (
			values.newPassword === values.confirmPassword &&
			!makingApiCall &&
			auth?.profile?.email
		) {
			setMakingApiCall(true);
			const data = {
				...values,
				password: values.newPassword,
				email: auth?.profile?.email,
			};
			dispatch(changePassword(data))
				.unwrap()
				.then(() => {
					message.success('Password changed successfully');
				})
				.catch((err) => {
					if (err.message === 'INVALID_OLD_PASSWORD') {
						message.error('Old password is incorrect');
					} else if (err.message === 'NEW_PASSWORD_IS_SAME_AS_OLD_PASSWORD') {
						message.error(
							'New password is same as old password, please enter a different password',
						);
					} else message.error('Error occured while changing password, please try again');
				})
				.finally(() => {
					setMakingApiCall(false);
				});
		}
	};
	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};
	return (
		<Spin
			size='large'
			indicator={<LoadingOutlined style={{ fontSize: 54 }} spin />}
			tip={
				<div>
					<h2>Loading...</h2>
				</div>
			}
			spinning={makingApiCall}>
			<Row type='flex' justify='center'>
				<Col
					xs={{ span: 12, offset: 1 }}
					lg={{ span: 10, offset: 1 }}
					style={{ marginTop: '-8em' }}>
					<LoginSVG fillColor={Brand?.brandData?.webPrimary || '#ffa500'} width='35em' />
				</Col>
				<Col xs={{ span: 12, offset: 1 }} lg={{ span: 10, offset: 1 }}>
					<Form
						name='ChangePassword'
						layout='vertical'
						initialValues={{ remember: true }}
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
						autoComplete='off'>
						<h2 style={{ textAlign: 'center' }}>Change password</h2>

						<Form.Item
							label='Old Password'
							name='oldPassword'
							rules={[{ required: true, message: 'Old password is required' }]}>
							<Input.Password style={{ width: '100%' }} />
						</Form.Item>
						<Form.Item
							label='New Password'
							name='newPassword'
							rules={[{ required: true, message: 'New password is required' }]}>
							<Input.Password style={{ width: '100%' }} />
						</Form.Item>
						<Form.Item
							name='confirmPassword'
							label='Confirm Password'
							dependencies={['newPassword']}
							rules={[
								{
									required: true,
									message: 'Please confirm your new password',
								},
								({ getFieldValue }) => ({
									validator(_, value) {
										if (!value || getFieldValue('newPassword') === value) {
											return Promise.resolve();
										}
										return Promise.reject(
											new Error(
												'The two passwords that you entered do not match',
											),
										);
									},
								}),
							]}
							hasFeedback>
							<Input.Password />
						</Form.Item>
						<Form.Item style={{ textAlign: 'center' }}>
							<Button type='primary' htmlType='submit' disabled={makingApiCall}>
								{makingApiCall ? 'Changing password...' : 'Change password'}
							</Button>
						</Form.Item>
					</Form>
				</Col>
			</Row>
		</Spin>
	);
};

export default ChangePasswordForm;
