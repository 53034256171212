/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */

import React from 'react';
import { Drawer, Form, Select, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { style } from '../../../Styles';
import { bookingPoolStatuses } from '../../../Utils/Constants/utils';
import { getAllBookingPoolsWithCretaria } from '../../../redux/bookingPoolSlice';

const FilteringDrawerBookingPool = ({
	closeFilteringDrawer,
	isFilteringDrawerOpened,
	resetFilter,
	dateRange,
}) => {
	const { Drivers } = useSelector((state) => state.drivers);
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const getSpanStyle = (status) => {
		switch (status) {
			case 'Completed':
				return style.statusCompleted;
			case 'Started':
				return style.statusInProgress;
			default:
				break;
		}
	};

	const onSave = (values) => {
		const start = new Date(dateRange[0]).toISOString();
		const end = new Date(dateRange[1]).toISOString();
		dispatch(
			getAllBookingPoolsWithCretaria({
				status: values.status !== undefined ? values.status : null,
				driverId: values.driverId !== undefined ? values.driverId : null,
				start: start !== undefined ? start : null,
				end: end !== undefined ? end : null,
			}),
		).then(() => {
			closeFilteringDrawer();
		});
	};

	const onDrawerClose = () => {
		form.setFieldsValue({});
		// close drawer
		closeFilteringDrawer();
	};
	return (
		<Drawer
			form={form}
			title='Filter Booking Pools'
			placement='right'
			closable={false}
			onClose={onDrawerClose}
			width={400}
			open={isFilteringDrawerOpened}>
			<Form
				name='Booking pool filter'
				layout='vertical'
				initialValues={{}}
				onFinish={(values) => onSave(values)}
				onFinishFailed={() => {}}
				autoComplete='off'
				onValuesChange={() => {}}>
				<Form.Item label='Status' name='status'>
					<Select
						showArrow
						showSearch
						optionFilterProp='filterBy'
						allowClear
						optionLabelProp='label'>
						{bookingPoolStatuses.map((status) => (
							<Select.Option
								key={status.id}
								filterBy={status.name}
								value={status.value}
								label={status.name}>
								<span style={style.dataContainer}>
									<div style={getSpanStyle(status.name)}>{status.name}</div>
								</span>
							</Select.Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item label='Driver' name='driverId'>
					<Select
						size='medium'
						showArrow
						showSearch
						optionFilterProp='filterBy'
						allowClear
						optionLabelProp='label'>
						{Drivers.driversList?.map((p) => (
							<Select.Option
								key={p.id}
								filterBy={`${p.name}`}
								label={`${p.name} ${p.surname}`}
								// disabled={!p.active}
								value={p.id}>
								<div>{` ${p.name}  ${p.surname}`}</div>
								<span style={{ color: 'gray' }}>{p.email}</span>
							</Select.Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item style={{ float: 'right' }}>
					<Button
						type='default'
						style={{ marginBottom: 32, marginRight: 8 }}
						onClick={() => closeFilteringDrawer()}>
						Cancel
					</Button>
					<Button type='primary' style={{ marginBottom: 32 }} htmlType='submit'>
						Filter
					</Button>
				</Form.Item>
				<Form.Item style={{ float: 'left' }}>
					<Button
						type='default'
						styles={{ backgroundColor: 'red' }}
						style={{ marginBottom: 32, marginRight: 8 }}
						onClick={() => resetFilter()}>
						Reset filter
					</Button>
				</Form.Item>
			</Form>
		</Drawer>
	);
};

export default FilteringDrawerBookingPool;
