import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../axios';

const initialState = {
	corporates: { loading: false, corporatesList: [] },
	selectedCorporate: { loading: false, corporate: null },
	corporateRiders: { loading: false, ridersList: [] },
	corporateDiscount: { loading: false, discount: null },
	corporateAllowedArea: { loading: false, allowedArea: [] },
	selectedAllowedArea: { loading: false, allowedArea: null },
	listOfCorporateSchedule: { loading: false, schedules: [] },
};

export const getAllCorporates = createAsyncThunk('corporates/getAllCorporates', async () => {
	try {
		const response = await axiosInstance.get(`corporates`);
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
			/* data: error.response.data, */
		};
		throw customError;
	}
});

export const getCorporateById = createAsyncThunk('corporates/getCorporateById', async (id) => {
	try {
		const response = await axiosInstance.get(`corporate/${id}`);
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
			/* data: error.response.data, */
		};
		throw customError;
	}
});

export const createCorporate = createAsyncThunk('corporates/createCorporate', async (data) => {
	try {
		const response = await axiosInstance.post(`corporate`, data);
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
			/* data: error.response.data, */
		};
		throw customError;
	}
});

export const updateCorporate = createAsyncThunk('corporates/updateCorporate', async (data) => {
	try {
		const response = await axiosInstance.put(`corporate`, data);
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
			/* data: error.response.data, */
		};
		throw customError;
	}
});

export const deleteCorporate = createAsyncThunk('corporates/deleteCorporate', async (id) => {
	try {
		await axiosInstance.delete(`corporate/${id}`);
		return id;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
			/* data: error.response.data, */
		};
		throw customError;
	}
});

export const inviteCorporateRider = createAsyncThunk(
	'corporates/inviteCorporateRider',
	async (data) => {
		try {
			const response = await axiosInstance.post(
				`corporate/${data.corporateId}/invite-rider?email=${data.email}`,
			);
			return response.data;
		} catch (error) {
			const customError = {
				name: 'Custom axios error',
				message: error.response.data.message,
				/* data: error.response.data, */
			};
			throw customError;
		}
	},
);

export const getCorporateRiders = createAsyncThunk('corporates/getCorporateRiders', async (id) => {
	try {
		const response = await axiosInstance.get(`corporate/${id}/riders`);
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
			/* data: error.response.data, */
		};
		throw customError;
	}
});

export const deleteCorporateRider = createAsyncThunk(
	'corporates/deleteCorporateRider',
	async (data) => {
		try {
			await axiosInstance.delete(
				`corporate/${data.corporateId}/remove-rider?email=${data.encodedEmail}`,
			);
			return data?.originalEmail;
		} catch (error) {
			const customError = {
				name: 'Custom axios error',
				message: error.response.data.message,
				/* data: error.response.data, */
			};
			throw customError;
		}
	},
);

export const resendInvitationToRider = createAsyncThunk(
	'corporates/resendInvitationToRider',
	async (data) => {
		try {
			const response = await axiosInstance.post(
				`corporate/${data.corporateId}/resend-invitation-email?email=${data.encodedEmail}`,
			);
			return {
				email: data.email,
				response,
			};
		} catch (error) {
			const customError = {
				name: 'Custom axios error',
				message: error.response.data.message,
				/* data: error.response.data, */
			};
			throw customError;
		}
	},
);

export const saveCorporateDiscount = createAsyncThunk(
	'corporates/saveCorporateDiscount',
	async (data) => {
		try {
			const response = await axiosInstance.post(
				`corporate/${data.corporateId}/discount`,
				data,
			);
			return response.data;
		} catch (error) {
			const customError = {
				name: 'Custom axios error',
				message: error.response.data.message,
				/* data: error.response.data, */
			};
			throw customError;
		}
	},
);

export const saveAllowedArea = createAsyncThunk('corporates/saveAllowedArea', async (data) => {
	try {
		const response = await axiosInstance.post(`corporate/allowed-area`, data);
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
			/* data: error.response.data, */
		};
		throw customError;
	}
});

export const deleteAllowedArea = createAsyncThunk('corporates/deleteAllowedArea', async (id) => {
	try {
		await axiosInstance.delete(`corporate/allowed-area/${id}`);
		return id;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
			/* data: error.response.data, */
		};
		throw customError;
	}
});

export const getAllAllowedAreasForCorporate = createAsyncThunk(
	'corporates/getAllAllowedAreasForCorporate',
	async (id) => {
		try {
			const response = await axiosInstance.get(`corporate/${id}/allowed-areas`);
			return response.data;
		} catch (error) {
			const customError = {
				name: 'Custom axios error',
				message: error.response.data.message,
				/* data: error.response.data, */
			};
			throw customError;
		}
	},
);

export const saveCorporateSchedule = createAsyncThunk(
	'corporates/saveCorporateSchedule',
	async (data) => {
		try {
			const response = await axiosInstance.put(`corporate/schedules`, data);
			return response.data;
		} catch (error) {
			const customError = {
				name: 'Custom axios error',
				message: error.response.data.message,
				/* data: error.response.data, */
			};
			throw customError;
		}
	},
);

export const fetchCorporateSchedule = createAsyncThunk(
	'corporates/fetchCorporateSchedule',
	async (id) => {
		try {
			const response = await axiosInstance.get(`corporate/${id}/schedules`);
			return response.data;
		} catch (error) {
			const customError = {
				name: 'Custom axios error',
				message: error.response.data.message,
				/* data: error.response.data, */
			};
			throw customError;
		}
	},
);

export const createCorporateScheduleRow = createAsyncThunk(
	'corporates/createCorporateScheduleRow',
	async (data) => {
		try {
			const response = await axiosInstance.post(
				`corporate/${data.corporateId}/schedule`,
				data,
			);
			return response.data;
		} catch (error) {
			const customError = {
				name: 'Custom axios error',
				message: error.response.data.message,
				/* data: error.response.data, */
			};
			throw customError;
		}
	},
);

export const deleteCorporateScheduleRow = createAsyncThunk(
	'corporates/deleteCorporateScheduleRow',
	async (corporateScheduleId) => {
		try {
			await axiosInstance.delete(`corporate/schedule/${corporateScheduleId}`);
			return corporateScheduleId;
		} catch (error) {
			const customError = {
				name: 'Custom axios error',
				message: error.response.data.message,
				/* data: error.response.data, */
			};
			throw customError;
		}
	},
);

export const updateCorporateScheduleRow = createAsyncThunk(
	'corporates/updateCorporateScheduleRow',
	async (data) => {
		try {
			const response = await axiosInstance.put(`corporate/schedule`, data);
			return response.data;
		} catch (error) {
			const customError = {
				name: 'Custom axios error',
				message: error.response.data.message,
				/* data: error.response.data, */
			};
			throw customError;
		}
	},
);

const corporateSlice = createSlice({
	name: 'corporate',
	initialState,
	reducers: {
		resetSelectedCorporate: (state) => {
			state.selectedCorporate = { loading: false, corporate: null };
		},
		setSelectedAllowedArea: (state, action) => {
			state.selectedAllowedArea.allowedArea = action.payload;
		},
		resetSelectedAllowedArea: (state) => {
			state.selectedAllowedArea = { loading: false, allowedArea: null };
		},
	},
	extraReducers: {
		[getAllCorporates.pending]: (state) => {
			state.corporates.loading = true;
		},
		[getAllCorporates.rejected]: (state) => {
			state.corporates.loading = false;
		},
		[getAllCorporates.fulfilled]: (state, action) => {
			state.corporates.loading = false;
			state.corporates.corporatesList = Object.values(action.payload);
		},
		[getCorporateById.pending]: (state) => {
			state.selectedCorporate.loading = true;
		},
		[getCorporateById.rejected]: (state) => {
			state.selectedCorporate.loading = false;
		},
		[getCorporateById.fulfilled]: (state, action) => {
			state.selectedCorporate.loading = false;
			state.selectedCorporate.corporate = action.payload;
			state.corporateRiders.ridersList = action.payload.riders || [];
		},
		[createCorporate.pending]: (state) => {
			state.corporates.loading = true;
		},
		[createCorporate.rejected]: (state) => {
			state.corporates.loading = false;
		},
		[createCorporate.fulfilled]: (state, action) => {
			state.corporates.loading = false;
			state.corporates.corporatesList.push(action.payload);
		},
		[updateCorporate.pending]: (state) => {
			state.corporates.loading = true;
		},
		[updateCorporate.rejected]: (state) => {
			state.corporates.loading = false;
		},
		[updateCorporate.fulfilled]: (state, action) => {
			state.corporates.loading = false;
			const index = state.corporates.corporatesList.findIndex(
				(corporate) => corporate.id === action.payload.id,
			);
			state.corporates.corporatesList[index] = action.payload;
		},
		[deleteCorporate.pending]: (state) => {
			state.corporates.loading = true;
		},
		[deleteCorporate.rejected]: (state) => {
			state.corporates.loading = false;
		},
		[deleteCorporate.fulfilled]: (state, action) => {
			state.corporates.loading = false;
			state.corporates.corporatesList = state.corporates.corporatesList.filter((item) => {
				return item.id !== action.payload;
			});
		},
		[inviteCorporateRider.pending]: (state) => {
			state.corporateRiders.loading = true;
		},
		[inviteCorporateRider.rejected]: (state) => {
			state.corporateRiders.loading = false;
		},
		[inviteCorporateRider.fulfilled]: (state, action) => {
			state.corporateRiders.loading = false;
			state.corporateRiders.ridersList.push(action.payload);
		},
		[getCorporateRiders.pending]: (state) => {
			state.corporateRiders.loading = true;
		},
		[getCorporateRiders.rejected]: (state) => {
			state.corporateRiders.loading = false;
		},
		[getCorporateRiders.fulfilled]: (state, action) => {
			state.corporateRiders.loading = false;
			state.corporateRiders.ridersList = Object.values(action.payload);
		},
		[deleteCorporateRider.pending]: (state) => {
			state.corporateRiders.loading = true;
		},
		[deleteCorporateRider.rejected]: (state) => {
			state.corporateRiders.loading = false;
		},
		[deleteCorporateRider.fulfilled]: (state, action) => {
			state.corporateRiders.loading = false;
			state.corporateRiders.ridersList = state.corporateRiders.ridersList.filter(
				(rider) => rider.email !== action.payload,
			);
		},
		[resendInvitationToRider.pending]: (state) => {
			state.corporateRiders.loading = true;
		},
		[resendInvitationToRider.rejected]: (state) => {
			state.corporateRiders.loading = false;
		},
		[resendInvitationToRider.fulfilled]: (state, action) => {
			state.corporateRiders.loading = false;
			// update the corporate rider with email = action.payload.email with the new data which is action.payload.response
			const index = state.corporateRiders.ridersList.findIndex(
				(rider) => rider.email === action.payload.email,
			);
			state.corporateRiders.ridersList[index] = action.payload.response.data;
		},
		[saveCorporateDiscount.pending]: (state) => {
			state.corporateDiscount.loading = true;
		},
		[saveCorporateDiscount.rejected]: (state) => {
			state.corporateDiscount.loading = false;
		},
		[saveCorporateDiscount.fulfilled]: (state, action) => {
			state.corporateDiscount.loading = false;
			state.corporateDiscount.discount = action.payload;
		},
		[saveAllowedArea.pending]: (state) => {
			state.corporateAllowedArea.loading = true;
		},
		[saveAllowedArea.rejected]: (state) => {
			state.corporateAllowedArea.loading = false;
		},
		[saveAllowedArea.fulfilled]: (state, action) => {
			state.corporateAllowedArea.loading = false;
			state.corporateAllowedArea.allowedArea.push(action.payload);
		},
		[deleteAllowedArea.pending]: (state) => {
			state.corporateAllowedArea.loading = true;
		},
		[deleteAllowedArea.rejected]: (state) => {
			state.corporateAllowedArea.loading = false;
		},
		[deleteAllowedArea.fulfilled]: (state, action) => {
			state.corporateAllowedArea.loading = false;
			state.corporateAllowedArea.allowedArea = state.corporateAllowedArea.allowedArea.filter(
				(area) => area.id !== action.payload,
			);
		},
		[getAllAllowedAreasForCorporate.pending]: (state) => {
			state.corporateAllowedArea.loading = true;
		},
		[getAllAllowedAreasForCorporate.rejected]: (state) => {
			state.corporateAllowedArea.loading = false;
		},
		[getAllAllowedAreasForCorporate.fulfilled]: (state, action) => {
			state.corporateAllowedArea.loading = false;
			state.corporateAllowedArea.allowedArea = Object.values(action.payload);
		},
		[saveCorporateSchedule.pending]: (state) => {
			state.listOfCorporateSchedule.loading = true;
		},
		[saveCorporateSchedule.rejected]: (state) => {
			state.listOfCorporateSchedule.loading = false;
		},
		[saveCorporateSchedule.fulfilled]: (state, action) => {
			state.listOfCorporateSchedule.loading = false;
			state.listOfCorporateSchedule.schedules = action.payload;
		},
		[fetchCorporateSchedule.pending]: (state) => {
			state.listOfCorporateSchedule.loading = true;
		},
		[fetchCorporateSchedule.rejected]: (state) => {
			state.listOfCorporateSchedule.loading = false;
		},
		[fetchCorporateSchedule.fulfilled]: (state, action) => {
			state.listOfCorporateSchedule.loading = false;
			state.listOfCorporateSchedule.schedules = action.payload;
		},
		[createCorporateScheduleRow.pending]: (state) => {
			state.listOfCorporateSchedule.loading = true;
		},
		[createCorporateScheduleRow.rejected]: (state) => {
			state.listOfCorporateSchedule.loading = false;
		},
		[createCorporateScheduleRow.fulfilled]: (state, action) => {
			state.listOfCorporateSchedule.loading = false;
			state.listOfCorporateSchedule.schedules.push(action.payload);
		},
		[deleteCorporateScheduleRow.pending]: (state) => {
			state.listOfCorporateSchedule.loading = true;
		},
		[deleteCorporateScheduleRow.rejected]: (state) => {
			state.listOfCorporateSchedule.loading = false;
		},
		[deleteCorporateScheduleRow.fulfilled]: (state, action) => {
			state.listOfCorporateSchedule.loading = false;
			state.listOfCorporateSchedule.schedules =
				state.listOfCorporateSchedule.schedules.filter(
					(schedule) => schedule.id !== action.payload,
				);
		},
		[updateCorporateScheduleRow.pending]: (state) => {
			state.listOfCorporateSchedule.loading = true;
		},
		[updateCorporateScheduleRow.rejected]: (state) => {
			state.listOfCorporateSchedule.loading = false;
		},
		[updateCorporateScheduleRow.fulfilled]: (state, action) => {
			state.listOfCorporateSchedule.loading = false;
			const index = state.listOfCorporateSchedule.schedules.findIndex(
				(schedule) => schedule.id === action.payload.id,
			);
			state.listOfCorporateSchedule.schedules[index] = action.payload;
		},
	},
});

export default corporateSlice.reducer;

export const { resetSelectedCorporate, resetSelectedAllowedArea, setSelectedAllowedArea } =
	corporateSlice.actions;
