/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable import/no-extraneous-dependencies */
import { Breadcrumb, Button, Card, Input, message } from 'antd';
import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { ReloadOutlined } from '@ant-design/icons';
import {
	deleteLocationWiseFare,
	getAllLocationsWiseFare,
	updateLocationWiseFare,
} from '../../redux/LocationWiseFareSlice';

import { style } from '../../Styles';
import CustomTableComponents from '../Global/CustomTableComponents';
import LocationWiseFareDrawer from './LocationWiseFareDrawer';
import ActionButton from './ActionButton';

const LocationWiseFare = ({ role }) => {
	const [filter, setfilter] = useState('');
	const { Settings } = useSelector((state) => state.settings);

	const LocationWiseFareColumns = [
		{
			title: 'ID',
			dataIndex: 'id',
			defaultSortOrder: 'ascend',
			sorter: (a, b) => a.id - b.id,
		},
		{
			title: 'Source Location',
			key: 'sourceLocation',
			render: (record) => {
				return <span>{`${record?.sourceLocation?.locationName} `}</span>;
			},
			defaultSortOrder: 'ascend',
			sorter: (a, b) =>
				a.sourceLocation?.locationName.length - b.sourceLocation?.locationName.length,
		},
		{
			title: 'Destination Location',
			key: 'destinationLocation',
			render: (record) => {
				return <span>{`${record?.destinationLocation?.locationName} `}</span>;
			},
			defaultSortOrder: 'ascend',
			sorter: (a, b) =>
				a.destinationLocation?.locationName.length -
				b.destinationLocation?.locationName.length,
		},
		{
			title: 'Flat Fare',
			key: 'flatFare',
			render: (record) => {
				return (
					<span>{`${record?.flatFare} ${Settings?.settingsData?.currency || '€'} `}</span>
				);
			},
			defaultSortOrder: 'ascend',
			sorter: (a, b) => a.flatFare - b.flatFare,
		},
		{
			title: 'Vehicle Type',
			key: 'vehicleType',
			render: (record) => {
				return <span style={style.tags}>{`${record?.vehicleType?.vehicleType} `}</span>;
			},
			defaultSortOrder: 'ascend',

			sorter: (a, b) => a.vehicleType.vehicleType.length - b.vehicleType.vehicleType.length,
		},
		{
			title: 'Status',
			key: 'status',
			render: (record) => {
				return (
					<span style={style.dataContainer}>
						{record.isActive ? (
							<div style={style.statusActif}>ACTIVE</div>
						) : (
							<div style={style.statusInactif}>INACTIVE</div>
						)}
					</span>
				);
			},
			sorter: {
				compare: (a, b) => a.isActive - b.isActive,
				multiple: 1,
			},
			width: 90,
		},
		{
			title: 'Actions',
			key: 'actions',
			render: (record) => (
				<ActionButton
					record={record}
					onDelete={onDelete}
					onRowClick={onRowClick}
					onChangeStatus={onChangeStatus}
				/>
			),
			disabled: !role?.includes('SUPER_ADMIN'),
		},
	];
	const [makingApiCall, setMakingApiCall] = useState(false);
	const dispatch = useDispatch();
	const { LocationsWiseFare } = useSelector((state) => state.locationsWiseFare);
	const [locationWiseFare, setLocationWiseFare] = useState(null);
	const [showDrwer, setShowDrwer] = useState(false);

	const onDelete = (id) => {
		setMakingApiCall(true);
		dispatch(
			deleteLocationWiseFare({
				id,
			}),
		)
			.unwrap()
			.then(() => {
				message.success('Location ise Fare deleted successfully');
			})
			.catch(() => {
				message.error('Could not delete Locationwise Fare, please try again');
			})
			.finally(() => {
				setMakingApiCall(false);
			});
	};

	const onChangeStatus = (values) => {
		setMakingApiCall(true);

		const action = updateLocationWiseFare({
			id: values.id,
			locationWiseFare: values,
		});

		dispatch(action)
			.unwrap()
			.then(() => {
				message.success('Locationwise Fare saved successfully');
			})
			.catch((e) => {
				if (e.message === 'LOCATION_WISE_FARE_ALREADY_EXISTS') {
					message.error('Locationwise Fare already exists');
				} else {
					message.error('Could not save Locationwise Fare, please try again');
				}
			})
			.finally(() => {
				setMakingApiCall(false);
			});
	};
	const handleOpenDrawer = () => {
		setShowDrwer(true);
	};
	const handleCloseDrawer = () => {
		setLocationWiseFare(null);
		setShowDrwer(false);
	};
	const fetchAllLocationsWiseFare = async () => {
		setMakingApiCall(true);
		dispatch(getAllLocationsWiseFare())
			.unwrap()
			.then(() => {})
			.catch(() => {
				message.error('Could not fetch Locationwise Fare, please try again');
			})
			.finally(() => {
				setMakingApiCall(false);
			});
	};
	useEffect(() => {
		fetchAllLocationsWiseFare();
	}, []);

	const onRowClick = (record) => {
		setLocationWiseFare(record);
		handleOpenDrawer();
	};

	let data = Object.values(LocationsWiseFare.locationsWiseFareList);
	data = data.map((item) => {
		return { ...item, key: item.id };
	});
	const filtredData = data.filter((item) => {
		return (
			item?.destinationLocation?.locationName
				.toString()
				.toLowerCase()
				.indexOf(filter.toLowerCase()) !== -1 ||
			item.id.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
			item?.flatFare.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
			item?.sourceLocation?.locationName
				.toString()
				.toLowerCase()
				.indexOf(filter.toLowerCase()) !== -1 ||
			item?.vehicleType?.vehicleType
				.toString()
				.toLowerCase()
				.indexOf(filter.toLowerCase()) !== -1
		);
	});
	return (
		<>
			<Card
				style={{
					margin: 16,
					maxHeight: '90%', // Set maximum width
					height: 'auto', // Allow width to be dynamic
				}}>
				<div style={style.inlineContent}>
					<div
						style={{
							display: 'flex',
							alignContent: 'center',
							alignItems: 'center',
							float: 'left',
							marginBottom: 8,
						}}>
						<Breadcrumb
							style={{ margin: '0 3em 0 0', fontSize: '1.1em' }}
							items={[
								{
									title: 'Locations',
								},
								{
									title: 'Locationwise Fare',
								},
							]}
						/>
						<Button
							type='default'
							size='small'
							// style={{ margin: '0 8px 8px 0' }}
							onClick={() => fetchAllLocationsWiseFare()}>
							<ReloadOutlined />
						</Button>
					</div>

					<div
						style={{
							...style.inlineContent,
							float: 'right',
							marginBottom: 8,
						}}>
						<Input.Search
							placeholder='Search'
							style={{ margin: '0 8px 8px 0' }}
							value={filter}
							onChange={(e) => setfilter(e.target.value)}
						/>
						{role?.includes('SUPER_ADMIN') && (
							<Button
								type='default'
								style={{ float: 'right', marginBottom: 8 }}
								onClick={handleOpenDrawer}>
								Add Locationwise Fare
							</Button>
						)}
					</div>
				</div>
				<CustomTableComponents
					isMakingApiCall={makingApiCall}
					columns={LocationWiseFareColumns.filter((item) => {
						return !item.disabled;
					})}
					dataArray={filtredData}
					handleDoubleClickRow={() => (role?.includes('SUPER_ADMIN') ? onRowClick() : {})}
					handleSelectedRow={() => {}}
				/>
			</Card>
			{showDrwer && (
				<LocationWiseFareDrawer
					handleClose={handleCloseDrawer}
					open={handleOpenDrawer}
					locationWiseFareId={locationWiseFare?.id}
				/>
			)}
		</>
	);
};

export default LocationWiseFare;
