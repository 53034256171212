/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */

import { Alert, Card, Form, Input, Modal, Select, Space, Spin, message } from 'antd';
import React, { useState } from 'react';
import { EuroCircleTwoTone, CreditCardTwoTone } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { style } from '../../../Styles';
import refundReasons from '../../../Utils/RefundReasons';
import { refundBooking } from '../../../redux/bookingSlice';

const layout = {
	labelCol: {
		span: 8,
		align: 'left',
	},
	wrapperCol: {
		span: 16,
	},
};

const RefundBookingModal = ({ show, onClose, booking }) => {
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const [makingApiCall, setMakingApiCall] = useState(false);

	const onRefundTypeChange = (value) => {
		if (value === 'FULL') {
			form.setFieldsValue({
				amountToRefund: booking?.priceBreakdown?.paidAmount,
			});
		} else {
			form.setFieldsValue({
				amountToRefund: 0,
			});
		}
	};

	const onReasonChange = (value) => {
		if (value === 'OTHER') {
			form.setFieldsValue({
				refundReasonDescription: '',
			});
		}
	};

	const handleRefundBooking = async (data) => {
		console.log('data', data);
		setMakingApiCall(true);

		dispatch(refundBooking(data))
			.unwrap()
			.then(() => {
				message.success('Booking refunded successfully');
				setMakingApiCall(false);
				form.resetFields();
				onClose();
			})
			.catch((error) => {
				console.log('error', error);
				message.error('Failed to refund booking');
			})
			.finally(() => {
				setMakingApiCall(false);
			});
	};

	// Watch all values
	const formValues = Form.useWatch([], form);

	return (
		<Modal
			title={`Refund Booking - ${booking?.id}`}
			centered
			open={show}
			onOk={() => {
				if (!makingApiCall) {
					// validate form
					form.validateFields()
						.then((values) => {
							// show confirm modal
							console.log('values', values);
							Modal.confirm({
								title: 'Confirm Refund',
								content: `Please note that the refund process for this booking is irreversible. Are you sure you want to proceed with the refund with amount of ${values.amountToRefund} EUR?`,
								onOk: () => {
									handleRefundBooking({
										bookingId: booking?.id,
										...values,
									});
								},
							});
						})
						.catch((error) => {
							console.log('error', error);
						});
				}
			}}
			onCancel={() => {
				if (!makingApiCall) {
					form.resetFields();
					onClose();
				}
			}}>
			<Spin spinning={makingApiCall} tip='Refund in process'>
				<Space
					direction='vertical'
					size='middle'
					style={{
						display: 'flex',
					}}>
					<Alert
						style={{
							marginTop: '1em',
							padding: '1.4em',
						}}
						message={
							<span>
								Payment Method - <strong>{booking?.paymentType}</strong>
							</span>
						}
						description={
							<span>
								This booking was paid using{' '}
								<strong>
									{booking?.paymentType === 'CARD' ? ' credit card' : ' cash'}
								</strong>
								. Please select the type of refund and the amount to refund
							</span>
						}
						type='info'
						action={
							<Space>
								{booking?.paymentType === 'CARD' ? (
									<CreditCardTwoTone
										style={{
											fontSize: '1.5em',
										}}
									/>
								) : (
									<EuroCircleTwoTone
										style={{
											fontSize: '1.5em',
										}}
									/>
								)}
							</Space>
						}
					/>
					<Card
						title='Card title'
						style={{
							...style.boxShadow,
							marginTop: '1em',
							padding: '0.6em',
						}}
						size='small'>
						<Form {...layout} form={form} name='refund-form'>
							<Form.Item
								label='Type of Refund'
								name='refundType'
								rules={[
									{
										required: true,
										message: 'Please select the type of refund',
									},
								]}>
								<Select
									onChange={(value) => {
										onRefundTypeChange(value);
									}}>
									<Select.Option value='FULL'>Full</Select.Option>
									<Select.Option value='PARTIAL'>Partial</Select.Option>
								</Select>
							</Form.Item>
							<Form.Item
								label='Amount to refund'
								name='amountToRefund'
								rules={[
									{
										required: true,
										message: 'Please input the amount to refund',
									},
									{
										validator: (_, value) => {
											const paidAmount = parseFloat(
												booking?.priceBreakdown?.paidAmount,
											);
											const inputValue = parseFloat(value);
											if (
												Number.isNaN(paidAmount) ||
												Number.isNaN(inputValue)
											) {
												return Promise.reject(
													new Error('Invalid paid amount'),
												);
											}
											if (inputValue < 0.5) {
												return Promise.reject(
													new Error(
														'Amount to refund cannot be less than 0.5',
													),
												);
											}
											if (inputValue > paidAmount) {
												return Promise.reject(
													new Error(
														`The max amount to refund is ${paidAmount}`,
													),
												);
											}
											return Promise.resolve();
										},
									},
								]}>
								<Input
									prefix={<EuroCircleTwoTone />}
									suffix='EUR'
									type='number'
									disabled={formValues?.refundType === 'FULL'}
								/>
							</Form.Item>

							<Form.Item
								label='Reason'
								name='refundReason'
								rules={[
									{
										required: true,
										message: 'Please select the reason for the refund',
									},
								]}>
								<Select onChange={onReasonChange}>
									{refundReasons.map((reason) => (
										<Select.Option key={reason.value} value={reason.value}>
											{reason.label}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item
								label='Description'
								name='refundReasonDescription'
								rules={[
									{
										required: formValues?.refundReason === 'OTHER',
										message: 'Please provide a description',
									},
								]}>
								<Input.TextArea placeholder='Please provide a description' />
							</Form.Item>
						</Form>
					</Card>
				</Space>
			</Spin>
		</Modal>
	);
};

export default RefundBookingModal;
