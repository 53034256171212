import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import useSSE from '../CustomHooks/useSSE';

const initialState = {
	driversLastLocation: null,
};

const endpoint = 'gods-view';
const eventListener = 'drivers-last-location-event';

export const getDriversLastLocation = createAsyncThunk(
	'godsView/getDriversLastLocation',
	async (status) => {
		try {
			const data = useSSE(`${endpoint}?status=${status}`, eventListener);
			console.log(data);
			return data;
		} catch (error) {
			const customError = {
				name: 'Custom error',
				message: error.response.data.message,
				/* data: error.response.data, */
			};
			throw customError;
		}
	},
);

export const driversSlice = createSlice({
	name: 'godsView',
	initialState,
	reducers: {},
	extraReducers: {
		[getDriversLastLocation.pending]: (state) => {
			state.driversLastLocation = null;
		},
		[getDriversLastLocation.rejected]: (state) => {
			state.driversLastLocation = null;
		},
		[getDriversLastLocation.fulfilled]: (state, action) => {
			state.driversLastLocation = action.payload;
		},
	},
});

export default driversSlice.reducer;
