import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../axios';

const initialState = {
	bookingPools: { loading: false, bookingPoolList: null },
	driverNavigation: { loading: false, driverNavigationList: null },
};

export const getAllBookingPools = createAsyncThunk(
	'bookingPool/getAllBookingPools',
	async (data) => {
		try {
			const response = await axios.patch(`/booking-pool`, data);
			return response.data;
		} catch (error) {
			const customError = {
				name: 'Custom axios error',
				message: error.response.data.message,
				/* data: error.response.data, */
			};
			throw customError;
		}
	},
);

export const getAllBookingPoolsWithCretaria = createAsyncThunk(
	'bookingPool/getAllBookingPoolsWithCretaria',
	async (filterData) => {
		try {
			const response = await axios.patch(`/booking-pool/filter`, filterData);
			return response.data;
		} catch (error) {
			const customError = {
				name: 'Custom axios error',
				message: error.response.data.message,
				/* data: error.response.data, */
			};
			throw customError;
		}
	},
);

export const getDriverNavigation = createAsyncThunk(
	'bookingPool/getDriverNavigation',
	async (bookingPoolId) => {
		try {
			const response = await axios.get(`/driver-navigation/${bookingPoolId}`);
			return response.data;
		} catch (error) {
			const customError = {
				name: 'Custom axios error',
				message: error.response.data.message,
				/* data: error.response.data, */
			};
			throw customError;
		}
	},
);

export const bookingPoolSlice = createSlice({
	name: 'bookingPool',
	initialState,
	reducers: {
		resetDriverNavigation: (state) => {
			state.driverNavigation.loading = false;
			state.driverNavigation.driverNavigationList = null;
		},
	},
	extraReducers: {
		[getAllBookingPools.pending]: (state) => {
			state.bookingPools.loading = true;
		},
		[getAllBookingPools.rejected]: (state) => {
			state.bookingPools.loading = false;
		},
		[getAllBookingPools.fulfilled]: (state, action) => {
			state.bookingPools.loading = false;
			state.bookingPools.bookingPoolList = Object.values(action.payload);
		},
		[getAllBookingPoolsWithCretaria.pending]: (state) => {
			state.bookingPools.loading = true;
		},
		[getAllBookingPoolsWithCretaria.rejected]: (state) => {
			state.bookingPools.loading = false;
		},
		[getAllBookingPoolsWithCretaria.fulfilled]: (state, action) => {
			state.bookingPools.loading = false;
			state.bookingPools.bookingPoolList = Object.values(action.payload);
		},
		[getDriverNavigation.pending]: (state) => {
			state.driverNavigation.loading = true;
		},
		[getDriverNavigation.rejected]: (state) => {
			state.driverNavigation.loading = false;
		},
		[getDriverNavigation.fulfilled]: (state, action) => {
			state.driverNavigation.loading = false;
			state.driverNavigation.driverNavigationList = Object.values(action.payload);
		},
	},
});
export const { resetDriverNavigation } = bookingPoolSlice.actions;
export default bookingPoolSlice.reducer;
