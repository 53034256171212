import { Button, Card, Col, Row, Spin, Tooltip } from 'antd';
import React, { useState } from 'react';
import {
	CloseCircleOutlined,
	CarOutlined,
	FieldTimeOutlined,
	CheckCircleOutlined,
	InfoCircleOutlined,
	LoadingOutlined,
	ReloadOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import CustomCountingCard from './CustomCountingCard';
import { getBookingStatistics } from '../../redux/dashboardSlice';

const RideStatistics = () => {
	const navigate = useNavigate();
	const { dashboard } = useSelector((state) => state.dashboard);
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();

	const reload = () => {
		setLoading(true);
		dispatch(getBookingStatistics())
			.then(() => {})
			.catch((error) => {
				console.log('error', error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<Spin
			size='large'
			indicator={<LoadingOutlined style={{ fontSize: 54 }} spin />}
			tip={
				<div>
					<h2>Loading...</h2>
				</div>
			}
			spinning={loading}>
			<Card
				type='inner'
				title='Bookings Statistics'
				bordered={false}
				extra={
					<div>
						<Tooltip title='This widget displays booking statistics, presenting the number of bookings that are currently being processed or are active. These include ongoing, completed, and canceled bookings'>
							<InfoCircleOutlined />
						</Tooltip>
						<Button
							style={{
								marginLeft: '1em',
							}}
							type='default'
							size='small'
							onClick={() => reload()}>
							<ReloadOutlined />
						</Button>
					</div>
				}
				style={{
					width: '100%',
				}}>
				<Row gutter={16}>
					<Col
						style={{
							cursor: 'pointer',
						}}
						onClick={() => {
							navigate('/app/main/booking');
						}}
						span={12}>
						<CustomCountingCard
							title='Total Bookings'
							count={dashboard?.bookingStatistics?.totalBookings}
							currency=''
							icon={<CarOutlined />}
						/>
					</Col>
					<Col
						style={{
							cursor: 'pointer',
						}}
						onClick={() => {
							navigate(
								'/app/main/booking',
								{ state: { status: 'IN_PROGRESS' } },
								{ replace: true },
							);
						}}
						span={12}>
						<CustomCountingCard
							title='In Progress Bookings'
							count={dashboard?.bookingStatistics?.onGoingBookings}
							currency=''
							icon={<FieldTimeOutlined />}
						/>
					</Col>
					<Col
						style={{
							cursor: 'pointer',
							marginTop: '0.5em',
						}}
						onClick={() => {
							navigate(
								'/app/main/booking',
								{ state: { status: 'CANCELLED' } },
								{ replace: true },
							);
						}}
						span={12}>
						<CustomCountingCard
							title='Cancelled Bookings'
							count={dashboard?.bookingStatistics?.cancelledBookings}
							currency=''
							icon={<CloseCircleOutlined />}
						/>
					</Col>
					<Col
						style={{
							cursor: 'pointer',
							marginTop: '0.5em',
						}}
						onClick={() => {
							navigate(
								'/app/main/booking',
								{ state: { status: 'COMPLETED' } },
								{ replace: true },
							);
						}}
						span={12}>
						<CustomCountingCard
							title='Completed Bookings'
							count={dashboard?.bookingStatistics?.completedBookings}
							currency=''
							icon={<CheckCircleOutlined />}
						/>
					</Col>
				</Row>
			</Card>
		</Spin>
	);
};

export default RideStatistics;
