/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useCallback, useEffect, useState } from 'react';
import { Drawer, Spin, message } from 'antd';
import { useSelector } from 'react-redux';
import ShowConfirmClose from '../Modals/ShowConfirmClose';
import {
	createCorporate,
	getCorporateById,
	resetSelectedAllowedArea,
	resetSelectedCorporate,
	updateCorporate,
} from '../../redux/corporateSlice';
import CorporateForm from './CorporateForm';

const CorporateDrawer = ({ onClose, corporateId, dispatch }) => {
	const [unsavedChanges, setUnsavedChanges] = useState(false);
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const { corporates, selectedCorporate } = useSelector((state) => state.corporates);
	const [cor, setCor] = useState(null);

	const handleClose = useCallback(
		(hasUnsavedChanges) => {
			dispatch(resetSelectedCorporate());
			dispatch(resetSelectedAllowedArea());
			if (hasUnsavedChanges && !confirmationOpen) {
				setConfirmationOpen(true); // Mark that the confirmation modal is open
				ShowConfirmClose(() => {
					setConfirmationOpen(false); // Reset when the confirmation modal is closed

					onClose();
				});
			} else {
				onClose();
			}
		},
		[onClose, confirmationOpen],
	);

	const handleDirty = useCallback(() => {
		setUnsavedChanges(true);
	}, []);

	const saveCorporate = async (values) => {
		const action = corporateId
			? updateCorporate({
					...values,
					corporateId,
			  })
			: createCorporate(values);
		dispatch(action)
			.unwrap()
			.then(() => {
				message.success('Corporate saved successfully');
				setUnsavedChanges(false);
			})
			.catch((e) => {
				if (e.message === 'CORPORATE_ALREADY_EXIST') {
					message.error('Could not save make, it is already exist');
				} else {
					message.error(e.message);
				}
			})
			.finally(() => {
				onClose();
			});
	};

	const onSaveFailed = (error) => {
		console.log('Failed:', error);
	};

	useEffect(() => {
		const abortController = new AbortController();

		if (corporateId) {
			dispatch(getCorporateById(corporateId))
				.unwrap()
				.then((data) => {
					setCor(data);
				})
				.catch((error) => console.log(error));
		}
		return () => abortController.abort();
	}, [corporateId, dispatch]);
	return (
		<Drawer
			width={500}
			title={!selectedCorporate?.corporate ? 'Add Corporate' : 'Update Corporate'}
			placement='right'
			onClose={() => handleClose(unsavedChanges)}
			open>
			<Spin size='large' spinning={corporates?.loading} style={{ marginTop: 64 }} delay={500}>
				<CorporateForm
					corporate={cor}
					onSave={saveCorporate}
					onSaveFailed={onSaveFailed}
					onCancel={handleClose}
					onDirty={handleDirty}
					unsavedChanges={unsavedChanges}
					setUnsavedChanges={setUnsavedChanges}
					corporateId={corporateId}
				/>
			</Spin>
		</Drawer>
	);
};

export default CorporateDrawer;
