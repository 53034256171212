import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';

export function formatDateAndTime(dateString) {
	dayjs.extend(utcPlugin);

	const date = dayjs.utc(dateString);
	const formattedDate = date.format('YYYY-MM-DD hh:mm a');

	return formattedDate;
}

export function formatDate(dateString) {
	dayjs.extend(utcPlugin);

	const date = dayjs.utc(dateString);
	const formattedDate = date.format('YYYY-MM-DD');

	return formattedDate;
}

// Utility function to check if a date is today or before
export function isTodayOrBefore(current) {
	const today = dayjs().startOf('day'); // Get the current date at the start of the day
	return current.isBefore(today); // Return true if the current date is before today
}
export function convertDateToUtc(date) {
	dayjs.extend(utcPlugin);

	const utcDate = dayjs.utc(date);
	return utcDate;
}
