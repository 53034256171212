export const generateRandomUniqueHexColorFromName = (name) => {
	let color = '#';
	// eslint-disable-next-line no-plusplus
	for (let i = 0; i < 3; i++) {
		color += name.charCodeAt(i).toString(16);
	}
	return color;
};

export const generatePasscode = (length) => {
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
	let passcode = '';
	const separatorIndex = 4; // Index of the separator (-)

	// eslint-disable-next-line no-plusplus
	for (let i = 0; i < length; i++) {
		if (i === separatorIndex) {
			passcode += '-';
		} else {
			const randomIndex = Math.floor(Math.random() * characters.length);
			passcode += characters[randomIndex];
		}
	}

	return passcode;
};
