/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
import { Button, Form, Input } from 'antd';
import React from 'react';

const MarkForm = ({ onSave, onSaveFailed, make, onCancel, onDirty, unsavedChanges }) => {
	return (
		<Form
			name='make'
			layout='vertical'
			autoComplete='off'
			initialValues={make}
			onFinish={onSave}
			onFinishFailed={onSaveFailed}
			onValuesChange={() => onDirty()}>
			<Form.Item
				label='Name'
				name='name'
				rules={[{ required: true, message: 'Please input make name' }]}>
				<Input />
			</Form.Item>
			<Form.Item style={{ float: 'right' }}>
				<Button
					type='default'
					style={{ marginBottom: 32, marginRight: 8 }}
					onClick={() => onCancel(unsavedChanges)}>
					Cancel
				</Button>
				<Button
					type='primary'
					style={{ marginBottom: 32 }}
					htmlType='submit'
					disabled={!unsavedChanges}>
					{!make ? 'Save' : 'Update'}
				</Button>
			</Form.Item>
		</Form>
	);
};

export default MarkForm;
