/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
import { List } from 'antd';
import React from 'react';
import { EyeOutlined } from '@ant-design/icons';
import { style } from '../../Styles';
import ImageComponent from '../Global/ImageComponent/ImageComponent';
import CustomAvatar from '../Global/CustomAvatar/CustomAvatar';

const BookingList = ({ bookings, setselectedBooking, openModalBooking, role }) => {
	return (
		<div style={{ ...style.boxShadow, height: '230px', marginTop: '1em', overflow: 'auto' }}>
			<List
				itemLayout='horizontal'
				dataSource={bookings}
				renderItem={(item, index) => (
					<List.Item>
						<List.Item.Meta
							key={index}
							style={{ padding: '0 20px 0 20px' }}
							avatar={
								item?.rider?.profilePicture ? (
									<ImageComponent
										size={20}
										imageId={item?.rider?.profilePicture}
									/>
								) : (
									<CustomAvatar
										name={`${item?.rider?.name} ${item?.rider?.surname}`}
										type='initials'
										size={30}
									/>
								)
							}
							title={
								<a href='https://ant.design' onClick={(e) => e.preventDefault()}>
									{role?.includes('SUPER_ADMIN')
										? `${item?.rider?.name} ${item?.rider?.surname}`
										: `${item?.rider?.name}`}
								</a>
							}
							description={
								<div
									role='button'
									tabIndex={0}
									style={{ display: 'inline-block', cursor: 'pointer' }}
									onClick={() => {
										setselectedBooking(item);
										openModalBooking();
									}}
									onKeyDown={(e) => {
										if (e.key === 'Enter') {
											setselectedBooking(item);
											openModalBooking();
										}
									}}>
									<EyeOutlined />
									{` View details`}
								</div>
							}
						/>
					</List.Item>
				)}
			/>
		</div>
	);
};

export default BookingList;
