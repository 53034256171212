/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
import { Col, Row } from 'antd';
import React from 'react';
import { MinusOutlined, EuroCircleOutlined, CreditCardOutlined } from '@ant-design/icons';
import { style } from '../../Styles';

const labelStyle = {};
const valueStyle = { textAlign: 'right', fontWeight: 'bold' };
const PriceBreakdown = ({ priceBreakdown, paymentType }) => {
	return (
		<div
			style={{
				...style.boxShadow,
				marginTop: '1em',
				padding: '0.6em',
			}}>
			<Row justify='space-between' style={{ marginLeft: '2em' }}>
				<Col span={12}>
					<div>
						<span style={labelStyle}>Initial Fare:</span>
					</div>

					<div>
						<span style={labelStyle}>Peak Surcharge:</span>
					</div>

					<div>
						<span style={labelStyle}>Night Shift:</span>
					</div>

					<div>
						<span style={labelStyle}>Cancellation Fee:</span>
					</div>
					<div>
						<span style={labelStyle}>Discounted Fare:</span>
					</div>
					<MinusOutlined />
					<div style={{ fontWeight: 'bold' }}>
						<span style={labelStyle}>Total:</span>
					</div>
					<MinusOutlined />
					<div style={{ fontWeight: 'bold' }}>
						<span style={labelStyle}>Payment Type:</span>
					</div>
				</Col>
				<Col span={12}>
					<div>
						<span style={valueStyle}>
							{`€${priceBreakdown?.initialFare.toFixed(2)}`}
						</span>
					</div>
					<div>
						<span style={valueStyle}>{`€${priceBreakdown?.peakSurcharge.toFixed(
							2,
						)}`}</span>
					</div>

					<div>
						<span style={valueStyle}>
							{`€${priceBreakdown?.nightShiftSurcharge.toFixed(2)}`}
						</span>
					</div>
					<div>
						<span style={valueStyle}>
							{`€${priceBreakdown?.cancellationFee.toFixed(2)}`}
						</span>
					</div>
					<div>
						<span style={valueStyle}>
							{priceBreakdown?.discount
								? `€${priceBreakdown?.discount?.toFixed(2)}`
								: '€0.00'}
						</span>
					</div>
					<MinusOutlined />
					<div style={{ fontWeight: 'bold' }}>
						<span style={valueStyle}>
							{priceBreakdown?.paidAmount
								? `€${priceBreakdown?.paidAmount.toFixed(2)}`
								: `€${priceBreakdown?.totalFare.toFixed(2)}`}
						</span>
					</div>
					<MinusOutlined />

					<div style={{ fontWeight: 'bold' }}>
						{paymentType}{' '}
						{paymentType === 'CASH' ? <EuroCircleOutlined /> : <CreditCardOutlined />}
					</div>
				</Col>
			</Row>
		</div>
	);
};

export default PriceBreakdown;
