/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
import { Table } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const CustomTableComponents = ({
	isMakingApiCall,
	columns,
	dataArray,
	handleSelectedRow,
	handleDoubleClickRow,
	numberOfRows,
}) => {
	const pageSize = numberOfRows || 10;
	const showSizeChanger = !numberOfRows;

	return (
		<div style={{ overflowX: 'auto' }}>
			<Table
				pagination={{
					defaultPageSize: pageSize || 10,
					showSizeChanger,
					showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
					pageSizeOptions: ['10', '20', '50', '100'],
				}}
				loading={{
					spinning: isMakingApiCall,
					indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
					tip: (
						<div>
							<h2>Loading...</h2>
						</div>
					),
				}}
				columns={columns}
				dataSource={dataArray}
				onRow={(record) => {
					return {
						onClick: () => handleSelectedRow(record),
						onDoubleClick: () => handleDoubleClickRow(record),
					};
				}}
				scroll={{ x: 'max-content', y: '73vh' }}
			/>
		</div>
	);
};

export default CustomTableComponents;
