/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import { Drawer, Spin, message } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addNewRider, getRiderById, updateRiderData } from '../../redux/riderSlice';
import RiderForm from './RiderForm';
import ShowConfirmClose from '../Modals/ShowConfirmClose';
import { downloadImage, uploadProfilPicture } from '../../redux/fileSlice';

const DrawerRider = ({ onClose, riderId, handleDlete }) => {
	const [unsavedChanges, setUnsavedChanges] = useState(false);
	const dispatch = useDispatch();
	const [makingApiCall, setMakingApiCall] = useState(false);
	const [rider, setRider] = useState(undefined);

	const [fileList, setFileList] = useState([]);
	const [uploading, setUploading] = useState(false);
	const [isFileChosen, setIsFileChosen] = useState(false);
	const handleUpload = (callback, values) => {
		if (fileList.length !== 0 && isFileChosen) {
			setUploading(true);
			dispatch(uploadProfilPicture(fileList[0]?.originFileObj))
				.unwrap()
				.then((res) => {
					setFileList([]);
					callback(values, res.id);
				})
				.catch(() => {
					message.error('upload failed.');
				})
				.finally(() => {
					setUploading(false);
				});
		} else callback(values, null);
	};

	const onSave = (values) => {
		handleSave(values);
	};

	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const handleClose = useCallback(
		(hasUnsavedChanges) => {
			if (hasUnsavedChanges && !confirmationOpen) {
				setConfirmationOpen(true); // Mark that the confirmation modal is open
				ShowConfirmClose(() => {
					setConfirmationOpen(false); // Reset when the confirmation modal is closed
					onClose();
				});
			} else {
				onClose();
			}
		},
		[onClose, confirmationOpen],
	);

	const handleDirty = useCallback(() => {
		setUnsavedChanges(true);
	}, []);

	const handleSave = async (values) => {
		setMakingApiCall(true);
		await handleUpload(saveRider, values); // Wait for the upload to complete and update the pictureId
	};

	const saveRider = async (values, profilePictureId) => {
		const action = riderId
			? updateRiderData({
					riderId,
					rider: {
						...values,
						email: values.email.trim().toLowerCase(),
						profilePhoto: isFileChosen ? profilePictureId : rider.profilePicture,
						active: rider.active,
					},
			  })
			: addNewRider({
					rider: {
						...values,
						email: values.email.trim().toLowerCase(),
						profilePhoto: profilePictureId,
					},
			  });
		dispatch(action)
			.unwrap()
			.then(() => {
				message.success('Rider saved successfully');
				setUnsavedChanges(false);
			})
			.catch((e) => {
				if (e.message === 'RIDER_ALREADY_EXIST') {
					message.error('Could not save rider, it is already exist');
				} else {
					message.error(e.message);
				}
			})
			.finally(() => {
				onClose();
				setMakingApiCall(false);
			});
	};

	const onSaveFailed = (error) => {
		console.log('Failed:', error);
	};

	const fetchImage = async (imageId) => {
		setMakingApiCall(true);

		return dispatch(downloadImage(imageId))
			.unwrap()
			.then((data) => {
				const imgUrl = URL.createObjectURL(data);
				setMakingApiCall(false);
				return imgUrl;
			})
			.catch((error) => {
				setMakingApiCall(false);
				throw error;
			});
	};

	useEffect(() => {
		const abortController = new AbortController();

		if (riderId) {
			setMakingApiCall(true);
			const calls = [
				dispatch(
					getRiderById({
						riderId,
					}),
				),
			];
			Promise.all(calls)
				.then((res) => {
					if (riderId) {
						setRider(res[0].payload);
						if (
							res[0].payload?.profilePicture &&
							res[0].payload?.profilePicture !== 0
						) {
							fetchImage(res[0].payload?.profilePicture).then((imgUrl) => {
								setFileList([
									{
										uid: '-1',
										name: 'image.png',
										status: 'done',
										url: imgUrl,
									},
								]);
							});
						}
					}
				})
				.catch(() => {
					onClose();
				})
				.finally(() => {
					setMakingApiCall(false);
				});
		}
		return () => abortController.abort();
	}, [riderId, dispatch]);

	return (
		<Drawer
			width={500}
			title={!riderId ? 'Add Rider' : 'Update Rider'}
			placement='right'
			onClose={() => handleClose(unsavedChanges)}
			open>
			<Spin size='large' spinning={makingApiCall} style={{ marginTop: 64 }} delay={500}>
				{!makingApiCall && (
					<RiderForm
						rider={rider}
						onSave={onSave}
						onSaveFailed={onSaveFailed}
						onDeleteRider={handleDlete}
						onCancel={handleClose}
						onDirty={handleDirty}
						unsavedChanges={unsavedChanges}
						setUnsavedChanges={setUnsavedChanges}
						fileList={fileList}
						setFileList={setFileList}
						handleUpload={handleUpload}
						uploading={uploading}
						setIsFileChosen={setIsFileChosen}
					/>
				)}
			</Spin>
		</Drawer>
	);
};

export default DrawerRider;
