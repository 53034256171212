/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import { Form, Input, Radio, Select, Button, Divider, Spin } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { countryPhoneCode } from '../../Utils/country-phone-codes';
import UploadComponent from '../Global/CustomAvatar/UploadComponent';
import getCountryFromIP from '../../Utils/GettingCountryFromIP';

const optionsGender = [
	{ label: 'Male', value: 'Male' },
	{ label: 'Female', value: 'Female' },
	{ label: 'Undisclosed', value: 'Undisclosed' },
];
const validateMessages = {
	types: {
		email: '${label} is not a valid email',
	},
};

const RiderForm = ({
	onSave,
	onSaveFailed,
	rider,
	onCancel,
	onDirty,
	unsavedChanges,
	fileList,
	setFileList,
	handleUpload,
	uploading,
	setIsFileChosen,
}) => {
	const [form] = useForm();
	const [countryFromIp, setCountryFromIp] = useState('Malta');
	const [phoneCodeFromIp, setPhoneCodeFromIp] = useState('356');
	useEffect(() => {
		getCountryFromIP().then((country) => {
			setCountryFromIp(country);
			// Find the corresponding phone code for the country
			const foundCountry = countryPhoneCode.find((c) => c.en === country);
			if (foundCountry) {
				form.setFieldsValue({
					phoneCountryCode: rider ? rider?.phoneCountryCode : foundCountry.phoneCode,
					country: rider ? rider?.country : foundCountry.en,
				});
				setPhoneCodeFromIp(foundCountry.phoneCode);
				setCountryFromIp(foundCountry.en);
			}
		});
	}, []);
	const [selectedGender, setSelectedGender] = useState('');
	const onChangeGender = ({ target: { value } }) => {
		setSelectedGender(value);
	};

	return (
		<Form
			form={form}
			name='rider'
			layout='vertical'
			autoComplete='off'
			initialValues={rider}
			validateMessages={validateMessages}
			onFinish={onSave}
			onFinishFailed={onSaveFailed}
			onValuesChange={() => onDirty()}>
			<Form.Item
				label='Profile Picture'
				name='profilePicture'
				style={{ textAlign: 'center' }}>
				<Spin spinning={uploading}>
					<UploadComponent
						fileList={fileList}
						setFileList={setFileList}
						handleUpload={handleUpload}
						uploading={uploading}
						setIsFileChosen={setIsFileChosen}
					/>
				</Spin>
			</Form.Item>
			<Form.Item
				label='Name'
				name='name'
				rules={[{ required: true, message: 'Please input rider name' }]}>
				<Input />
			</Form.Item>
			<Form.Item
				label='Surname'
				name='surname'
				rules={[{ required: true, message: 'Please input rider surname' }]}>
				<Input />
			</Form.Item>
			<Form.Item
				label='Email'
				name='email'
				rules={[
					{
						required: true,
						message: 'Please input  Email',
					},
					{
						pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
						message: 'Please enter a valid email address',
					},
				]}>
				<Input />
			</Form.Item>
			<Form.Item
				label='Country'
				name='country'
				defaultValue={rider ? rider.country : countryFromIp}
				rules={[{ required: true, message: 'Please select rider country' }]}>
				<Select
					size='medium'
					showArrow
					showSearch
					optionFilterProp='filterBy'
					allowClear
					optionLabelProp='label'
					onChange={(country) => {
						const foundCountry = countryPhoneCode.find((c) => c.en === country);
						if (foundCountry) {
							form.setFieldsValue({
								phoneCountryCode: foundCountry.phoneCode,
							});
							setPhoneCodeFromIp(foundCountry.phoneCode);
							setCountryFromIp(foundCountry.en);
						}
					}}
					popupMatchSelectWidth={false}>
					{countryPhoneCode.map((p) => (
						<Select.Option
							key={p.en}
							filterBy={`${p.en}`}
							label={
								<>
									<span role='img'>{p.emoji}</span>
									{p.en}
								</>
							}>
							<span role='img'>{p.emoji}</span>
							{p.en} <span style={{ color: 'gray' }} />
						</Select.Option>
					))}
				</Select>
			</Form.Item>
			<Form.Item
				label='Phone Country Code'
				name='phoneCountryCode'
				defaultValue={rider ? rider.phoneCountryCode : phoneCodeFromIp}
				rules={[{ required: true, message: 'Please select phone country code' }]}>
				<Select
					size='medium'
					showArrow
					showSearch
					optionFilterProp='filterBy'
					allowClear
					optionLabelProp='label'
					popupMatchSelectWidth={false}>
					{countryPhoneCode.map((p) => (
						<Select.Option
							key={p.en}
							filterBy={`${p.en} +${p.phoneCode}`}
							value={p.phoneCode}
							label={
								<>
									<span role='img'>{p.emoji}</span>
									{` +(${p.phoneCode})`}
								</>
							}>
							<span role='img'>{p.emoji}</span>{' '}
							<span style={{ color: 'gray' }}>{`+(${p.phoneCode})`}</span>
						</Select.Option>
					))}
				</Select>
			</Form.Item>
			<Form.Item
				label='Phone'
				name='phone'
				rules={[{ required: true, message: 'Please input rider Phone' }]}>
				<Input />
			</Form.Item>

			<Form.Item
				label='Gender'
				name='gender'
				rules={[{ required: true, message: 'Please input rider Phone' }]}>
				<Radio.Group
					options={optionsGender}
					onChange={onChangeGender}
					value={selectedGender}
					optionType='button'
					buttonStyle='solid'
				/>
			</Form.Item>
			{/* 	<Form.Item
				label='Currency'
				name='currency'
				rules={[{ required: true, message: 'Please Select currency!' }]}>
				<Select
					size='medium'
					showArrow
					showSearch
					optionFilterProp='filterBy'
					allowClear
					optionLabelProp='label'
					popupMatchSelectWidth={false}>
					{currency.map((currency) => (
						<Select.Option key={currency} label={currency}>
							{currency}
						</Select.Option>
					))}
				</Select>
			</Form.Item> */}
			<Divider />
			<Form.Item style={{ float: 'right' }}>
				<Button
					type='default'
					style={{ marginBottom: 32, marginRight: 8 }}
					onClick={() => onCancel(unsavedChanges)}>
					Cancel
				</Button>
				<Button
					type='primary'
					style={{ marginBottom: 32 }}
					htmlType='submit'
					disabled={!unsavedChanges}>
					{!rider ? 'Save' : 'Update'}
				</Button>
			</Form.Item>
		</Form>
	);
};

export default RiderForm;
