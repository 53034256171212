export const driversVehicles = [
	{
		id: 1,
		make: 'Audi',
		model: 'A3',
		year: '2017',
		licencePlate: '1234tn198',
		driver: 'test driver',
		vehiculeColor: 'yellow',
		vehiculeType: ['saloon'],
		status: true,
	},
	{
		id: 2,
		make: 'Volkswagen',
		model: 'Golf 7',
		year: '2019',
		licencePlate: '5674tn220',
		driver: 'driver 2',
		vehiculeColor: 'yellow',
		vehiculeType: ['saloon', 'van'],
		status: false,
	},
	{
		id: 2,
		make: 'Ford',
		model: 'Ford Transit',
		year: '2019',
		licencePlate: '5674tn220',
		driver: 'driver 2',
		vehiculeColor: 'yellow',
		vehiculeType: ['saloon', 'van'],
		status: false,
	},
];

export const vehicleList = [
	{
		id: 1,
		label: 'Audi',
		models: [
			{
				id: 1,
				label: 'A3',
			},
			{
				id: 2,
				label: 'A4',
			},
			{
				id: 3,
				label: 'Coupe',
			},
			{
				id: 4,
				label: 'Tt',
			},
		],
	},
	{
		id: 2,
		label: 'Toyota',
		models: [
			{
				id: 1,
				label: 'C-hr',
			},
			{
				id: 2,
				label: 'Corona',
			},
			{
				id: 3,
				label: 'Avalon',
			},
			{
				id: 4,
				label: 'Mirai',
			},
		],
	},
	{
		id: 3,
		label: 'Alpha romeo',
		models: [
			{
				id: 1,
				label: 'Milano',
			},
			{
				id: 2,
				label: 'Spider',
			},
		],
	},
	{
		id: 4,
		label: 'Volkswagen',
		models: [
			{
				id: 1,
				label: 'Golf 6',
			},
			{
				id: 2,
				label: 'Golf 7',
			},
			{
				id: 3,
				label: 'Polo',
			},
			{
				id: 4,
				label: 'Passat',
			},
		],
	},
	{
		id: 4,
		label: 'Ford',
		models: [
			{
				id: 1,
				label: 'Ford Transit',
			},
		],
	},
];

export const driverList = [
	{
		id: 1,
		name: 'driver',
		surname: 'one ',
		email: 'driver@test.com',
	},
	{
		id: 2,
		name: 'driver',
		surname: 'two ',
		email: 'driver2@test.com',
	},
	{
		id: 3,
		name: 'driver',
		surname: 'three ',
		email: 'driver3@test.com',
	},
];

export const vehicleType = [
	{
		id: 1,
		value: 'SALOON',
	},
	{
		id: 2,
		value: 'VAN',
	},
];

export const defaultCenterMap = {
	lat: 35.93479200313205, // Random coordinate within Malta,
	lng: 14.391888335128291, // Random coordinate within Malta
};
