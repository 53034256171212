import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../axios';

const initialState = {
	googleMaps: { loading: false, googleMapsKey: null },
};

export const getRandomGoogleMapsKey = createAsyncThunk(
	'googleMapsKey/getRandomGoogleMapsKey',
	async () => {
		try {
			const response = await axios.get(`googleMapsKey`);
			return response.data;
		} catch (error) {
			const customError = {
				name: 'Custom axios error',
				message: error.response.data.message,
				/* data: error.response.data, */
			};
			throw customError;
		}
	},
);

export const googleMapsKeySlice = createSlice({
	name: 'googleMapsKey',
	initialState,
	reducers: {},
	extraReducers: {
		[getRandomGoogleMapsKey.pending]: (state) => {
			state.googleMaps.loading = true;
		},
		[getRandomGoogleMapsKey.rejected]: (state) => {
			state.googleMaps.loading = false;
		},
		[getRandomGoogleMapsKey.fulfilled]: (state, action) => {
			state.googleMaps.loading = false;
			state.googleMaps.googleMapsKey = action.payload;
		},
	},
});

export default googleMapsKeySlice.reducer;
