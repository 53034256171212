/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
import React from 'react';
import { Modal } from 'antd';

const ModalShowPicture = ({ open, setOpen, imageURL }) => {
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Modal
			visible={open}
			onCancel={handleClose}
			style={{
				width: '100%',
				height: '100%',
			}}
			footer={null}>
			<img
				alt='Document_file'
				src={imageURL}
				style={{ width: '100%', height: '100%', objectFit: 'contain' }}
			/>
		</Modal>
	);
};

export default ModalShowPicture;
