import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../axios';

const initialState = {
	fleetOperator: { loading: false, fleetOperatorList: null, selectedFleet: null },
};

export const getAllFleetOperators = createAsyncThunk(
	'fleetOperator/getAllFleetOperators',
	async () => {
		try {
			const response = await axios.get(`fleet-operator`);
			return response.data;
		} catch (error) {
			const customError = {
				name: 'Cannot get fleet operators',
				message: error.response.data.message,
				/* data: error.response.data, */
			};
			throw customError;
		}
	},
);

export const addNewFleetOperator = createAsyncThunk(
	'fleetOperator/addNewFleetOperator',
	async (data) => {
		try {
			const response = await axios.post(`fleet-operator`, data);
			return response.data;
		} catch (error) {
			const customError = {
				name: 'Cannot add fleet operator',
				message: error.response.data.message,
				/* data: error.response.data, */
			};
			throw customError;
		}
	},
);

export const updateFleetOperator = createAsyncThunk(
	'fleetOperator/updateFleetOperator',
	async (data) => {
		try {
			const response = await axios.put(`fleet-operator/${data.id}`, data);
			return response.data;
		} catch (error) {
			const customError = {
				name: 'Cannot update fleet operator',
				message: error.response.data.message,
				/* data: error.response.data, */
			};
			throw customError;
		}
	},
);

export const deleteFleetOperator = createAsyncThunk(
	'fleetOperator/deleteFleetOperator',
	async (id) => {
		try {
			const response = await axios.delete(`fleet-operator/${id}`);
			return response.data;
		} catch (error) {
			const customError = {
				name: 'Cannot delete fleet operator',
				message: error.response.data.message,
				/* data: error.response.data, */
			};
			throw customError;
		}
	},
);

export const getFleetOperatorById = createAsyncThunk(
	'fleetOperator/getFleetOperatorById',
	async (id) => {
		try {
			const response = await axios.get(`fleet-operator/${id}`);
			return response.data;
		} catch (error) {
			const customError = {
				name: 'Cannot get fleet operator',
				message: error.response.data.message,
				/* data: error.response.data, */
			};
			throw customError;
		}
	},
);

export const changeFleetOperatorStatus = createAsyncThunk(
	'fleetOperator/changeFleetOperatorStatus',
	async (data) => {
		try {
			const response = await axios.patch(`fleet-operator/${data.id}/`, data);
			return response.data;
		} catch (error) {
			const customError = {
				name: 'Cannot change fleet operator status',
				message: error.response.data.message,
				/* data: error.response.data, */
			};
			throw customError;
		}
	},
);

export const fleetOperatorSlice = createSlice({
	name: 'FleetOperator',
	initialState,
	reducers: {},
	extraReducers: {
		[getAllFleetOperators.pending]: (state) => {
			state.fleetOperator.loading = true;
		},
		[getAllFleetOperators.fulfilled]: (state, action) => {
			state.fleetOperator.loading = false;
			state.fleetOperator.fleetOperatorList = action.payload;
		},
		[getAllFleetOperators.rejected]: (state) => {
			state.fleetOperator.loading = false;
		},
		[addNewFleetOperator.pending]: (state) => {
			state.fleetOperator.loading = true;
		},
		[addNewFleetOperator.fulfilled]: (state, action) => {
			state.fleetOperator.loading = false;
			state.fleetOperator.fleetOperatorList.push(action.payload);
		},
		[addNewFleetOperator.rejected]: (state) => {
			state.fleetOperator.loading = false;
		},
		[updateFleetOperator.pending]: (state) => {
			state.fleetOperator.loading = true;
		},
		[updateFleetOperator.fulfilled]: (state, action) => {
			state.fleetOperator.loading = false;
			const index = state.fleetOperator.fleetOperatorList.findIndex(
				(fleetOperator) => fleetOperator.id === action.payload.id,
			);
			state.fleetOperator.fleetOperatorList[index] = action.payload;
		},
		[updateFleetOperator.rejected]: (state) => {
			state.fleetOperator.loading = false;
		},
		[deleteFleetOperator.pending]: (state) => {
			state.fleetOperator.loading = true;
		},
		[deleteFleetOperator.fulfilled]: (state, action) => {
			state.fleetOperator.loading = false;
			const index = state.fleetOperator.fleetOperatorList.findIndex(
				(fleetOperator) => fleetOperator.id === action.payload.id,
			);
			state.fleetOperator.fleetOperatorList.splice(index, 1);
		},
		[deleteFleetOperator.rejected]: (state) => {
			state.fleetOperator.loading = false;
		},
		[getFleetOperatorById.pending]: (state) => {
			state.fleetOperator.loading = true;
		},
		[getFleetOperatorById.fulfilled]: (state, action) => {
			state.fleetOperator.loading = false;
			state.fleetOperator.selectedFleet = action.payload;
		},
		[getFleetOperatorById.rejected]: (state) => {
			state.fleetOperator.loading = false;
		},
		[changeFleetOperatorStatus.pending]: (state) => {
			state.fleetOperator.loading = true;
		},
		[changeFleetOperatorStatus.fulfilled]: (state, action) => {
			state.fleetOperator.loading = false;
			const index = state.fleetOperator.fleetOperatorList.findIndex(
				(fleetOperator) => fleetOperator.id === action.payload.id,
			);
			state.fleetOperator.fleetOperatorList[index] = action.payload;
		},
		[changeFleetOperatorStatus.rejected]: (state) => {
			state.fleetOperator.loading = false;
		},
	},
});

export default fleetOperatorSlice.reducer;
