/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-restricted-syntax */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../axios';

const initialState = {
	files: {
		loading: false,
		vehicleTypeFiles: [],
	},
	IdDocuments: {
		loading: false,
		idDocumentsList: [],
	},
	tagDocuments: {
		loading: false,
		tagDocumentsList: [],
	},
	drivingLicenceDocuments: {
		loading: false,
		DrivingLicenceDocumentsList: [],
	},
	policeConductDocuments: {
		loading: false,
		PoliceConductDocumentsList: [],
	},
	driverFetchedDocuments: {
		loading: false,
		driverDocuments: [],
	},
	vehicleLicencePlateDocuments: {
		loading: false,
		vehicleLicencePlateDocumentsList: [],
	},
	vehicleLicenceDiscDocuments: {
		loading: false,
		vehicleLicenceDiscDocumentsList: [],
	},
	vehicleInsuranceDocuments: {
		loading: false,
		vehicleInsuranceDocumentsList: [],
	},
	vehicleFetchedDocuments: {
		loading: false,
		vehicleFetchedDocumentsList: [],
	},
};

export const getDriverDocuments = createAsyncThunk(
	'Files/getDriverDocuments',
	async (documents) => {
		try {
			return documents;
		} catch (error) {
			const customError = {
				name: 'Error getDriverDocuments',
				message: error.response.data.message,
			};
			throw customError;
		}
	},
);

export const getDriverVehicleDocuments = createAsyncThunk(
	'Files/getDriverVehicleDocuments',
	async (documents) => {
		try {
			return documents;
		} catch (error) {
			const customError = {
				name: 'Error getDriverVehicleDocuments',
				message: error.response.data.message,
			};
			throw customError;
		}
	},
);

export const getVehicleTypeFiles = createAsyncThunk('Files/getVehicleTypeFiles', async () => {
	try {
		const response = await axios.get(`files/vehiclesTypes`);
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Error getVehicleTypeFiles',
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const downloadImage = createAsyncThunk('Files/downloadImage', async (fileId) => {
	try {
		const response = await axios.get(`files/${fileId}`, {
			responseType: 'blob',
		});
		return response.data;
	} catch (error) {
		const err = {
			name: error.name,
			message: error.response.data.message,
		};
		throw err;
	}
});

export const uploadProfilPicture = createAsyncThunk('Files/uploadProfilePicture', async (file) => {
	try {
		const formData = new FormData();
		formData.append('file', file);
		const response = await axios.post(`files/profilePicture`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
		return response.data;
	} catch (error) {
		const customError = {
			name: error.name,
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const uploadBrandLogo = createAsyncThunk('Files/uploadBrandLogo', async (file) => {
	try {
		const formData = new FormData();
		formData.append('file', file);
		const response = await axios.post(`files/brandLogo`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
		return response.data;
	} catch (error) {
		const customError = {
			name: error.name,
			message: error.response.data.message,
		};
		throw customError;
	}
});
export const uploadFleetOperatorDocument = createAsyncThunk(
	'Files/uploadFleetOperatorDocument',
	async (file) => {
		try {
			const formData = new FormData();
			formData.append('file', file);
			const response = await axios.post(`files/fleetOperatorDocument`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});
			return response.data;
		} catch (error) {
			const customError = {
				name: error.name,
				message: error.response.data.message,
			};
			throw customError;
		}
	},
);

export const uploadMultiDocuments = createAsyncThunk('Files/uploadMultiDocuments', async (data) => {
	try {
		// filter data.files to remove old files
		const newFiles = data?.files.filter((file) => !file.oldFile);
		const formData = new FormData();
		if (newFiles) {
			for (const file of newFiles) {
				formData.append('files', file);
			}

			const response = await axios.post(`multiple-files?type=${data?.type}`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});
			const payload = {
				data: response.data,
				type: data?.type,
				component: data?.component,
			};
			return payload;
		}
		return [];
	} catch (error) {
		const customError = {
			name: error.name,
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const setDriverTagDocuments = createAsyncThunk(
	'Files/setDriverTagDocuments',
	async (data) => {
		try {
			return data;
		} catch (error) {
			const customError = {
				name: error.name,
				message: error.response.data.message,
			};
			throw customError;
		}
	},
);

export const setDriverLicenceDocuments = createAsyncThunk(
	'Files/setDriverLicenceDocuments',
	async (data) => {
		try {
			return data;
		} catch (error) {
			const customError = {
				name: error.name,
				message: error.response.data.message,
			};
			throw customError;
		}
	},
);

export const setDriverPoliceConductDocuments = createAsyncThunk(
	'Files/setDriverPoliceConductDocuments',
	async (data) => {
		try {
			return data;
		} catch (error) {
			const customError = {
				name: error.name,
				message: error.response.data.message,
			};
			throw customError;
		}
	},
);

export const setDriverIdDocuments = createAsyncThunk('Files/setDriverIdDocuments', async (data) => {
	try {
		return data;
	} catch (error) {
		const customError = {
			name: error.name,
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const removeFromDriverDocuments = createAsyncThunk(
	'Files/removeFromDriverDocuments',
	async (data) => {
		try {
			return data;
		} catch (error) {
			const customError = {
				name: error.name,
				message: error.response.data.message,
			};
			throw customError;
		}
	},
);

export const setVehicleLicencePlateDocuments = createAsyncThunk(
	'Files/setVehicleLicencePlateDocuments',
	async (data) => {
		try {
			return data;
		} catch (error) {
			const customError = {
				name: error.name,
				message: error.response.data.message,
			};
			throw customError;
		}
	},
);

export const setVehicleLicenceDiscDocuments = createAsyncThunk(
	'Files/setVehicleLicenceDiscDocuments',
	async (data) => {
		try {
			return data;
		} catch (error) {
			const customError = {
				name: error.name,
				message: error.response.data.message,
			};
			throw customError;
		}
	},
);

export const setVehicleInsuranceDocuments = createAsyncThunk(
	'Files/setVehicleInsuranceDocuments',
	async (data) => {
		try {
			return data;
		} catch (error) {
			const customError = {
				name: error.name,
				message: error.response.data.message,
			};
			throw customError;
		}
	},
);

export const removeFromVehicleDocuments = createAsyncThunk(
	'Files/removeFromVehicleDocuments',
	async (data) => {
		try {
			return data;
		} catch (error) {
			const customError = {
				name: error.name,
				message: error.response.data.message,
			};
			throw customError;
		}
	},
);

export const fileSlice = createSlice({
	name: 'Files',
	initialState,
	reducers: {
		// add reducer to empty the state state.tagDocuments.tagDocumentsList = []; state.drivingLicenceDocuments.DrivingLicenceDocumentsList = []; state.policeConductDocuments.PoliceConductDocumentsList = [];
		resetDocuments: (state) => {
			state.tagDocuments.tagDocumentsList = [];
			state.drivingLicenceDocuments.DrivingLicenceDocumentsList = [];
			state.policeConductDocuments.PoliceConductDocumentsList = [];
			// state.IdDocuments.idDocumentsList = [];
			state.vehicleLicencePlateDocuments = {
				loading: false,
				vehicleLicencePlateDocumentsList: [],
			};
			state.vehicleLicenceDiscDocuments = {
				loading: false,
				vehicleLicenceDiscDocumentsList: [],
			};
			state.vehicleInsuranceDocuments = {
				loading: false,
				vehicleInsuranceDocumentsList: [],
			};
		},
		resetFetchedDocuments: (state) => {
			state.driverFetchedDocuments = {
				loading: false,
				driverDocuments: [],
			};
		},
		resetFetchedVehicleDocuments: (state) => {
			state.vehicleFetchedDocuments = {
				loading: false,
				vehicleFetchedDocumentsList: [],
			};
		},
	},
	extraReducers: {
		[getVehicleTypeFiles.pending]: (state) => {
			state.files.loading = true;
		},
		[getVehicleTypeFiles.rejected]: (state) => {
			state.files.loading = false;
		},
		[getVehicleTypeFiles.fulfilled]: (state, action) => {
			state.files.loading = false;
			state.files.vehicleTypeFiles = action.payload;
		},
		[uploadMultiDocuments.pending]: (state) => {
			state.tagDocuments.loading = true;
			state.drivingLicenceDocuments.loading = true;
			state.policeConductDocuments.loading = true;
		},
		[uploadMultiDocuments.rejected]: (state) => {
			state.tagDocuments.loading = false;
			state.drivingLicenceDocuments.loading = false;
			state.policeConductDocuments.loading = false;
		},
		[uploadMultiDocuments.fulfilled]: (state, action) => {
			state.driverFetchedDocuments.loading = false;
			const { data, component } = action.payload;
			const uploadedDocuments = data?.map((doc) => {
				return (
					// format the documents to match the documents in the state

					{
						uid: doc.id,
						id: doc.id,
						name: doc.filename,
						status: 'done',
						url: null,
						type: doc.type,
						oldFile: false,
					}
				);
			});
			// add the new list uploadedDocuments to the state
			if (component === 'DRIVER') {
				state.driverFetchedDocuments.driverDocuments = [
					...state.driverFetchedDocuments.driverDocuments,
					...uploadedDocuments,
				];
			} else {
				state.vehicleFetchedDocuments.vehicleFetchedDocumentsList = [
					...state.vehicleFetchedDocuments.vehicleFetchedDocumentsList,
					...uploadedDocuments,
				];
			}
		},
		[getDriverDocuments.pending]: (state) => {
			state.driverFetchedDocuments.loading = true;
		},
		[getDriverDocuments.rejected]: (state) => {
			state.driverFetchedDocuments.loading = false;
		},
		[getDriverDocuments.fulfilled]: (state, action) => {
			state.driverFetchedDocuments.loading = false;
			state.driverFetchedDocuments.driverDocuments = action.payload;
		},

		[getDriverVehicleDocuments.pending]: (state) => {
			state.vehicleFetchedDocuments.loading = true;
		},
		[getDriverVehicleDocuments.rejected]: (state) => {
			state.vehicleFetchedDocuments.loading = false;
		},
		[getDriverVehicleDocuments.fulfilled]: (state, action) => {
			state.vehicleFetchedDocuments.loading = false;
			state.vehicleFetchedDocuments.vehicleFetchedDocumentsList = action.payload;
		},
		[setDriverTagDocuments.pending]: (state) => {
			state.tagDocuments.loading = true;
		},
		[setDriverTagDocuments.rejected]: (state) => {
			state.tagDocuments.loading = false;
		},
		[setDriverTagDocuments.fulfilled]: (state, action) => {
			state.tagDocuments.loading = false;
			state.tagDocuments.tagDocumentsList = action.payload;
		},
		[setDriverLicenceDocuments.pending]: (state) => {
			state.drivingLicenceDocuments.loading = true;
		},
		[setDriverLicenceDocuments.rejected]: (state) => {
			state.drivingLicenceDocuments.loading = false;
		},
		[setDriverLicenceDocuments.fulfilled]: (state, action) => {
			state.drivingLicenceDocuments.loading = false;
			state.drivingLicenceDocuments.DrivingLicenceDocumentsList = action.payload;
		},
		[setDriverPoliceConductDocuments.pending]: (state) => {
			state.policeConductDocuments.loading = true;
		},
		[setDriverPoliceConductDocuments.rejected]: (state) => {
			state.policeConductDocuments.loading = false;
		},
		[setDriverPoliceConductDocuments.fulfilled]: (state, action) => {
			state.policeConductDocuments.loading = false;
			state.policeConductDocuments.PoliceConductDocumentsList = action.payload;
		},
		[removeFromDriverDocuments.pending]: (state) => {
			state.driverFetchedDocuments.loading = true;
		},
		[removeFromDriverDocuments.rejected]: (state) => {
			state.driverFetchedDocuments.loading = false;
		},
		[removeFromDriverDocuments.fulfilled]: (state, action) => {
			state.driverFetchedDocuments.loading = false;
			// action.payload is the the list of documents to remove from the state driverFetchedDocuments
			const documentsToRemove = action.payload;
			// filter the documents to remove from the state driverFetchedDocuments
			const newDocuments = state.driverFetchedDocuments.driverDocuments.filter(
				(doc) => documentsToRemove.findIndex((d) => d.id === doc.id) === -1,
			);
			// update the state driverFetchedDocuments with the new list of documents
			state.driverFetchedDocuments.driverDocuments = newDocuments;
		},
		[setVehicleLicencePlateDocuments.pending]: (state) => {
			state.vehicleLicencePlateDocuments.loading = true;
		},
		[setVehicleLicencePlateDocuments.rejected]: (state) => {
			state.vehicleLicencePlateDocuments.loading = false;
		},
		[setVehicleLicencePlateDocuments.fulfilled]: (state, action) => {
			state.vehicleLicencePlateDocuments.loading = false;
			state.vehicleLicencePlateDocuments.vehicleLicencePlateDocumentsList = action.payload;
		},
		[setVehicleLicenceDiscDocuments.pending]: (state) => {
			state.vehicleLicenceDiscDocuments.loading = true;
		},
		[setVehicleLicenceDiscDocuments.rejected]: (state) => {
			state.vehicleLicenceDiscDocuments.loading = false;
		},
		[setVehicleLicenceDiscDocuments.fulfilled]: (state, action) => {
			state.vehicleLicenceDiscDocuments.loading = false;
			state.vehicleLicenceDiscDocuments.vehicleLicenceDiscDocumentsList = action.payload;
		},
		[setVehicleInsuranceDocuments.pending]: (state) => {
			state.vehicleInsuranceDocuments.loading = true;
		},
		[setVehicleInsuranceDocuments.rejected]: (state) => {
			state.vehicleInsuranceDocuments.loading = false;
		},
		[setVehicleInsuranceDocuments.fulfilled]: (state, action) => {
			state.vehicleInsuranceDocuments.loading = false;
			state.vehicleInsuranceDocuments.vehicleInsuranceDocumentsList = action.payload;
		},
		[removeFromVehicleDocuments.pending]: (state) => {
			state.vehicleFetchedDocuments.loading = true;
		},
		[removeFromVehicleDocuments.rejected]: (state) => {
			state.vehicleFetchedDocuments.loading = false;
		},
		[removeFromVehicleDocuments.fulfilled]: (state, action) => {
			state.vehicleFetchedDocuments.loading = false;
			const documentsToRemove = action.payload;
			const newDocuments = state.vehicleFetchedDocuments.vehicleFetchedDocumentsList.filter(
				(doc) => documentsToRemove.findIndex((d) => d.id === doc.id) === -1,
			);
			state.vehicleFetchedDocuments.vehicleFetchedDocumentsList = newDocuments;
		},
	},
});

export default fileSlice.reducer;
export const { resetDocuments, resetFetchedDocuments, resetFetchedVehicleDocuments } =
	fileSlice.actions;
