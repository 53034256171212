/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable import/namespace */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */

import { Breadcrumb, Button, Card, Input, message, DatePicker, Space, Tooltip, Form } from 'antd';
import { useLoadScript } from '@react-google-maps/api';

import React, { useState, useEffect, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
	ReloadOutlined,
	MinusOutlined,
	FilterOutlined,
	FileExcelOutlined,
	UsergroupAddOutlined,
	UserOutlined,
	SearchOutlined,
} from '@ant-design/icons';
import { CSVLink } from 'react-csv';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import { useLocation, useParams } from 'react-router';
import Highlighter from 'react-highlight-words';
import { getAllBookings, getAllBookingsWithCretaria } from '../../redux/bookingSlice';
import { style } from '../../Styles';
import CustomTableComponents from '../Global/CustomTableComponents';
import DetailsModal from './DetailsModal';
import CustomAvatar from '../Global/CustomAvatar/CustomAvatar';
import { getRandomGoogleMapsKey } from '../../redux/googleMapsKeySlice';
import { formatDateAndTime } from '../../Utils/date-utils';
import ImageComponent from '../Global/ImageComponent/ImageComponent';
import FilteringDrawer from './Filtering/FilteringDrawer';
import { getAllDrivers } from '../../redux/driverSlice';
import { getAllRiders } from '../../redux/riderSlice';
import { formatEnumValue } from '../../Utils/ArrayToObject';
import { getAllCorporates } from '../../redux/corporateSlice';
import ActionButtonWithRefund from './ActionButtonWithRefund';
import RefundBookingModal from './Refund/RefundBookingModal';
import ModalRefundDetails from './Refund/ModalRefundDetails';
import { getSpanStyle } from '../../Utils/style-depending-on-status';
import { bookingStatuses } from '../../Utils/Constants/utils';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';
const libraries = ['core', 'places', 'geocoding', 'routes', 'geometry'];
const today = dayjs().startOf('day');
const tomorrow = dayjs().add(1, 'day').startOf('day');

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(utc);

const Booking = ({ role }) => {
	const location = useLocation();
	const [makingApiCall, setMakingApiCall] = useState(false);
	const [csvData, setCsvData] = useState([]);
	const [csvHeaders, setCsvHeaders] = useState([]);
	const [dateRange, setDateRange] = useState([today, tomorrow]);
	const [showRefundModal, setShowRefundModal] = useState(false);
	const [showRefundDetails, setShowRefundDetails] = useState(false);
	const { googleMaps } = useSelector((state) => state.googleMapsKey);
	const { Settings } = useSelector((state) => state.settings);
	const { corporates } = useSelector((state) => state.corporates);
	const [form] = Form.useForm();
	const openRefundModal = () => {
		setShowRefundModal(true);
	};
	const closeRefundModal = () => {
		setShowRefundModal(false);
	};
	const openRefundDetailsModal = (record) => {
		setSelectedBooking(record);
		setShowRefundDetails(true);
	};
	const closeRefunDetailsdModal = () => {
		setSelectedBooking(null);
		setShowRefundDetails(false);
	};

	const [isFilteringDrawerOpened, setIsFilteringDrawerOpened] = useState(false);

	const findCorporateName = (id) => {
		const corporate = corporates.corporatesList.find((item) => item.id === id);
		return corporate?.name;
	};
	const currencyRender = () => {
		return <span>{Settings?.settingsData?.currency || '€'}</span>;
	};

	const { isLoaded } = useLoadScript({
		googleMapsApiKey: googleMaps?.googleMapsKey?.key,
		libraries,
	});

	const dispatch = useDispatch();
	const { booking } = useSelector((state) => state.booking);
	const [filter, setfilter] = useState('');
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedBooking, setSelectedBooking] = useState(null);
	const [selectedBookingId, setselectedBookingId] = useState(null);
	const fetchCorporates = () => {
		dispatch(getAllCorporates());
	};

	useEffect(() => {
		fetchCorporates();
	}, [dispatch]);

	const loadDriversAndRiders = () => {
		const promise = Promise.all([dispatch(getAllDrivers()), dispatch(getAllRiders())]);
		promise.then(() => {});
	};
	const openFilteringDrawer = () => {
		loadDriversAndRiders();
		setIsFilteringDrawerOpened(true);
	};

	const closeFilteringDrawer = () => {
		setIsFilteringDrawerOpened(false);
	};

	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	const fetchGoogleMapsKey = () => {
		dispatch(getRandomGoogleMapsKey())
			.then(() => {})
			.catch(() => {
				message.error('Error getting google maps key');
			});
	};

	const [searchText, setSearchText] = useState('');
	const [searchedColumn, setSearchedColumn] = useState('');
	const searchInput = useRef(null);
	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};
	const handleReset = (clearFilters) => {
		clearFilters();
		setSearchText('');
	};

	const getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
			<div
				style={{
					padding: 8,
				}}
				onKeyDown={(e) => e.stopPropagation()}>
				<Input
					ref={searchInput}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
					style={{
						marginBottom: 8,
						display: 'block',
					}}
				/>
				<Space>
					<Button
						type='primary'
						onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size='small'
						style={{
							width: 90,
						}}>
						Search
					</Button>
					<Button
						onClick={() => clearFilters && handleReset(clearFilters)}
						size='small'
						style={{
							width: 90,
						}}>
						Reset
					</Button>
					<Button
						type='link'
						size='small'
						onClick={() => {
							confirm({
								closeDropdown: false,
							});
							setSearchText(selectedKeys[0]);
							setSearchedColumn(dataIndex);
						}}>
						Filter
					</Button>
					<Button
						type='link'
						size='small'
						onClick={() => {
							close();
						}}>
						close
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered) => (
			<SearchOutlined
				style={{
					color: filtered ? '#1677ff' : undefined,
				}}
			/>
		),
		onFilter: (value, record) =>
			record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
		onFilterDropdownOpenChange: (visible) => {
			if (visible) {
				setTimeout(() => searchInput.current?.select(), 100);
			}
		},
		render: (text) =>
			searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{
						backgroundColor: '#ffc069',
						padding: 0,
					}}
					searchWords={[searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ''}
				/>
			) : (
				text
			),
	});

	const columns = [
		{
			title: 'ID',
			key: 'id',
			dataIndex: 'id',
			defaultSortOrder: 'ascend',
			sorter: (a, b) => a.id - b.id,
			...getColumnSearchProps('id'),
			width: '20px',
		},
		{
			title: 'Date',
			key: 'date',
			render: (record) => {
				return record?.created ? formatDateAndTime(record?.created) : <MinusOutlined />;
			},
			sorter: (a, b) => {
				const dateA = new Date(a.created);
				const dateB = new Date(b.created);
				return dateA.getTime() - dateB.getTime();
			},
			width: '100px',
		},
		{
			title: (
				<Tooltip title='Number of Seats'>
					<span>Number of Seats</span>
				</Tooltip>
			),
			dataIndex: 'numberOfSeats',
			sorter: {
				compare: (a, b) => a.numberOfSeats - b.numberOfSeats,
				multiple: 4,
			},
			width: '100px',
		},
		{
			title: 'Driver',
			key: 'driver',
			render: (record) => {
				const fullName = `${record?.driver?.name} ${record?.driver?.surname} `;
				return record?.driver ? (
					<div style={{ display: 'flex', alignItems: 'center' }}>
						{record?.driver?.profilePicture ? (
							<ImageComponent size={40} imageId={record?.driver?.profilePicture} />
						) : (
							<CustomAvatar size={40} name={fullName} type='initials' />
						)}
						<div>
							<span>{`${record?.driver?.name} ${record?.driver?.surname} `}</span>
							{!record.driver?.active ? (
								<span
									style={{
										color: 'GrayText',
										fontSize: '12px',
										display: 'block',
										marginLeft: '2px',
									}}>
									(Inactive)
								</span>
							) : null}
						</div>
					</div>
				) : (
					<MinusOutlined />
				);
			},
			defaultSortOrder: 'ascend',
			sorter: (a, b) => a?.driver?.name.length - b?.driver?.name.length,
			width: '160px',
		},
		{
			title: 'Rider',
			key: 'rider',
			render: (record) => {
				const fullName = role?.includes('SUPER_ADMIN')
					? `${record?.rider?.name} ${record?.rider?.surname}`
					: `${record?.rider?.name}`;
				return record?.rider ? (
					<div style={{ display: 'flex', alignItems: 'center' }}>
						{record?.rider?.profilePicture ? (
							<ImageComponent size={40} imageId={record?.rider?.profilePicture} />
						) : (
							<CustomAvatar size={40} name={fullName} type='initials' />
						)}
						<div>
							<span>{`${fullName} `}</span>
							{!record.rider?.active ? (
								<span
									style={{
										color: 'GrayText',
										fontSize: '12px',
										display: 'block',
										marginLeft: '2px',
									}}>
									(Inactive)
								</span>
							) : null}
						</div>
					</div>
				) : (
					<MinusOutlined />
				);
			},

			defaultSortOrder: 'ascend',
			sorter: (a, b) => a?.rider?.name.length - b?.rider?.name.length,
			width: '160px',
		},
		{
			title: 'Total Fare',
			key: 'totalFare',
			render: (record) => {
				return (
					<span>
						{currencyRender()}
						{`${record?.priceBreakdown?.totalFare?.toFixed(2)}`}
					</span>
				);
			},
			defaultSortOrder: 'ascend',
			sorter: (a, b) => a?.priceBreakdown?.totalFare - b?.priceBreakdown?.totalFare,
			width: '100px',
		},
		{
			title: 'Discounted Fare',
			key: 'discount',
			render: (record) => {
				return record?.priceBreakdown?.discount ? (
					<span>
						{currencyRender()}
						{`${record?.priceBreakdown?.discount?.toFixed(2)}`}
					</span>
				) : (
					<MinusOutlined />
				);
			},
			defaultSortOrder: 'ascend',
			sorter: (a, b) => a.priceBreakdown?.discount - b.priceBreakdown?.discount,
			width: '120px',
		},
		{
			title: 'Paid Amount',
			key: 'paidAmount',
			render: (record) => {
				const paidAmount = record?.priceBreakdown?.paidAmount;
				const totalFare = record?.priceBreakdown?.totalFare;
				return (
					<span>
						{currencyRender()}
						{paidAmount !== null && paidAmount !== undefined
							? paidAmount.toFixed(2)
							: totalFare.toFixed(2)}
					</span>
				);
			},
			defaultSortOrder: 'ascend',
			sorter: (a, b) => a.priceBreakdown?.paidAmount - b.priceBreakdown?.paidAmount,
			width: '100px',
		},
		{
			title: 'Cancellation Fee',
			key: 'cancellationFee',
			render: (record) => {
				const cancellationFee = record?.priceBreakdown?.cancellationFee;

				return cancellationFee !== null && cancellationFee !== undefined ? (
					<span>
						{currencyRender()}
						{cancellationFee.toFixed(2)}
					</span>
				) : (
					<MinusOutlined />
				);
			},
			defaultSortOrder: 'ascend',
			sorter: (a, b) => a.priceBreakdown?.paidAmount - b.priceBreakdown?.paidAmount,
			width: '150px',
		},
		{
			title: 'Mode',
			key: 'taxiMode',
			render: (record) => {
				// const taxiMode is to filter record?.taxiModeWithRole and search for the item that have role === 'DRIVER'
				const taxiMode = record?.bookingType;
				return (
					<span style={style.dataContainer}>
						{taxiMode ? (
							taxiMode === 'SHARED' ? (
								<div
									style={{
										...style.tags,
										color: '#008080',
										backgroundColor: '#e0f2f1',
										borderColor: '#4db6ac',
									}}>
									<UsergroupAddOutlined /> {taxiMode && taxiMode}
								</div>
							) : (
								<div
									style={{
										...style.tags,
										color: '#f39c12',
										backgroundColor: '#fff3cd',
										borderColor: '#e08e0b',
									}}>
									<UserOutlined /> {taxiMode && taxiMode}
								</div>
							)
						) : (
							<MinusOutlined />
						)}
					</span>
				);
			},
			width: '80px',
		},
		{
			title: 'Vehicle Type',
			key: 'vehicleType',
			render: (record) => {
				return record?.vehicleType && record?.vehicleType?.vehicleType ? (
					<span style={style.tags}>{`${formatEnumValue(
						record?.vehicleType?.vehicleType,
					)} `}</span>
				) : (
					<MinusOutlined />
				);
			},
			defaultSortOrder: 'ascend',

			sorter: (a, b) =>
				a?.vehicleType?.vehicleType?.length - b?.vehicleType?.vehicleType?.length,
			width: '80px',
		},
		{
			title: 'Corporate',
			key: 'corporate',
			render: (record) => {
				return record?.corporateId ? (
					<span style={style.tags}>{`${findCorporateName(record?.corporateId)} `}</span>
				) : (
					<MinusOutlined />
				);
			},
			width: '120px',
		},
		{
			title: 'Status',
			key: 'status',
			render: (record) => {
				return (
					<span style={style.dataContainer}>
						<div style={getSpanStyle(record?.bookingStatus?.name)}>
							{formatEnumValue(record.bookingStatus?.name)}
						</div>
						{record?.bookingRefundHistories &&
						record?.bookingRefundHistories?.length > 0 ? (
							<span
								style={{
									color: 'GrayText',
									fontSize: '12px',
									marginLeft: '2px',
									marginTop: '5px',
								}}>
								{' '}
								(Refunded)
							</span>
						) : null}
					</span>
				);
			},
			sorter: {
				compare: (a, b) => a?.bookingStatus?.name.length - b?.bookingStatus?.name.length,
				multiple: 1,
			},
			width: '80px',
		},
		{
			title: 'Actions',
			key: 'actions',
			render: (text, record) => (
				<ActionButtonWithRefund
					record={record}
					onSeeDetails={openModal}
					setSelectedBooking={setSelectedBooking}
					onRefund={openRefundModal}
					showRefundDetails={showRefundDetails}
					openRefundDetailsModal={openRefundDetailsModal}
					closeRefunDetailsdModal={closeRefunDetailsdModal}
				/>
			),
			width: '90px',
		},
	];

	const fetchAllBookings = async () => {
		setMakingApiCall(true);
		// Set start to 00:00
		const start = dateRange[0].startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

		// Set end to 23:59
		const end = dateRange[1].endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
		let statusToFilterWith = null;
		if (location?.state?.status) {
			const bookingStatus = bookingStatuses.find(
				(status) => status.enum === location?.state?.status,
			);
			statusToFilterWith = bookingStatus.id;
		}
		const action = location?.state?.status
			? getAllBookingsWithCretaria({
					statusId: location?.state?.status ? statusToFilterWith : null,
					driverId: null,
					riderId: null,
					start: start !== undefined ? start : null,
					end: end !== undefined ? end : null,
					corporateId: null,
			  })
			: getAllBookings({
					start,
					end,
			  });

		dispatch(action)
			.unwrap()
			.then(() => {})
			.catch(() => {
				message.error('Error while fetching bookings');
			})
			.finally(() => {
				setMakingApiCall(false);
			});
	};
	useEffect(() => {
		fetchGoogleMapsKey();
	}, [dispatch]);

	useEffect(() => {
		fetchAllBookings();
	}, [dispatch, dateRange]);

	const data = booking?.bookingList ? Object.values(booking.bookingList) : [];

	const filtredData = data.filter((item) => {
		const filterLowerCase = filter.toLowerCase();

		return (
			item?.id?.toString().toLowerCase().includes(filterLowerCase) ||
			item?.driver?.name.toLowerCase().includes(filterLowerCase) ||
			(role?.includes('SUPER_ADMIN') &&
				item?.rider?.surname.toLowerCase().includes(filterLowerCase)) ||
			item?.rider?.name.toLowerCase().includes(filterLowerCase) ||
			item?.driver?.surname.toLowerCase().includes(filterLowerCase) ||
			item?.vehicleType?.vehicleType.toLowerCase().includes(filterLowerCase) ||
			item?.bookingStatus?.name.toLowerCase().includes(filterLowerCase)
		);
	});

	const onGridDoubleClick = (record) => {
		setselectedBookingId(record.id);
		setSelectedBooking(record);
		openModal();
	};

	const handleGenerateCsv = (event, done) => {
		const promise = async () => {
			const formattedBooking = [];
			if (filtredData != null) {
				Object.values(filtredData).forEach((bookingItem) => {
					const riderFullName =
						role?.includes('SUPER_ADMIN') || role?.includes('CORPORATE_ADMIN')
							? `${bookingItem?.rider?.name} ${bookingItem?.rider?.surname} `
							: `${bookingItem?.rider?.name}`;
					const obj = {
						id: bookingItem.id,
						created: bookingItem.created,
						updated: bookingItem.updated,
						rider: riderFullName,
						driver: bookingItem.driver
							? `${bookingItem.driver.name} ${bookingItem.driver.surname}`
							: 'No driver assigned',
						vehicleType: bookingItem?.vehicleType?.vehicleType || 'No vehicle type',
						totalFare: bookingItem?.priceBreakdown?.totalFare,
						initialFare: bookingItem.priceBreakdown.initialFare,
						nightShiftSurcharge: bookingItem.priceBreakdown.nightShiftSurcharge,
						peakSurcharge: bookingItem.priceBreakdown.peakSurcharge,
						discount: bookingItem?.priceBreakdown?.discount || 0,
						paidAmount: bookingItem?.priceBreakdown?.paidAmount || 0,
						cancellationFee: bookingItem?.priceBreakdown?.cancellationFee || 0,
						bookingStatus: bookingItem?.bookingStatus?.name || 'No status',
						pickupAddress: bookingItem?.pickupAddress || 'No pickup address',
						dropOffAddress: bookingItem?.dropOffAddress || 'No drop off address',
						distance: `${bookingItem?.distance} KM` || 'No distance',
						corporateName: findCorporateName(bookingItem.corporateId) || 'No corporate',
					};
					return formattedBooking.push(obj);
				});
			}
			setCsvData(formattedBooking);
			setCsvHeaders([
				{
					label: 'ID',
					key: 'id',
					show: true,
				},
				{
					label: 'Created',
					key: 'created',
					show: true,
				},
				{
					label: 'Updated',
					key: 'updated',
					show: true,
				},
				{
					label: 'Rider',
					key: 'rider',
					show: true,
				},
				{
					label: 'Driver',
					key: 'driver',
					show: true,
				},
				{
					label: 'Vehicle Type',
					key: 'vehicleType',
					show: true,
				},
				{
					label: 'Total Fare',
					key: 'totalFare',
					show: true,
				},
				{
					label: 'Initial Fare',
					key: 'initialFare',
					show: true,
				},
				{
					label: 'Night Shift Surcharge',
					key: 'nightShiftSurcharge',
					show: true,
				},
				{
					label: 'Peak Surcharge',
					key: 'peakSurcharge',
					show: true,
				},
				{
					label: 'Corporate Account',
					key: 'corporateName',
					show: true,
				},
				{
					label: 'Discount',
					key: 'discount',
					show: true,
				},
				{
					label: 'Paid Amount',
					key: 'paidAmount',
					show: true,
				},
				{
					label: 'Cancellation Fee',
					key: 'cancellationFee',
					show: true,
				},
				{
					label: 'Booking Status',
					key: 'bookingStatus',
					show: true,
				},
				{
					label: 'Pickup Address',
					key: 'pickupAddress',
					show: true,
				},
				{
					label: 'Drop Off Address',
					key: 'dropOffAddress',
					show: true,
				},
				{
					label: 'Distance',
					key: 'distance',
					show: true,
				},
			]);
		};
		promise().then(done());
	};

	const onDateRangeChange = (value) => {
		setDateRange(value);
	};

	const { id } = useParams();

	useEffect(() => {
		if (id) {
			// get booking by id
			const bookingById = data.find((bookingItem) => bookingItem.id === parseInt(id, 10));
			setSelectedBooking(bookingById);
			openModal();
		}
	}, [id]);

	return (
		<>
			<Card
				style={{
					margin: 16,
					maxHeight: '90%', // Set maximum width
					height: 'auto', // Allow width to be dynamic
				}}>
				<div style={style.inlineContent}>
					<div
						style={{
							display: 'flex',
							alignContent: 'center',
							alignItems: 'center',
							float: 'left',
							marginBottom: 8,
						}}>
						<Breadcrumb
							style={{ margin: '0 3em 0 0', fontSize: '1.1em' }}
							items={[
								{
									title: 'Bookings',
								},
							]}
						/>
						<Button type='default' size='small' onClick={() => fetchAllBookings()}>
							<ReloadOutlined />
						</Button>
					</div>
					<div
						style={{
							display: 'flex',
							alignContent: 'center',
							alignItems: 'center',
							float: 'center',
							marginBottom: 8,
						}}>
						<RangePicker
							onChange={onDateRangeChange}
							defaultValue={dateRange}
							format={dateFormat}
							style={{ width: '100%' }}
							allowClear={false}
						/>
					</div>
					<div
						style={{
							...style.inlineContent,
							float: 'right',
							marginBottom: 8,
						}}>
						<CSVLink
							data={csvData}
							headers={csvHeaders.filter((header) => header.show)}
							asyncOnClick
							onClick={handleGenerateCsv}
							separator=','
							style={{ marginRight: '0.5em', marginTop: '-9px' }}
							filename={`export-bookings-${new Date()
								.toISOString()
								.slice(0, 30)}.csv`}>
							<Button icon={<FileExcelOutlined />}>Export</Button>
						</CSVLink>

						<Button
							icon={<FilterOutlined />}
							onClick={openFilteringDrawer}
							style={{ marginRight: '0.5em', marginTop: '-9px' }}>
							Filter
						</Button>
						<Input.Search
							placeholder='Search'
							style={{ margin: '0 8px 8px 0' }}
							value={filter}
							onChange={(e) => setfilter(e.target.value)}
						/>
					</div>
				</div>
				<CustomTableComponents
					isMakingApiCall={makingApiCall}
					columns={columns}
					dataArray={filtredData}
					handleDoubleClickRow={(record) => {
						onGridDoubleClick(record);
					}}
					handleSelectedRow={() => {}}
				/>
			</Card>

			{isModalOpen && (
				<DetailsModal
					isModalOpen={isModalOpen}
					closeModal={closeModal}
					// booking={selectedBooking}
					isLoaded={isLoaded}
					role={role}
					bookingId={selectedBookingId}
				/>
			)}
			{isFilteringDrawerOpened && (
				<FilteringDrawer
					closeFilteringDrawer={closeFilteringDrawer}
					isFilteringDrawerOpened={isFilteringDrawerOpened}
					resetFilter={fetchAllBookings}
					dateRange={dateRange}
					role={role}
					form={form}
				/>
			)}
			<RefundBookingModal
				show={showRefundModal}
				onClose={closeRefundModal}
				booking={selectedBooking}
			/>
			<ModalRefundDetails
				show={showRefundDetails}
				onClose={closeRefunDetailsdModal}
				booking={selectedBooking}
			/>
		</>
	);
};

export default Booking;
