/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-restricted-syntax */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../axios';
import { arrayToObject } from '../Utils/ArrayToObject';

const initialState = {
	Makes: {
		loading: false,
		makeList: [],
	},
};

export const getAllMakes = createAsyncThunk('Make/getAllMakes', async () => {
	try {
		const response = await axios.get(`makes`);
		const data = arrayToObject(response.data, 'id');
		return data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const getMakeById = createAsyncThunk('Make/getMakeById', async (makeId) => {
	try {
		const response = await axios.get(`make/${makeId}`);
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const createMake = createAsyncThunk('Make/createMake', async (requestData) => {
	try {
		const response = await axios.post(`make`, requestData);
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const updateMake = createAsyncThunk('Make/updateMake', async (requestData) => {
	try {
		const response = await axios.put(`make/${requestData.id}`, requestData);
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const deleteMake = createAsyncThunk('Make/deleteMake', async (id) => {
	try {
		await axios.delete(`make/${id}`);
		return id;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const makeSlice = createSlice({
	name: 'Make',
	initialState,
	reducers: {},
	extraReducers: {
		[getAllMakes.pending]: (state) => {
			state.Makes.loading = true;
		},
		[getAllMakes.rejected]: (state) => {
			state.Makes.loading = false;
		},
		[getAllMakes.fulfilled]: (state, action) => {
			state.Makes.loading = false;
			state.Makes.makeList = Object.values(action.payload);
		},
		[createMake.pending]: (state) => {
			state.Makes.loading = true;
		},
		[createMake.rejected]: (state) => {
			state.Makes.loading = false;
		},
		[createMake.fulfilled]: (state, action) => {
			state.Makes.loading = false;
			state.Makes.makeList.push(action.payload);
		},
		[updateMake.pending]: (state) => {
			state.Makes.loading = true;
		},
		[updateMake.rejected]: (state) => {
			state.Makes.loading = false;
		},
		[updateMake.fulfilled]: (state, action) => {
			state.Makes.loading = false;
			state.Makes.makeList = state.Makes.makeList.map((item) => {
				if (item.id === action.payload.id) {
					item = action.payload;
					return item;
				}
				return item;
			});
		},
		[deleteMake.pending]: (state) => {
			state.Makes.loading = true;
		},
		[deleteMake.rejected]: (state) => {
			state.Makes.loading = false;
		},
		[deleteMake.fulfilled]: (state, action) => {
			state.Makes.loading = false;
			state.Makes.makeList = state.Makes.makeList.filter((item) => {
				return item.id !== action.payload;
			});
		},
	},
});

export default makeSlice.reducer;
