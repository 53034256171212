/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
import { Modal, Spin } from 'antd';
import React, { useState } from 'react';

const ModalGoogleMaps = ({ record, setIsMapModalOpened }) => {
	const [isLoaded, setIsLoaded] = useState(false);
	return (
		<Modal
			visible
			title={`Map for the location ${record?.locationName}`}
			footer={null}
			onCancel={() => setIsMapModalOpened(false)}
			width={800}
			centered>
			<Spin spinning={!isLoaded} tip='Loading Google Maps...'>
				<iframe
					frameBorder='0'
					style={{ border: 0 }}
					allowFullScreen=''
					aria-hidden='false'
					// eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
					tabIndex='0'
					title='googleMaps'
					onLoad={() => {
						setIsLoaded(true);
					}} // Set the mapLoaded state to true when the iframe loads
					src={`https://maps.google.com/maps?q=${record?.latitude}, ${record?.longitude}&z=15&output=embed`}
					width='100%'
					height='450'
				/>
			</Spin>
		</Modal>
	);
};

export default ModalGoogleMaps;
