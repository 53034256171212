/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Breadcrumb, Card, Input, Segmented, message } from 'antd';
import {
	CheckCircleOutlined,
	CarOutlined,
	PlayCircleOutlined,
	RadarChartOutlined,
} from '@ant-design/icons';

import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { style } from '../../Styles';
import DriverCard from './DriverCard';
import { getRandomGoogleMapsKey } from '../../redux/googleMapsKeySlice';
import GodsViewMap from './GodsViewMap';
import useSSE from '../../CustomHooks/useSSE';
import './style.css';

const { Search } = Input;
const endpoint = 'gods-view';
const eventListener = 'gods-view-event';

const GodsView = () => {
	const location = useLocation();

	const [selectedStatus, setSelectedStatus] = useState(location?.state?.selectedOption || 'ALL');
	const data = useSSE(`${endpoint}?status=${selectedStatus}`, eventListener);
	const [filter, setfilter] = useState('');
	const [selectedDriver, setSelectedDriver] = useState(null);
	const [zoom, setZoom] = useState(7);

	const dispatch = useDispatch();

	const fetchGoogleMapsKey = () => {
		dispatch(getRandomGoogleMapsKey())
			.then(() => {})
			.catch(() => {
				message.error('Error getting google maps key');
			});
	};

	const filtredData = data?.filter((item) => {
		return (
			item.user.name.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
			item.user.surname.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
			item.user.email.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1
		);
	});
	useEffect(() => {
		fetchGoogleMapsKey();
	}, [dispatch]);

	return (
		<Card style={{ margin: 16, height: '90vh' }}>
			<div style={style.inlineContent}>
				<div
					style={{
						display: 'flex',
						alignContent: 'center',
						alignItems: 'center',
						marginBottom: 8,
					}}>
					<Breadcrumb
						style={{ margin: '0 1em 0 0', fontSize: '1.1em' }} // Adjust margin as needed
						items={[
							{
								title: "God's View",
							},
						]}
					/>
				</div>
				<div
					style={{
						...style.inlineContent,
						marginBottom: 8,
					}}>
					<Segmented
						defaultValue={selectedStatus}
						size='middle'
						onChange={(e) => {
							setfilter('');
							setSelectedDriver(null);
							setSelectedStatus(e);
						}}
						options={[
							{
								label: 'Enroute to pickup',
								value: 'ENROUTE_TO_PICKUP',
								icon: <CarOutlined />,
							},
							{
								label: 'Booking started',
								value: 'BOOKING_STARTED',
								icon: <PlayCircleOutlined />,
							},
							{
								label: 'Available',
								value: 'AVAILABLE',
								icon: <CheckCircleOutlined />,
							},
							{
								label: 'All (even not available drivers)',
								value: 'ALL',
								icon: <RadarChartOutlined />,
							},
						]}
					/>
				</div>
			</div>
			<Card style={{ height: '100%', width: '100%' }} bordered={false}>
				<Card.Grid style={{ width: '25%', height: '73vh' }}>
					<div
						style={{
							height: '100%',
							width: '100%',
							overflow: 'auto',
							maxHeight: '100%',
						}}>
						<Search
							placeholder='Search'
							onChange={(e) => setfilter(e.target.value)}
							style={{ width: '100%' }}
							allowClear
						/>

						{filtredData?.map((driver) => (
							<DriverCard
								zoom={zoom}
								setZoom={setZoom}
								driver={driver}
								setSelectedDriver={setSelectedDriver}
								width='96%'
							/>
						))}
					</div>
				</Card.Grid>
				<Card.Grid style={{ width: '75%', height: '73vh' }}>
					<div style={{ height: '100%', width: '100%' }}>
						<GodsViewMap
							zoom={zoom}
							setZoom={setZoom}
							drivers={filtredData || []}
							selectedDriver={selectedDriver}
							setSelectedDriver={setSelectedDriver}
						/>
					</div>
				</Card.Grid>
			</Card>
		</Card>
	);
};

export default GodsView;
