import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../axios";
import {
  getAllRestrcitedAreaEndPoint,
  restrictedAreaByIdEndPoint,
  restrictedAreaEndPoint,
} from "../Utils/Constants/endPoints";
import { tenantId } from "../Utils/Constants/utils";

const initialState = {
  RestrictedArea: { restrictedAreaList: [] },
  SelectedRestrictedArea: { restrictedArea: null },
};

export const getAllRestrictedArea = createAsyncThunk(
  "RestrictedArea/getAllRestrictedArea",
  async () => {
    try {
      const response = await axios.get(getAllRestrcitedAreaEndPoint);
      return response.data;
    } catch (error) {
      const err = {
        name: error.code,
        message: error.message,
      };
      throw err;
    }
  }
);

export const getRestrictedArea = createAsyncThunk(
  "RestrictedArea/getRestrictedArea",
  async (params) => {
    try {
      const response = await axios.get(
        restrictedAreaByIdEndPoint(params.restrictedAreaId)
      );
      return response.data;
    } catch (error) {
      const err = {
        name: error.code,
        message: error.message,
      };
      throw err;
    }
  }
);

export const createRestrictedArea = createAsyncThunk(
  "RestrictedArea/createRestrictedArea",
  async (params) => {
    const data = { ...params.data, tenantId };
    try {
      const response = await axios.post(restrictedAreaEndPoint, data);
      return response.data;
    } catch (error) {
      const err = {
        name: error.code,
        message: error.message,
      };
      throw err;
    }
  }
);

export const updateRestrictedArea = createAsyncThunk(
  "RestrictedArea/updateRestrictedArea",
  async (params) => {
    const data = { ...params.data, tenantId };
    try {
      const response = await axios.put(
        restrictedAreaByIdEndPoint(params.restrictedAreaId),
        data
      );
      return response.data;
    } catch (error) {
      const err = {
        name: error.code,
        message: error.message,
      };
      throw err;
    }
  }
);
export const deleteRestrictedArea = createAsyncThunk(
  "RestrictedArea/deleteRestrictedArea",
  async (params) => {
    try {
      await axios.delete(restrictedAreaByIdEndPoint(params.restrictedAreaId));
      return params.restrictedAreaId;
    } catch (error) {
      const err = {
        name: error.code,
        message: error.message,
      };
      throw err;
    }
  }
);

export const restrictedAreaSlice = createSlice({
  name: "RestrictedArea",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllRestrictedArea.fulfilled, (state, action) => {
        state.RestrictedArea.restrictedAreaList = Object.values(action.payload);
      })
      .addCase(getRestrictedArea.fulfilled, (state, action) => {
        state.SelectedRestrictedArea.restrictedArea = action.payload;
      })
      .addCase(createRestrictedArea.fulfilled, (state, action) => {
        state.RestrictedArea.restrictedAreaList.push(action.payload);
      })
      .addCase(updateRestrictedArea.fulfilled, (state, action) => {
        state.RestrictedArea.restrictedAreaList =
          state.RestrictedArea.restrictedAreaList.map((item) => {
            if (item.id === action.payload.id) {
              item = action.payload;
              return item;
            }
            return item;
          });
      })
      .addCase(deleteRestrictedArea.fulfilled, (state, action) => {
        state.RestrictedArea.restrictedAreaList =
          state.RestrictedArea.restrictedAreaList.filter(
            (item) => item.id !== action.payload
          );
      });
  },
});

export default restrictedAreaSlice.reducer;
