/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-nested-ternary */

import React, { useEffect, useState } from 'react';
import { Button, Card, Breadcrumb, message, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { ReloadOutlined, MinusOutlined } from '@ant-design/icons';
import { changeRiderStatus, deleteRider, getAllRiders } from '../../redux/riderSlice';
import DrawerRider from './DrawerRider';
import { style } from '../../Styles';
import CustomTableComponents from '../Global/CustomTableComponents';
import ActionButtonWithChangeStatus from '../Global/ActionButtonWithChangeStatus';
import ImageComponent from '../Global/ImageComponent/ImageComponent';
import CustomAvatar from '../Global/CustomAvatar/CustomAvatar';

const Rider = () => {
	const [filter, setfilter] = useState('');
	const columns = [
		{
			title: 'Profile Picture',
			key: 'profilePicture',
			render: (record) => {
				return record.profilePicture ? (
					<ImageComponent size={40} imageId={record?.profilePicture} />
				) : (
					<CustomAvatar name={`${record.name} ${record.surname}`} type='initials' />
				);
			},
			width: 130,
			show: true,
		},
		{
			title: 'Name',
			key: 'name',
			render: (record) => {
				return <span>{`${record.name} ${record.surname}`}</span>;
			},
			sorter: {
				compare: (a, b) => a.name - b.name,
				multiple: 5,
			},
			show: true,
		},
		{
			title: 'Email',
			dataIndex: 'email',
			sorter: {
				compare: (a, b) => a.email - b.email,
				multiple: 4,
			},
			show: true,
		},
		{
			title: 'Phone',
			key: 'phone',
			render: (record) => {
				return <span>{`+(${record.phoneCountryCode}) ${record.phone}`}</span>;
			},
			sorter: {
				compare: (a, b) => a.phone - b.phone,
				multiple: 3,
			},
			show: true,
		},
		{
			title: 'Gender',
			key: 'gender',
			render: (record) => {
				return record?.gender ? <span>{record.gender}</span> : <MinusOutlined />;
			},
			sorter: {
				compare: (a, b) => a.status - b.status,
				multiple: 1,
			},
			show: true,
		},

		{
			title: 'Status',
			key: 'status',
			render: (record) => {
				return (
					<span style={style.dataContainer}>
						{record.active ? (
							<div style={style.statusActif}>ACTIVE</div>
						) : (
							<div style={style.statusInactif}>INACTIVE</div>
						)}
					</span>
				);
			},
			sorter: {
				compare: (a, b) => a.status - b.status,
				multiple: 1,
			},
			width: 90,
			show: true,
		},
		{
			title: 'Actions',
			key: 'actions',
			render: (text, record) => (
				<ActionButtonWithChangeStatus
					record={record}
					onDelete={() => handleDlete(record.id, () => {})}
					onRowClick={() => handleSelectedRider(record)}
					onChangeStatus={() => handleChangeStatus(record)}
				/>
			),

			show: true,
		},
	];
	const [displayDrawer, setDisplayDrawer] = useState(false);
	const [selectedRider, setSelectedRider] = useState(null);
	const [isMakingApiCall, setMakingApiCall] = useState(true);
	const showDrawer = () => {
		setDisplayDrawer(true);
	};
	const onCloseDrawer = () => {
		setDisplayDrawer(false);
		setSelectedRider(null);
	};

	const dispatch = useDispatch();

	const { Riders } = useSelector((state) => state.riders);
	const fetchAll = () => {
		dispatch(getAllRiders())
			.unwrap()
			.then()
			.catch(() => {
				message.error('Error fetching riders');
			})
			.finally(() => {
				setMakingApiCall(false);
			});
	};
	useEffect(() => {
		fetchAll();
	}, [dispatch]);

	const handleDlete = (id, calbback) => {
		setMakingApiCall(true);
		dispatch(
			deleteRider({
				riderId: id,
			}),
		)
			.unwrap()
			.then(() => {
				message.success('Rider deleted successfully');
				calbback();
			})
			.catch(() => {})
			.finally(() => {
				onCloseDrawer();
				setMakingApiCall(false);
			});
	};

	const handleChangeStatus = (record) => {
		setMakingApiCall(true);
		dispatch(
			changeRiderStatus({
				userId: record.id,
				active: !record.active,
			}),
		)
			.unwrap()
			.then(() => {
				message.success('Driver status updated successfully');
			})
			.catch(() => {
				message.error('Error updating driver status');
			})
			.finally(() => {
				setMakingApiCall(false);
			});
	};

	const handleSelectedRider = (selectedRider) => {
		setSelectedRider(selectedRider);
		showDrawer();
	};

	let ridersArray = Object.values(Riders.ridersList);
	ridersArray = ridersArray.map((item) => {
		return { ...item, key: item.id };
	});

	const filtredData = ridersArray.filter((item) => {
		return (
			item.id.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
			item.country.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
			item.email.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
			item.name.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
			item.surname.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1
		);
	});

	return (
		<>
			<Card
				style={{
					margin: 16,
					maxHeight: '90%', // Set maximum width
					height: 'auto', // Allow width to be dynamic
				}}>
				<div style={style.inlineContent}>
					<div
						style={{
							display: 'flex',
							alignContent: 'center',
							alignItems: 'center',
							float: 'left',
							marginBottom: 8,
						}}>
						<Breadcrumb
							style={{ margin: '0 3em 0 0', fontSize: '1.1em' }}
							items={[
								{
									title: 'Riders',
								},
							]}
						/>
						<Button
							type='default'
							size='small'
							// style={{ margin: '0 8px 8px 0' }}
							onClick={() => fetchAll()}>
							<ReloadOutlined />
						</Button>
					</div>
					<div
						style={{
							...style.inlineContent,
							float: 'right',
							marginBottom: 8,
						}}>
						<Input.Search
							placeholder='Search'
							style={{ margin: '0 8px 8px 0' }}
							value={filter}
							onChange={(e) => setfilter(e.target.value)}
						/>

						<Button
							type='default'
							style={{ float: 'right', marginBottom: 8 }}
							onClick={showDrawer}>
							Add Rider
						</Button>
					</div>
				</div>
				<CustomTableComponents
					isMakingApiCall={isMakingApiCall}
					columns={columns.filter((item) => item.show)}
					dataArray={filtredData}
					handleSelectedRow={() => {}}
					handleDoubleClickRow={(record) => handleSelectedRider(record)}
				/>
			</Card>
			{displayDrawer && (
				<DrawerRider
					onClose={onCloseDrawer}
					riderId={selectedRider?.id}
					handleDlete={handleDlete}
				/>
			)}
		</>
	);
};
export default Rider;
