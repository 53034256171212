/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-shadow */

/* eslint-disable no-restricted-syntax */

/* eslint-disable react/prop-types */
import { Avatar, Button, Modal, Upload, message } from 'antd';
import React, { useState } from 'react';
import { PlusOutlined, CameraOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import { useDispatch } from 'react-redux';
import ImageComponent from '../ImageComponent/ImageComponent';
import { uploadProfileImage } from '../../../redux/authSlice';

const avatarTypes = [
	'bottts',
	'adventurer',
	'adventurer-neutral',
	'avataaars',
	'avataaars-neutral',
	'big-ears',
	'big-ears-neutral',
	'big-smile',
	'initials',
	'lorelei',
	'lorelei-neutral',
	'micah',
	'miniavs',
	'open-peeps',
	'personas',
	'pixel-art',
	'pixel-art-neutral',
	'shapes',
	'thumbs',
];
const uploadButton = (
	<div>
		<PlusOutlined />
		<div style={{ marginTop: 8 }}>Upload</div>
	</div>
);
const AvatarWithUpload = ({ type, name, size, profilePictureId }) => {
	const [fileList, setFileList] = useState([]);
	const [uploading, setUploading] = useState(false);
	const [isAllowed, setIsAllowed] = useState(true);
	const dispatch = useDispatch();
	const handleUpload = () => {
		setUploading(true);
		dispatch(uploadProfileImage(fileList[0]?.originFileObj))
			.unwrap()
			.then(() => {
				setFileList([]);
				setIsModalOpened(false);
				message.success('Profile picture changed successfully.');
			})
			.catch(() => {
				message.error('upload failed.');
			})
			.finally(() => {
				setUploading(false);
			});
	};

	const avatarUrl = process.env.REACT_APP_AVATAR_URL;

	const selectedType = avatarTypes.find((t) => t === type) || 'bottts-neutral';
	const [isModalOpened, setIsModalOpened] = useState(false);
	const handlePhotoButtonClick = () => {
		setIsModalOpened(true);
	};
	const handleUploadChange = ({ fileList: newFileList }) => {
		setFileList(newFileList);
	};

	const getSrcFromFile = (file) => {
		return new Promise((resolve) => {
			const reader = new FileReader();
			reader.readAsDataURL(file.originFileObj);
			reader.onload = () => resolve(reader.result);
		});
	};
	const dummyRequest = ({ onSuccess }) => {
		setTimeout(() => {
			onSuccess('ok');
		}, 0);
	};
	const props = {
		onRemove: (file) => {
			const newFileList = fileList.filter((item) => item.uid !== file.uid);
			setFileList(newFileList);
		},

		onPreview: (file) => {
			const src = file.url || getSrcFromFile(file);
			const imgWindow = window.open(src);

			if (imgWindow) {
				const image = new Image();
				image.src = src;
				imgWindow.document.write(image.outerHTML);
			} else {
				window.location.href = src;
			}
		},
		beforeUpload: (file) => {
			const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
			if (!isJpgOrPng) {
				message.error('You can only upload JPG/PNG file');
			}
			const isLt2M = file.size / 1024 / 1024 < 2;
			if (!isLt2M) {
				message.error('Image must smaller than 2MB');
			}
			setIsAllowed(isJpgOrPng && isLt2M);
			return isJpgOrPng && isLt2M;
		},

		listType: 'picture-card',
		fileList,
		onChange: (fileList) => {
			handleUploadChange(fileList);
		},
		maxCount: 1,
	};

	return (
		<div style={{ position: 'relative', display: 'inline-block' }}>
			{profilePictureId != null ? (
				<ImageComponent size={200} imageId={profilePictureId} />
			) : (
				<Avatar
					shape='square'
					size={size || 35}
					src={`${avatarUrl}/${selectedType}/svg?seed=${name}`}
					style={{ marginRight: '0.5em' }}
				/>
			)}

			<div
				style={{
					position: 'absolute',
					bottom: 0,
					right: 0,
					marginRight: '0.9em',
					marginBottom: '0.3em',
				}}>
				<Button shape='square' icon={<CameraOutlined />} onClick={handlePhotoButtonClick} />
				<Modal
					style={{ textAlign: 'center' }}
					open={isModalOpened}
					onCancel={() => setIsModalOpened(false)}
					footer={null}>
					<ImgCrop showGrid rotationSlider aspectSlider showReset>
						<Upload {...props} listType='picture-card' customRequest={dummyRequest}>
							{fileList.length === 0 ? uploadButton : null}
						</Upload>
					</ImgCrop>
					<Button
						type='primary'
						onClick={handleUpload}
						disabled={fileList.length === 0 || !isAllowed}
						loading={uploading}
						style={{
							marginTop: 16,
						}}>
						{uploading ? 'Uploading' : 'Start Upload'}
					</Button>
				</Modal>
			</div>
		</div>
	);
};

export default AvatarWithUpload;
