/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unsafe-optional-chaining */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { MinusOutlined, ReloadOutlined, FilterOutlined, FilterFilled } from '@ant-design/icons';
import {
	Breadcrumb,
	Button,
	Card,
	DatePicker,
	Input,
	Rate,
	Tooltip,
	Typography,
	message,
} from 'antd';
import { useLoadScript } from '@react-google-maps/api';
import { getAllReviewsByCriteria } from '../../redux/reviewSlice';
import { formatDateAndTime } from '../../Utils/date-utils';
import ImageComponent from '../Global/ImageComponent/ImageComponent';
import CustomAvatar from '../Global/CustomAvatar/CustomAvatar';
import { style } from '../../Styles';
import CustomTableComponents from '../Global/CustomTableComponents';
import FilteringDrawer from './FilteringDrawer';
import { getAllDrivers } from '../../redux/driverSlice';
import { getAllRiders } from '../../redux/riderSlice';
import DetailsModal from '../Booking/DetailsModal';
import { getRandomGoogleMapsKey } from '../../redux/googleMapsKeySlice';
import { getBookingById } from '../../redux/bookingSlice';

const { Text } = Typography;
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';
const today = dayjs().startOf('day');
const tomorrow = dayjs().add(1, 'day').startOf('day');
const libraries = ['core', 'places', 'geocoding', 'routes', 'geometry'];

const Reviews = ({ role }) => {
	const dispatch = useDispatch();
	const [filter, setfilter] = useState('');
	const [dateRange, setDateRange] = useState([today, tomorrow]);
	const [isFilteringDrawerOpened, setIsFilteringDrawerOpened] = useState(false);
	const { reviews } = useSelector((state) => state.reviews);
	const { googleMaps } = useSelector((state) => state.googleMapsKey);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedBooking, setSelectedBooking] = useState(null);
	const [isFilteringEmpty, setIsFilteringEmpty] = useState(true);
	const { Brand } = useSelector((state) => state.brand);

	const { isLoaded } = useLoadScript({
		googleMapsApiKey: googleMaps?.googleMapsKey?.key,
		libraries,
	});

	const onBookingIdClick = (bookingId) => {
		dispatch(getBookingById(bookingId))
			.unwrap()
			.then((response) => {
				setSelectedBooking(response);
				openModal();
			})
			.catch((error) => {
				console.log('error: ', error);
				message.error('Error getting booking details');
			});
	};
	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	const fetchGoogleMapsKey = () => {
		dispatch(getRandomGoogleMapsKey())
			.then(() => {})
			.catch(() => {
				message.error('Error getting google maps key');
			});
	};
	const loadDriversAndRiders = () => {
		const promise = Promise.all([dispatch(getAllDrivers()), dispatch(getAllRiders())]);
		promise.then(() => {});
	};
	const openFilteringDrawer = () => {
		loadDriversAndRiders();
		setIsFilteringDrawerOpened(true);
	};

	const closeFilteringDrawer = () => {
		setIsFilteringDrawerOpened(false);
	};
	const fetchReviews = () => {
		// Set start to 00:00
		const start = dateRange[0].startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

		// Set end to 23:59
		const end = dateRange[1].endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
		dispatch(
			getAllReviewsByCriteria({
				start,
				end,
			}),
		)
			.unwrap()
			.then((result) => {
				console.log('result', result);
			})
			.catch((error) => {
				console.log('error: ', error);
			});
	};

	useEffect(() => {
		fetchGoogleMapsKey();
	}, [dispatch]);

	useEffect(() => {
		fetchReviews();
	}, [dispatch, dateRange]);

	const onDateRangeChange = (value) => {
		setDateRange(value);
	};

	// eslint-disable-next-line consistent-return
	const getSpanStyle = (status) => {
		switch (status) {
			case 'COMPLETED':
				return style.statusCompleted;
			case 'CANCELLED':
				return style.statusCancelled;
			case 'PENDING':
				return style.statusPending;
			case 'IN_PROGRESS':
				return style.statusInProgress;
			case 'ACCEPTED':
				return style.statusAccepted;
			case 'REQUESTED':
				return style.statusRequested;
			case 'TIMED_OUT':
				return style.statusTimedOut;
			default:
				break;
		}
	};

	const columns = [
		{
			title: 'Booking ID',
			key: 'bookingId',
			render: (record) => {
				return record?.bookingId ? (
					<Button type='link' onClick={() => onBookingIdClick(record.bookingId)}>
						{record.bookingId}
					</Button>
				) : (
					<MinusOutlined />
				);
			},
			defaultSortOrder: 'ascend',
			sorter: (a, b) => a.bookingId - b.bookingId,
		},
		{
			title: 'Date',
			key: 'date',
			render: (record) => {
				return record?.created ? formatDateAndTime(record?.created) : <MinusOutlined />;
			},
			sorter: (a, b) => {
				const dateA = new Date(a.created);
				const dateB = new Date(b.created);
				return dateA.getTime() - dateB.getTime();
			},
		},
		{
			title: 'Number of Stars',
			key: 'rating',
			render: (record) => {
				console.log('record?.rating', record?.rating);
				return record.rating ? (
					<Rate
						allowHalf
						disabled
						value={record?.rating}
						defaultValue={record?.rating}
						style={{
							fontSize: 15,
							color: Brand?.brandData?.webPrimary || 'orange',
						}}
					/>
				) : (
					<Rate
						allowHalf
						disabled
						defaultValue={0}
						style={{
							fontSize: 15,
							color: Brand?.brandData?.webPrimary || 'orange',
						}}
					/>
				);
			},
			sorter: {
				compare: (a, b) => a.rating - b.rating,
				multiple: 4,
			},
		},
		{
			title: 'Driver',
			key: 'driver',
			render: (record) => {
				const fullName = `${record?.driver?.name} ${record?.driver?.surname} `;
				return record?.driver ? (
					<div style={{ display: 'flex', alignItems: 'center' }}>
						{record?.driver?.profilePicture ? (
							<ImageComponent size={40} imageId={record?.driver?.profilePicture} />
						) : (
							<CustomAvatar size={40} name={fullName} type='initials' />
						)}
						<span>{`${record?.driver?.name} ${record?.driver?.surname} `}</span>
						{!record.driver?.active ? (
							<span
								style={{ color: 'GrayText', fontSize: '12px', marginLeft: '2px' }}>
								(Inactive)
							</span>
						) : null}
					</div>
				) : (
					<MinusOutlined />
				);
			},
			defaultSortOrder: 'ascend',
			sorter: (a, b) => a?.driver?.name.length - b?.driver?.name.length,
		},
		{
			title: 'Rider',
			key: 'rider',
			render: (record) => {
				const fullName = role?.includes('SUPER_ADMIN')
					? `${record?.rider?.name} ${record?.rider?.surname} `
					: `${record?.rider?.name}`;
				return record?.rider ? (
					<div style={{ display: 'flex', alignItems: 'center' }}>
						{record?.rider?.profilePicture ? (
							<ImageComponent size={40} imageId={record?.rider?.profilePicture} />
						) : (
							<CustomAvatar size={40} name={fullName} type='initials' />
						)}
						<span>{`${fullName} `}</span>{' '}
						{!record.rider?.active ? (
							<span
								style={{ color: 'GrayText', fontSize: '12px', marginLeft: '2px' }}>
								{' '}
								(Inactive)
							</span>
						) : null}
					</div>
				) : (
					<MinusOutlined />
				);
			},
			defaultSortOrder: 'ascend',
			sorter: (a, b) => a?.rider?.name.length - b?.rider?.name.length,
		},
		{
			title: 'Comment',
			key: 'comment',
			render: (record) => {
				return record?.comment ? (
					<div
						style={{
							maxWidth: '21.875rem',
						}}>
						<Tooltip title={record.comment}>
							<Text ellipsis={{ rows: 2, expandable: true }}>{record.comment}</Text>
						</Tooltip>
					</div>
				) : (
					<MinusOutlined />
				);
			},
			defaultSortOrder: 'ascend',
			width: '100px',
			sorter: (a, b) => a.priceBreakdown?.discount - b.priceBreakdown?.discount,
		},
	];

	const filtredData = reviews?.reviewList?.filter((item) => {
		return (
			(item?.bookingId && item?.bookingId.toString().includes(filter)) ||
			(item?.driver?.name &&
				item?.driver?.name.toLowerCase().includes(filter.toLowerCase())) ||
			(item?.driver?.surname &&
				item?.driver?.surname.toLowerCase().includes(filter.toLowerCase())) ||
			(item?.rider?.name && item?.rider?.name.toLowerCase().includes(filter.toLowerCase())) ||
			(item?.rider?.surname &&
				item?.rider?.surname.toLowerCase().includes(filter.toLowerCase())) ||
			(item?.rating && item?.rating.toString().toLowerCase().includes(filter.toLowerCase()))
		);
	});

	return (
		<Card
			style={{
				margin: 16,
				maxHeight: '90%', // Set maximum width
				height: 'auto', // Allow width to be dynamic
			}}>
			<div style={style.inlineContent}>
				<div
					style={{
						display: 'flex',
						alignContent: 'center',
						alignItems: 'center',
						float: 'left',
						marginBottom: 8,
					}}>
					<Breadcrumb
						style={{ margin: '0 3em 0 0', fontSize: '1.1em' }}
						items={[
							{
								title: 'Reviews',
							},
						]}
					/>
					<Button type='default' size='small' onClick={() => fetchReviews()}>
						<ReloadOutlined />
					</Button>
				</div>
				<div
					style={{
						display: 'flex',
						alignContent: 'center',
						alignItems: 'center',
						float: 'center',
						marginBottom: 8,
					}}>
					<RangePicker
						onChange={onDateRangeChange}
						defaultValue={dateRange}
						format={dateFormat}
						style={{ width: '100%' }}
						allowClear={false}
					/>
				</div>
				<div
					style={{
						...style.inlineContent,
						float: 'right',
						marginBottom: 8,
					}}>
					<Button
						icon={isFilteringEmpty ? <FilterOutlined /> : <FilterFilled />}
						onClick={openFilteringDrawer}
						style={{ marginRight: '0.5em', marginTop: '-9px' }}>
						Filter
					</Button>
					<Input.Search
						placeholder='Search'
						style={{ margin: '0 8px 8px 0' }}
						value={filter}
						onChange={(e) => setfilter(e.target.value)}
					/>
				</div>
			</div>
			<CustomTableComponents
				isMakingApiCall={reviews?.loading}
				columns={columns}
				dataArray={filtredData}
				handleDoubleClickRow={(record) => {
					onBookingIdClick(record.bookingId);
				}}
				handleSelectedRow={() => {}}
			/>

			{isModalOpen && (
				<DetailsModal
					isModalOpen={isModalOpen}
					closeModal={closeModal}
					booking={selectedBooking}
					getSpanStyle={getSpanStyle}
					isLoaded={isLoaded}
					role={role}
				/>
			)}

			<FilteringDrawer
				closeFilteringDrawer={closeFilteringDrawer}
				isFilteringDrawerOpened={isFilteringDrawerOpened}
				resetFilter={fetchReviews}
				dateRange={dateRange}
				role={role}
				setIsFilteringEmpty={setIsFilteringEmpty}
			/>
		</Card>
	);
};

export default Reviews;
