/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-danger */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */

import { Breadcrumb, Button, DatePicker, Input, Select, Space, Spin, Tooltip, message } from 'antd';
import dayjs from 'dayjs';
import React, { useState, useEffect, useRef } from 'react';

import utc from 'dayjs/plugin/utc';
import {
	MinusOutlined,
	ReloadOutlined,
	MobileOutlined,
	LaptopOutlined,
	SearchOutlined,
	FileExcelOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import Highlighter from 'react-highlight-words';
import { useLoadScript } from '@react-google-maps/api';
import { CSVLink } from 'react-csv';
import { getAllLogsByCriteria } from '../../redux/auditLogSlice';

import { formatDateAndTime } from '../../Utils/date-utils';
import ImageComponent from '../Global/ImageComponent/ImageComponent';
import CustomAvatar from '../Global/CustomAvatar/CustomAvatar';
import { style } from '../../Styles';
import CustomTableComponents from '../Global/CustomTableComponents';
import {
	auditLogsActions,
	getAuditDescription,
	getAuditDescriptionWitoutStyle,
} from '../../Utils/AuditLogsDescriptions';
import { getAllUsersForAllRoles } from '../../redux/userSlice';
import { getAllVehicles } from '../../redux/driversVehiclesSlice';
import { getBookingById } from '../../redux/bookingSlice';
import DetailsModal from '../Booking/DetailsModal';
import { getRandomGoogleMapsKey } from '../../redux/googleMapsKeySlice';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';
const today = dayjs().startOf('day');
const tomorrow = dayjs().add(1, 'day').startOf('day');
dayjs.extend(utc);
const libraries = ['core', 'places', 'geocoding', 'routes', 'geometry'];

const AuditLogs = ({ makingApiCall, dispatch, setMakingApiCall, role }) => {
	const [dateRange, setDateRange] = useState([today, tomorrow]);
	const [filter, setfilter] = useState('');
	const { logs } = useSelector((state) => state.auditLog);
	const { allUsers } = useSelector((state) => state.users);
	const [selectedAction, setSelectedAction] = useState(null);
	const [selectedUser, setSelectedUser] = useState(null);
	const [selectedVehicle, setSelectedVehicle] = useState(null);
	const { DriversVehicles } = useSelector((state) => state.driversVehicle);
	const [selectedBooking, setSelectedBooking] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const { googleMaps } = useSelector((state) => state.googleMapsKey);
	const [csvData, setCsvData] = useState([]);
	const [csvHeaders, setCsvHeaders] = useState([]);

	const fetchAuditLogs = async () => {
		setMakingApiCall(true);
		const start = new Date(dateRange[0]).toISOString();
		const end = new Date(dateRange[1]).toISOString();
		dispatch(
			getAllLogsByCriteria({
				start,
				end,
				action: selectedAction,
				userId: selectedUser,
				vehicleId: selectedVehicle,
			}),
		)
			.unwrap()
			.then(() => {})
			.catch(() => {
				message.error('Error while fetching bookings');
			})
			.finally(() => {
				setMakingApiCall(false);
			});
	};

	const fetchAllUsers = async () => {
		setMakingApiCall(true);
		dispatch(getAllUsersForAllRoles())
			.unwrap()
			.then(() => {})
			.catch(() => {
				message.error('Error while fetching users');
			})
			.finally(() => {
				setMakingApiCall(false);
			});
	};

	const fetchAllVehicles = () => {
		dispatch(getAllVehicles())
			.unwrap()
			.then()
			.catch(() => {
				message.error('Failed to fetch drivers vehicles');
			})
			.finally(() => {
				setMakingApiCall(false);
			});
	};

	const fetchGoogleMapsKey = () => {
		dispatch(getRandomGoogleMapsKey())
			.then(() => {})
			.catch(() => {
				message.error('Error getting google maps key');
			});
	};

	useEffect(() => {
		fetchAllUsers();
		fetchAllVehicles();
		fetchGoogleMapsKey();
	}, [dispatch]);

	useEffect(() => {
		fetchAuditLogs();
	}, [dispatch, dateRange, selectedAction, selectedUser, selectedVehicle]);

	const onDateRangeChange = (value) => {
		setDateRange(value);
	};

	const [searchText, setSearchText] = useState('');
	const [searchedColumn, setSearchedColumn] = useState('');
	const searchInput = useRef(null);
	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};
	const handleReset = (clearFilters) => {
		clearFilters();
		setSearchText('');
	};

	// eslint-disable-next-line consistent-return
	const getSpanStyle = (status) => {
		switch (status) {
			case 'COMPLETED':
				return style.statusCompleted;
			case 'CANCELLED':
				return style.statusCancelled;
			case 'PENDING':
				return style.statusPending;
			case 'IN_PROGRESS':
				return style.statusInProgress;
			case 'ACCEPTED':
				return style.statusAccepted;
			case 'REQUESTED':
				return style.statusRequested;
			case 'TIMED_OUT':
				return style.statusTimedOut;
			default:
				break;
		}
	};

	const { isLoaded } = useLoadScript({
		googleMapsApiKey: googleMaps?.googleMapsKey?.key,
		libraries,
	});

	const getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
			<div
				style={{
					padding: 8,
				}}
				onKeyDown={(e) => e.stopPropagation()}>
				<Input
					ref={searchInput}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
					style={{
						marginBottom: 8,
						display: 'block',
					}}
				/>
				<Space>
					<Button
						type='primary'
						onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size='small'
						style={{
							width: 90,
						}}>
						Search
					</Button>
					<Button
						onClick={() => clearFilters && handleReset(clearFilters)}
						size='small'
						style={{
							width: 90,
						}}>
						Reset
					</Button>
					<Button
						type='link'
						size='small'
						onClick={() => {
							confirm({
								closeDropdown: false,
							});
							setSearchText(selectedKeys[0]);
							setSearchedColumn(dataIndex);
						}}>
						Filter
					</Button>
					<Button
						type='link'
						size='small'
						onClick={() => {
							close();
						}}>
						close
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered) => (
			<SearchOutlined
				style={{
					color: filtered ? '#1677ff' : undefined,
				}}
			/>
		),
		onFilter: (value, record) =>
			record[dataIndex]?.toString()?.toLowerCase().includes(value?.toLowerCase()),
		onFilterDropdownOpenChange: (visible) => {
			if (visible) {
				setTimeout(() => searchInput.current?.select(), 100);
			}
		},
		render: (text) =>
			searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{
						backgroundColor: '#ffc069',
						padding: 0,
					}}
					searchWords={[searchText]}
					autoEscape
					textToHighlight={text ? text?.toString() : ''}
				/>
			) : (
				text
			),
	});

	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	const onBookingIdClick = (bookingId) => {
		dispatch(getBookingById(bookingId))
			.unwrap()
			.then((response) => {
				setSelectedBooking(response);
				openModal();
			})
			.catch((error) => {
				console.log('error: ', error);
				message.error('Error getting booking details');
			});
	};

	const columns = [
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id',
			defaultSortOrder: 'ascend',
			sorter: (a, b) => a.id - b.id,
		},
		{
			title: 'Date',
			key: 'date',
			render: (record) => {
				return record?.created ? formatDateAndTime(record?.created) : <MinusOutlined />;
			},
			sorter: (a, b) => {
				const dateA = new Date(a.created);
				const dateB = new Date(b.created);
				return dateA.getTime() - dateB.getTime();
			},
			width: '12%',
		},
		{
			title: 'User',
			key: 'user',
			render: (record) => {
				const fullName = `${record?.user?.name} ${record?.user?.surname} `;
				return record?.user ? (
					<div style={{ display: 'flex', alignItems: 'center' }}>
						{record?.user?.profilePicture ? (
							<ImageComponent size={40} imageId={record?.user?.profilePicture} />
						) : (
							<CustomAvatar name={fullName} type='initials' />
						)}
						<span>{`${record?.user?.name} ${record?.user?.surname} `}</span>
						{!record.user?.active ? (
							<span style={{ color: 'GrayText', fontSize: '12px' }}>(Inactive)</span>
						) : null}
					</div>
				) : (
					<MinusOutlined />
				);
			},
		},
		{
			title: 'Action',
			key: 'description',
			render: (record) => {
				return record?.action ? (
					<div
						dangerouslySetInnerHTML={{
							__html: getAuditDescription(
								record?.action,
								record?.user,
								record?.created,
								record?.bookingId,
							),
						}}
					/>
				) : (
					<MinusOutlined />
				);
			},
		},
		{
			title: 'Booking ID',
			key: 'bookingId',
			...getColumnSearchProps('bookingId'),
			render: (record) => {
				return record?.bookingId ? (
					<Button type='link' onClick={() => onBookingIdClick(record.bookingId)}>
						{record.bookingId}
					</Button>
				) : (
					<MinusOutlined />
				);
			},
			defaultSortOrder: 'ascend',
			sorter: (a, b) => a.bookingId - b.bookingId,
			width: '10%',
		},

		{
			title: 'Vehicle Name',
			key: 'vehicleName',
			render: (record) => {
				return record?.driverVehicle ? (
					<span>{`${record?.driverVehicle?.licencePlate}`}</span>
				) : (
					<MinusOutlined />
				);
			},
		},
		{
			title: 'Source',
			key: 'source',
			render: (record) => {
				return record?.source ? (
					record?.source === 'WEB_APP' ? (
						<span style={style.dataContainer}>
							<div
								style={{
									...style.statusCompleted,
									display: 'flex',
									justifyContent: 'center',
									gap: '5px',
								}}>
								<LaptopOutlined />
								Web App
							</div>
						</span>
					) : record?.source === 'RIDER_APP' ? (
						<span style={style.dataContainer}>
							<div
								style={{
									...style.statusInProgress,
									display: 'flex',
									justifyContent: 'center',
									gap: '5px',
								}}>
								<MobileOutlined />
								Rider App
							</div>
						</span>
					) : record?.source === 'DRIVER_APP' ? (
						<span style={style.dataContainer}>
							<div
								style={{
									...style.statusAccepted,
									display: 'flex',
									justifyContent: 'center',
									gap: '5px',
								}}>
								<MobileOutlined />
								Driver App
							</div>
						</span>
					) : null // Added null as a placeholder for the last condition
				) : (
					<MinusOutlined />
				);
			},
		},
	];

	const data = logs?.logsList ? Object.values(logs.logsList) : [];

	const filtredData = data.filter((item) => {
		return (
			item?.user?.name?.toLowerCase()?.includes(filter?.toLowerCase()) ||
			item?.user?.surname?.toLowerCase()?.includes(filter?.toLowerCase()) ||
			item?.bookingId?.toString()?.toLowerCase()?.includes(filter?.toLowerCase()) ||
			item?.driverVehicle?.licencePlate?.toLowerCase()?.includes(filter?.toLowerCase()) ||
			item?.action?.toLowerCase()?.includes(filter?.toLowerCase())
		);
	});

	const handleGenerateCsv = (event, done) => {
		const promise = async () => {
			const formattedLog = [];
			if (filtredData != null) {
				Object.values(filtredData).forEach((logItem) => {
					const userFullName = `${logItem?.user?.name} ${logItem?.user?.surname} `;

					const obj = {
						id: logItem.id,
						date: logItem.created,
						updated: logItem.updated,
						user: userFullName,
						action: getAuditDescriptionWitoutStyle(logItem?.action, logItem?.user),
						bookingId: logItem?.bookingId || '-',
						vehicleName: logItem?.driverVehicle?.licencePlate || '-',
						source: logItem?.source
							? logItem?.source === 'WEB_APP'
								? 'Web App'
								: logItem?.source === 'RIDER_APP'
								? 'Rider App'
								: logItem?.source === 'DRIVER_APP'
								? 'Driver App'
								: '-'
							: '-',
					};
					return formattedLog.push(obj);
				});
			}
			setCsvData(formattedLog);
			setCsvHeaders([
				{
					label: 'ID',
					key: 'id',
					show: true,
				},
				{
					label: 'Date',
					key: 'date',
					show: true,
				},
				{
					label: 'User',
					key: 'user',
					show: true,
				},
				{
					label: 'Action',
					key: 'action',
					show: true,
				},
				{
					label: 'Booking ID',
					key: 'bookingId',
					show: true,
				},
				{
					label: 'Vehicle Name',
					key: 'vehicleName',
					show: true,
				},
				{
					label: 'Source',
					key: 'source',
					show: true,
				},
			]);
		};
		promise().then(done());
	};
	return (
		<Spin spinning={makingApiCall} delay={500}>
			<div
				style={{
					borderRadius: '17px',
					background: '#ffffff',
					marginTop: '1em',
				}}>
				<div style={style.inlineContent}>
					<div
						style={{
							display: 'flex',
							alignContent: 'center',
							alignItems: 'center',
							float: 'left',
							marginBottom: 8,
						}}>
						<Breadcrumb
							style={{ margin: '0 3em 0 0', fontSize: '1.1em' }}
							items={[
								{
									title: 'Audit Log',
								},
							]}
						/>
						<Button type='default' size='small' onClick={() => fetchAuditLogs()}>
							<ReloadOutlined />
						</Button>
					</div>
					<div
						style={{
							display: 'flex',
							alignContent: 'center',
							alignItems: 'center',
							float: 'center',
							marginBottom: 8,
						}}>
						<RangePicker
							onChange={onDateRangeChange}
							defaultValue={dateRange}
							format={dateFormat}
							style={{ width: '100%', margin: '0 8px 8px 0' }}
							allowClear={false}
						/>
					</div>
					<div
						style={{
							...style.inlineContent,
							float: 'right',
							marginBottom: 8,
						}}>
						<CSVLink
							data={csvData}
							headers={csvHeaders.filter((header) => header.show)}
							asyncOnClick
							onClick={handleGenerateCsv}
							separator=','
							style={{ marginRight: '0.5em', marginTop: '-9px' }}
							filename={`export-bookings-${new Date()
								.toISOString()
								.slice(0, 30)}.csv`}>
							<Button icon={<FileExcelOutlined />}>Export</Button>
						</CSVLink>
						<Select
							placeholder='Select action'
							style={{ margin: '0 8px 8px 0', width: '13em' }}
							allowClear
							onClear={() => setSelectedAction(null)}
							onChange={(value) => {
								setSelectedAction(value);
							}}>
							{auditLogsActions.map((item) => (
								<Select.Option key={item.value} value={item.value}>
									{item.label}
								</Select.Option>
							))}
						</Select>
						<Select
							placeholder='Select user'
							style={{ margin: '0 8px 8px 0', width: '10em' }}
							allowClear
							showSearch
							optionFilterProp='filterBy'
							optionLabelProp='label'
							onClear={() => setSelectedUser(null)}
							onChange={(value) => {
								setSelectedUser(value);
							}}>
							{Object.values(allUsers)?.map((item) => (
								<Select.Option
									key={item.id}
									value={item.id}
									label={
										<div style={style.inlineContent}>
											<Tooltip title={`${item.name} ${item.surname}`}>
												<span>{`${item.name} ${item.surname}`}</span>
											</Tooltip>
										</div>
									}
									filterBy={`${item.name} +${item.surname}`}>
									<div style={style.inlineContent}>
										<Tooltip title={`${item.name} ${item.surname}`}>
											<span
												style={{
													marginLeft: '8px',
												}}>{`${item.name} ${item.surname}`}</span>
										</Tooltip>
									</div>
								</Select.Option>
							))}
						</Select>
						<Select
							placeholder='Select vehicle'
							style={{ margin: '0 8px 8px 0', width: '10em' }}
							allowClear
							showSearch
							optionFilterProp='filterBy'
							optionLabelProp='label'
							onClear={() => setSelectedVehicle(null)}
							onChange={(value) => {
								setSelectedVehicle(value);
							}}>
							{Object.values(DriversVehicles?.driversVehiclesList)?.map((item) => (
								<Select.Option
									key={item.id}
									value={item.id}
									label={
										<div style={style.inlineContent}>
											<Tooltip title={`${item.licencePlate}`}>
												<span>{`${item.licencePlate}`}</span>
											</Tooltip>
										</div>
									}
									filterBy={`${item.licencePlate}`}>
									<div style={style.inlineContent}>
										<Tooltip title={`${item.licencePlate}`}>
											<span>{`${item.licencePlate}`}</span>
										</Tooltip>
									</div>
								</Select.Option>
							))}
						</Select>
						<Input.Search
							placeholder='Search'
							style={{ margin: '0 8px 8px 0', width: '15em' }}
							value={filter}
							onChange={(e) => setfilter(e.target.value)}
						/>
					</div>
				</div>
				<CustomTableComponents
					isMakingApiCall={makingApiCall}
					columns={columns}
					dataArray={filtredData}
					handleDoubleClickRow={() => {}}
					handleSelectedRow={() => {}}
				/>
				{isModalOpen && (
					<DetailsModal
						isModalOpen={isModalOpen}
						closeModal={closeModal}
						booking={selectedBooking}
						getSpanStyle={getSpanStyle}
						isLoaded={isLoaded}
						role={role}
					/>
				)}
			</div>
		</Spin>
	);
};

export default AuditLogs;
