/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from 'react';
import { Drawer, message, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
	addNewDriver,
	getDriverById,
	resetSelectedDriver,
	updateDriver,
} from '../../redux/driverSlice';
import DriverForm from './DriverForm';
import ShowConfirmClose from '../Modals/ShowConfirmClose';
import {
	downloadImage,
	resetDocuments,
	resetFetchedDocuments,
	uploadProfilPicture,
} from '../../redux/fileSlice';

/* const documentTypes = {
	TAG_DOCUMENT: 'TAG_DOCUMENT',
	DRIVER_LICENSE_DOCUMENT: 'DRIVER_LICENSE_DOCUMENT',
	POLICE_CONDUCT_DOCUMENT: 'POLICE_CONDUCT_DOCUMENT',
};

const documentMappings = {
	TAG_DOCUMENT: 'tagExpiryDate',
	DRIVER_LICENSE_DOCUMENT: 'drivingLicenceExpiryDate',
	POLICE_CONDUCT_DOCUMENT: 'policeConductExpiryDate',
}; */

const DrawerDriver = ({ onClose, driverId, handleDeleteDriver }) => {
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [unsavedChanges, setUnsavedChanges] = useState(false);
	const dispatch = useDispatch();
	const [makingApiCall, setMakingApiCall] = useState(false);
	const [driver, setDriver] = useState(undefined);
	const [fileList, setFileList] = useState([]);
	const [uploading, setUploading] = useState(false);
	const [isFileChosen, setIsFileChosen] = useState(false);

	const handleUpload = (callback, values) => {
		if (fileList.length !== 0 && isFileChosen) {
			setUploading(true);
			dispatch(uploadProfilPicture(fileList[0]?.originFileObj))
				.unwrap()
				.then((res) => {
					setFileList([]);
					callback(values, res.id);
				})
				.catch(() => {
					message.error('upload failed.');
				})
				.finally(() => {
					setUploading(false);
				});
		} else callback(values, null);
	};

	const onSave = async (values) => {
		await handleSaveDriver(values);
	};

	const handleClose = useCallback(
		(hasUnsavedChanges) => {
			if (hasUnsavedChanges && !confirmationOpen) {
				setConfirmationOpen(true); // Mark that the confirmation modal is open
				ShowConfirmClose(() => {
					setConfirmationOpen(false); // Reset when the confirmation modal is closed
					onClose();
				});
			} else {
				onClose();
			}
			dispatch(resetFetchedDocuments());
			dispatch(resetSelectedDriver());
		},
		[onClose, confirmationOpen],
	);

	const handleDirty = useCallback(() => {
		setUnsavedChanges(true);
	}, []);

	const handleSaveDriver = async (values) => {
		setMakingApiCall(true);
		await handleUpload(saveDriver, values); // Wait for the upload to complete and update the pictureId
	};
	const { driverFetchedDocuments } = useSelector((state) => state.files);

	const saveDriver = async (values, profilePictureId) => {
		const documents = [];
		const allDocuments = driverFetchedDocuments?.driverDocuments;
		const tagDocumentsList = allDocuments?.filter(
			(doc) => doc.type === 'TAG_DOCUMENT' || doc.documentType === 'TAG_DOCUMENT',
		);
		const drivingLicenceDocumentsList = allDocuments?.filter(
			(doc) =>
				doc.type === 'DRIVER_LICENSE_DOCUMENT' ||
				doc.documentType === 'DRIVER_LICENSE_DOCUMENT',
		);
		const policeConductDocumentsList = allDocuments?.filter(
			(doc) =>
				doc.type === 'POLICE_CONDUCT_DOCUMENT' ||
				doc.documentType === 'POLICE_CONDUCT_DOCUMENT',
		);
		const idDocumentsList = allDocuments?.filter(
			(doc) => doc.type === 'ID_CARD' || doc.documentType === 'ID_CARD',
		);
		if (tagDocumentsList?.length > 0) {
			const tagDocumentsPayload = tagDocumentsList.map((item) => {
				return {
					fileId: item?.id,
					type: 'TAG_DOCUMENT',
					expiryDate: values?.tagExpiryDate,
				};
			});
			documents.push(...tagDocumentsPayload);
		}

		if (drivingLicenceDocumentsList?.length > 0) {
			const licencePayload = drivingLicenceDocumentsList?.map((item) => {
				return {
					fileId: item.id,
					type: 'DRIVER_LICENSE_DOCUMENT',
					expiryDate: values?.licenceExpiryDate,
				};
			});
			documents.push(...licencePayload);
		}
		if (policeConductDocumentsList?.length > 0) {
			const policePayload = policeConductDocumentsList?.map((item) => {
				return {
					fileId: item.id,
					type: 'POLICE_CONDUCT_DOCUMENT',
					expiryDate: values?.policeConductExpiryDate,
				};
			});
			documents.push(...policePayload);
		}
		if (idDocumentsList?.length > 0) {
			const idPayload = idDocumentsList?.map((item) => {
				return {
					fileId: item.id,
					type: 'ID_CARD',
					expiryDate: values?.idExpiryDate,
				};
			});
			documents.push(...idPayload);
		}
		const action = driverId
			? updateDriver({
					driverId: driver.id,
					driver: {
						...values,
						email: values.email.trim().toLowerCase(),
						profilePhoto: isFileChosen ? profilePictureId : driver.profilePicture,
						active: driver.active,
						documents,
					},
			  })
			: addNewDriver({
					driver: {
						...values,
						email: values.email.trim().toLowerCase(),
						profilePhoto: profilePictureId,
						documents,
					},
			  });

		await dispatch(action)
			.unwrap()
			.then(() => {
				message.success('Driver saved successfully');
				onClose();
				setUnsavedChanges(false);
				dispatch(resetDocuments());
				dispatch(resetFetchedDocuments());
			})
			.catch((e) => {
				console.log('error', e);
				if (e.message === 'DRIVER_ALREADY_EXISTS') {
					message.error('Could not save driver, it already exists');
				} else {
					message.error(e.message);
				}
			})
			.finally(() => {
				setMakingApiCall(false);
			});
	};

	const onSaveFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};

	const fetchImage = async (imageId) => {
		setMakingApiCall(true);

		return dispatch(downloadImage(imageId))
			.unwrap()
			.then((data) => {
				const imgUrl = URL.createObjectURL(data);
				setMakingApiCall(false);
				return imgUrl;
			})
			.catch((error) => {
				setMakingApiCall(false);
				throw error;
			});
	};

	const fetchDriverDetailsAndProfilePicrture = async () => {
		setMakingApiCall(true);

		const calls = [
			dispatch(
				getDriverById({
					driverId,
				}),
			),
		];
		Promise.all(calls)
			.then((res) => {
				if (driverId) {
					setDriver(res[0].payload);
					if (res[0].payload?.profilePicture && res[0].payload?.profilePicture !== 0) {
						fetchImage(res[0].payload?.profilePicture).then((imgUrl) => {
							setFileList([
								{
									uid: '-1',
									name: 'image.png',
									status: 'done',
									url: imgUrl,
								},
							]);
						});
					}
				}
			})
			.catch(() => {
				onClose();
			})
			.finally(() => {
				setMakingApiCall(false);
			});
	};

	useEffect(() => {
		const abortController = new AbortController();

		if (driverId) {
			fetchDriverDetailsAndProfilePicrture();
		}
		return () => abortController.abort();
	}, [driverId, dispatch]);

	return (
		<Drawer
			title={!driverId ? 'Add Driver' : 'Update Driver'}
			placement='right'
			onClose={() => handleClose(unsavedChanges)}
			width={500}
			open>
			<Spin size='large' spinning={makingApiCall} style={{ marginTop: 64 }} delay={500}>
				{!makingApiCall && (
					<DriverForm
						driver={driver}
						onSave={onSave}
						onSaveFailed={onSaveFailed}
						onDeleteDriver={handleDeleteDriver}
						onCancel={handleClose}
						onDirty={handleDirty}
						unsavedChanges={unsavedChanges}
						setUnsavedChanges={setUnsavedChanges}
						fileList={fileList}
						setFileList={setFileList}
						handleUpload={handleUpload}
						uploading={uploading}
						setIsFileChosen={setIsFileChosen}
					/>
				)}
			</Spin>
		</Drawer>
	);
};

export default DrawerDriver;
