import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../axios';
import { arrayToObject } from '../Utils/ArrayToObject';

const initialState = {
	DriversVehicles: { loading: false, driversVehiclesList: [] },
	SelectedVehicle: { loading: false, driverVehicle: null },
};

export const getAllVehicles = createAsyncThunk('Vehicles/getAllVehicles', async (driverId) => {
	try {
		let url = 'driversVehicles';
		if (driverId) {
			url += `?driverId=${driverId}`;
		}
		const response = await axios.get(url);
		let { data } = response;
		data = arrayToObject(data, 'id');
		return data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const addNewVehicle = createAsyncThunk('DriverVehicles/addNewVehicle', async (data) => {
	const tmpVehicle = data.vehicle;
	let vehicle = { ...tmpVehicle };
	vehicle = { ...vehicle };
	try {
		const response = await axios.post(`driverVehicles`, vehicle);
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const getVehicleById = createAsyncThunk('DriverVehicles/getVehicleById', async (data) => {
	try {
		const response = await axios.get(`driverVehicle/${data.vehicleId}`);
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const deleteVehicle = createAsyncThunk('DriversVehicles/deleteVehicle', async (data) => {
	try {
		await axios.delete(`driverVehicles/${data.vehicleId}`);
		return data.vehicleId;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const updateVehicle = createAsyncThunk('DriversVehicles/updateVehicle', async (data) => {
	const tmpVehicle = data.vehicle;
	const vehicle = { ...tmpVehicle };

	try {
		const response = await axios.put(`driverVehicles/${data.vehicleId}`, vehicle);
		return response.data;
	} catch (error) {
		console.log(error);
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const driversVehiclesSlice = createSlice({
	name: 'DriversVehicles',
	initialState,
	reducers: {},
	extraReducers: {
		[getAllVehicles.pending]: (state) => {
			state.DriversVehicles.loading = true;
		},
		[getAllVehicles.rejected]: (state) => {
			state.DriversVehicles.loading = false;
		},
		[getAllVehicles.fulfilled]: (state, action) => {
			state.DriversVehicles.loading = false;
			state.DriversVehicles.driversVehiclesList = Object.values(action.payload);
		},
		[getVehicleById.pending]: (state) => {
			state.SelectedVehicle.loading = true;
		},
		[getVehicleById.rejected]: (state) => {
			state.SelectedVehicle.loading = false;
		},
		[getVehicleById.fulfilled]: (state, action) => {
			state.SelectedVehicle.loading = false;
			state.SelectedVehicle.driverVehicle = action.payload;
		},
		[addNewVehicle.pending]: (state) => {
			state.DriversVehicles.loading = true;
		},
		[addNewVehicle.rejected]: (state) => {
			state.DriversVehicles.loading = false;
		},
		[addNewVehicle.fulfilled]: (state, action) => {
			state.DriversVehicles.loading = false;
			state.DriversVehicles.driversVehiclesList.push(action.payload);
		},
		[deleteVehicle.pending]: (state) => {
			state.DriversVehicles.loading = true;
		},
		[deleteVehicle.rejected]: (state) => {
			state.DriversVehicles.loading = false;
		},
		[deleteVehicle.fulfilled]: (state, action) => {
			state.DriversVehicles.loading = false;
			state.DriversVehicles.driversVehiclesList =
				state.DriversVehicles.driversVehiclesList.filter((item) => {
					return item.id !== action.payload;
				});
		},
		[updateVehicle.pending]: (state) => {
			state.DriversVehicles.loading = true;
		},
		[updateVehicle.rejected]: (state) => {
			state.DriversVehicles.loading = false;
		},
		[updateVehicle.fulfilled]: (state, action) => {
			state.DriversVehicles.loading = false;
			state.DriversVehicles.driversVehiclesList =
				state.DriversVehicles.driversVehiclesList.map((item) => {
					if (item.id === action.payload.id) {
						item = action.payload;
						return item;
					}
					return item;
				});
		},
	},
});

export default driversVehiclesSlice.reducer;
