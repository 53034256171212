import React from 'react';
import { Route, Routes } from 'react-router';
import Login from './Auth/Login';
import ForgetPasswordRequest from './Auth/ForgetPasswordRequest';
import CheckResetCodeValidation from './Auth/CheckResetCodeValidation';
import ChangePassword from './Auth/ChangePassword';
import NotFound from './notFound/NotFound';

const NotLoggedInArea = () => {
	return (
		<Routes>
			<Route path='/login' element={<Login />} />
			<Route path='/forget-password' element={<ForgetPasswordRequest />} />
			<Route path='/check-reset-code' element={<CheckResetCodeValidation />} />
			<Route path='/reset-password' element={<ChangePassword />} />
			<Route path='/*' element={<NotFound />} />
		</Routes>
	);
};

export default NotLoggedInArea;
