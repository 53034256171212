/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */

import React, { useEffect, useState } from 'react';
import { Drawer, Form, Select, Button, message, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { style } from '../../../Styles';
import { bookingStatuses } from '../../../Utils/Constants/utils';
import { getAllBookingsWithCretaria } from '../../../redux/bookingSlice';

const FilteringDrawer = ({
	closeFilteringDrawer,
	isFilteringDrawerOpened,
	resetFilter,
	dateRange,
	role,
	form,
}) => {
	const location = useLocation();

	const { Riders } = useSelector((state) => state.riders);
	const { Drivers } = useSelector((state) => state.drivers);
	const [isMakingApiCall, setIsMakingApiCall] = useState(false);
	const { corporates } = useSelector((state) => state.corporates);
	const dispatch = useDispatch();
	const getSpanStyle = (status) => {
		switch (status) {
			case 'Completed':
				return style.statusCompleted;
			case 'Cancelled':
				return style.statusCancelled;
			case 'Pending':
				return style.statusPending;
			case 'In Progress':
				return style.statusInProgress;
			case 'Accepted':
				return style.statusAccepted;
			case 'Requested':
				return style.statusRequested;
			case 'Timed Out':
				return style.statusTimedOut;
			default:
				break;
		}
	};
	useEffect(() => {
		if (location?.state?.status) {
			const bookingStatus = bookingStatuses.find(
				(status) => status.enum === location?.state?.status,
			);
			form.setFieldsValue({ statusId: bookingStatus.id });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location?.state?.status]);

	const handleResetForm = () => {
		if (location && location.state) {
			location.state.status = null;
		}
		form.setFieldsValue({
			statusId: null,
			driverId: null,
			riderId: null,
			corporateId: null,
		});
		resetFilter();
	};

	const onSave = (values) => {
		setIsMakingApiCall(true);
		const start = new Date(dateRange[0]).toISOString();
		const end = new Date(dateRange[1]).toISOString();
		dispatch(
			getAllBookingsWithCretaria({
				statusId: values.statusId !== undefined ? values.statusId : null,
				driverId: values.driverId !== undefined ? values.driverId : null,
				riderId: values.riderId !== undefined ? values.riderId : null,
				start: start !== undefined ? start : null,
				end: end !== undefined ? end : null,
				corporateId: values.corporateId !== undefined ? values.corporateId : null,
			}),
		)
			.then(() => {
				closeFilteringDrawer();
			})
			.catch((err) => {
				console.log(err, 'error');
				message.error('Failed to filter bookings');
			})
			.finally(() => {
				setIsMakingApiCall(false);
			});
	};

	const onDrawerClose = () => {
		// close drawer
		closeFilteringDrawer();
	};
	return (
		<Drawer
			form={form}
			title='Filter bookings'
			placement='right'
			closable={false}
			onClose={onDrawerClose}
			width={400}
			open={isFilteringDrawerOpened}>
			<Spin spinning={isMakingApiCall}>
				<Form
					form={form}
					name='Booking filter'
					layout='vertical'
					initialValues={{}}
					onFinish={(values) => onSave(values)}
					onFinishFailed={() => {}}
					autoComplete='off'
					onValuesChange={() => {}}>
					<Form.Item label='Status' name='statusId'>
						<Select
							showArrow
							showSearch
							optionFilterProp='filterBy'
							allowClear
							onClear={() => {
								form.setFieldsValue({ statusId: null });
								location.state.status = null;
							}}
							optionLabelProp='label'>
							{bookingStatuses.map((status) => (
								<Select.Option
									key={status.name}
									filterBy={status.name}
									value={status.id}
									label={status.name}>
									<span style={style.dataContainer}>
										<div style={getSpanStyle(status.name)}>{status.name}</div>
									</span>
								</Select.Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item label='Driver' name='driverId'>
						<Select
							size='medium'
							showArrow
							showSearch
							optionFilterProp='filterBy'
							allowClear
							optionLabelProp='label'>
							{Drivers.driversList?.map((p) => (
								<Select.Option
									key={p.id}
									filterBy={`${p.name}`}
									label={`${p.name} ${p.surname}`}
									// disabled={!p.active}
									value={p.id}>
									<div>{` ${p.name}  ${p.surname}`}</div>
									<span style={{ color: 'gray' }}>{p.email}</span>
								</Select.Option>
							))}
						</Select>
					</Form.Item>
					{role?.includes('SUPER_ADMIN') && (
						<Form.Item label='Rider' name='riderId'>
							<Select
								size='medium'
								showArrow
								showSearch
								optionFilterProp='filterBy'
								allowClear
								optionLabelProp='label'>
								{Riders.ridersList?.map((p) => (
									<Select.Option
										key={p.id}
										filterBy={`${p.name}`}
										label={
											role?.includes('SUPER_ADMIN')
												? `${p?.name} ${p?.surname} `
												: `${p?.name}`
										}
										// disabled={!p.active}
										value={p.id}>
										<div>
											{role?.includes('SUPER_ADMIN')
												? `${p?.name} ${p?.surname} `
												: `${p?.name}`}
										</div>
										{role?.includes('SUPER_ADMIN') && (
											<span style={{ color: 'gray' }}>{p.email}</span>
										)}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					)}

					{role?.includes('SUPER_ADMIN') || role?.includes('CORPORATE_ADMIN') ? (
						<Form.Item label='Corporate' name='corporateId'>
							<Select
								size='medium'
								showArrow
								showSearch
								optionFilterProp='filterBy'
								allowClear
								optionLabelProp='label'>
								{corporates?.corporatesList?.map((p) => (
									<Select.Option
										key={p.id}
										filterBy={`${p.name}`}
										label={`${p.name}`}
										value={p.id}>
										<div>{`${p.name}`}</div>
										<span style={{ color: 'gray' }}>{p.email}</span>
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					) : null}

					<Form.Item style={{ float: 'right' }}>
						<Button
							type='default'
							style={{ marginBottom: 32, marginRight: 8 }}
							onClick={() => closeFilteringDrawer()}>
							Cancel
						</Button>
						<Button type='primary' style={{ marginBottom: 32 }} htmlType='submit'>
							Filter
						</Button>
					</Form.Item>
					<Form.Item style={{ float: 'left' }}>
						<Button
							type='default'
							styles={{ backgroundColor: 'red' }}
							style={{ marginBottom: 32, marginRight: 8 }}
							onClick={() => handleResetForm()}>
							Reset filter
						</Button>
					</Form.Item>
				</Form>
			</Spin>
		</Drawer>
	);
};

export default FilteringDrawer;
