import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../axios';

const initialState = {
	KmRangeFare: { loading: false, kmRangeFareList: [] },
	SelectedKmRangeFare: { loading: false, kmRangeFare: null },
};

export const getAllKmRangeFares = createAsyncThunk('KmRangeFare/getAllKmRangeFares', async () => {
	try {
		const response = await axios.get(`kmRangeFares`);
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
			/* data: error.response.data, */
		};
		throw customError;
	}
});
export const getKmRangeFareById = createAsyncThunk(
	'KmRangeFare/getKmRangeFareById',
	async (data) => {
		const { id } = data;
		try {
			const response = await axios.get(`kmRangeFare/${id}`);
			return response.data;
		} catch (error) {
			const customError = {
				name: 'Custom axios error',
				message: error.response.data.message,
				/* data: error.response.data, */
			};
			throw customError;
		}
	},
);
export const addNewKmRangeFare = createAsyncThunk('KmRangeFare/addNewKmRangeFare', async (data) => {
	const { kmRangeFare } = data;
	try {
		const response = await axios.post(`kmRangeFare`, {
			...kmRangeFare,
		});
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
			/* data: error.response.data, */
		};
		throw customError;
	}
});
export const updateKmRangeFare = createAsyncThunk('KmRangeFare/updateKmRangeFare', async (data) => {
	const { kmRangeFare } = data;
	try {
		const response = await axios.put(`kmRangeFare/${data.id}`, {
			...kmRangeFare,
		});
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
			/* data: error.response.data, */
		};
		throw customError;
	}
});
export const deleteKmRangeFare = createAsyncThunk('KmRangeFare/deleteKmRangeFare', async (data) => {
	const { id } = data;
	try {
		await axios.delete(`kmRangeFare/${id}`);
		return data.id;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
			/* data: error.response.data, */
		};
		throw customError;
	}
});
const KmRangeFareSlice = createSlice({
	name: 'KmRangeFare',
	initialState,
	reducers: {},
	extraReducers: {
		[getAllKmRangeFares.pending]: (state) => {
			state.KmRangeFare.loading = true;
		},
		[getAllKmRangeFares.fulfilled]: (state, action) => {
			state.KmRangeFare.loading = false;
			state.KmRangeFare.kmRangeFareList = Object.values(action.payload);
		},
		[getAllKmRangeFares.rejected]: (state) => {
			state.KmRangeFare.loading = false;
		},
		[getKmRangeFareById.pending]: (state) => {
			state.SelectedKmRangeFare.loading = true;
		},
		[getKmRangeFareById.fulfilled]: (state, action) => {
			state.SelectedKmRangeFare.loading = false;
			state.SelectedKmRangeFare.kmRangeFare = action.payload;
		},
		[getKmRangeFareById.rejected]: (state) => {
			state.SelectedKmRangeFare.loading = false;
		},
		[addNewKmRangeFare.pending]: (state) => {
			state.KmRangeFare.loading = true;
		},
		[addNewKmRangeFare.fulfilled]: (state, action) => {
			state.KmRangeFare.loading = false;
			state.KmRangeFare.kmRangeFareList.push(action.payload);
		},
		[addNewKmRangeFare.rejected]: (state) => {
			state.KmRangeFare.loading = false;
		},
		[updateKmRangeFare.pending]: (state) => {
			state.KmRangeFare.loading = true;
		},
		[updateKmRangeFare.fulfilled]: (state, action) => {
			state.KmRangeFare.loading = false;
			const index = state.KmRangeFare.kmRangeFareList.findIndex(
				(kmRangeFare) => kmRangeFare.id === action.payload.id,
			);
			state.KmRangeFare.kmRangeFareList[index] = action.payload;
		},
		[updateKmRangeFare.rejected]: (state) => {
			state.KmRangeFare.loading = false;
		},
		[deleteKmRangeFare.pending]: (state) => {
			state.KmRangeFare.loading = true;
		},
		[deleteKmRangeFare.fulfilled]: (state, action) => {
			state.KmRangeFare.loading = false;
			const index = state.KmRangeFare.kmRangeFareList.findIndex(
				(kmRangeFare) => kmRangeFare.id === action.payload,
			);
			state.KmRangeFare.kmRangeFareList.splice(index, 1);
		},
		[deleteKmRangeFare.rejected]: (state) => {
			state.KmRangeFare.loading = false;
		},
	},
});
export default KmRangeFareSlice.reducer;
