export const orEmpty = (str) => str || '';

export const arrayToObject = (array) =>
	array.reduce((obj, item) => {
		obj[item.id] = item;
		return obj;
	}, {});

export function formatEnumValue(enumValue) {
	const words = enumValue?.split('_'); // Split enum value by underscores
	const formattedWords = words?.map((word) => {
		return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(); // Capitalize first letter, lowercase the rest
	});

	return formattedWords?.join(' '); // Join formatted words with spaces
}

export const getBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});

export const RefundReasons = [
	{ value: 'SERVICE_QUALITY', label: 'Service Quality' },
	{ value: 'FAILURE_TO_PROVIDE_SERVICE', label: 'Failure to provide service' },
	{ value: 'PAYMENT_ISSUES', label: 'Payment issues' },
	{ value: 'SAFETY_CONCERNS', label: 'Safety concerns' },
	{ value: 'UNEXPECTED_CIRCUMSTANCES', label: 'Unexpected circumstances' },
	{ value: 'OTHER', label: 'Other' },
];
