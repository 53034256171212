/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-useless-fragment */
import { Card, Tooltip, Select, Spin, Button } from 'antd';
import React from 'react';
import { InfoCircleOutlined, ReloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { DonutChart } from 'bizcharts';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBookingStatisticsByPeriod } from '../../redux/dashboardSlice';
import NoData from './NoData';

const { Option } = Select;

const RidesPieChart = () => {
	const [selectedOption, setSelectedOption] = useState('1W');
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const { dashboard } = useSelector((state) => state.dashboard);

	const reload = () => {
		fetchRidesPieChartData(selectedOption);
	};
	const fetchRidesPieChartData = async (value) => {
		setLoading(true);
		dispatch(getBookingStatisticsByPeriod(value))
			.then()
			.catch()
			.finally(() => {
				setLoading(false);
			});
	};
	const handleSelectChange = (value) => {
		setSelectedOption(value);
		fetchRidesPieChartData(value);
	};
	const totalRides = dashboard?.ridesCount?.reduce((a, b) => a + b.value, 0);
	return (
		<Spin
			size='large'
			indicator={<LoadingOutlined style={{ fontSize: 54 }} spin />}
			tip={
				<div>
					<h2>Loading...</h2>
				</div>
			}
			spinning={loading}>
			<Card
				type='inner'
				title='Bookings'
				extra={
					<div>
						<Tooltip title='This widget displays site statistics including the count of drivers, riders, bookings, and earnings.'>
							<InfoCircleOutlined />
						</Tooltip>
						<Button
							style={{
								marginLeft: '1em',
							}}
							type='default'
							size='small'
							onClick={() => reload()}>
							<ReloadOutlined />
						</Button>
						<Select
							defaultValue={selectedOption}
							style={{ marginLeft: 16, width: 120 }}
							onChange={handleSelectChange}>
							<Option value='1W'>Last Week</Option>
							<Option value='1M'>Last Month</Option>
							<Option value='3M'>Last 3 Month</Option>
							<Option value='1Y'>Last Year</Option>
						</Select>
					</div>
				}
				bordered={false}
				style={{
					width: '100%',
				}}>
				{dashboard?.ridesCount?.length === 0 ? (
					<NoData height={300} />
				) : (
					<DonutChart
						data={dashboard?.ridesCount || []}
						title={{
							style: {
								fontFamily: 'Arial',
							},
							visible: true,
							text: `Bookings Count ${totalRides}`,
						}}
						description={{
							style: {
								fontFamily: 'Arial',
							},
							visible: true,
							text: '* This is the count for all Bookings (Pending, Accepted, Timed out, Completed, In progress, Cancelled, Requested).',
						}}
						autoFit
						height={300}
						radius={0.7}
						padding='auto'
						angleField='value'
						colorField='type'
						pieStyle={{
							stroke: 'white',
							lineWidth: 5,
						}}
					/>
				)}
			</Card>
		</Spin>
	);
};

export default RidesPieChart;
