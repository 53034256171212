/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { Button, Modal, Result, Space, Spin, Typography, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CaretRightOutlined, MinusOutlined } from '@ant-design/icons';
import { getBookingRefundHistoryByBookingId } from '../../../redux/bookingSlice';
import { formatDateAndTime } from '../../../Utils/date-utils';
import ImageComponent from '../../Global/ImageComponent/ImageComponent';
import CustomAvatar from '../../Global/CustomAvatar/CustomAvatar';
import { RefundReasons } from '../../../Utils/ArrayToObject';

const { Paragraph, Text } = Typography;

const ModalRefundDetails = ({ booking, show, onClose }) => {
	const [makingApiCall, setMakingApiCall] = useState(false);
	const [refundDetails, setRefundDetails] = useState(null);
	const dispatch = useDispatch();
	const fetchBookingRefundDetails = async () => {
		setMakingApiCall(true);
		dispatch(getBookingRefundHistoryByBookingId(booking.id))
			.unwrap()
			.then((response) => {
				setRefundDetails(response);
			})
			.catch(() => {
				message.error('Error while fetching booking refund details');
			})
			.finally(() => {
				setMakingApiCall(false);
			});
	};

	useEffect(() => {
		if (show && booking?.id) {
			fetchBookingRefundDetails();
		}
	}, [dispatch, booking?.id]);

	const getReasonLabel = (reason) => {
		const refundReason = RefundReasons.find((item) => item.value === reason);
		return refundReason ? refundReason.label : <MinusOutlined />;
	};

	return (
		<Modal
			title={`Details of Refund Booking - ${booking?.id}`}
			centered
			open={show}
			footer={
				<Button key='back' onClick={onClose}>
					Close
				</Button>
			}>
			<Spin spinning={makingApiCall && refundDetails} tip='Loading Refund Details'>
				<Space
					direction='vertical'
					size='middle'
					style={{
						display: 'flex',
					}}>
					<Result
						status='success'
						title={`Successfully Refunded Booking with ID: ${booking?.id}`}
						subTitle={`Please be advised that the refund has been successfully processed from our end. The refunded amount will be credited to the customer's account within 3-5 business days through our payment gateway.`}
						extra={[]}>
						<div className='desc'>
							<Paragraph>
								<Text
									strong
									style={{
										fontSize: 15,
										textAlign: 'left',
									}}>
									<div>
										{refundDetails?.refundedBy?.profilePicture ? (
											<ImageComponent
												size={30}
												imageId={refundDetails?.refundedBy?.profilePicture}
											/>
										) : (
											<CustomAvatar
												size={30}
												name={`${refundDetails?.refundedBy?.name} ${refundDetails?.refundedBy?.surname} `}
												type='initials'
											/>
										)}
										<span
											style={{
												marginLeft: 8,
											}}>
											{`${refundDetails?.refundedBy?.name} ${refundDetails?.refundedBy?.surname}`}{' '}
											submitted the refund on{' '}
											{formatDateAndTime(refundDetails?.created)}
										</span>
									</div>
								</Text>
							</Paragraph>
							<Paragraph>
								<CaretRightOutlined className='site-result-demo-error-icon' />
								Amount :{' '}
								<Text type='danger'>{refundDetails?.amountToRefund} EUR</Text>
							</Paragraph>
							<Paragraph>
								<CaretRightOutlined className='site-result-demo-error-icon' />
								Reason :{' '}
								<Text type='danger'>
									{getReasonLabel(refundDetails?.refundReason)}
								</Text>
							</Paragraph>
							<Paragraph>
								<CaretRightOutlined className='site-result-demo-error-icon' />
								Description{' '}
								<Text type='danger'>
									{refundDetails?.refundReasonDescription
										? refundDetails?.refundReasonDescription
										: '-'}
								</Text>
							</Paragraph>
						</div>
					</Result>
				</Space>
			</Spin>
		</Modal>
	);
};

export default ModalRefundDetails;
