/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { TagOutlined, TagsOutlined } from '@ant-design/icons';
import { Card, Tabs } from 'antd';
import { useDispatch } from 'react-redux';
import { style } from '../../Styles';
import Bundle from './Bundle';
import PurchasedBundle from './PurchasedBundle/PurchasedBundle';
import { getAllBundles } from '../../redux/bundleSlice';

const BundleMultiTab = () => {
	const [activeTab, setActiveTab] = useState('1');
	const dispatch = useDispatch();
	const onChange = (key) => {
		setActiveTab(key);
	};
	const fetchBundles = () => {
		dispatch(getAllBundles());
	};

	useEffect(() => {
		fetchBundles();
	}, []);

	const bundleTabItems = [
		{
			label: (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-start',
					}}>
					<TagOutlined />
					<span style={style.navItem}>Bundles</span>
				</div>
			),
			key: '1',
			children: <Bundle />,
			show: true,
		},
		{
			label: (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-start',
					}}>
					<TagsOutlined />
					<span style={style.navItem}>Purchased Bundles</span>
				</div>
			),
			key: '2',
			children: <PurchasedBundle />,
			show: true,
		},
	];
	return (
		<Card style={{
					margin: 16,
					maxHeight: '90%', // Set maximum width
					height: 'auto', // Allow width to be dynamic
				}}>
			<Tabs
				onChange={onChange}
				type='card'
				items={bundleTabItems.filter((tab) => tab.show)}
				defaultActiveKey={activeTab}
			/>
		</Card>
	);
};

export default BundleMultiTab;
