/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */

import React, { useState } from 'react';
import { Drawer, Form, Select, Button, message, Spin, Rate } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getAllReviewsByCriteria } from '../../redux/reviewSlice';

const FilteringDrawer = ({
	closeFilteringDrawer,
	isFilteringDrawerOpened,

	dateRange,
	role,
	setIsFilteringEmpty,
}) => {
	const { Riders } = useSelector((state) => state.riders);
	const { Drivers } = useSelector((state) => state.drivers);
	const [isMakingApiCall, setIsMakingApiCall] = useState(false);
	const { Brand } = useSelector((state) => state.brand);
	const [form] = Form.useForm();
	const dispatch = useDispatch();

	const onSave = (values) => {
		setIsMakingApiCall(true);
		const start = new Date(dateRange[0]).toISOString();
		const end = new Date(dateRange[1]).toISOString();
		if (values.numberOfStars === 0) {
			values.numberOfStars = null;
		}
		// check if the values are empty then set the isFilteringEmpty to true
		const isValuesEmpty =
			(values.numberOfStars === null ||
				values.numberOfStars === undefined ||
				values.numberOfStars === 0) &&
			values.driverId === undefined &&
			values.riderId === undefined;
		setIsFilteringEmpty(isValuesEmpty);
		dispatch(
			getAllReviewsByCriteria({
				numberOfStars:
					values.numberOfStars !== undefined || values.numberOfStars !== 0
						? values.numberOfStars
						: null,
				driverId: values.driverId !== undefined ? values.driverId : null,
				riderId: values.riderId !== undefined ? values.riderId : null,
				start: start !== undefined ? start : null,
				end: end !== undefined ? end : null,
			}),
		)
			.then(() => {
				closeFilteringDrawer();
			})
			.catch((err) => {
				console.log(err, 'error');
				message.error('Failed to filter reviews');
			})
			.finally(() => {
				setIsMakingApiCall(false);
			});
	};

	const onDrawerClose = () => {
		// close drawer
		closeFilteringDrawer();
	};
	return (
		<Drawer
			form={form}
			title='Filter Reviews'
			placement='right'
			closable={false}
			onClose={onDrawerClose}
			width={400}
			open={isFilteringDrawerOpened}>
			<Spin spinning={isMakingApiCall}>
				<Form
					form={form}
					name='Review filter'
					layout='vertical'
					initialValues={{}}
					onFinish={(values) => onSave(values)}
					onFinishFailed={() => {}}
					autoComplete='off'
					onValuesChange={() => {}}>
					<Form.Item
						label='Rating'
						name='numberOfStars'
						tooltip='
						You can clear the rating by clicking on the stars again.
					'>
						<Rate
							allowClear
							style={{
								fontSize: 36,
								color: Brand?.brandData?.webPrimary || 'orange',
							}}
						/>
					</Form.Item>
					<Form.Item
						label='Driver'
						tooltip='Choose a driver to filter reviews by.'
						name='driverId'>
						<Select
							size='medium'
							showArrow
							showSearch
							optionFilterProp='filterBy'
							allowClear
							optionLabelProp='label'>
							{Drivers.driversList?.map((p) => (
								<Select.Option
									key={p.id}
									filterBy={`${p.name}`}
									label={`${p.name} ${p.surname}`}
									// disabled={!p.active}
									value={p.id}>
									<div>{` ${p.name}  ${p.surname}`}</div>
									<span style={{ color: 'gray' }}>{p.email}</span>
								</Select.Option>
							))}
						</Select>
					</Form.Item>
					{role?.includes('SUPER_ADMIN') && (
						<Form.Item
							tooltip='Choose a rider to filter reviews by.'
							label='Rider'
							name='riderId'>
							<Select
								size='medium'
								showArrow
								showSearch
								optionFilterProp='filterBy'
								allowClear
								optionLabelProp='label'>
								{Riders.ridersList?.map((p) => (
									<Select.Option
										key={p.id}
										filterBy={`${p.name}`}
										label={
											role?.includes('SUPER_ADMIN')
												? `${p?.name} ${p?.surname} `
												: `${p?.name}`
										}
										// disabled={!p.active}
										value={p.id}>
										<div>
											{role?.includes('SUPER_ADMIN')
												? `${p?.name} ${p?.surname} `
												: `${p?.name}`}
										</div>
										{role?.includes('SUPER_ADMIN') && (
											<span style={{ color: 'gray' }}>{p.email}</span>
										)}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					)}

					<Form.Item style={{ float: 'right' }}>
						<Button
							type='default'
							style={{ marginBottom: 32, marginRight: 8 }}
							onClick={() => closeFilteringDrawer()}>
							Cancel
						</Button>
						<Button type='primary' style={{ marginBottom: 32 }} htmlType='submit'>
							Filter
						</Button>
					</Form.Item>
					<Form.Item style={{ float: 'left' }}>
						<Button
							type='default'
							styles={{ backgroundColor: 'red' }}
							style={{ marginBottom: 32, marginRight: 8 }}
							onClick={() => {
								form.resetFields();
								setIsFilteringEmpty(true);
							}}>
							Reset filter
						</Button>
					</Form.Item>
				</Form>
			</Spin>
		</Drawer>
	);
};

export default FilteringDrawer;
