export default async function getCountryFromIP() {
	let data = 'Malta';
	await fetch('https://ipapi.co/json/')
		.then((res) => {
			data = res.json().country_name;
		})
		.catch((err) => {
			console.log('Cannot get the country from IP', err);
		});

	return data;
}
