/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */

import React, { useState, useContext, useEffect } from 'react';

import { Alert, Card, Col, Form, Row, Spin, Switch, message } from 'antd';
import { TenantFeaturesContext } from '../../Context/TenantFeatureContext';
import { formatEnumValue } from '../../Utils/ArrayToObject';
import AlertBanner from '../Global/AlertBanner';

const TenantFeature = ({ globalFeatures }) => {
	console.log('globalFeatures: ', globalFeatures.length);
	const [makingApiCall, setMakingApiCall] = useState(false);
	const context = useContext(TenantFeaturesContext);

	const checkFeatureEnabled = (feature) => {
		if (context.tenantFeatures.length) {
			const foundFeature = context.tenantFeatures.find((f) => f.feature.id === feature.id);
			if (foundFeature) {
				return true;
			}
		}
		return false;
	};

	return (
		<Spin spinning={makingApiCall}>
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<Card
					hoverable
					title='Check the available features'
					bordered={false}
					style={{ width: 600, textAlign: 'center' }}>
					<Col span={24}>
						{globalFeatures.length ? (
							<Form style={{ textAlign: 'center' }}>
								<Row gutter={[16, 16]}>
									{globalFeatures.map((feature) => (
										<>
											<Col span={8} key={feature.id}>
												<Form.Item
													label={formatEnumValue(feature.name)}
													name={feature.name}
													valuePropName='checked'>
													<Switch
														defaultChecked={checkFeatureEnabled(
															feature,
														)}
														disabled
													/>
												</Form.Item>
											</Col>
											<Col span={14} key={feature.id}>
												<AlertBanner
													title='Car pooling is a feature that allows you to share your car with other people.'
													description={null}
													type='info'
													showIcon={false}
												/>
											</Col>
										</>
									))}
								</Row>
							</Form>
						) : (
							<Alert
								message='No features found'
								description='No features found for this tenant'
								type='warning'
								showIcon
							/>
						)}
					</Col>
				</Card>
			</div>
		</Spin>
	);
};

export default TenantFeature;
