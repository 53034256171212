
export const getAllVehiclesTypesEndPoint = `vehiclesTypes`;
export const vehicleTypeByIdEndPoint = (vehicleTypeId) => `vehicleType/${vehicleTypeId}`;
export const peakSurchargeByVehicleTypeEndPoint = (vehicleTypeId) =>
	`vehiclesTypes/${vehicleTypeId}/peakSurcharge`;
export const getAllNightShiftByVehicleTypeEndPoint = (vehicleTypeId) =>
	`vehiclesTypes/${vehicleTypeId}/nightShift`;
export const vehicleTypeEndPoint = `vehicleType`;
// location
export const getAllLocationsEndPoint = `locations`;
export const locationsEndPoint = `location`;
export const locationByIdEndPoint = (locationId) => `location/${locationId}`;
export const locationByLocationForEndPoint = `locations`;
// restricted Area
export const getAllRestrcitedAreaEndPoint = `restrictedAreas`;
export const restrictedAreaByIdEndPoint = (id) => `restrictedArea/${id}`;
export const restrictedAreaEndPoint = `restrictedArea`;
