/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import { Form, Input, Radio, Select, Button, Divider, Spin, Tag } from 'antd';
import { useSelector } from 'react-redux';
import { useForm } from 'antd/es/form/Form';
import { countryPhoneCode } from '../../Utils/country-phone-codes';
import UploadComponent from '../Global/CustomAvatar/UploadComponent';
import { style } from '../../Styles';
import getCountryFromIP from '../../Utils/GettingCountryFromIP';

const optionsGender = [
	{ label: 'Male', value: 'Male' },
	{ label: 'Female', value: 'Female' },
	{ label: 'Undisclosed', value: 'Undisclosed' },
];
const validateMessages = {
	types: {
		email: '${label} is not a valid email',
	},
};

const UserForm = ({
	onSave,
	onSaveFailed,
	user,
	onCancel,
	onDirty,
	unsavedChanges,
	fileList,
	setFileList,
	handleUpload,
	uploading,
	setIsFileChosen,
}) => {
	const [selectedGender, setSelectedGender] = useState('');
	const [form] = useForm();
	const [countryFromIp, setCountryFromIp] = useState('Malta');
	const [phoneCodeFromIp, setPhoneCodeFromIp] = useState('356');
	const onChangeGender = ({ target: { value } }) => {
		setSelectedGender(value);
	};
	useEffect(() => {
		getCountryFromIP().then((country) => {
			setCountryFromIp(country);
			// Find the corresponding phone code for the country
			const foundCountry = countryPhoneCode.find((c) => c.en === country);
			if (foundCountry) {
				form.setFieldsValue({
					phoneCountryCode: user ? user?.phoneCountryCode : foundCountry.phoneCode,
					country: user ? user?.country : foundCountry.en,
				});
				setPhoneCodeFromIp(foundCountry.phoneCode);
				setCountryFromIp(foundCountry.en);
			}
		});
	}, []);
	const { fleetOperator } = useSelector((state) => state.fleetOperator);
	const hasRole = (role) => {
		return user?.roles?.some((r) => r?.name === role);
	};
	const [role, setRole] = useState(hasRole('SUPER_ADMIN') ? 'SUPER_ADMIN' : 'ADMIN');
	const { auth } = useSelector((state) => state.auth);

	const onRoleChange = (e) => {
		setRole(e);
	};

	const initialValues = {
		...user,
		fleetOperators: user?.fleetOperators?.map((fo) => fo?.id) || [],
		role,
	};

	return (
		<Form
			form={form}
			name='rider'
			layout='vertical'
			autoComplete='off'
			initialValues={initialValues}
			validateMessages={validateMessages}
			onFinish={onSave}
			onFinishFailed={onSaveFailed}
			onValuesChange={() => onDirty()}>
			<Form.Item
				label='Profile Picture'
				name='profilePicture'
				style={{ textAlign: 'center' }}>
				<Spin spinning={uploading}>
					<UploadComponent
						fileList={fileList}
						setFileList={setFileList}
						handleUpload={handleUpload}
						uploading={uploading}
						setIsFileChosen={setIsFileChosen}
					/>
				</Spin>
			</Form.Item>
			{!user?.id && auth?.profile?.role?.includes('SUPER_ADMIN') && (
				<Form.Item
					label='Role'
					name='role'
					rules={[{ required: true, message: 'Please select role' }]}>
					<Select
						onChange={onRoleChange}
						size='medium'
						showArrow
						showSearch
						optionFilterProp='filterBy'
						allowClear
						optionLabelProp='label'
						getPopupContainer={(trigger) => trigger.parentNode}>
						<Select.Option key='Admin' value='ADMIN'>
							Admin
						</Select.Option>
						<Select.Option key='Super Admin' value='SUPER_ADMIN'>
							Super Admin
						</Select.Option>
					</Select>
				</Form.Item>
			)}

			{role === 'ADMIN' && !user?.role?.includes('SUPER_ADMIN') && (
				<Form.Item
					label='Fleet Operator'
					name='fleetOperators'
					rules={[{ required: true, message: 'Please select Fleet operators' }]}>
					<Select
						size='medium'
						showArrow
						showSearch
						optionFilterProp='filterBy'
						mode='multiple'
						allowClear
						optionLabelProp='label'>
						{fleetOperator.fleetOperatorList.map((fo) => (
							<Select.Option
								key={fo.id}
								filterBy={fo.name}
								value={fo.id}
								label={fo.name}
								mode='multiple'
								disabled={!fo.active}>
								<div style={style.inlineContent}>
									<span
										style={{
											display: 'flex',
											alignContent: 'center',
											alignItems: 'center',
											float: 'left',
										}}>
										{fo.name}
									</span>
									{fo.active ? (
										<Tag color='green'>Active</Tag>
									) : (
										<Tag color='red'>Inactive</Tag>
									)}
								</div>
							</Select.Option>
						))}
					</Select>
				</Form.Item>
			)}

			<Form.Item
				label='Name'
				name='name'
				rules={[{ required: true, message: 'Please input rider name' }]}>
				<Input />
			</Form.Item>
			<Form.Item
				label='Surname'
				name='surname'
				rules={[{ required: true, message: 'Please input rider surname' }]}>
				<Input />
			</Form.Item>
			<Form.Item
				label='Email'
				name='email'
				rules={[
					{
						required: true,
						message: 'Please input Email',
					},
					{
						pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
						message: 'Please enter a valid email address',
					},
				]}>
				<Input />
			</Form.Item>
			<Form.Item
				label='Country'
				name='country'
				initialValue={user ? user?.country : countryFromIp}
				rules={[{ required: true, message: 'Please select rider country' }]}>
				<Select
					size='medium'
					showArrow
					showSearch
					optionFilterProp='filterBy'
					allowClear
					optionLabelProp='label'
					popupMatchSelectWidth={false}
					onChange={(country) => {
						const foundCountry = countryPhoneCode.find((c) => c.en === country);
						if (foundCountry) {
							form.setFieldsValue({
								phoneCountryCode: foundCountry.phoneCode,
							});
							setPhoneCodeFromIp(foundCountry.phoneCode);
							setCountryFromIp(foundCountry.en);
						}
					}}
					getPopupContainer={(trigger) => trigger.parentNode}>
					{countryPhoneCode.map((p) => (
						<Select.Option
							key={p.en}
							filterBy={`${p.en}`}
							label={
								<>
									<span role='img'>{p.emoji}</span>
									{p.en}
								</>
							}>
							<span role='img'>{p.emoji}</span>
							{p.en} <span style={{ color: 'gray' }} />
						</Select.Option>
					))}
				</Select>
			</Form.Item>
			<Form.Item
				label='Phone Country Code'
				name='phoneCountryCode'
				initialValue={user ? user?.phoneCountryCode : phoneCodeFromIp}
				rules={[{ required: true, message: 'Please select phone country code' }]}>
				<Select
					size='medium'
					showArrow
					showSearch
					optionFilterProp='filterBy'
					allowClear
					optionLabelProp='label'
					popupMatchSelectWidth={false}
					getPopupContainer={(trigger) => trigger.parentNode}>
					{countryPhoneCode.map((p) => (
						<Select.Option
							key={p.phoneCode}
							value={p.phoneCode}
							filterBy={`${p.en} +${p.phoneCode}`}
							label={
								<>
									<span role='img'>{p.emoji}</span>
									{` +(${p.phoneCode}) `}
								</>
							}>
							<span role='img'>{p.emoji}</span>
							{' '}
							<span style={{ color: 'gray' }}>{`+(${p.phoneCode})`}</span>
						</Select.Option>
					))}
				</Select>
			</Form.Item>
			<Form.Item
				label='Phone'
				name='phone'
				rules={[
					{ required: true, message: 'Please input rider Phone' },
					{
						pattern: /^\d+$/,
						message: 'Phone number must contain only numbers',
					},
				]}>
				<Input />
			</Form.Item>
			<Form.Item
				label='Gender'
				name='gender'
				rules={[{ required: true, message: 'Please input rider Phone' }]}>
				<Radio.Group
					options={optionsGender}
					onChange={onChangeGender}
					value={selectedGender}
					optionType='button'
					buttonStyle='solid'
				/>
			</Form.Item>
			{/* 	<Form.Item
				label='Currency'
				name='currency'
				rules={[{ required: true, message: 'Please Select currency!' }]}>
				<Select
					size='medium'
					showArrow
					showSearch
					optionFilterProp='filterBy'
					allowClear
					optionLabelProp='label'
					popupMatchSelectWidth={false}
					getPopupContainer={(trigger) => trigger.parentNode}>
					{currency.map((currency) => (
						<Select.Option key={currency} label={currency}>
							{currency}
						</Select.Option>
					))}
				</Select>
			</Form.Item> */}
			<Divider />
			<Form.Item style={{ float: 'right' }}>
				<Button
					type='default'
					style={{ marginBottom: 32, marginRight: 8 }}
					onClick={() => onCancel(unsavedChanges)}>
					Cancel
				</Button>
				<Button
					type='primary'
					style={{ marginBottom: 32 }}
					htmlType='submit'
					disabled={!unsavedChanges}>
					{!user ? 'Save' : 'Update'}
				</Button>
			</Form.Item>
		</Form>
	);
};

export default UserForm;
