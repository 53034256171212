import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../axios';

const initialState = {
	reviews: { loading: false, reviewList: null },
};

export const getAllReviewsByCriteria = createAsyncThunk(
	'reviews/getAllReviewsByCriteria',
	async (data) => {
		try {
			const response = await axiosInstance.patch(`/review/filter`, data);
			return response.data;
		} catch (error) {
			const customError = {
				name: 'Custom axios error',
				message: error.response.data.message,
				/* data: error.response.data, */
			};
			throw customError;
		}
	},
);

export const logsSlice = createSlice({
	name: 'reviews',
	initialState,
	reducers: {},
	extraReducers: {
		[getAllReviewsByCriteria.pending]: (state) => {
			state.reviews.loading = true;
		},
		[getAllReviewsByCriteria.rejected]: (state) => {
			state.reviews.loading = false;
		},
		[getAllReviewsByCriteria.fulfilled]: (state, action) => {
			state.reviews.loading = false;
			state.reviews.reviewList = Object.values(action.payload);
		},
	},
});

export default logsSlice.reducer;
