/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
import { Button, Form, Input } from 'antd';
import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useLoadScript } from '@react-google-maps/api';
import MapWithAutocomplete from '../Global/GoogleMap/MapWithAutocomplete';

const libraries = ['core', 'places', 'geocoding', 'routes', 'geometry'];

const WayPointForm = ({
	onSave,
	onSaveFailed,
	wayPoint,
	onCancel,
	onDirty,
	unsavedChanges,
	setUnsavedChanges,
}) => {
	const [selectedLocation, setSelectedLocation] = useState(null);
	const [autocomplete, setAutocomplete] = useState(null);
	const [searchInput, setSearchInput] = useState('');
	const { googleMaps } = useSelector((state) => state.googleMapsKey);

	const { isLoaded } = useLoadScript({
		googleMapsApiKey: googleMaps?.googleMapsKey?.key,
		libraries,
	});
	useEffect(() => {
		if (wayPoint) {
			setSelectedLocation({
				lat: wayPoint.latitude,
				lng: wayPoint.longitude,
			});
		}
	}, [wayPoint]);

	return (
		<Form
			name='WayPoint'
			layout='vertical'
			autoComplete='off'
			initialValues={wayPoint}
			onFinish={(values) =>
				onSave({
					...values,
					id: wayPoint?.id,
					latitude: selectedLocation.lat,
					longitude: selectedLocation.lng,
				})
			}
			onFinishFailed={onSaveFailed}
			onValuesChange={() => onDirty()}>
			<Form.Item
				label='Location Name'
				name='locationName'
				rules={[
					{
						required: true,
						message: 'Please enter location name',
					},
				]}>
				<Input />
			</Form.Item>
			<Form.Item
				label='Point'
				name='point'
				rules={[
					{
						validator: (_, value) => {
							if (!value) {
								if (!selectedLocation) {
									// eslint-disable-next-line prefer-promise-reject-errors
									return Promise.reject('Please select a location');
								}
							}
							return Promise.resolve();
						},
					},
				]}
				required>
				{isLoaded && googleMaps?.googleMapsKey?.key && (
					<MapWithAutocomplete
						setSearchInput={setSearchInput}
						setSelectedLocation={setSelectedLocation}
						setUnsavedChanges={setUnsavedChanges}
						autocomplete={autocomplete}
						selectedLocation={selectedLocation}
						setAutocomplete={setAutocomplete}
						searchInput={searchInput}
					/>
				)}
			</Form.Item>
			<Form.Item style={{ float: 'right' }}>
				<Button
					type='default'
					style={{ marginBottom: 32, marginRight: 8 }}
					onClick={() => onCancel(unsavedChanges)}>
					Cancel
				</Button>
				<Button
					type='primary'
					style={{ marginBottom: 32 }}
					htmlType='submit'
					disabled={!unsavedChanges}>
					{!wayPoint ? 'Save' : 'Update'}
				</Button>
			</Form.Item>
		</Form>
	);
};

export default WayPointForm;
