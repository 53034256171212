/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { Button, DatePicker, Form, Input, Select, Spin } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { generatePasscode } from '../../../Utils/random-hex-color';

const dateFormat = 'YYYY/MM/DD';

const PurchasedBundleForm = ({
	onSave,
	purchasedBundle,
	purchasedBundleId,
	onSaveFailed,
	onCancel,
	onDirty,
	unsavedChanges,
	setUnsavedChanges,
}) => {
	const { selectedPurchasedBundle } = useSelector((state) => state.purchasedBundles);
	const { bundles } = useSelector((state) => state.bundles);
	const [form] = Form.useForm();
	useEffect(() => {
		if (selectedPurchasedBundle?.purchasedBundle) {
			form.setFieldValue('bundleId', selectedPurchasedBundle?.purchasedBundle?.bundleId);
			form.setFieldValue('code', selectedPurchasedBundle?.purchasedBundle?.code);
			form.setFieldValue(
				'codeValidUntil',
				dayjs(selectedPurchasedBundle?.purchasedBundle?.codeValidUntil, dateFormat),
			);
			form.setFieldValue(
				'startDate',
				dayjs(selectedPurchasedBundle?.purchasedBundle?.startDate, dateFormat),
			);
			form.setFieldValue('buyerName', selectedPurchasedBundle?.purchasedBundle?.buyerName);
			form.setFieldValue(
				'buyerSurname',
				selectedPurchasedBundle?.purchasedBundle?.buyerSurname,
			);
			form.setFieldValue('buyerEmail', selectedPurchasedBundle?.purchasedBundle?.buyerEmail);
			form.setFieldValue('buyerPhone', selectedPurchasedBundle?.purchasedBundle?.buyerPhone);
		} else handleRefresh();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [purchasedBundle]);

	const [randomCode, setRandomCode] = useState(generatePasscode(9));

	// Function to handle refresh icon click
	const handleRefresh = () => {
		const newCode = generatePasscode(9);
		setRandomCode(newCode);
		form.setFieldValue('code', newCode);
	};

	const handleCodeChange = () => {
		setUnsavedChanges(true);
		handleRefresh();
	};

	return purchasedBundleId && !purchasedBundle ? (
		<Spin spinning />
	) : (
		<Form
			form={form}
			name='purchaseBundle'
			layout='vertical'
			autoComplete='off'
			initialValues={purchasedBundle}
			onFinish={onSave}
			onFinishFailed={onSaveFailed}
			onValuesChange={() => onDirty()}>
			<Spin spinning={selectedPurchasedBundle?.loading}>
				<Form.Item
					label='Bundle'
					name='bundleId'
					rules={[{ required: true, message: 'Please select a bundle' }]}>
					<Select placeholder='Please select a bundle'>
						{bundles?.bundleList?.map((bundle) => (
							<Select.Option key={bundle.id} value={bundle.id}>
								{bundle.name}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item
					label='Random Code'
					name='code'
					rules={[
						{
							required: true,
							message: 'Please Choose Code',
						},
					]}>
					<Input
						size='small'
						readOnly
						value={randomCode}
						suffix={
							<Button
								type='text'
								icon={<ReloadOutlined />}
								onClick={handleCodeChange}
							/>
						}
					/>
				</Form.Item>

				<Form.Item label='Code Validity' name='codeValidUntil'>
					<DatePicker
						format={dateFormat}
						style={{ width: '100%' }}
						disabledDate={(current) => {
							// Can not select days before today and today
							return current && current < dayjs().endOf('day');
						}}
					/>
				</Form.Item>

				<Form.Item
					label='Start Date'
					name='startDate'
					rules={[
						{
							required: true,
							message: 'Please Choose start date',
						},
					]}>
					<DatePicker
						format={dateFormat}
						style={{ width: '100%' }}
						disabledDate={(current) => {
							// Disable dates before today
							return current && current < dayjs().startOf('day');
						}}
					/>
				</Form.Item>
				<Form.Item
					label='Buyer Name'
					name='buyerName'
					rules={[{ required: true, message: 'Please input buyer name' }]}>
					<Input />
				</Form.Item>

				<Form.Item
					label='Buyer Surname'
					name='buyerSurname'
					rules={[{ required: true, message: 'Please input buyer surname' }]}>
					<Input />
				</Form.Item>

				<Form.Item
					label='Buyer Email'
					name='buyerEmail'
					rules={[{ required: true, message: 'Please input buyer email' }]}>
					<Input />
				</Form.Item>

				<Form.Item
					label='Buyer Phone'
					name='buyerPhone'
					rules={[{ required: true, message: 'Please input buyer phone' }]}>
					<Input />
				</Form.Item>

				<Form.Item style={{ float: 'right' }}>
					<Button
						type='default'
						style={{ marginBottom: 32, marginRight: 8 }}
						onClick={() => onCancel(unsavedChanges)}>
						Cancel
					</Button>
					<Button
						type='primary'
						style={{ marginBottom: 32 }}
						htmlType='submit'
						disabled={!unsavedChanges}>
						{!selectedPurchasedBundle?.purchasedBundle ? 'Save' : 'Update'}
					</Button>
				</Form.Item>
			</Spin>
		</Form>
	);
};

export default PurchasedBundleForm;
