/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/namespace */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */

import { Breadcrumb, Button, Card, Input, message, DatePicker } from 'antd';
import { useLoadScript } from '@react-google-maps/api';

import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { ReloadOutlined, MinusOutlined, FilterOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import { style } from '../../Styles';
import CustomTableComponents from '../Global/CustomTableComponents';
import ActionButton from '../Booking/ActionButton';
import CustomAvatar from '../Global/CustomAvatar/CustomAvatar';
import { getRandomGoogleMapsKey } from '../../redux/googleMapsKeySlice';
import { formatDateAndTime } from '../../Utils/date-utils';
import ImageComponent from '../Global/ImageComponent/ImageComponent';
import FilteringDrawer from './Filtering/FilteringDrawer';
import { getAllDrivers } from '../../redux/driverSlice';
import { getAllRiders } from '../../redux/riderSlice';
import { getAllBookingPools, resetDriverNavigation } from '../../redux/bookingPoolSlice';
import DetailsModalBookingPool from './DetailsModalBookingPool';
import DetailsModal from '../Booking/DetailsModal';
import { getSpanStyle } from '../../Utils/style-depending-on-status';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';
const libraries = ['core', 'places', 'geocoding', 'routes', 'geometry'];
const today = dayjs().startOf('day');
const tomorrow = dayjs().add(1, 'day').startOf('day');

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(utc);

const BookingPool = ({ role }) => {
	const [selectedBooking, setselectedBooking] = useState(null);
	const [isModalBookingOpen, setIsModalBookingOpen] = useState(false);
	const [makingApiCall, setMakingApiCall] = useState(false);
	const [dateRange, setDateRange] = useState([today, tomorrow]);
	const { googleMaps } = useSelector((state) => state.googleMapsKey);

	const { isLoaded } = useLoadScript({
		googleMapsApiKey: googleMaps?.googleMapsKey?.key,
		libraries,
	});
	const [isFilteringDrawerOpened, setIsFilteringDrawerOpened] = useState(false);

	const dispatch = useDispatch();
	const { bookingPools } = useSelector((state) => state.bookingPool);
	const [filter, setfilter] = useState('');
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedBookingPool, setSelectedBookingPool] = useState(null);
	const loadDriversAndRiders = () => {
		const promise = Promise.all([dispatch(getAllDrivers()), dispatch(getAllRiders())]);
		promise.then(() => {});
	};
	const openFilteringDrawer = () => {
		loadDriversAndRiders();
		setIsFilteringDrawerOpened(true);
	};

	const closeFilteringDrawer = () => {
		setIsFilteringDrawerOpened(false);
	};

	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		dispatch(resetDriverNavigation());
		setIsModalOpen(false);
	};

	const openModalBooking = () => {
		setIsModalBookingOpen(true);
	};

	const closeModalBooking = () => {
		setIsModalBookingOpen(false);
	};

	const fetchGoogleMapsKey = () => {
		dispatch(getRandomGoogleMapsKey())
			.then(() => {})
			.catch(() => {
				message.error('Error getting google maps key');
			});
	};

	const columns = [
		{
			title: 'ID',
			dataIndex: 'id',
			defaultSortOrder: 'ascend',
			sorter: (a, b) => a.id - b.id,
		},
		{
			title: 'Date',
			key: 'date',
			render: (record) => {
				return record?.created ? formatDateAndTime(record?.created) : <MinusOutlined />;
			},
			sorter: (a, b) => {
				const dateA = new Date(a.created);
				const dateB = new Date(b.created);
				return dateA.getTime() - dateB.getTime();
			},
		},
		{
			title: 'Driver',
			key: 'driver',
			render: (record) => {
				const fullName = `${record?.driver?.name} ${record?.driver?.surname} `;
				return record?.driver ? (
					<div style={{ display: 'flex', alignItems: 'center' }}>
						{record?.driver?.profilePicture ? (
							<ImageComponent size={40} imageId={record?.driver?.profilePicture} />
						) : (
							<CustomAvatar name={fullName} type='initials' />
						)}
						<span>{`${record?.driver?.name} ${record?.driver?.surname} `}</span>
						{!record.driver?.active ? (
							<span style={{ color: 'GrayText', fontSize: '12px' }}>(Inactive)</span>
						) : null}
					</div>
				) : (
					<MinusOutlined />
				);
			},
			defaultSortOrder: 'ascend',
			sorter: (a, b) => a?.driver?.name.length - b?.driver?.name.length,
		},

		{
			title: 'Status',
			key: 'status',
			render: (record) => {
				return (
					<span style={style.dataContainer}>
						<div style={getSpanStyle(record?.status)}>{record.status}</div>
					</span>
				);
			},
			sorter: {
				compare: (a, b) => a?.bookingStatus?.name.length - b?.bookingStatus?.name.length,
				multiple: 1,
			},
		},
		{
			title: 'Actions',
			key: 'actions',
			render: (text, record) => (
				<ActionButton
					record={record}
					onSeeDetails={openModal}
					setSelectedBooking={setSelectedBookingPool}
				/>
			),
		},
	];

	const fetchAllBookingPools = async () => {
		setMakingApiCall(true);
		// Set start to 00:00
		const start = dateRange[0].startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

		// Set end to 23:59
		const end = dateRange[1].endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

		dispatch(
			getAllBookingPools({
				start,
				end,
			}),
		)
			.unwrap()
			.then(() => {})
			.catch(() => {
				message.error('Error while fetching bookings');
			})
			.finally(() => {
				setMakingApiCall(false);
			});
	};
	useEffect(() => {
		fetchGoogleMapsKey();
	}, [dispatch]);

	useEffect(() => {
		fetchAllBookingPools();
	}, [dispatch, dateRange]);

	const data = bookingPools?.bookingPoolList ? Object.values(bookingPools.bookingPoolList) : [];

	const filtredData = data.filter((item) => {
		return (
			item?.driver?.name.toLowerCase()?.indexOf(filter.toLowerCase()) !== -1 ||
			item?.driver?.surname.toLowerCase()?.indexOf(filter.toLowerCase()) !== -1 ||
			item?.status.toLowerCase()?.indexOf(filter.toLowerCase()) !== -1
		);
	});

	const onGridDoubleClick = (record) => {
		setSelectedBookingPool(record);
		openModal();
	};

	const onDateRangeChange = (value) => {
		setDateRange(value);
	};

	return (
		<>
			<Card
				style={{
					margin: 16,
					maxHeight: '90%', // Set maximum width
					height: 'auto', // Allow width to be dynamic
				}}>
				<div style={style.inlineContent}>
					<div
						style={{
							display: 'flex',
							alignContent: 'center',
							alignItems: 'center',
							float: 'left',
							marginBottom: 8,
						}}>
						<Breadcrumb
							style={{ margin: '0 3em 0 0', fontSize: '1.1em' }}
							items={[
								{
									title: 'Booking Pools',
								},
							]}
						/>
						<Button type='default' size='small' onClick={() => fetchAllBookingPools()}>
							<ReloadOutlined />
						</Button>
					</div>
					<div
						style={{
							display: 'flex',
							alignContent: 'center',
							alignItems: 'center',
							float: 'center',
							marginBottom: 8,
						}}>
						<RangePicker
							onChange={onDateRangeChange}
							defaultValue={dateRange}
							format={dateFormat}
							style={{ width: '100%' }}
							allowClear={false}
						/>
					</div>
					<div
						style={{
							...style.inlineContent,
							float: 'right',
							marginBottom: 8,
						}}>
						<Button
							icon={<FilterOutlined />}
							onClick={openFilteringDrawer}
							style={{ marginRight: '0.5em', marginTop: '-9px' }}>
							Filter
						</Button>
						<Input.Search
							placeholder='Search'
							style={{ margin: '0 8px 8px 0' }}
							value={filter}
							onChange={(e) => setfilter(e.target.value)}
						/>
					</div>
				</div>
				<CustomTableComponents
					isMakingApiCall={makingApiCall}
					columns={columns}
					dataArray={filtredData}
					handleDoubleClickRow={(record) => {
						onGridDoubleClick(record);
					}}
					handleSelectedRow={() => {}}
				/>
			</Card>

			{isModalOpen && (
				<DetailsModalBookingPool
					isModalOpen={isModalOpen}
					closeModal={closeModal}
					booking={selectedBookingPool?.bookings[0]}
					getSpanStyle={getSpanStyle}
					isLoaded={isLoaded}
					bookingPool={selectedBookingPool}
					setselectedBooking={setselectedBooking}
					selectedBooking={selectedBooking}
					openModalBooking={openModalBooking}
					role={role}
				/>
			)}

			{isModalBookingOpen && (
				<DetailsModal
					isModalOpen={isModalBookingOpen}
					closeModal={closeModalBooking}
					getSpanStyle={getSpanStyle}
					isLoaded={isLoaded}
					role={role}
					bookingId={selectedBooking?.id}
				/>
			)}

			<FilteringDrawer
				closeFilteringDrawer={closeFilteringDrawer}
				isFilteringDrawerOpened={isFilteringDrawerOpened}
				resetFilter={fetchAllBookingPools}
				dateRange={dateRange}
			/>
		</>
	);
};

export default BookingPool;
