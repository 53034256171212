/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { Drawer, Spin, message } from 'antd';
import { useEffect } from 'react';
// import { FilePdfOutlined } from '@ant-design/icons'; // Import the file icon from Ant Design

import ShowConfirmClose from '../Modals/ShowConfirmClose';
import {
	addNewFleetOperator,
	getFleetOperatorById,
	updateFleetOperator,
} from '../../redux/fleetOperatorSlice';
import { downloadImage, uploadFleetOperatorDocument } from '../../redux/fileSlice';
import FleetOperatorForm from './FleetOperatorForm';

const FleetOperatorDrawer = ({ onClose, fleetId, handleDelete }) => {
	const [unsavedChanges, setUnsavedChanges] = useState(false);
	const dispatch = useDispatch();
	const [makingApiCall, setMakingApiCall] = useState(false);
	const [fleet, setFleet] = useState(undefined);

	const [fileList, setFileList] = useState([]);
	const [uploading, setUploading] = useState(false);
	const [isFileChosen, setIsFileChosen] = useState(false);

	const handleUpload = (callback, values) => {
		if (fileList.length !== 0 && isFileChosen) {
			setUploading(true);
			dispatch(uploadFleetOperatorDocument(fileList[0]?.originFileObj))
				.unwrap()
				.then((res) => {
					setFileList([]);
					callback(values, res.id);
				})
				.catch(() => {
					setMakingApiCall(false);
				})
				.finally(() => {
					setUploading(false);
				});
		} else callback(values, null);
	};
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const handleClose = useCallback(
		(hasUnsavedChanges) => {
			if (hasUnsavedChanges && !confirmationOpen) {
				setConfirmationOpen(true); // Mark that the confirmation modal is open
				ShowConfirmClose(() => {
					setConfirmationOpen(false); // Reset when the confirmation modal is closed
					onClose();
				});
			} else {
				onClose();
			}
		},
		[onClose, confirmationOpen],
	);

	const handleDirty = useCallback(() => {
		setUnsavedChanges(true);
	}, []);

	const saveFleet = async (values, documentId) => {
		const action = fleetId
			? updateFleetOperator({
					...values,
					id: fleetId,
					documentId: isFileChosen ? documentId : fleet.documentId,
			  })
			: addNewFleetOperator({
					...values,
					documentId,
			  });
		dispatch(action)
			.unwrap()
			.then(() => {
				message.success('Fleet operator saved successfully');
				setUnsavedChanges(false);
				onClose();
			})
			.catch(() => {
				message.error("Couldn't save fleet operator");
			})
			.finally(() => {
				setMakingApiCall(false);
			});
	};

	const onSaveFailed = (error) => {
		console.log('Failed:', error);
	};
	const handleSave = async (values) => {
		setMakingApiCall(true);
		await handleUpload(saveFleet, values); // Wait for the upload to complete and update the pictureId
	};
	const onSave = (values) => {
		handleSave(values);
	};

	const fetchImage = async (imageId) => {
		setMakingApiCall(true);

		return dispatch(downloadImage(imageId))
			.unwrap()
			.then((data) => {
				const imgUrl = URL.createObjectURL(data);
				setMakingApiCall(false);
				return imgUrl;
			})
			.catch((error) => {
				setMakingApiCall(false);
				throw error;
			});
	};

	useEffect(() => {
		const abortController = new AbortController();

		if (fleetId) {
			setMakingApiCall(true);
			const calls = [dispatch(getFleetOperatorById(fleetId))];
			Promise.all(calls)
				.then((res) => {
					if (fleetId) {
						setFleet(res[0].payload);
						if (res[0].payload?.documentId && res[0].payload?.documentId !== 0) {
							fetchImage(res[0].payload?.documentId).then((imgUrl) => {
								setFileList([
									{
										uid: '-1',
										name: 'Fleet Operator Document',
										status: 'done',
										url: imgUrl,
									},
								]);
							});
						}
					}
				})
				.catch(() => {
					onClose();
				})
				.finally(() => {
					setMakingApiCall(false);
				});
		}
		return () => abortController.abort();
	}, [fleetId, dispatch]);
	return (
		<Drawer
			width={500}
			title={!fleetId ? 'Add Fleet Operator' : 'Update Fleet Operator'}
			placement='right'
			onClose={() => handleClose(unsavedChanges)}
			open>
			<Spin size='large' spinning={makingApiCall} style={{ marginTop: 64 }} delay={500}>
				{!makingApiCall && (
					<FleetOperatorForm
						fleet={fleet}
						onSave={onSave}
						onSaveFailed={onSaveFailed}
						onDeleteFleet={handleDelete}
						onCancel={handleClose}
						onDirty={handleDirty}
						unsavedChanges={unsavedChanges}
						setUnsavedChanges={setUnsavedChanges}
						fileList={fileList}
						setFileList={setFileList}
						handleUpload={handleUpload}
						uploading={uploading}
						setIsFileChosen={setIsFileChosen}
						onDelete={handleDelete}
					/>
				)}
			</Spin>
		</Drawer>
	);
};

export default FleetOperatorDrawer;
