import React from 'react';

const ReviewNotSubmited = () => {
	return (
		<div
			style={{
				padding: '20px',
				display: 'flex',
				textAlign: 'center',
				alignItems: 'center',
			}}>
			<img
				width='45%'
				src={`${process.env.PUBLIC_URL}/images/feedback.svg`}
				alt='feedback'
				style={{ marginRight: '2em' }}
			/>
			<h4>Review Not Submitted yet</h4>
		</div>
	);
};

export default ReviewNotSubmited;
