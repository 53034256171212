/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
import { Button, Dropdown, Menu, Modal } from 'antd';
import React, { useState } from 'react';

import {
	MoreOutlined,
	EditOutlined,
	DeleteOutlined,
	StopOutlined,
	CheckOutlined,
	ExclamationCircleFilled,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';

const ActionButton = ({ record, onDelete, onRowClick, onChangeStatus, recordName }) => {
	const [menuVisible, setMenuVisible] = useState(false);
	const { Brand } = useSelector((state) => state.brand);

	const handleMenuClick = ({ key }) => {
		const objToUpdate = {
			...record,
			sourceLocationId: record.sourceLocation.id,
			destinationLocationId: record.destinationLocation.id,
			vehicleTypeId: record.vehicleType.id,
		};
		// switch case depending on the key
		switch (key) {
			case 'edit':
				onRowClick(record);
				break;
			case 'delete':
				Modal.confirm({
					title: (
						<>
							Are you sure you want to delete <b>{recordName || record.id}</b> ?
						</>
					),
					icon: (
						<ExclamationCircleFilled
							style={{ color: Brand?.brandData?.webPrimary || 'orange' }}
						/>
					),
					okText: 'Yes', // Customize OK button text
					cancelText: 'No', // Customize Cancel button text
					cancelButtonProps: {
						style: {
							color: Brand?.brandData?.webPrimary || 'orange',
							backgroundColor: 'white',
							borderColor: Brand?.brandData?.webPrimary || 'orange',
						},
					},
					okButtonProps: {
						style: {
							color: 'white',
							backgroundColor: Brand?.brandData?.webPrimary || 'orange',
						},
					},
					onOk: () => {
						onDelete(record.id);
					},
					onCancel: () => {},
				});
				break;
			case 'disable':
				Modal.confirm({
					title: (
						<>
							Are you sure you want to disable <b>{recordName || record.id}</b> ?
						</>
					),
					icon: (
						<ExclamationCircleFilled
							style={{ color: Brand?.brandData?.webPrimary || 'orange' }}
						/>
					),
					okText: 'Yes', // Customize OK button text
					cancelText: 'No', // Customize Cancel button text
					cancelButtonProps: {
						style: {
							color: Brand?.brandData?.webPrimary || 'orange',
							backgroundColor: 'white',
							borderColor: Brand?.brandData?.webPrimary || 'orange',
						},
					},
					okButtonProps: {
						style: {
							color: 'white',
							backgroundColor: Brand?.brandData?.webPrimary || 'orange',
						},
					},
					onOk: () => {
						onChangeStatus({
							...objToUpdate,
							isActive: false,
						});
					},
					onCancel: () => {},
				});
				break;
			case 'enable':
				Modal.confirm({
					title: (
						<>
							Are you sure you want to enable <b>{recordName || record.id}</b>?
						</>
					),
					icon: (
						<ExclamationCircleFilled
							style={{ color: Brand?.brandData?.webPrimary || 'orange' }}
						/>
					),
					okText: 'Yes', // Customize OK button text
					cancelText: 'No', // Customize Cancel button text
					cancelButtonProps: {
						style: {
							color: Brand?.brandData?.webPrimary || 'orange',
							backgroundColor: 'white',
							borderColor: Brand?.brandData?.webPrimary || 'orange',
						},
					},
					okButtonProps: {
						style: {
							color: 'white',
							backgroundColor: Brand?.brandData?.webPrimary || 'orange',
						},
					},
					onOk: () => {
						onChangeStatus({
							...objToUpdate,
							isActive: true,
						});
					},
					onCancel: () => {},
				});
				break;
			default:
				break;
		}
		setMenuVisible(false);
	};

	const menu = (
		<Menu onClick={handleMenuClick}>
			<Menu.Item key='edit' icon={<EditOutlined />}>
				Edit
			</Menu.Item>
			<Menu.Item key='delete' icon={<DeleteOutlined />} danger>
				Delete
			</Menu.Item>
			{record.isActive ? (
				<Menu.Item key='disable' icon={<StopOutlined />} danger>
					Disable
				</Menu.Item>
			) : (
				<Menu.Item key='enable' icon={<CheckOutlined />}>
					Enable
				</Menu.Item>
			)}
		</Menu>
	);

	return (
		<Dropdown
			overlay={menu}
			trigger={['click']}
			visible={menuVisible}
			onVisibleChange={(visible) => {
				setMenuVisible(visible);
			}}>
			<Button
				type='text'
				icon={<MoreOutlined />}
				onClick={(event) => event.stopPropagation()}
			/>
		</Dropdown>
	);
};

export default ActionButton;
