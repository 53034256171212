import { Modal } from 'antd';

export default (onOk) => {
	Modal.confirm({
		title: 'Are you sure you want to continue?',
		content: 'All unsaved changes will be lost.',
		autoFocusButton: null,
		okText: 'Continue',
		cancelText: 'Cancel',
		cancelButtonProps: {
			style: {
				color: '#faa200',
				backgroundColor: 'white',
				borderColor: '#faa200',
			},
		},
		okButtonProps: {
			style: { color: 'white', backgroundColor: '#faa200' },
		},
		onOk() {
			onOk();
		},
		onCancel() {},
	});
};
