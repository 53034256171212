/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import {
	Button,
	Col,
	Divider,
	Form,
	Input,
	Radio,
	Row,
	Select,
	Spin,
	Typography,
	message,
} from 'antd';
import { SaveOutlined, LoadingOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'antd/es/form/Form';
import { countryPhoneCode } from '../../Utils/country-phone-codes';
import AvatarWithUpload from '../Global/CustomAvatar/AvatarWithUpload';
import { permissionAdmin } from '../../Utils/Constants/utils';
import getCountryFromIP from '../../Utils/GettingCountryFromIP';

const { updateProfile } = require('../../redux/authSlice');

const optionsGender = [
	{ label: 'Male', value: 'Male' },
	{ label: 'Female', value: 'Female' },
	{ label: 'Undisclosed', value: 'Undisclosed' },
];
const { Title, Paragraph } = Typography;
const gridStyle = {
	width: '33%',
	textAlign: 'center',
};
const validateMessages = {
	types: {
		email: '${label} is not a valid email',
	},
};

const ProfileForm = () => {
	const [form] = useForm();
	const [countryFromIp, setCountryFromIp] = useState('Malta');
	const [phoneCodeFromIp, setPhoneCodeFromIp] = useState('356');

	useEffect(() => {
		getCountryFromIP().then((country) => {
			setCountryFromIp(country);
			// Find the corresponding phone code for the country
			const foundCountry = countryPhoneCode.find((c) => c.en === country);
			if (foundCountry) {
				form.setFieldsValue({
					phoneCountryCode: auth?.profile
						? auth?.profile?.phoneCountryCode
						: foundCountry.phoneCode,
					country: auth?.profile ? auth?.profile?.country : foundCountry.en,
				});
				setPhoneCodeFromIp(foundCountry.phoneCode);
				setCountryFromIp(foundCountry.en);
			}
		});
	}, []);
	const { auth } = useSelector((state) => state.auth);
	const [makingApiCall, setMakingApiCall] = useState(false);
	const dispatch = useDispatch();
	const onSave = (values) => {
		const data = {
			...values,
			profilePhoto: auth?.profile?.profilePicture,
			id: auth?.profile?.id,
			role: permissionAdmin,
		};
		updateProfileAction(data);
	};
	const onSaveFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};
	const onDirty = (e) => {
		console.log('dirty', e);
	};
	const [selectedGender, setSelectedGender] = useState('');
	const onChangeGender = ({ target: { value } }) => {
		setSelectedGender(value);
	};

	const updateProfileAction = (values) => {
		setMakingApiCall(true);
		dispatch(updateProfile(values))
			.then(() => {
				message.success('Profile updated successfully');
			})
			.catch(() => {
				message.error('An Error Occured while updating profile');
			})
			.finally(() => {
				setMakingApiCall(false);
			});
	};

	return (
		<Spin
			size='large'
			indicator={<LoadingOutlined style={{ fontSize: 54 }} spin />}
			tip={
				<div>
					<h2>Loading...</h2>
				</div>
			}
			spinning={makingApiCall}>
			<Form
				form={form}
				name='profile'
				layout='vertical'
				autoComplete='off'
				initialValues={auth?.profile}
				validateMessages={validateMessages}
				onFinish={onSave}
				onFinishFailed={onSaveFailed}
				onValuesChange={(e) => onDirty(e)}>
				<Row gutter={16}>
					<Col style={gridStyle}>
						{' '}
						<AvatarWithUpload
							type='initials'
							name={`${auth?.profile?.name} ${auth?.profile?.surname}`}
							size={200}
							profilePictureId={auth?.profile?.profilePicture || null}
						/>
						<Title
							level={4}>{`${auth?.profile?.name} ${auth?.profile?.surname}`}</Title>
						<Paragraph
							buttonStyle='solid'
							copyable={{
								text: `${auth?.profile?.email}`,
							}}>{`${auth?.profile?.email}`}</Paragraph>
					</Col>
					<Col style={gridStyle}>
						<Form.Item
							label='Email'
							name='email'
							disabled
							rules={[
								{
									required: true,
									message: 'Please input you Email',
									type: 'email',
								},
							]}>
							<Input disabled />
						</Form.Item>
						<Form.Item
							label='Name'
							name='name'
							rules={[{ required: true, message: 'Please input your name' }]}>
							<Input />
						</Form.Item>
						<Form.Item
							label='Surname'
							name='surname'
							rules={[
								{
									required: true,
									message: 'Please input your surname',
								},
							]}>
							<Input />
						</Form.Item>

						<Form.Item
							label='Phone Country Code'
							name='phoneCountryCode'
							style={{ textAlign: 'left' }}
							initialValue={auth?.profile?.phoneCountryCode || phoneCodeFromIp}
							rules={[
								{
									required: true,
									message: 'Please select phone country code',
								},
							]}>
							<Select
								size='medium'
								showArrow
								showSearch
								optionFilterProp='filterBy'
								allowClear
								optionLabelProp='label'
								popupMatchSelectWidth={false}
								getPopupContainer={(trigger) => trigger.parentNode}>
								{countryPhoneCode.map((p) => (
									<Select.Option
										key={p.en}
										filterBy={`${p.en} +${p.phoneCode}`}
										label={
											<>
												<span role='img'>{p.emoji}</span>{' '}
												{` +(${p.phoneCode}) ${p.en}`}{' '}
												{/* Added a space after the closing parenthesis */}
											</>
										}
										value={p.phoneCode}>
										<span role='img'>{p.emoji}</span>
										{`  ${p.en} `}{' '}
										<span style={{ color: 'gray' }}>{`+(${p.phoneCode})`}</span>
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					</Col>
					<Col style={gridStyle}>
						<Form.Item
							label='Phone'
							name='phone'
							rules={[
								{ required: true, message: 'Please input your Phone' },
								{
									pattern: /^\d+$/,
									message: 'Phone number must contain only numbers',
								},
							]}>
							<Input />
						</Form.Item>
						<Form.Item
							style={{ textAlign: 'left' }}
							label='Country'
							name='country'
							initialValue={auth?.profile?.country || countryFromIp}
							rules={[
								{
									required: true,
									message: 'Please select your country',
								},
							]}>
							<Select
								size='medium'
								showArrow
								showSearch
								optionFilterProp='filterBy'
								allowClear
								optionLabelProp='label'
								popupMatchSelectWidth={false}
								onChange={
									// eslint-disable-next-line no-shadow
									(value) => {
										const foundCountry = countryPhoneCode.find(
											(c) => c.en === value,
										);
										if (foundCountry) {
											form.setFieldsValue({
												phoneCountryCode: foundCountry.phoneCode,
											});
											setPhoneCodeFromIp(foundCountry.phoneCode);
											setCountryFromIp(foundCountry.en);
										}
									}
								}
								getPopupContainer={(trigger) => trigger.parentNode}>
								{countryPhoneCode.map((p) => (
									<Select.Option
										key={p.en}
										filterBy={`${p.en}`}
										label={
											<>
												<span role='img'>{p.emoji}</span> {p.en}
											</>
										}>
										<span role='img'>{p.emoji}</span> {p.en}{' '}
										<span style={{ color: 'gray' }} />
									</Select.Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item
							style={{ textAlign: 'left' }}
							label='Gender'
							name='gender'
							rules={[{ required: true, message: 'Please input your Phone' }]}>
							<Radio.Group
								options={optionsGender}
								onChange={onChangeGender}
								value={selectedGender}
								optionType='button'
								buttonStyle='solid'
							/>
						</Form.Item>
						<Divider />
						<Form.Item style={{ textAlign: 'right' }}>
							<Button
								type='primary'
								htmlType='submit'
								style={{ textAlign: 'right' }}
								icon={<SaveOutlined />}>
								Save
							</Button>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Spin>
	);
};

export default ProfileForm;
