/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable import/prefer-default-export */
import { Button, Col, Empty, message, Modal, Row } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateFareSurcharge } from '../../redux/vehicleTypeSlice';
import { style } from '../../Styles';
import { PEAK_SURCHARGE } from '../../Utils/Constants/utils';
import { CardFareSurcharge } from './CardFareSurcharge';

export const FareSurchargeModal = ({
	isModalOpen,
	fareSurchargeData,
	type,
	vehicleTypeId,
	setIsModalOpen,
	handleCancel,
	onFsChange,
}) => {
	const [isMakingApiCall, setMakingApiCall] = useState(false);
	const [isSaveDisabled, setSaveDisabled] = useState(true);

	const dispatch = useDispatch();

	const [faresSurcharges, setFareSurcharge] = useState([]);

	const addNewComponentPeakSurchage = () => {
		setFareSurcharge([
			...faresSurcharges,
			{
				day: '',
				endTime: '',
				fareSurchargeType: type,
				startTime: '',
				surcharge: '',
			},
		]);
	};
	useEffect(() => {
		if (fareSurchargeData) {
			setFareSurcharge(fareSurchargeData);
		}
	}, [fareSurchargeData]);

	useEffect(() => {
		if (
			faresSurcharges.find(
				(item) =>
					item.day === '' ||
					item.endTime === '' ||
					item.startTime === '' ||
					item.surcharge === '',
			) === undefined
		) {
			setSaveDisabled(false);
		} else {
			setSaveDisabled(true);
		}
	}, [faresSurcharges]);

	const handleSave = () => {
		if (vehicleTypeId) {
			setMakingApiCall(true);
			const data = faresSurcharges.map((item) => {
				if (item.startTime.length > 5) {
					const startTime = dayjs(item.startTime).format('HH:mm');
					item = { ...item, startTime };
				}
				if (item.endTime.length > 5) {
					const endTime = dayjs(item.endTime).format('HH:mm');
					item = { ...item, endTime };
				}
				return item;
			});
			const request = {
				id: vehicleTypeId,
				data,
			};
			dispatch(updateFareSurcharge(request))
				.unwrap()
				.then(() => {
					message.success('Fare Surcharge updated successfully');
				})
				.catch(() => {
					message.error('Failed to update Fare Surcharge');
				})
				.finally(() => {
					setMakingApiCall(false);
				});
		} else {
			const data = faresSurcharges.map((item) => {
				if (item.startTime.length > 5) {
					const startTime = dayjs(item.startTime).format('HH:mm');
					item = { ...item, startTime };
				}
				if (item.endTime.length > 5) {
					const endTime = dayjs(item.endTime).format('HH:mm');
					item = { ...item, endTime };
				}
				return item;
			});
			onFsChange(data);
		}
		setIsModalOpen(false);
	};

	const updateStateFaresCharges = (newFaresSurcharges) => {
		setFareSurcharge(newFaresSurcharges);
	};

	return (
		<Modal
			className='ant-modal-my-modal-class'
			open={isModalOpen}
			onOk={handleSave}
			onCancel={handleCancel}
			width={1100}
			style={{ zIndex: 1100 }}
			maskStyle={{ zIndex: 1000 }}
			getContainer={() => document.querySelector('.ant-drawer-content')}
			footer={[
				<Button key='back' onClick={handleCancel}>
					Cancel
				</Button>,
				<Button
					key='submit'
					type='primary'
					loading={isMakingApiCall}
					onClick={handleSave}
					disabled={isSaveDisabled}>
					Save
				</Button>,
			]}>
			<Row>
				<Col span={24} style={style.headerModal}>
					<h3>{type === PEAK_SURCHARGE ? 'Peak Surcharge' : 'Night Shift'}</h3>
					<Button onClick={() => addNewComponentPeakSurchage()}>
						Add {type === PEAK_SURCHARGE ? 'Peak Surcharge' : 'Night Shift'}
					</Button>
				</Col>
			</Row>
			<Row style={{ maxHeight: '60vh', overflowY: 'scroll' }}>
				{faresSurcharges.length === 0 ? (
					<Col span={24}>
						<Empty description='No Peak surchage' />
					</Col>
				) : (
					faresSurcharges?.map((item, index) => {
						return (
							<CardFareSurcharge
								isOpen={isModalOpen}
								key={index}
								index={index}
								fareSurcharge={item}
								faresSurcharges={faresSurcharges}
								updateFaresSurcharge={updateStateFaresCharges}
							/>
						);
					})
				)}
			</Row>
		</Modal>
	);
};
