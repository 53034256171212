/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/default */
/* eslint-disable import/namespace */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-extraneous-dependencies */
import { Badge, Card, Col, Modal, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CloseOutlined } from '@ant-design/icons';
import { formatDateAndTime } from '../../Utils/date-utils';
import BookingList from './BookingList';
import DriverCard from './DriverCard';
import { getDriverNavigation } from '../../redux/bookingPoolSlice';
import BookingPoolMaps from './BookingPoolMap';

const DetailsModalBookingPool = ({
	closeModal,
	isModalOpen,
	booking,
	getSpanStyle,
	isLoaded,
	bookingPool,
	selectedBooking,
	setselectedBooking,
	openModalBooking,
	role,
}) => {
	const [makingApiCall] = useState(false);

	const { googleMaps } = useSelector((state) => state.googleMapsKey);
	const distpatch = useDispatch();
	const fetchDriverNavigation = async () => {
		distpatch(getDriverNavigation(bookingPool?.id));
	};

	useEffect(() => {
		if (bookingPool?.id) {
			fetchDriverNavigation();
		}
	}, [bookingPool?.id]);

	return (
		<Modal
			style={{ top: 10 }}
			width={800}
			title={
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}>
					<div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
							}}>
							<h3
								style={{
									marginRight: '0.5em',
									marginBottom: '0',
									marginTop: '0',
								}}>{`${bookingPool?.id}. Booking Pool Details`}</h3>
							<h5
								style={{
									margin: '0',
									fontSize: '0.8em',
									color: '#9E9E9E',
								}}>
								{formatDateAndTime(bookingPool?.created)}
							</h5>
						</div>
						{/* Tax component goes here */}
					</div>
					<div
						style={{
							width: '48%',
							height: '50px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'flex-end',
						}}>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'end',
								justifyContent: 'flex-end',
							}}>
							<Badge
								className='site-badge-count-109'
								count={bookingPool?.status}
								style={{
									backgroundColor: getSpanStyle(bookingPool?.status)
										?.backgroundColor,
									color: getSpanStyle(bookingPool?.status)?.color,
									borderColor: getSpanStyle(bookingPool?.status)?.borderColor,
									fontWeight: 'bold',
									// marginRight: '0.5em',
								}}
							/>
							{booking?.bookingStatus.name === 'CANCELLED' && (
								<h5
									style={{
										margin: '0',
										fontSize: '0.8em',
										color: '#9E9E9E',
									}}>
									{booking?.cancellationHistory?.cancellationReason?.reason}
								</h5>
							)}
						</div>

						<CloseOutlined
							style={{
								marginLeft: '0.9em',
								color: 'white', // Set the color to white
								backgroundColor: '#ff3232', // Set the background color to red
								borderRadius: '20%', // Make it a circle with border radius
								cursor: 'pointer', // Add a pointer cursor for better UX
								transition: 'color 0.3s, transform 0.3s', // Add smooth transitions
							}}
							onClick={closeModal}
							onMouseEnter={(e) => {
								e.target.style.transform = 'scale(1.2)'; // Scale up on hover
							}}
							onMouseLeave={(e) => {
								e.target.style.transform = 'scale(1)'; // Scale back down on hover out
							}}
						/>
					</div>
				</div>
			}
			open={isModalOpen}
			onOk={closeModal}
			onCancel={closeModal}
			closable={false}
			footer={null}>
			<Spin spinning={makingApiCall && !googleMaps?.googleMapsKey?.key} tip='Loading...'>
				<Card>
					<Row>
						<Col span={24} style={{ marginRight: '20px' }}>
							{isLoaded && googleMaps?.googleMapsKey?.key && (
								<BookingPoolMaps
									pickup={booking?.pickUp}
									dropOff={booking?.dropOff}
								/>
							)}
						</Col>
					</Row>
					<Row>
						<Col span={11} style={{ marginRight: '20px' }}>
							<h3
								style={{
									margin: '0.7em 0 0.7em 0.5em',
								}}>
								Driver Information
							</h3>
							<DriverCard
								assigned={bookingPool?.driver}
								data={bookingPool?.driver}
								type='Driver'
								totalEarning={
									bookingPool?.bookings?.reduce(
										// eslint-disable-next-line no-unsafe-optional-chaining
										(acc, curr) => acc + curr?.priceBreakdown?.totalFare,
										0,
									) || 0
								}
							/>
						</Col>

						<Col span={12}>
							<h3
								style={{
									margin: '0.7em 0 0.7em 0.5em',
								}}>
								Bookings List
							</h3>
							<BookingList
								bookings={bookingPool?.bookings}
								selectedBooking={selectedBooking}
								setselectedBooking={setselectedBooking}
								openModalBooking={openModalBooking}
								role={role}
							/>
						</Col>
					</Row>
				</Card>
			</Spin>
		</Modal>
	);
};

export default DetailsModalBookingPool;
