import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../axios';
import { arrayToObject } from '../Utils/ArrayToObject';
import { permissionRider, tenantId } from '../Utils/Constants/utils';

const initialState = {
	Riders: { loading: false, ridersList: [] },
	SelectedRider: { loading: false, rider: null },
};

export const getAllRiders = createAsyncThunk('Rider/getAllRiders', async () => {
	const body = {
		role: 'RIDER',
		tenantId: 1,
	};
	try {
		const response = await axios.patch(`riders`, body);
		const data = arrayToObject(response.data, 'id');
		return data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const addNewRider = createAsyncThunk('Rider/addNewRider', async (data) => {
	const tmpRider = data.rider;
	const role = permissionRider;
	const rider = { ...tmpRider, tenantId, role };
	try {
		const response = await axios.post(`riders`, rider);
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const getRiderById = createAsyncThunk('Rider/getRiderById', async (data) => {
	try {
		const response = await axios.get(`riders/${data.riderId}`);
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const deleteRider = createAsyncThunk('Rider/deleteRider', async (data) => {
	try {
		await axios.delete(`riders/${data.riderId}/RIDER`);
		return data.riderId;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const updateRiderData = createAsyncThunk('Rider/updateRider', async (data) => {
	try {
		const role = permissionRider;
		const rider = { ...data.rider, role };
		const response = await axios.put(`riders/${data.riderId}`, rider);
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const changeRiderStatus = createAsyncThunk('Rider/changeRiderStatus', async (data) => {
	try {
		await axios.put(`users/${data.userId}/status`, { active: data.active });
		return data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const riderSlice = createSlice({
	name: 'Rider',
	initialState,
	reducers: {},
	extraReducers: {
		[getAllRiders.pending]: (state) => {
			state.Riders.loading = true;
		},
		[getAllRiders.rejected]: (state) => {
			state.Riders.loading = false;
		},
		[getAllRiders.fulfilled]: (state, action) => {
			state.Riders.loading = false;
			state.Riders.ridersList = Object.values(action.payload);
		},
		[addNewRider.pending]: (state) => {
			state.Riders.loading = true;
		},
		[addNewRider.rejected]: (state) => {
			state.Riders.loading = false;
		},
		[addNewRider.fulfilled]: (state, action) => {
			state.Riders.loading = false;
			state.Riders.ridersList.push(action.payload);
		},
		[getRiderById.pending]: (state) => {
			state.SelectedRider.loading = true;
		},
		[getRiderById.rejected]: (state) => {
			state.SelectedRider.loading = false;
		},
		[getRiderById.fulfilled]: (state, action) => {
			state.SelectedRider.loading = false;
			state.SelectedRider.rider = action.payload;
		},
		[deleteRider.pending]: (state) => {
			state.Riders.loading = true;
		},
		[deleteRider.rejected]: (state) => {
			state.Riders.loading = false;
		},
		[deleteRider.fulfilled]: (state, action) => {
			state.Riders.loading = false;
			state.Riders.ridersList = state.Riders.ridersList.filter((item) => {
				return item.id !== action.payload;
			});
		},
		[updateRiderData.pending]: (state) => {
			state.Riders.loading = true;
		},
		[updateRiderData.rejected]: (state) => {
			state.Riders.loading = false;
		},
		[updateRiderData.fulfilled]: (state, action) => {
			state.Riders.loading = false;
			state.Riders.ridersList = state.Riders.ridersList.map((item) => {
				if (item.id === action.payload.id) {
					item = action.payload;
					return item;
				}
				return item;
			});
		},
		[changeRiderStatus.pending]: (state) => {
			state.Riders.loading = true;
		},
		[changeRiderStatus.fulfilled]: (state, action) => {
			state.Riders.loading = false;
			state.Riders.ridersList = state.Riders.ridersList.map((item) => {
				if (item.id === action.payload.userId) {
					item = { ...item, active: action.payload.active };
					return item;
				}
				return item;
			});
		},
	},
});

export default riderSlice.reducer;
