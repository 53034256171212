import React from 'react';
import { Card, Tabs } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import ProfileForm from './ProfileForm';
import ChangePasswordForm from './ChangePasswordForm';

const Profile = () => {
	return (
		<Card title='Profile' style={{
					margin: 16,
					maxHeight: '90%', // Set maximum width
					height: 'auto', // Allow width to be dynamic
				}}>
			<Tabs
				style={{ minHeight: '70vh' }}
				tabPosition='left'
				items={[
					{
						label: (
							<div>
								<UserOutlined />
								<span>Personal Informations</span>
							</div>
						),
						key: '1',
						children: <ProfileForm />,
					},
					{
						label: (
							<div>
								<LockOutlined />
								<span>Change Password</span>
							</div>
						),
						key: '2',
						children: <ChangePasswordForm />,
					},
				]}
			/>
		</Card>
	);
};

export default Profile;
