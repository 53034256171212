/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Breadcrumb, Button, Card, Input, message } from 'antd';
import { ReloadOutlined, EyeOutlined } from '@ant-design/icons';
import {
	deleteCorporate,
	getAllCorporates,
	getCorporateById,
	resetSelectedCorporate,
} from '../../redux/corporateSlice';
import { style } from '../../Styles';
import CustomTableComponents from '../Global/CustomTableComponents';
import CorporateDrawer from './CorporateDrawer';

import ActionButton from '../Global/ActionsButton';
import CorporateModal from './CorporateModal';
import { SHARED_PRIVATE } from '../../Utils/Constants/utils';

const corporateModalTitle = {
	riders: 'Riders List',
	schedule: 'Schedule',
	allowedAreas: 'Allowed Areas',
	discount: 'Discount',
	admins: 'Admins List',
};

const Corporate = ({ role }) => {
	const { corporates } = useSelector((state) => state.corporates);
	const [filter, setFilter] = useState('');
	const [isDrawerOpened, setIsDrawerOpened] = useState(false);
	const [corporateId, setCorporateId] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [title, setTitle] = useState('');

	const closeDrawer = () => {
		setIsDrawerOpened(false);
		setCorporateId(null);
	};

	const openDrawer = (record) => {
		setIsDrawerOpened(true);
		if (record) setCorporateId(record.id);
	};
	const dispatch = useDispatch();
	const fetchCorporates = () => {
		dispatch(getAllCorporates());
	};

	const openModal = (titleType, selectedCorporateId) => {
		dispatch(getCorporateById(selectedCorporateId));
		setIsModalOpen(true);
		setTitle(corporateModalTitle[titleType]);
		setCorporateId(selectedCorporateId);
	};

	const onModalClose = () => {
		dispatch(resetSelectedCorporate());
		setIsModalOpen(false);
		setTitle('');
		setCorporateId(null);
	};

	useEffect(() => {
		fetchCorporates();
	}, [dispatch]);

	const handleDelete = (id, calbback) => {
		dispatch(deleteCorporate(id))
			.unwrap()
			.then(() => {
				message.success('Make deleted successfully');
				calbback();
			})
			.catch(() => {});
	};

	const columns = [
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id',
		},
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Applied For',
			dataIndex: 'appliedFor',
			key: 'appliedFor',
			render: (record) => {
				return <span>{record === SHARED_PRIVATE ? 'SHARED & PRIVATE' : record}</span>;
			}
		},
		{
			title: 'Admins',
			key: 'admins',
			render: (record) => {
				return (
					<span style={style.btnView}>
						{record?.admins?.length > 0 ? (
							<Button
								type='default'
								style={{ height: 30 }}
								onClick={() => {
									openModal('admins', record.id);
								}}>
								<EyeOutlined />
							</Button>
						) : (
							<Badge dot>
								<Button
									type='default'
									style={{ height: 30 }}
									onClick={() => {
										openModal('admins', record.id);
									}}>
									<EyeOutlined />
								</Button>
							</Badge>
						)}
					</span>
				);
			},
			width: 150,
		},
		{
			title: 'Riders',
			key: 'riders',
			render: (record) => {
				return (
					<span style={style.btnView}>
						{record?.riders?.length > 0 ? (
							<Button
								type='default'
								style={{ height: 30 }}
								onClick={() => {
									openModal('riders', record.id);
								}}>
								<EyeOutlined />
							</Button>
						) : (
							<Badge dot>
								<Button
									type='default'
									style={{ height: 30 }}
									onClick={() => {
										openModal('riders', record.id);
									}}>
									<EyeOutlined />
								</Button>
							</Badge>
						)}
					</span>
				);
			},
			width: 150,
		},
		{
			title: 'Discount',
			key: 'discount',
			render: (record) => {
				return (
					<span style={style.dataContainer}>
						{record?.discount ? (
							<Button
								type='default'
								style={{ height: 30 }}
								onClick={() => {
									openModal('discount', record.id);
								}}>
								<EyeOutlined />
							</Button>
						) : (
							<Badge dot>
								<Button
									type='default'
									style={{ height: 30 }}
									onClick={() => {
										openModal('discount', record.id);
									}}>
									<EyeOutlined />
								</Button>
							</Badge>
						)}
					</span>
				);
			},
			width: 150,
		},
		{
			title: 'Allowed Areas',
			key: 'allowedAreas',
			render: (record) => {
				return (
					<span style={style.dataContainer}>
						{record?.allowedAreas?.length > 0 ? (
							<Button
								type='default'
								style={{ height: 30 }}
								onClick={() => {
									openModal('allowedAreas', record.id);
								}}>
								<EyeOutlined />
							</Button>
						) : (
							<Badge dot>
								<Button
									type='default'
									style={{ height: 30 }}
									onClick={() => {
										openModal('allowedAreas', record.id);
									}}>
									<EyeOutlined />
								</Button>
							</Badge>
						)}
					</span>
				);
			},
			width: 150,
		},
		{
			title: 'Schedule',
			key: 'schedule',
			render: (record) => {
				return (
					<span style={style.dataContainer}>
						{record?.schedules ? (
							<Button
								type='default'
								style={{ height: 30 }}
								onClick={() => {
									openModal('schedule', record.id);
								}}>
								<EyeOutlined />
							</Button>
						) : (
							<Badge dot>
								<Button
									type='default'
									style={{ height: 30 }}
									onClick={() => {
										openModal('schedule', record.id);
									}}>
									<EyeOutlined />
								</Button>
							</Badge>
						)}
					</span>
				);
			},
			width: 150,
		},
		{
			title: 'Actions',
			key: 'actions',
			render: (text, record) => (
				<ActionButton
					record={record}
					onDelete={() => handleDelete(record.id)}
					onEdit={() => openDrawer(record)}
				/>
			),
			
		},
	];
	const data = corporates?.corporatesList ? Object.values(corporates.corporatesList) : [];
	const filtredData = data.filter((item) => {
		return item?.name.toLowerCase()?.indexOf(filter.toLowerCase()) !== -1;
	});

	return (
		<Card style={{
					margin: 16,
					maxHeight: '90%', // Set maximum width
					height: 'auto', // Allow width to be dynamic
				}}>
			<div style={style.inlineContent}>
				<div
					style={{
						display: 'flex',
						alignContent: 'center',
						alignItems: 'center',
						float: 'left',
						marginBottom: 8,
					}}>
					<Breadcrumb
						style={{ margin: '0 3em 0 0', fontSize: '1.1em' }}
						items={[
							{
								title: 'Corporates',
							},
						]}
					/>
					<Button type='default' size='small' onClick={() => fetchCorporates()}>
						<ReloadOutlined />
					</Button>
				</div>

				<div
					style={{
						...style.inlineContent,
						float: 'right',
						marginBottom: 8,
					}}>
					<Input.Search
						placeholder='Search'
						style={{ margin: '0 8px 8px 0' }}
						value={filter}
						onChange={(e) => setFilter(e.target.value)}
					/>
					{role?.includes('SUPER_ADMIN') && (
						<Button
							type='default'
							style={{ float: 'right', marginBottom: 8 }}
							onClick={() => openDrawer()}>
							Add Corporate
						</Button>
					)}
				</div>
			</div>
			<CustomTableComponents
				isMakingApiCall={corporates?.loading}
				columns={columns}
				dataArray={filtredData}
				handleDoubleClickRow={(record) => {
					openDrawer(record);
				}}
				handleSelectedRow={() => {}}
			/>
			{isDrawerOpened && (
				<CorporateDrawer
					onClose={closeDrawer}
					corporateId={corporateId}
					dispatch={dispatch}
				/>
			)}
			{isModalOpen && (
				<CorporateModal
					isModalOpen={isModalOpen}
					title={title}
					onModalClose={onModalClose}
				/>
			)}
		</Card>
	);
};

export default Corporate;
