/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
import { Button, Dropdown, Menu, Modal } from 'antd';
import React, { useState } from 'react';

import {
	MoreOutlined,
	EditOutlined,
	DeleteOutlined,
	ExclamationCircleFilled,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';

const ActionButton = ({ record, onEdit, onDelete, recordName }) => {
	const [menuVisible, setMenuVisible] = useState(false);
	const { Brand } = useSelector((state) => state.brand);

	const handleMenuClick = ({ key }) => {
		// switch case depending on the key
		switch (key) {
			case 'edit':
				onEdit(record.id);
				break;
			case 'delete':
				Modal.confirm({
					title: (
						<>
							Are you sure you want to delete <b>{recordName || record.name}</b> ?
						</>
					),
					icon: (
						<ExclamationCircleFilled
							style={{ color: Brand?.brandData?.webPrimary || 'orange' }}
						/>
					),
					okText: 'Yes', // Customize OK button text
					cancelText: 'No', // Customize Cancel button text
					cancelButtonProps: {
						style: {
							color: Brand?.brandData?.webPrimary || 'orange',
							backgroundColor: 'white',
							borderColor: Brand?.brandData?.webPrimary || 'orange',
						},
					},
					okButtonProps: {
						style: {
							color: 'white',
							backgroundColor: Brand?.brandData?.webPrimary || 'orange',
						},
					},
					onOk: () => {
						onDelete(record.id, () => {});
					},
					onCancel: () => {},
				});
				break;
			default:
				break;
		}
		setMenuVisible(false);
	};
	const menu = (
		<Menu onClick={handleMenuClick}>
			<Menu.Item key='edit' icon={<EditOutlined />}>
				Edit
			</Menu.Item>
			<Menu.Divider />

			<Menu.Item key='delete' icon={<DeleteOutlined />} danger>
				Delete
			</Menu.Item>
		</Menu>
	);
	return (
		<Dropdown
			overlay={menu}
			trigger={['click']}
			open={menuVisible}
			onOpenChange={(visible) => {
				setMenuVisible(visible);
			}}
			placement='bottomRight'>
			<Button
				type='text'
				icon={<MoreOutlined />}
				onClick={(event) => event.stopPropagation()}
			/>
		</Dropdown>
	);
};
export default ActionButton;
