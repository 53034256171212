import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../axios';

// Define the initial state for the bundle entity
const initialState = {
	bundles: { loading: false, bundleList: [] },
	selectedBundle: { loading: false, bundle: null },
};

export const getAllBundles = createAsyncThunk('bundles/getAllBundles', async () => {
	try {
		const response = await axiosInstance.get(`/bundles`);
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
			/* data: error.response.data, */
		};
		throw customError;
	}
});

export const getBundleById = createAsyncThunk('bundles/getBundleById', async (id) => {
	try {
		const response = await axiosInstance.get(`/bundle/${id}`);
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
			/* data: error.response.data, */
		};
		throw customError;
	}
});

export const createBundle = createAsyncThunk('bundles/createBundle', async (data) => {
	try {
		const response = await axiosInstance.post(`/bundle`, data);
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
			/* data: error.response.data, */
		};
		throw customError;
	}
});

export const updateBundle = createAsyncThunk('bundles/updateBundle', async (data) => {
	try {
		const response = await axiosInstance.put(`/bundle`, data);
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
			/* data: error.response.data, */
		};
		throw customError;
	}
});

export const deleteBundle = createAsyncThunk('bundles/deleteBundle', async (id) => {
	try {
		await axiosInstance.delete(`/bundle/${id}`);
		return id;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
			/* data: error.response.data, */
		};
		throw customError;
	}
});

// Define the bundle slice
const bundleSlice = createSlice({
	name: 'bundles',
	initialState,
	reducers: {
		resetSelectedBundle: (state) => {
			state.selectedBundle = { loading: false, bundle: null };
		},
	},
	extraReducers: {
		[getAllBundles.pending]: (state) => {
			state.bundles.loading = true;
		},
		[getAllBundles.fulfilled]: (state, action) => {
			state.bundles.loading = false;
			state.bundles.bundleList = action.payload;
		},
		[getAllBundles.rejected]: (state) => {
			state.bundles.loading = false;
		},
		[getBundleById.pending]: (state) => {
			state.selectedBundle.loading = true;
		},
		[getBundleById.fulfilled]: (state, action) => {
			state.selectedBundle.loading = false;
			state.selectedBundle.bundle = action.payload;
		},
		[getBundleById.rejected]: (state) => {
			state.selectedBundle.loading = false;
		},
		[createBundle.pending]: (state) => {
			state.bundles.loading = true;
		},
		[createBundle.fulfilled]: (state, action) => {
			state.bundles.loading = false;
			state.bundles.bundleList.push(action.payload);
		},
		[createBundle.rejected]: (state) => {
			state.bundles.loading = false;
		},
		[updateBundle.pending]: (state) => {
			state.bundles.loading = true;
		},
		[updateBundle.fulfilled]: (state, action) => {
			state.bundles.loading = false;
			state.bundles.bundleList = state.bundles.bundleList.map((item) => {
				if (item.id === action.payload.id) {
					item = action.payload;
					return item;
				}
				return item;
			});
		},
		[updateBundle.rejected]: (state) => {
			state.bundles.loading = false;
		},
		[deleteBundle.pending]: (state) => {
			state.bundles.loading = true;
		},
		[deleteBundle.fulfilled]: (state, action) => {
			state.bundles.loading = false;
			state.bundles.bundleList = state.bundles.bundleList.filter((item) => {
				return item.id !== action.payload;
			});
		},
	},
});

export const { resetSelectedBundle } = bundleSlice.actions;
// Export the bundle reducer
export default bundleSlice.reducer;
