/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from 'react';
import {
	Form,
	Input,
	Select,
	Button,
	Divider,
	Spin,
	Tag,
	Radio,
	DatePicker,
	Row,
	message,
	Col,
} from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'antd/es/form/Form';
import { FileTextOutlined } from '@ant-design/icons';
import { countryPhoneCode } from '../../Utils/country-phone-codes';
import UploadComponent from '../Global/CustomAvatar/UploadComponent';
import { style } from '../../Styles';
import getCountryFromIP from '../../Utils/GettingCountryFromIP';
import UploadDocumentsModal from '../Global/UploadDocumentsModal';
import {
	downloadImage,
	getDriverDocuments,
	setDriverIdDocuments,
	setDriverLicenceDocuments,
	setDriverPoliceConductDocuments,
	setDriverTagDocuments,
	uploadMultiDocuments,
} from '../../redux/fileSlice';

const dateFormat = 'YYYY/MM/DD';
const validateMessages = {
	types: {
		email: '${label} is not a valid email',
	},
};

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(utc);

const optionsGender = [
	{ label: 'Male', value: 'Male' },
	{ label: 'Female', value: 'Female' },
	{ label: 'Undisclosed', value: 'Undisclosed' },
];

const tomorrow = dayjs().add(1, 'day');

const DriverForm = ({
	onSave,
	onSaveFailed,
	driver,
	onCancel,
	onDirty,
	unsavedChanges,
	fileList,
	setFileList,
	handleUpload,
	uploading,
	setIsFileChosen,
}) => {
	const [form] = useForm();
	const { driverFetchedDocuments } = useSelector((state) => state.files);
	const [countryFromIp, setCountryFromIp] = useState('Malta');
	const [phoneCodeFromIp, setPhoneCodeFromIp] = useState('356');
	const [selectedGender, setSelectedGender] = useState('');
	const [openUploadModal, setOpenUploadModal] = useState(false);
	const [modalTitle, setModalTitle] = useState('');
	const [isUploadingMultiDocuments, setIsUploadingMultiDocuments] = useState(false);
	const [documentList, setDocumentList] = useState([
		driverFetchedDocuments?.driverDocuments || [],
	]);

	const dispatch = useDispatch();
	const onChangeGender = ({ target: { value } }) => {
		setSelectedGender(value);
	};
	const { SelectedDriver } = useSelector((state) => state.drivers);
	const fetchAllDriverDocuments = async () => {
		const fetchDocumentsCalls = [];
		const fetchedDocuemnts = [];
		SelectedDriver?.driver?.driverDocuments?.forEach((document) => {
			fetchDocumentsCalls.push(
				dispatch(downloadImage(document?.fileId))
					.unwrap()
					.then((data) => {
						const imgUrl = URL.createObjectURL(data);

						fetchedDocuemnts.push({
							uid: document.fileId,
							id: document.fileId,
							name: document.originalFileName,
							status: 'done',
							url: imgUrl,
							type: document.documentType,
							oldFile: true,
						});
					})
					.catch((error) => {
						throw error;
					}),
			);
		});

		Promise.all(fetchDocumentsCalls)
			.then(() => {
				dispatch(getDriverDocuments(fetchedDocuemnts));
			})
			.catch((error) => {
				console.log('error', error);
			});
	};

	useEffect(() => {
		fetchAllDriverDocuments();
	}, []);
	/* useEffect(() => {
		if (driverFetchedDocuments?.driverDocuments) {
			dispatch(setDriverDocuemnts());
		}
	}, [driver, driverFetchedDocuments?.driverDocuments]); */
	useEffect(() => {
		getCountryFromIP().then((country) => {
			setCountryFromIp(country);
			// Find the corresponding phone code for the country
			const foundCountry = countryPhoneCode.find((c) => c.en === country);
			if (foundCountry) {
				form.setFieldsValue({
					phoneCountryCode: driver ? driver?.phoneCountryCode : foundCountry.phoneCode,
					country: driver ? driver?.country : foundCountry.en,
				});
				setPhoneCodeFromIp(foundCountry.phoneCode);
				setCountryFromIp(foundCountry.en);
			}
		});
	}, []);

	const { fleetOperator } = useSelector((state) => state.fleetOperator);

	const getExpiryDateValue = (type) => {
		if (driver?.driverDocuments) {
			const document = driver?.driverDocuments.find((doc) => doc.documentType === type);
			if (document) {
				return dayjs(document?.expiryDate);
			}
		}
		return undefined;
	};

	const initialValues = {
		...driver,
		fleetOperators: driver?.fleetOperators?.map((fo) => fo?.id) || [],
		phoneCountryCode: driver ? driver?.phoneCountryCode : phoneCodeFromIp,
		country: driver ? driver?.country : countryFromIp,
		tagExpiryDate: getExpiryDateValue('TAG_DOCUMENT'),
		licenceExpiryDate: getExpiryDateValue('DRIVER_LICENSE_DOCUMENT'),
		policeConductExpiryDate: getExpiryDateValue('POLICE_CONDUCT_DOCUMENT'),
		idExpiryDate: getExpiryDateValue('ID_CARD'),
	};

	const openUploadModalHandler = (title) => {
		setModalTitle(title);
		setOpenUploadModal(true);
	};

	const uploadDocuments = (data) => {
		const files = data?.files;
		const type = data?.type;
		setIsUploadingMultiDocuments(true);
		const newFiles = files?.filter((file) => !file.oldFile);

		dispatch(uploadMultiDocuments({ files: newFiles, type, component: 'DRIVER' }))
			.unwrap()
			.then((response) => {
				message.success('Documents uploaded successfully');
				if (response?.type === 'TAG_DOCUMENT') {
					dispatch(setDriverTagDocuments(response?.data));
				}
				if (response?.type === 'DRIVER_LICENSE_DOCUMENT') {
					dispatch(setDriverLicenceDocuments(response?.data));
				}
				if (response?.type === 'POLICE_CONDUCT_DOCUMENT') {
					dispatch(setDriverPoliceConductDocuments(response?.data));
				}
				if (response?.type === 'ID_CARD') {
					dispatch(setDriverIdDocuments(response?.data));
				}
				setOpenUploadModal(false);
				setDocumentList([]);
			})
			.catch((e) => {
				console.error(e);
				message.error('Error while uploading documents, please try again later');
			})
			.finally(() => {
				setIsUploadingMultiDocuments(false);
			});
	};

	return (
		<Form
			form={form}
			name='driver'
			layout='vertical'
			initialValues={initialValues}
			validateMessages={validateMessages}
			onFinish={onSave}
			onFinishFailed={onSaveFailed}
			autoComplete='off'
			onValuesChange={() => onDirty()}>
			<Form.Item
				label='Profile Picture'
				name='profilePicture'
				style={{ textAlign: 'center' }}>
				<Spin spinning={uploading}>
					<UploadComponent
						fileList={fileList}
						setFileList={setFileList}
						handleUpload={handleUpload}
						uploading={uploading}
						setIsFileChosen={setIsFileChosen}
					/>
				</Spin>
			</Form.Item>
			<Form.Item
				label='Name'
				name='name'
				rules={[{ required: true, message: 'Please input driver name' }]}>
				<Input />
			</Form.Item>
			<Form.Item
				label='Surname'
				name='surname'
				rules={[{ required: true, message: 'Please input driver surname' }]}>
				<Input />
			</Form.Item>
			<Form.Item
				label='Email'
				name='email'
				rules={[
					{
						required: true,
						message: 'Please input  Email!',
					},
					{
						pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
						message: 'Please enter a valid email address',
					},
				]}>
				<Input />
			</Form.Item>
			<Form.Item
				label='Fleet Operator'
				name='fleetOperators'
				rules={[{ required: true, message: 'Please select Fleet operators' }]}>
				<Select
					size='medium'
					showArrow
					showSearch
					optionFilterProp='filterBy'
					mode='multiple'
					allowClear
					optionLabelProp='label'>
					{fleetOperator.fleetOperatorList.map((fo) => (
						<Select.Option
							key={fo.id}
							filterBy={fo.name}
							value={fo.id}
							label={fo.name}
							mode='multiple'
							disabled={!fo.active}>
							<div style={style.inlineContent}>
								<span
									style={{
										display: 'flex',
										alignContent: 'center',
										alignItems: 'center',
										float: 'left',
									}}>
									{fo.name}
								</span>
								{fo.active ? (
									<Tag color='green'>Active</Tag>
								) : (
									<Tag color='red'>Inactive</Tag>
								)}
							</div>
						</Select.Option>
					))}
				</Select>
			</Form.Item>
			<Form.Item
				label='Country'
				name='country'
				initialValue={driver ? driver?.country : countryFromIp}
				rules={[{ required: true, message: 'Please select driver country' }]}>
				<Select
					size='medium'
					showArrow
					showSearch
					optionFilterProp='filterBy'
					allowClear
					onChange={(country) => {
						const foundCountry = countryPhoneCode.find((c) => c.en === country);
						if (foundCountry) {
							form.setFieldsValue({
								phoneCountryCode: foundCountry.phoneCode,
							});
							setPhoneCodeFromIp(foundCountry.phoneCode);
							setCountryFromIp(foundCountry.en);
						}
					}}
					optionLabelProp='label'>
					{countryPhoneCode.map((p) => (
						<Select.Option
							key={p.en}
							filterBy={`${p.en} +${p.phoneCode}`}
							value={p.en}
							label={
								<>
									<span role='img'>{p.emoji}</span>
									{`  ${p.en}`}
								</>
							}>
							<span role='img'>{p.emoji}</span>
							{`  ${p.en} `}
						</Select.Option>
					))}
				</Select>
			</Form.Item>
			<Form.Item
				label='Phone Country Code'
				name='phoneCountryCode'
				defaultValue={driver ? driver.phoneCountryCode : phoneCodeFromIp}
				rules={[{ required: true, message: 'Please select phone country code' }]}>
				<Select
					size='medium'
					showArrow
					showSearch
					optionFilterProp='filterBy'
					allowClear
					optionLabelProp='label'>
					{countryPhoneCode.map((p) => (
						<Select.Option
							key={p.en}
							filterBy={`${p.en} +${p.phoneCode}`}
							value={p.phoneCode}
							label={
								<>
									<span role='img'>{p.emoji}</span>
									{` +(${p.phoneCode})`}
								</>
							}>
							<span role='img'>{p.emoji}</span>{' '}
							<span style={{ color: 'gray' }}>{`+(${p.phoneCode})`}</span>
						</Select.Option>
					))}
				</Select>
			</Form.Item>
			<Form.Item
				label='Phone'
				name='phone'
				rules={[{ required: true, message: 'Please input driver phone' }]}>
				<Input />
			</Form.Item>
			<Form.Item
				label='Gender'
				name='gender'
				rules={[{ required: true, message: 'Please input rider Phone' }]}>
				<Radio.Group
					options={optionsGender}
					onChange={onChangeGender}
					value={selectedGender}
					optionType='button'
					buttonStyle='solid'
				/>
			</Form.Item>

			<Form.Item label='Address' name='address'>
				<Input />
			</Form.Item>

			<Divider orientation='left' orientationMargin='0'>
				ID Card
			</Divider>
			<Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
				<Col span={16}>
					<Form.Item
						label='Expiry date'
						name='idExpiryDate'
						rules={[
							{
								required:
									driverFetchedDocuments?.driverDocuments.filter(
										(doc) =>
											doc.type === 'ID_CARD' ||
											doc.documentType === 'ID_CARD',
									).length > 0,
								message: 'Please Choose Expiry Date for ID Document',
							},
						]}>
						<DatePicker
							format={dateFormat}
							style={{ width: '100%' }}
							disabledDate={(current) => {
								return current && current < tomorrow;
							}}
						/>
					</Form.Item>
				</Col>
				<Col span={8}>
					<span style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
						<Row>
							<Button
								icon={<FileTextOutlined />}
								shape='square'
								onClick={() => openUploadModalHandler('Upload ID card Documents')}>
								{
									driverFetchedDocuments?.driverDocuments.filter(
										(doc) =>
											doc.type === 'ID_CARD' ||
											doc.documentType === 'ID_CARD',
									).length
								}{' '}
								Files
							</Button>
						</Row>
					</span>
				</Col>
			</Row>

			<Divider orientation='left' orientationMargin='0'>
				Tag
			</Divider>
			<Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
				<Col span={16}>
					<Form.Item
						label='Expiry date'
						name='tagExpiryDate'
						rules={[
							{
								required:
									driverFetchedDocuments?.driverDocuments.filter(
										(doc) =>
											doc.type === 'TAG_DOCUMENT' ||
											doc.documentType === 'TAG_DOCUMENT',
									).length > 0,
								message: 'Please Choose Expiry Date for Tag Document',
							},
						]}>
						<DatePicker
							format={dateFormat}
							style={{ width: '100%' }}
							disabledDate={(current) => {
								return current && current < tomorrow;
							}}
						/>
					</Form.Item>
				</Col>
				<Col span={8}>
					<span style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
						<Row>
							<Button
								icon={<FileTextOutlined />}
								shape='square'
								onClick={() => openUploadModalHandler('Upload Tag Documents')}>
								{
									driverFetchedDocuments?.driverDocuments.filter(
										(doc) =>
											doc.type === 'TAG_DOCUMENT' ||
											doc.documentType === 'TAG_DOCUMENT',
									).length
								}{' '}
								Files
							</Button>
						</Row>
					</span>
				</Col>
			</Row>

			<Divider orientation='left' orientationMargin='0'>
				Driving Licence
			</Divider>
			<Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
				<Col span={16}>
					<Form.Item
						label='Expiry date'
						name='licenceExpiryDate'
						rules={[
							{
								required:
									driverFetchedDocuments?.driverDocuments.filter(
										(doc) =>
											doc.type === 'DRIVER_LICENSE_DOCUMENT' ||
											doc.documentType === 'DRIVER_LICENSE_DOCUMENT',
									).length > 0,
								message: 'Please Choose Expiry Date for Driving Licence Document',
							},
						]}>
						<DatePicker
							format={dateFormat}
							style={{ width: '100%' }}
							disabledDate={(current) => {
								return current && current < tomorrow;
							}}
						/>
					</Form.Item>
				</Col>
				<Col span={8}>
					<span style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
						<Row>
							<Button
								icon={<FileTextOutlined />}
								shape='square'
								onClick={() =>
									openUploadModalHandler('Upload Driving Licence Documents')
								}>
								{
									driverFetchedDocuments?.driverDocuments.filter(
										(doc) =>
											doc.type === 'DRIVER_LICENSE_DOCUMENT' ||
											doc.documentType === 'DRIVER_LICENSE_DOCUMENT',
									).length
								}{' '}
								Files
							</Button>
						</Row>
					</span>
				</Col>
			</Row>

			<Divider orientation='left' orientationMargin='0'>
				Police Conduct
			</Divider>
			<Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
				<Col span={16}>
					<Form.Item
						label='Expiry date'
						name='policeConductExpiryDate'
						rules={[
							{
								required:
									driverFetchedDocuments?.driverDocuments.filter(
										(doc) =>
											doc.type === 'POLICE_CONDUCT_DOCUMENT' ||
											doc.documentType === 'POLICE_CONDUCT_DOCUMENT',
									).length > 0,
								message: 'Please Choose Expiry Date for Police Conduct Document',
							},
						]}>
						<DatePicker
							format={dateFormat}
							style={{ width: '100%' }}
							disabledDate={(current) => {
								return current && current < tomorrow;
							}}
						/>
					</Form.Item>
				</Col>
				<Col span={8}>
					<span style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
						<Row>
							<Button
								icon={<FileTextOutlined />}
								shape='square'
								onClick={() =>
									openUploadModalHandler('Upload Police Conduct Documents')
								}>
								{
									driverFetchedDocuments?.driverDocuments.filter(
										(doc) =>
											doc.type === 'POLICE_CONDUCT_DOCUMENT' ||
											doc.documentType === 'POLICE_CONDUCT_DOCUMENT',
									).length
								}{' '}
								Files
							</Button>
						</Row>
					</span>
				</Col>
			</Row>
			<UploadDocumentsModal
				title={modalTitle}
				visible={openUploadModal}
				setVisible={setOpenUploadModal}
				onUpload={uploadDocuments}
				isUploadingMultiDocuments={isUploadingMultiDocuments}
				documentList={documentList}
				setDocumentList={setDocumentList}
				driverId={driver?.id}
				setTitle={setModalTitle}
				componentType='DRIVER'
			/>
			<Divider />
			<Form.Item style={{ float: 'right' }}>
				<Button
					type='default'
					style={{ marginBottom: 32, marginRight: 8 }}
					onClick={() => onCancel(unsavedChanges)}>
					Cancel
				</Button>
				<Button
					type='primary'
					style={{ marginBottom: 32 }}
					htmlType='submit'
					disabled={
						// !unsavedChanges or else driverFetchedDocuments?.driverDocuments have all items in the array with oldFile: true
						!unsavedChanges &&
						driverFetchedDocuments?.driverDocuments?.filter((doc) => !doc.oldFile)
							.length === 0 &&
						SelectedDriver?.driver?.driverDocuments?.length ===
							driverFetchedDocuments?.driverDocuments?.length
					}>
					{driver ? 'Update' : 'Save'}
				</Button>
			</Form.Item>
		</Form>
	);
};

export default DriverForm;
