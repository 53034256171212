import React from 'react';

const DriverNotAssigned = () => {
	return (
		<div
			style={{
				padding: '20px',
				display: 'flex',
				textAlign: 'center',
				alignItems: 'center',
			}}>
			<img
				width='32%'
				src={`${process.env.PUBLIC_URL}/images/taxi-driver.png`}
				alt='taxi-driver'
				style={{ marginRight: '2em' }}
			/>
			<h2>Driver Not Assigned yet</h2>
		</div>
	);
};

export default DriverNotAssigned;
