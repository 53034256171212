/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
import React from 'react';
import { Table } from 'antd';

const CostumeTableWithSelect = ({
	isMakingApiCall,
	columns,
	dataArray,
	handleSelectedRow,
	handleDoubleClickRow,
	numberOfRows,
	rowSelection,
}) => {
	const pageSize = numberOfRows || 10;
	const showSizeChanger = !numberOfRows;
	console.log('selectedRowKeys', rowSelection.selectedRowKeys);
	return (
		<div style={{ overflowX: 'auto' }}>
			<Table
				pagination={{
					defaultPageSize: pageSize || 10,
					showSizeChanger,
					showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
					pageSizeOptions: ['5', '10', '20', '50', '100'],
				}}
				loading={isMakingApiCall}
				columns={columns}
				dataSource={dataArray}
				onRow={(record) => {
					return {
						onClick: () => handleSelectedRow(record),
						onDoubleClick: () => handleDoubleClickRow(record),
					};
				}}
				scroll={{ x: 'max-content' }}
				rowSelection={rowSelection}
				rowKey='id'
			/>
		</div>
	);
};

export default CostumeTableWithSelect;
