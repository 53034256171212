/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import { DeleteOutlined, EditOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import {
	Button,
	Col,
	Divider,
	Form,
	Input,
	Modal,
	Row,
	Select,
	Skeleton,
	TimePicker,
	message,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'; // Import the plugin
import { daysOfWeekWithCapitalValue } from '../../Utils/Constants/utils';
import {
	createCorporateScheduleRow,
	deleteCorporateScheduleRow,
	fetchCorporateSchedule,
	updateCorporateScheduleRow,
} from '../../redux/corporateSlice';
import CustomTableComponents from '../Global/CustomTableComponents';
import { style } from '../../Styles';
import { formatEnumValue } from '../../Utils/ArrayToObject';
// Extend Day.js with the plugin
dayjs.extend(isSameOrAfter);

const Schedule = () => {
	const { selectedCorporate, listOfCorporateSchedule } = useSelector((state) => state.corporates);
	const [filter, setFilter] = useState('');
	const [startTime, setStartTime] = useState('');
	const [endTime, setEndTime] = useState('');
	const [selectedRow, setSelectedRow] = useState(null);
	const dispatch = useDispatch();

	const { Brand } = useSelector((state) => state.brand);

	const resetForm = () => {
		form.resetFields();
		setSelectedRow(null);
	};

	const handleDelete = (record) => {
		Modal.confirm({
			title: (
				<>
					Are you sure you want to delete schedule with ID: <b>{record?.id}</b> ?
				</>
			),
			icon: (
				<ExclamationCircleFilled
					style={{ color: Brand?.brandData?.webPrimary || 'orange' }}
				/>
			),
			okText: 'Yes', // Customize OK button text
			cancelText: 'No', // Customize Cancel button text
			cancelButtonProps: {
				style: {
					color: Brand?.brandData?.webPrimary || 'orange',
					backgroundColor: 'white',
					borderColor: Brand?.brandData?.webPrimary || 'orange',
				},
			},
			okButtonProps: {
				style: {
					color: 'white',
					backgroundColor: Brand?.brandData?.webPrimary || 'orange',
				},
			},
			onOk: () => {
				dispatch(deleteCorporateScheduleRow(record.id))
					.unwrap()
					.then(() => {
						message.success('Schedule deleted successfully');
					})
					.catch(() => {
						message.error('Error deleting schedule');
					});
			},
			onCancel: () => {},
		});
	};

	const columns = [
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id',
		},
		{
			title: 'Day',

			key: 'day',
			render: (text, record) => <p>{formatEnumValue(record?.day)}</p>,
		},
		{
			title: 'Start Time',
			dataIndex: 'startTime',
			key: 'startTime',
		},
		{
			title: 'End Time',
			dataIndex: 'endTime',
			key: 'endTime',
		},
		{
			title: 'Action',
			key: 'action',
			render: (text, record) => (
				<>
					<Button
						icon={<DeleteOutlined />}
						style={{ marginRight: 8 }}
						onClick={() => {
							handleDelete(record);
						}}
					/>
					<Button
						icon={<EditOutlined />}
						onClick={() => {
							setSelectedRow(record);
						}}
					/>
				</>
			),
		},
	];

	useEffect(() => {
		if (selectedRow) {
			const startTimeValue = selectedRow?.startTime
				? dayjs(
						`${dayjs().format('YYYY-MM-DD')} ${selectedRow?.startTime}`,
						'YYYY-MM-DD HH:mm',
				  )
				: undefined;
			const endTimeValue = selectedRow?.endTime
				? dayjs(
						`${dayjs().format('YYYY-MM-DD')} ${selectedRow?.endTime}`,
						'YYYY-MM-DD HH:mm',
				  )
				: undefined;

			form.setFieldsValue({
				day: selectedRow?.day,
				startTime: startTimeValue,
				endTime: endTimeValue,
			});
			setStartTime(selectedRow?.startTime);
			setEndTime(selectedRow?.endTime);
		}
	}, [selectedRow]);

	const data = listOfCorporateSchedule?.schedules
		? Object.values(listOfCorporateSchedule?.schedules)
		: [];
	const filtredData = data.filter((item) => {
		return item?.day.toLowerCase()?.indexOf(filter.toLowerCase()) !== -1;
	});

	useEffect(() => {
		if (selectedCorporate?.corporate) {
			dispatch(fetchCorporateSchedule(selectedCorporate?.corporate?.id));
		}
	}, [dispatch, selectedCorporate]);
	const [form] = Form.useForm();

	const onFinish = (values) => {
		const newSchedule = {
			...values,
			startTime,
			endTime,
			corporateId: selectedCorporate?.corporate?.id,
			id: selectedRow?.id,
		};
		const action = selectedRow
			? updateCorporateScheduleRow(newSchedule)
			: createCorporateScheduleRow(newSchedule);
		dispatch(action)
			.unwrap()
			.then(() => {
				message.success('Schedule saved successfully');
				resetForm();
			})
			.catch(() => {
				message.error('Error saving schedule');
			});
	};

	// handle datetime change
	const onChange = (value, timeString, name) => {
		if (name === 'startTime') setStartTime(dayjs(value).format('HH:mm'));
		if (name === 'endTime') setEndTime(dayjs(value).format('HH:mm'));
	};

	return (
		<div>
			<Skeleton loading={listOfCorporateSchedule.loading} avatar active>
				<Divider orientation='left' orientationMargin={0}>
					Add schedule
				</Divider>
				<Form
					form={form}
					layout='vertical'
					name='scheduleForm'
					onFinish={(values) => {
						onFinish(values);
					}}>
					<Row gutter={[16, 16]}>
						<Col xs={24} sm={8}>
							<Form.Item
								label='Day'
								name='day'
								rules={[
									{
										required: true,
										message: 'Please select day',
									},
								]}>
								<Select placeholder='Select week day' style={{ width: '100%' }}>
									{daysOfWeekWithCapitalValue.map((item) => (
										<Select.Option key={item.value} value={item.value}>
											{item.label}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col xs={24} sm={8}>
							<Form.Item
								label='Start Time'
								name='startTime'
								rules={[
									{
										required: true,
										message: 'Please select start time',
									},
								]}>
								<TimePicker
									showTime={{ format: 'HH:mm' }}
									onChange={(time, timeString) =>
										onChange(time, timeString, 'startTime')
									}
									value={startTime}
									format='HH:mm'
									style={{ width: '100%' }}
									needConfirm={false}
								/>
							</Form.Item>
						</Col>
						<Col xs={24} sm={8}>
							<Form.Item
								label='End Time'
								name='endTime'
								rules={[
									{
										required: true,
										message: 'Please select end time',
									},
									({ getFieldValue }) => ({
										validator(_, value) {
											const start = dayjs(
												getFieldValue('startTime'),
												'HH:mm',
											);
											const end = dayjs(value, 'HH:mm');
											if (!start.isValid() || !end.isValid()) {
												// If either start time or end time is invalid, no validation needed
												return Promise.resolve();
											}

											if (end.isAfter(start)) {
												return Promise.resolve();
											}

											// eslint-disable-next-line prefer-promise-reject-errors
											return Promise.reject(
												'End time must be greater than the start time',
											);
										},
									}),
								]}>
								<TimePicker
									showTime={{ format: 'HH:mm' }}
									onChange={(time, timeString) =>
										onChange(time, timeString, 'endTime')
									}
									value={endTime}
									format='HH:mm'
									style={{ width: '100%' }}
									needConfirm={false}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Form.Item
						style={{
							display: 'flex',
							justifyContent: 'flex-end',
						}}>
						<Button type='default' onClick={resetForm} style={{ marginRight: '10px' }}>
							Reset
						</Button>
						<Button type='primary' htmlType='submit'>
							{selectedRow ? 'Update' : 'Create'}
						</Button>
					</Form.Item>
				</Form>

				<Divider orientation='left' orientationMargin={0}>
					List of schedules
				</Divider>
				<Row style={{ marginBottom: 8, display: 'flex', justifyContent: 'end' }}>
					<div
						style={{
							...style.inlineContent,
							float: 'right',
							marginBottom: 8,
						}}>
						<Input.Search
							placeholder='Search'
							style={{ float: 'right', margin: '0 8px 8px 0' }}
							value={filter}
							onChange={(e) => setFilter(e.target.value)}
						/>
					</div>
				</Row>
				<CustomTableComponents
					isMakingApiCall={false}
					columns={columns}
					dataArray={filtredData || []}
					handleSelectedRow={() => {}}
					handleDoubleClickRow={() => {}}
				/>
			</Skeleton>
		</div>
	);
};

export default Schedule;
