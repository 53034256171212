/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
import { Drawer, Spin, message } from "antd";
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import ShowConfirmClose from "../Modals/ShowConfirmClose";
import {
  addNewWayPoint,
  getWayPointById,
  updateWayPoint,
} from "../../redux/WayPointSlice";
import WayPointForm from "./WayPointForm";

const DrawerWayPoints = ({ wayPointId, onClose }) => {
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const dispatch = useDispatch();
  const [makingApiCall, setMakingApiCall] = useState(false);
  const [wayPoint, setWayPoint] = useState(null);

  const onSave = (values) => {
    saveWayPoint(values);
  };

  const handleClose = useCallback(
    (hasUnsavedChanges) => {
      if (hasUnsavedChanges) {
        ShowConfirmClose(() => {
          onClose();
        });
      } else {
        onClose();
      }
    },
    [onClose]
  );

  const handleDirty = useCallback(() => {
    setUnsavedChanges(true);
  }, []);

  const saveWayPoint = async (values) => {
    const action = wayPointId
      ? updateWayPoint({
          wayPointId,
          wayPoint: values,
        })
      : addNewWayPoint({
          wayPoint: values,
        });
    dispatch(action)
      .unwrap()
      .then(() => {
        message.success("Waypoint saved successfully");
        setUnsavedChanges(false);
      })
      .catch((e) => {
        if (e.message === "WAY_POINT_ALREADY_EXISTS") {
          message.error(
            "Could not save Waypoint, it is already exist with same name"
          );
        } else {
          message.error(e.message);
        }
      })
      .finally(() => {
        onClose();
        setMakingApiCall(false);
      });
  };

  const onSaveFailed = (error) => {
    console.log("Failed:", error);
  };

  useEffect(() => {
    const abortController = new AbortController();
    if (wayPointId) {
      setMakingApiCall(true);
      dispatch(getWayPointById({ wayPointId }))
        .unwrap()
        .then((originalWayPoint) => {
          setWayPoint(originalWayPoint);
        })
        .catch((e) => {
          if (e.message === "WAY_POINT_NOT_FOUND") {
            message.error("Could not find Waypoint");
          }
        })
        .finally(() => {
          setMakingApiCall(false);
        });
    } else {
      setWayPoint(null);
    }
    return () => abortController.abort();
  }, [dispatch, wayPointId]);

  return (
    <Drawer
      width={500}
      title={!wayPointId ? "Add Waypoint" : "Update Waypoint"}
      placement="right"
      onClose={() => handleClose(unsavedChanges)}
      open
    >
      <Spin
        size="large"
        spinning={makingApiCall}
        style={{ marginTop: 64 }}
        delay={500}
      >
        {!makingApiCall && (
          <WayPointForm
            wayPoint={wayPoint}
            onSave={onSave}
            onSaveFailed={onSaveFailed}
            onCancel={handleClose}
            onDirty={handleDirty}
            unsavedChanges={unsavedChanges}
            setUnsavedChanges={setUnsavedChanges}
          />
        )}
      </Spin>
    </Drawer>
  );
};

export default DrawerWayPoints;
