import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import originalAxios from 'axios';
import axios, { baseURL } from '../axios';

const initialState = {
	Brand: { loading: false, brandData: null },
};

export const getTenantBrand = createAsyncThunk('Brand/getTenantBrand', async () => {
	try {
		const response = await originalAxios.get(`${baseURL}tenant-brand`); // make use of the original axios since the tenant brand is a public route
		return response.data;
	} catch (error) {
		if (
			error.response.status === 404 ||
			error.response.data.message === 'TENANT_BRAND_NOT_FOUND'
		) {
			const customError = {
				name: 'TENANT_BRAND_NOT_FOUND',
				message: error.response.data.message,
				data: error.response.data,
			};
			throw customError;
		} else {
			const customError = {
				name: 'Custom axios error',
				message: error.response.data.message,
				data: error.response.data,
			};
			throw customError;
		}
	}
});

export const updateTenantBrand = createAsyncThunk('Brand/updateTenantBrand', async (data) => {
	try {
		const response = await axios.put(`/tenant-brand`, data);
		return response.data;
	} catch (error) {
		if (
			error.response.status === 404 ||
			error.response.data.message === 'TENANT_BRAND_NOT_FOUND'
		) {
			const customError = {
				name: 'TENANT_BRAND_NOT_FOUND',
				message: error.response.data.message,
				data: error.response.data,
			};
			throw customError;
		} else {
			const customError = {
				name: 'Custom axios error',
				message: error.response.data.message,
				data: error.response.data,
			};
			throw customError;
		}
	}
});

export const brandSlice = createSlice({
	name: 'Brand',
	initialState,
	reducers: {},
	extraReducers: {
		[getTenantBrand.pending]: (state) => {
			state.Brand.loading = true;
		},
		[getTenantBrand.fulfilled]: (state, action) => {
			state.Brand.loading = false;
			state.Brand.brandData = action.payload;
		},
		[getTenantBrand.rejected]: (state) => {
			state.Brand.loading = false;
			state.Brand.brandData = null;
		},
		[updateTenantBrand.pending]: (state) => {
			state.Brand.loading = true;
		},
		[updateTenantBrand.fulfilled]: (state, action) => {
			state.Brand.loading = false;
			state.Brand.brandData = action.payload;
		},
		[updateTenantBrand.rejected]: (state) => {
			state.Brand.loading = false;
			state.Brand.brandData = null;
		},
	},
});

export default brandSlice.reducer;
