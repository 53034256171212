// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/react-in-jsx-scope */
export const walletSvg = () => {
	return (
		<svg
			id='1'
			width='25'
			height='25'
			fill='#f5b151'
			viewBox='0 0 24 24'
			xmlns='http://www.w3.org/2000/svg'
			style={{ marginRight: '10px' }}>
			<path d='M4.476 4.874h15c.175 0 .35.011.524.033a3.094 3.094 0 0 0-3.633-2.604L4.031 4.409h-.014a3.094 3.094 0 0 0-1.925 1.225 4.105 4.105 0 0 1 2.384-.76Z' />
			<path d='M19.477 6h-15a3.003 3.003 0 0 0-3 3v9a3.003 3.003 0 0 0 3 3h15a3.003 3.003 0 0 0 3-3V9a3.004 3.004 0 0 0-3-3Zm-2.227 9a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Z' />
			<path d='M1.5 12.164V7.5c0-1.016.563-2.719 2.515-3.088 1.657-.31 3.298-.31 3.298-.31s1.078.75.187.75C6.61 4.852 6.633 6 7.5 6s0 1.102 0 1.102l-3.492 3.96L1.5 12.165Z' />
		</svg>
	);
};

export const navigateSvg = () => {
	return (
		<svg
			id='1'
			width='25'
			height='25'
			fill='#f5b151'
			viewBox='0 0 24 24'
			xmlns='http://www.w3.org/2000/svg'
			style={{ marginRight: '10px' }}>
			<path d='M12.136 2.25c-5.484-.073-9.96 4.403-9.886 9.887.073 5.259 4.355 9.54 9.614 9.614 5.484.075 9.96-4.402 9.885-9.885-.072-5.26-4.354-9.542-9.613-9.615Zm4.317 5.965-3.855 8.743c-.225.491-.974.329-.974-.213v-4.182a.188.188 0 0 0-.188-.187h-4.18c-.54 0-.703-.744-.214-.969l8.744-3.86a.503.503 0 0 1 .667.668Z' />
		</svg>
	);
};

export const mailSvg = () => {
	return (
		<svg
			id='1'
			width='25'
			height='25'
			fill='#f5b151'
			viewBox='0 0 24 24'
			xmlns='http://www.w3.org/2000/svg'>
			<path d='M19.875 3.75H4.125A2.628 2.628 0 0 0 1.5 6.375v11.25a2.628 2.628 0 0 0 2.625 2.625h15.75a2.627 2.627 0 0 0 2.625-2.625V6.375a2.627 2.627 0 0 0-2.625-2.625Zm-.665 4.342-6.75 5.25a.75.75 0 0 1-.92 0l-6.75-5.25a.75.75 0 1 1 .92-1.184L12 11.8l6.29-4.892a.75.75 0 0 1 .92 1.184Z' />
		</svg>
	);
};

export const phoneSvg = () => {
	return (
		<svg
			id='1'
			width='25'
			height='25'
			fill='#f5b151'
			viewBox='0 0 24 24'
			xmlns='http://www.w3.org/2000/svg'>
			<path d='M18.327 22.5c-.915 0-2.2-.331-4.125-1.407-2.34-1.312-4.15-2.524-6.478-4.846-2.245-2.243-3.337-3.695-4.865-6.476C1.132 6.63 1.426 4.984 1.755 4.28c.392-.842.97-1.345 1.718-1.844a8.263 8.263 0 0 1 1.343-.712l.13-.057c.231-.105.583-.263 1.028-.094.297.112.562.34.978.75.852.84 2.015 2.71 2.445 3.63.288.619.479 1.028.48 1.486 0 .537-.27.95-.598 1.397l-.182.242c-.356.469-.435.604-.383.846.104.486.884 1.933 2.165 3.212 1.281 1.278 2.686 2.008 3.174 2.112.253.054.39-.027.875-.397.069-.053.14-.107.215-.162.5-.372.894-.635 1.418-.635h.003c.456 0 .847.198 1.493.524.844.426 2.771 1.575 3.616 2.427.412.415.64.679.753.976.169.447.01.797-.094 1.031l-.057.129a8.27 8.27 0 0 1-.716 1.34c-.499.745-1.004 1.322-1.846 1.714a3.16 3.16 0 0 1-1.386.304Z' />
		</svg>
	);
};

export const personSvg = () => {
	return (
		<svg
			id='1'
			width='22'
			height='22'
			fill='#f5b151'
			viewBox='0 0 24 24'
			xmlns='http://www.w3.org/2000/svg'>
			<path d='M16.125 6.75c-.184 2.478-2.063 4.5-4.125 4.5-2.063 0-3.945-2.021-4.125-4.5-.188-2.578 1.64-4.5 4.125-4.5 2.484 0 4.312 1.969 4.125 4.5Z' />
			<path d='M12 14.25c-4.078 0-8.217 2.25-8.983 6.497-.092.512.197 1.003.733 1.003h16.5c.536 0 .826-.491.734-1.003C20.217 16.5 16.078 14.25 12 14.25Z' />
		</svg>
	);
};
