import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../axios/index';

const initialState = {
	dashboard: {
		loading: false,
		siteStatistics: null,
		bookingStatistics: null,
		ridesCount: null,
		driversCount: null,
		cancelledVsCompleted: null,
		registeredUsers: null,
	},
};

export const getSiteStatistics = createAsyncThunk('Dashboard/getSiteStatistics', async () => {
	try {
		const response = await axiosInstance.get(`dashboard/site-statistics`);
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Error login',
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const getBookingStatistics = createAsyncThunk('Dashboard/getBookingStatistics', async () => {
	try {
		const response = await axiosInstance.get(`dashboard/booking-statistics`);
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Error login',
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const getBookingStatisticsByPeriod = createAsyncThunk(
	'Dashboard/getBookingStatisticsByPeriod',
	async (period) => {
		try {
			const response = await axiosInstance.get(`dashboard/booking-analysis?period=${period}`);
			return response.data;
		} catch (error) {
			const customError = {
				name: 'Error getting booking statistics',
				message: error.response.data.message,
			};
			throw customError;
		}
	},
);

export const getDriverStatisticsByPeriod = createAsyncThunk(
	'Dashboard/getDriverStatisticsByPeriod',
	async (period) => {
		try {
			const response = await axiosInstance.get(`dashboard/drivers-analysis?period=${period}`);
			return response.data;
		} catch (error) {
			const customError = {
				name: 'Error getting drivers statistics',
				message: error.response.data.message,
			};
			throw customError;
		}
	},
);

export const completedVsCancelled = createAsyncThunk(
	'Dashboard/completedVsCancelled',
	async (period) => {
		try {
			const response = await axiosInstance.get(
				`dashboard/completed-vs-cancelled?period=${period}`,
			);
			return response.data;
		} catch (error) {
			const customError = {
				name: 'Error getting completed vs cancelled',
				message: error.response.data.message,
			};
			throw customError;
		}
	},
);

export const registeredUsers = createAsyncThunk('Dashboard/registeredUsers', async (period) => {
	try {
		const response = await axiosInstance.get(`dashboard/registered-users?period=${period}`);
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Error getting registered users',
			message: error.response.data.message,
		};
		throw customError;
	}
});

const formatBookingStatusName = (status) => {
	switch (status) {
		case 'COMPLETED':
			return 'Completed';
		case 'CANCELLED':
			return 'Cancelled';
		case 'PENDING':
			return 'Pending';
		case 'ACCEPTED':
			return 'Accepted';
		case 'REQUESTED':
			return 'Requested';
		case 'IN_PROGRESS':
			return 'In Progress';
		case 'TIMED_OUT':
			return 'Timed Out';
		default:
			return 'Unknown';
	}
};

export const dashboardSlice = createSlice({
	name: 'Dashboard',
	initialState,
	reducers: {},
	extraReducers: {
		[getSiteStatistics.pending]: (state) => {
			state.dashboard.loading = true;
		},
		[getSiteStatistics.fulfilled]: (state, action) => {
			state.dashboard.loading = false;
			state.dashboard.siteStatistics = action.payload;
		},
		[getSiteStatistics.rejected]: (state) => {
			state.dashboard.loading = false;
		},
		[getBookingStatistics.pending]: (state) => {
			state.dashboard.loading = true;
		},
		[getBookingStatistics.fulfilled]: (state, action) => {
			state.dashboard.loading = false;
			state.dashboard.bookingStatistics = action.payload;
		},
		[getBookingStatistics.rejected]: (state) => {
			state.dashboard.loading = false;
		},
		[getBookingStatisticsByPeriod.pending]: (state) => {
			state.dashboard.loading = true;
		},
		[getBookingStatisticsByPeriod.fulfilled]: (state, action) => {
			state.dashboard.loading = false;
			// format the data to be used in the chart to show a user fiendly booking status name
			const formattedData = action.payload.map((item) => {
				return {
					...item,
					type: formatBookingStatusName(item.type),
				};
			});
			state.dashboard.ridesCount = formattedData;
		},
		[getBookingStatisticsByPeriod.rejected]: (state) => {
			state.dashboard.loading = false;
		},
		[getDriverStatisticsByPeriod.pending]: (state) => {
			state.dashboard.loading = true;
		},
		[getDriverStatisticsByPeriod.fulfilled]: (state, action) => {
			state.dashboard.loading = false;
			state.dashboard.driversCount = action.payload;
		},
		[getDriverStatisticsByPeriod.rejected]: (state) => {
			state.dashboard.loading = false;
		},
		[completedVsCancelled.pending]: (state) => {
			state.dashboard.loading = true;
		},
		[completedVsCancelled.fulfilled]: (state, action) => {
			state.dashboard.loading = false;
			state.dashboard.cancelledVsCompleted = action.payload;
		},
		[completedVsCancelled.rejected]: (state) => {
			state.dashboard.loading = false;
		},
		[registeredUsers.pending]: (state) => {
			state.dashboard.loading = true;
		},
		[registeredUsers.fulfilled]: (state, action) => {
			state.dashboard.loading = false;
			state.dashboard.registeredUsers = action.payload;
		},
		[registeredUsers.rejected]: (state) => {
			state.dashboard.loading = false;
		},
	},
});

export default dashboardSlice.reducer;
