export const currency = ['EUR', 'USD'];
export const tenantId = 1;
export const permissionDriver = 'DRIVER';
export const permissionRider = 'RIDER';
export const permissionAdmin = 'ADMIN';
export const PEAK_SURCHARGE = 'PEAK_SURCHARGE';
export const NIGHT_SHIFT = 'NIGHT_SHIFT';
export const daysOfWeek = [
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
	'Sunday',
];
export const vehicleCategory = ['Car', 'Moto-Bike'];
export const fareModelStrategy = ['Incremental', 'Fixed'];
export const locationFor = [
	'Restriction Area',
	'Vehicle/Service Type',
	'Locationwise Fare',
	'Airport Surcharge',
];
export const RESTRICTION_AREA = 'Restriction Area';

export const restrictArea = ['Pick Up', 'Drop Off'];
export const restrictAreaType = ['Disallowed', 'Allowed'];
export const geo = [
	{
		id: 1,
		locationName: 'DDDD',
	},
	{
		id: 2,
		locationName: 'EEE',
	},
];
export const currencyItems = [
	{
		label: 'EUR',
		value: '€',
	},
	{
		label: 'USD',
		value: '$',
	},
];

export const driverRequestTypeItems = [
	{
		label: 'By nearest',
		value: 'BY_NEAREST',
	},
	{
		label: 'By least amount of rides',
		value: 'BY_LEAST_AMOUNT_OF_RIDES',
	},
];

export const bookingStatuses = [
	{
		name: 'Pending',
		style: 'statusPending',
		enum: 'PENDING',
		id: 1,
	},
	{
		name: 'In Progress',
		style: 'statusInProgress',
		enum: 'IN_PROGRESS',
		id: 2,
	},
	{
		name: 'Accepted',
		style: 'statusAccepted',
		enum: 'ACCEPTED',
		id: 3,
	},
	{
		name: 'Completed',
		style: 'statusCompleted',
		enum: 'COMPLETED',
		id: 4,
	},
	{
		name: 'Cancelled',
		style: 'statusCancelled',
		enum: 'CANCELLED',
		id: 5,
	},
	{
		name: 'Requested',
		style: 'statusRequested',
		enum: 'REQUESTED',
		id: 6,
	},
	{
		name: 'Timed Out',
		style: 'statusTimedOut',
		enum: 'TIMED_OUT',
		id: 7,
	},
];

export const bookingPoolStatuses = [
	{
		name: 'Started',
		style: 'statusInProgress',
		id: 1,
		value: 'STARTED',
	},
	{
		name: 'Completed',
		style: 'statusCompleted',
		id: 4,
		value: 'COMPLETED',
	},
];

export const daysOfWeekWithCapitalValue = [
	{ label: 'Monday', value: 'MONDAY' },
	{ label: 'Tuesday', value: 'TUESDAY' },
	{ label: 'Wednesday', value: 'WEDNESDAY' },
	{ label: 'Thursday', value: 'THURSDAY' },
	{ label: 'Friday', value: 'FRIDAY' },
	{ label: 'Saturday', value: 'SATURDAY' },
	{ label: 'Sunday', value: 'SUNDAY' },
];
export const SHARED = 'SHARED';
export const PRIVATE = 'PRIVATE';
export const SHARED_PRIVATE = 'SHARED_PRIVATE';
