/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/prop-types */
import { Drawer, message, Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import RestrictedAreaForm from './RestrictedAreaForm';
import {
	createRestrictedArea,
	getRestrictedArea,
	updateRestrictedArea,
} from '../../redux/restrictedAreaSlice';
import { getAllLocation } from '../../redux/locationSlice';
import ShowConfirmClose from '../Modals/ShowConfirmClose';

const RestrictedAreaDrawer = ({ onClose, restrictedAreaId, handleDlete }) => {
	const [unsavedChanges, setUnsavedChanges] = useState(false);
	const [makingApiCall, setMakingApiCall] = useState(false);
	const [messageApi, contextHolder] = message.useMessage();
	const dispatch = useDispatch();
	const [restrictArea, setRestrictArea] = useState(undefined);
	const [locationsFor, setLocationsFor] = useState(undefined);

	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const handleClose = useCallback(
		(hasUnsavedChanges) => {
			if (hasUnsavedChanges && !confirmationOpen) {
				setConfirmationOpen(true); // Mark that the confirmation modal is open
				ShowConfirmClose(() => {
					setConfirmationOpen(false); // Reset when the confirmation modal is closed
					onClose();
				});
			} else {
				onClose();
			}
		},
		[onClose, confirmationOpen],
	);

	const onSave = (values) => {
		values = { ...values, restrictArea: values.restrictArea.join(',') };
		console.log('values', values);

		if (!restrictedAreaId) addRestrictedArea(values);
		else updateRestrictedArea_(values);
	};

	const addRestrictedArea = (values) => {
		const locationId = values.locationName;
		delete values.locationName;
		values = { ...values, locationId };
		setMakingApiCall(true);
		dispatch(
			createRestrictedArea({
				data: values,
			}),
		)
			.unwrap()
			.then(() => {
				setUnsavedChanges(false);
				messageApi.open({
					type: 'success',
					content: 'Restricted Area saved successfully',
				});
			})
			.catch(() => {
				message.error('Restricted Area not saved successfully');
			})
			.finally(() => {
				onClose();
				setMakingApiCall(false);
			});
	};

	const updateRestrictedArea_ = (values) => {
		let locationId;
		if (!isNaN(values.locationName)) {
			locationId = values.locationName;
		} else {
			locationId = locationsFor.find((item) => item.locationName === values.locationName)?.id;
		}
		delete values.locationName;
		values = { ...values, locationId };
		setMakingApiCall(true);
		dispatch(
			updateRestrictedArea({
				restrictedAreaId,
				data: values,
			}),
		)
			.unwrap()
			.then(() => {
				messageApi.open({
					type: 'success',
					content: 'Restricted Area updated successfully',
				});
				setUnsavedChanges(false);
			})
			.catch(() => {
				message.error('Restricted Area not updated successfully');
			})
			.finally(() => {
				onClose();
				setMakingApiCall(false);
			});
	};

	const onSaveFailed = () => {};

	const handleDirty = useCallback(() => {
		setUnsavedChanges(true);
	}, []);

	useEffect(() => {
		const abortController = new AbortController();
		if (restrictedAreaId) {
			setMakingApiCall(true);
			const calls = [
				dispatch(
					getRestrictedArea({
						restrictedAreaId,
					}),
				),
			];
			Promise.all(calls)
				.then((res) => {
					if (restrictedAreaId) {
						setRestrictArea(res[0].payload);
					}
				})
				.catch(() => {
					message.error('Error while fetching Restricted Area');
					onClose();
				})
				.finally(() => {
					setMakingApiCall(false);
				});
		}

		return () => abortController.abort();
	}, [restrictedAreaId, dispatch]);

	useEffect(() => {
		const abortController = new AbortController();

		const calls = [dispatch(getAllLocation())];
		Promise.all(calls)
			.then((res) => {
				setLocationsFor(res[0].payload);
			})
			.catch(() => {
				message.error('Error while fetching locations');
			})
			.finally(() => {
				setMakingApiCall(false);
			});
		return () => abortController.abort();
	}, [dispatch]);

	return (
		<>
			{contextHolder}
			<Drawer
				width={500}
				title={!restrictedAreaId ? 'Add Restricted Area' : 'Update Restricted Area'}
				placement='right'
				onClose={() => handleClose(unsavedChanges)}
				open>
				<Spin size='large' spinning={makingApiCall} style={{ marginTop: 64 }} delay={500}>
					{!makingApiCall && (
						<RestrictedAreaForm
							restrictedArea={restrictArea}
							locationsFor={locationsFor}
							onSave={onSave}
							onSaveFailed={onSaveFailed}
							onDelete={handleDlete}
							onCancel={handleClose}
							onDirty={handleDirty}
							unsavedChanges={unsavedChanges}
							setUnsavedChanges={setUnsavedChanges}
						/>
					)}
				</Spin>
			</Drawer>
		</>
	);
};

export default RestrictedAreaDrawer;
