/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import { Drawer, message, Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAllLocation } from '../../redux/locationSlice';
import {
	addNewLocationWiseFare,
	deleteLocationWiseFare,
	getLocationWiseFareById,
	updateLocationWiseFare,
} from '../../redux/LocationWiseFareSlice';
import { getAllVehicleType } from '../../redux/vehicleTypeSlice';
import ShowConfirmClose from '../Modals/ShowConfirmClose';
import LocationWiseFareForm from './LocationWiseFareForm';

const LocationWiseFareDrawer = ({ locationWiseFareId, handleClose }) => {
	const [unsavedChanges, setUnsavedChanges] = useState(false);
	const dispatch = useDispatch();
	const [makingApiCall, setMakingApiCall] = useState(false);
	const [locationWiseFare, setLocationWiseFare] = useState(undefined);
	const fetchVehicleTypesAndLocations = () => {
		dispatch(getAllVehicleType());
		dispatch(getAllLocation());
	};

	useEffect(() => {
		fetchVehicleTypesAndLocations();
	}, []);

	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const handleCloseDrawer = useCallback(
		(hasUnsavedChanges) => {
			if (hasUnsavedChanges && !confirmationOpen) {
				setConfirmationOpen(true); // Mark that the confirmation modal is open
				ShowConfirmClose(() => {
					setConfirmationOpen(false); // Reset when the confirmation modal is closed
					handleClose();
				});
			} else {
				handleClose();
			}
		},
		[handleClose, confirmationOpen],
	);

	useEffect(() => {
		const abortController = new AbortController();

		if (locationWiseFareId) {
			setMakingApiCall(true);
			const calls = [
				dispatch(
					getLocationWiseFareById({
						id: locationWiseFareId,
					}),
				),
			];
			Promise.all(calls)
				.then((res) => {
					if (locationWiseFareId) {
						const response = res[0].payload;

						setLocationWiseFare({
							...response,
							vehicleTypeId: response.vehicleType.id,
							sourceLocationId: response.sourceLocation.id,
							destinationLocationId: response.destinationLocation.id,
						});
					}
				})
				.catch(() => {
					handleClose();
				})
				.finally(() => {
					setMakingApiCall(false);
				});
		}
		return () => abortController.abort();
	}, [locationWiseFareId, dispatch]);

	const handleDirty = useCallback(() => {
		setUnsavedChanges(true);
	}, []);

	const saveLocationWiseFare = (values) => {
		setMakingApiCall(true);
		values = {
			...values,
			isActive: locationWiseFare?.isActive,
		};
		const action = !locationWiseFareId
			? addNewLocationWiseFare({
					locationWiseFare: values,
			  })
			: updateLocationWiseFare({
					id: locationWiseFareId,
					locationWiseFare: values,
			  });

		dispatch(action)
			.unwrap()
			.then(() => {
				message.success('Locationwise Fare saved successfully');
				setUnsavedChanges(false);
			})
			.catch((e) => {
				if (e.message === 'LOCATION_WISE_FARE_ALREADY_EXISTS') {
					message.error('Locationwise Fare already exists');
				} else {
					message.error('Could not save Locationwise Fare, please try again');
				}
			})
			.finally(() => {
				handleClose();
				setMakingApiCall(false);
			});
	};

	const onSave = (values) => {
		saveLocationWiseFare(values);
	};

	const onDelete = (id) => {
		setMakingApiCall(true);
		dispatch(
			deleteLocationWiseFare({
				id,
			}),
		)
			.unwrap()
			.then(() => {
				message.success('Locationwise Fare deleted successfully');
				setUnsavedChanges(false);
			})
			.catch(() => {
				message.error('Could not delete Locationwise Fare, please try again');
			})
			.finally(() => {
				handleClose();
				setMakingApiCall(false);
			});
	};

	const onSaveFailed = () => {
		message.error('Could not save Locationwise Fare, please try again later');
	};

	return (
		<Drawer
			width={500}
			title={!locationWiseFareId ? 'Add Locationwise Fare' : 'Update Locationwise Fare'}
			closable={false}
			placement='right'
			onClose={() => handleClose()}
			open>
			<Spin size='large' spinning={false} style={{ marginTop: 64 }} delay={500}>
				{!makingApiCall && (
					<LocationWiseFareForm
						locationWiseFare={locationWiseFare}
						onDirty={handleDirty}
						unsavedChanges={unsavedChanges}
						onSave={onSave}
						onDelete={onDelete}
						onSaveFailed={onSaveFailed}
						onClose={handleCloseDrawer}
					/>
				)}
			</Spin>
		</Drawer>
	);
};

export default LocationWiseFareDrawer;
