/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
import { Rate, Tooltip } from 'antd';
import React from 'react';
import { style } from '../../Styles';
import ReviewNotSubmited from './ReviewNotSubmited';

const desc = [
	{ text: 'TERRIBLE', emoji: '😞' },
	{ text: 'BAD', emoji: '😕' },
	{ text: 'NORMAL', emoji: '😐' },
	{ text: 'GOOD', emoji: '🙂' },
	{ text: 'WONDERFUL', emoji: '😄' },
];

const ReviewCard = ({ assigned, review }) => {
	const ratingIndex = assigned && Math.round(review.rating) - 1;
	const ratingText = assigned && desc[ratingIndex].text;
	const ratingEmoji = assigned && desc[ratingIndex].emoji;
	return (
		<div
			style={{
				...style.boxShadow,
				height: '190px',
				marginTop: '1em',
				display: 'flex',
				alignItems: 'center',
			}}>
			{assigned ? (
				<div>
					<h4 style={{ margin: '0 20px ' }}>Review</h4>
					<div style={{ margin: '0 20px ' }}>
						<span>
							<Rate
								tooltips={desc.map((item) => item.text + item.emoji)}
								disabled
								value={review.rating}
							/>
							{review ? (
								<h4 className='ant-rate-text'>{`${ratingText} ${ratingEmoji}`}</h4>
							) : (
								''
							)}
						</span>
					</div>
					{review?.comment && (
						<div style={{ margin: '0 20px ' }}>
							<span>
								<b>Comment:</b>
								<Tooltip title={review.comment}>
									<span
										style={{
											display: '-webkit-box',
											WebkitBoxOrient: 'vertical',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											WebkitLineClamp: 1,
										}}>
										{review.comment}
									</span>
								</Tooltip>
							</span>
						</div>
					)}
				</div>
			) : (
				<ReviewNotSubmited />
			)}
		</div>
	);
};

export default ReviewCard;
