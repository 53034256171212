import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import axiosInstance, { baseURL } from '../axios/index';

const initialState = {
	auth: {
		loading: false,
		loggedIn: [],
		token: null,
		init: false,
		profile: null,
		uploadProgress: 0, // New field to track upload progress
	},
};

export const authentication = createAsyncThunk('Auth/login', async (values) => {
	try {
		const response = await axiosInstance.post(`login`, values);
		return response.data;
	} catch (error) {
		console.log(error);
		const customError = {
			name: 'Error login',
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const requestChangePassword = createAsyncThunk(
	'Auth/requestChangePassword',
	async (email) => {
		try {
			// trim email
			email = email.trim();
			const response = await axiosInstance.post(`requestPasswordReset`, {
				email,
			});

			return response;
		} catch (error) {
			console.log('Error', error);
			const customError = {
				name: 'Error login',
				message: error.response.data.message,
			};
			throw customError;
		}
	},
);

export const checkPasswordResetCode = createAsyncThunk(
	'Auth/checkPasswordResetCode',
	async (data) => {
		try {
			// trim email and code
			data.email = data.email.trim();
			data.code = data.code.trim();
			const response = await axiosInstance.post(`checkPasswordResetCode`, {
				code: data.code,
				email: data.email,
			});
			return response.data;
		} catch (error) {
			const customError = {
				name: 'Error login',
				message: error.response.data.message,
			};
			throw customError;
		}
	},
);

export const resetPassword = createAsyncThunk('Auth/resetPassword', async (data) => {
	try {
		// trim email and code
		data.email = data.email.trim();
		data.code = data.code.trim();
		const response = await axiosInstance.post(`resetPassword`, {
			code: data.code,
			email: data.email,
			password: data.password,
			tenantId: data.tenantId,
		});
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Error login',
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const logout = createAsyncThunk('Auth/logout', async () => {
	const response = await axiosInstance.post('logoutAndSaveLogs', {
		withCredentials: true,
	});
	return response.data;
});

export const refreshTokenFun = createAsyncThunk('auth/refreshToken', async () => {
	try {
		const { username } = initialState.auth.loggedIn;
		const { refreshToken } = initialState.auth.loggedIn;
		const response = await axios.get(`${baseURL}refresh-token`, {
			username,
			refreshToken,
		});
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.error,
			data: error.response.data,
		};
		throw customError;
	}
});

export const profile = createAsyncThunk('Auth/profile', async () => {
	try {
		const response = await axiosInstance.get(`me`);
		return response.data;
	} catch (error) {
		console.log(error);
		const customError = {
			name: 'Error Profile',
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const updateProfile = createAsyncThunk('Auth/updateProfile', async (data) => {
	try {
		const response = await axiosInstance.put(`users/${data.id}`, data);
		return response.data.user;
	} catch (error) {
		console.log(error);
		const customError = {
			name: 'Error Updating Profile',
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const changePassword = createAsyncThunk('Auth/changePassword', async (data) => {
	try {
		const response = await axiosInstance.put(`users/changePassword`, data);
		console.log('Response', response.data);
		return response.data;
	} catch (error) {
		console.log(error);
		const customError = {
			name: 'Error Updating Password',
			message: error.response.data.message,
		};
		throw customError;
	}
});

export const uploadProfileImage = createAsyncThunk(
	'Auth/uploadProfileImage',
	async (file, { dispatch }) => {
		try {
			const formData = new FormData();
			formData.append('file', file);

			const response = await axiosInstance.post(`files/profilePictureAndSetUser`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				onUploadProgress: (progressEvent) => {
					const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
					dispatch(setUploadProgress(progress)); // Dispatch an action to update the progress
				},
			});

			return response.data;
		} catch (error) {
			const customError = {
				name: error.name,
				message: error.response.data.message,
			};
			throw customError;
		}
	},
);

export const setUploadProgress = (progress) => ({
	type: 'SET_UPLOAD_PROGRESS',
	payload: progress,
});

export const authSlice = createSlice({
	name: 'Auth',
	initialState,
	reducers: {
		setToken: (state, action) => {
			state.auth.token = action.payload;
			state.auth.loading = false;
		},
		setLoggedIn: (state, action) => {
			state.auth.loggedIn = action.payload;
			state.auth.loading = false;
		},
		setUploadProgress: (state, action) => {
			state.auth.uploadProgress = action.payload; // Update the upload progress
		},
	},
	extraReducers: {
		[authentication.pending]: (state) => {
			state.auth.loading = true;
		},
		[authentication.rejected]: (state) => {
			state.auth.loading = false;
		},
		[authentication.fulfilled]: (state, action) => {
			state.auth.loading = false;
			state.auth.loggedIn = action.payload;
			state.auth.token = action.payload.accessToken;
			state.auth.init = true;
		},
		[refreshTokenFun.pending]: (state) => {
			state.auth.loggedIn = [];
			state.auth.loading = true;
		},
		[refreshTokenFun.fulfilled]: (state, action) => {
			state.auth.init = true;
			state.auth.token = action.payload.accessToken;
			state.auth.loading = false;
		},
		[refreshTokenFun.rejected]: (state) => {
			state.auth.loggedIn = [];
			state.auth.token = null;
			state.auth.loading = false;
		},
		[logout.fulfilled]: (state) => {
			state.auth.loggedIn = [];
			state.auth.init = false;
			state.auth.token = null;
			state.auth.profile = null;
		},
		[profile.pending]: (state) => {
			state.auth.loading = true;
		},
		[profile.rejected]: (state) => {
			state.auth.loading = false;
		},
		[profile.fulfilled]: (state, action) => {
			state.auth.loading = false;
			state.auth.profile = action.payload;
		},
		[updateProfile.pending]: (state) => {
			state.auth.loading = true;
		},
		[updateProfile.rejected]: (state) => {
			state.auth.loading = false;
		},
		[updateProfile.fulfilled]: (state, action) => {
			state.auth.loading = false;
			state.auth.profile = action.payload;
		},
		[changePassword.pending]: (state) => {
			state.auth.loading = true;
		},
		[changePassword.rejected]: (state) => {
			state.auth.loading = false;
		},
		[changePassword.fulfilled]: (state, action) => {
			state.auth.loading = false;
			state.auth.profile = action.payload;
		},
		[uploadProfileImage.pending]: (state) => {
			state.auth.loading = true;
		},
		[uploadProfileImage.rejected]: (state) => {
			state.auth.loading = false;
		},
		[uploadProfileImage.fulfilled]: (state, action) => {
			state.auth.loading = false;
			state.auth.profile = action.payload;
		},
	},
});
export const { setToken, setLoggedIn } = authSlice.actions;
export default authSlice.reducer;
