/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-extraneous-dependencies */
import { Breadcrumb, Button, Card, Input, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MinusOutlined, ReloadOutlined, EyeOutlined } from '@ant-design/icons';
import {
	deleteVehicleType,
	getAllVehicleType,
	getNightShiftByVehicleTypeId,
	getPeakSurchargeByVehicleTypeId,
} from '../../redux/vehicleTypeSlice';
import CustomTableComponents from '../Global/CustomTableComponents';
import { style } from '../../Styles';
import { FareSurchargeModal } from './FareSurchargeModal';
import DrawerVehicleType from './DrawerVehicleType';
import { getAllLocation } from '../../redux/locationSlice';
import ActionButton from '../Global/ActionsButton';
import ImageComponent from '../Global/ImageComponent/ImageComponent';
import { getVehicleTypeFiles } from '../../redux/fileSlice';
import { NIGHT_SHIFT, PEAK_SURCHARGE } from '../../Utils/Constants/utils';

// todo implement location name when location management settled

const VehicleType = ({ role }) => {
	const { auth } = useSelector((state) => state.auth);
	const { Settings } = useSelector((state) => state.settings);

	const [filter, setfilter] = useState('');
	const dispatch = useDispatch();
	const [isMakingApiCall, setMakingApiCall] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalType, setModalType] = useState('');
	const [displayDrawer, setDisplayDrawer] = useState(false);
	const [vehicleType, setVehicleType] = useState(null);
	const [selectedRow, setSelectedRow] = useState('');
	const currencyRender = () => {
		return <span>{Settings?.settingsData?.currency || '€'}</span>;
	};
	const fetchData = () => {
		Promise.all([dispatch(getAllLocation()), dispatch(getVehicleTypeFiles())])
			.then(() => {})
			.catch(() => {
				message.error('Could not fetch locations');
				message.error('Could not fetch data');
			})
			.finally(() => {});
	};

	useEffect(() => {
		fetchData();
	}, []);

	const { Location } = useSelector((state) => state.locations);
	const locationArray = Object.values(Location.locationsList);

	const columns = [
		{
			title: 'Image',
			key: 'imageId',
			render: (record) => {
				return record?.imageId ? (
					<ImageComponent imageId={record?.imageId} />
				) : (
					<MinusOutlined />
				);
			},
			sorter: {
				compare: (a, b) => a.vehicleType - b.vehicleType,
				multiple: 5,
			},
		},
		{
			title: 'Vehicle Type',
			key: 'vehicleType',
			render: (record) => {
				return <span>{record.vehicleType}</span>;
			},
			sorter: {
				compare: (a, b) => a.vehicleType - b.vehicleType,
				multiple: 5,
			},
		},
		{
			title: 'Base Fare',
			key: 'baseFare',
			render: (record) => {
				return (
					<div>
						{currencyRender()}
						{record.baseFare}
					</div>
				);
			},
		},
		{
			title: 'Price per KM',
			key: 'pricePerKm',
			render: (record) => {
				return record.pricePerKm ? (
					<span>
						{currencyRender()}
						{record.pricePerKm}
					</span>
				) : (
					<MinusOutlined />
				);
			},
		},
		{
			title: 'Cancellation Charge',
			key: 'riderCancellationCharge',
			render: (record) => {
				return (
					<span>
						{currencyRender()}
						{record.riderCancellationCharge}
					</span>
				);
			},
		},
		{
			title: 'Location',
			key: 'locationId',
			render: (record) => {
				const location = locationArray.find((l) => l.id === record.locationId);

				return location ? <span>{location.locationName}</span> : <MinusOutlined />;
			},
		},
		{
			title: 'Available Seat',
			key: 'availableSeat',
			render: (record) => {
				return <span>{record.availableSeat}</span>;
			},
		},
		{
			title: 'Peak Surcharge',
			key: 'peakSurcharge',
			render: (record) => {
				return (
					<span style={style.btnView}>
						<Button
							type='default'
							disabled={!record.peakSurcharge}
							style={{ height: 30 }}
							onClick={(event) => {
								setSelectedRow(record.id);
								loadPeakSurchargeData(event, record.id);
							}}>
							<EyeOutlined />
						</Button>
					</span>
				);
			},
			width: 60,
		},
		{
			title: 'Night Shift',
			key: 'nightShift',
			render: (record) => {
				return (
					<span style={style.dataContainer}>
						<Button
							type='default'
							disabled={!record.nightShift}
							style={{ height: 30 }}
							onClick={(event) => {
								setSelectedRow(record.id);
								loadNightShiftData(event, record.id);
							}}>
							<EyeOutlined />
						</Button>
					</span>
				);
			},
			width: 60,
		},
		// ? The vehicle type status is not used in the backend so we hide it for now
		/* {
			title: 'Status',
			key: 'active',
			render: (record) => {
				return (
					<span style={style.dataContainer}>
						{record.active ? (
							<div style={style.statusActif}>ACTIVE</div>
						) : (
							<div style={style.statusInactif}>INACTIVE</div>
						)}
					</span>
				);
			},
			width: 90,
		}, */
		{
			title: 'Actions',
			key: 'actions',
			render: (text, record) => (
				<ActionButton
					record={record}
					onDelete={() => handleDlete(record?.id, () => {})}
					onEdit={() => handleSelectedVehicleType(record)}
					recordName={record.vehicleType}
				/>
			),

			disabled: !role?.includes('SUPER_ADMIN'),
		},
	];

	const handleDlete = (id, calbback) => {
		setMakingApiCall(true);
		dispatch(
			deleteVehicleType({
				vehicleTypeId: id,
			}),
		)
			.unwrap()
			.then(() => {
				calbback();
				message.success('Vehicle type deleted successfully');
			})
			.catch(() => {})
			.finally(() => {
				onCloseDrawer();
				setMakingApiCall(false);
			});
	};
	const { VehicleType, PeakSurchage, NightShift } = useSelector((state) => state.vehiclesType);

	const loadPeakSurchargeData = (event, id) => {
		event.stopPropagation();
		setModalType(PEAK_SURCHARGE);
		makeApiCallPeakSurcharge(id);
	};
	const loadNightShiftData = (event, id) => {
		event.stopPropagation();
		setModalType(NIGHT_SHIFT);
		makeApiCallNightShift(id);
	};

	const makeApiCallPeakSurcharge = (id) => {
		const params = {
			vehicleTypeId: id,
		};
		dispatch(getPeakSurchargeByVehicleTypeId(params))
			.unwrap()
			.then()
			.catch(() => {
				message.error('Failed to load peak surcharge data');
			})
			.finally(() => {
				setIsModalOpen(true);
			});
	};
	const makeApiCallNightShift = (id) => {
		const params = {
			vehicleTypeId: id,
		};
		dispatch(getNightShiftByVehicleTypeId(params))
			.unwrap()
			.then()
			.catch(() => {
				message.error('Failed to load night shift data');
			})
			.finally(() => {
				setIsModalOpen(true);
			});
	};

	const handlePeakSurchageSwitch = (values, id) => {
		if (values && id) {
			setModalType(PEAK_SURCHARGE);
			setIsModalOpen(true);
			makeApiCallPeakSurcharge(id);
		} else if (values) {
			setModalType(PEAK_SURCHARGE);
			setIsModalOpen(true);
		}
	};

	const handleNightShiftSwitch = (values, id) => {
		if (values && id) {
			setModalType(NIGHT_SHIFT);
			setIsModalOpen(true);
			makeApiCallNightShift(id);
		} else if (values) {
			setModalType(NIGHT_SHIFT);
			setIsModalOpen(true);
		}
	};

	const handleCancel = () => {
		setIsModalOpen(false);
		setSelectedRow(null);
	};

	const fetchAll = () => {
		setMakingApiCall(true);
		dispatch(getAllVehicleType())
			.unwrap()
			.then()
			.catch(() => {
				message.error('Failed to load vehicle type data');
			})
			.finally(() => {
				setMakingApiCall(false);
			});
	};
	useEffect(() => {
		fetchAll();
	}, [dispatch, auth.token]);

	const handleSelectedVehicleType = (selectedVehicleType) => {
		setVehicleType(selectedVehicleType);
		setSelectedRow(selectedVehicleType.id);
		showDrawer();
	};
	const showDrawer = () => {
		setDisplayDrawer(true);
	};
	const onCloseDrawer = () => {
		setDisplayDrawer(false);
		setVehicleType(null);
	};

	const [fs, setFs] = useState([]);

	const handleFsChange = (newFs) => {
		setFs(newFs);
	};

	let vehicleTypeArray = Object.values(VehicleType.vehicleTypeList);
	vehicleTypeArray = vehicleTypeArray.map((item) => {
		return { ...item, key: item.id };
	});

	const filtredData = vehicleTypeArray.filter((item) => {
		return (
			item?.id?.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
			item?.vehicleCategory?.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
			item?.vehicleType?.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
			item?.vehicleTypeInfo?.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1
		);
	});
	console.log(role?.includes('SUPER_ADMIN'));
	return (
		<Card
			style={{
				margin: 16,
				maxHeight: '90%', // Set maximum width
				height: 'auto', // Allow width to be dynamic
			}}>
			<div style={style.inlineContent}>
				<div
					style={{
						display: 'flex',
						alignContent: 'center',
						alignItems: 'center',
						float: 'left',
						marginBottom: 8,
					}}>
					<Breadcrumb
						style={{ margin: '0 3em 0 0', fontSize: '1.1em' }}
						items={[
							{
								title: 'Vehicles',
							},
							{
								title: 'Vehicle Types',
							},
						]}
					/>

					<Button
						type='default'
						size='small'
						// style={{ margin: '0 8px 8px 0' }}
						onClick={() => fetchAll()}>
						<ReloadOutlined />
					</Button>
				</div>

				<div
					style={{
						...style.inlineContent,
						float: 'right',
						marginBottom: 8,
					}}>
					<Input.Search
						placeholder='Search'
						style={{ margin: '0 8px 8px 0' }}
						value={filter}
						onChange={(e) => setfilter(e.target.value)}
					/>
					{role?.includes('SUPER_ADMIN') && (
						<Button
							type='default'
							style={{ float: 'right', marginBottom: 8 }}
							onClick={showDrawer}>
							Add Vehicle Type
						</Button>
					)}
				</div>
			</div>
			<CustomTableComponents
				isMakingApiCall={isMakingApiCall}
				columns={columns.filter((item) => {
					return !item.disabled;
				})}
				dataArray={filtredData}
				handleSelectedRow={() => {}}
				handleDoubleClickRow={(record) =>
					role?.includes('SUPER_ADMIN') ? handleSelectedVehicleType(record) : {}
				}
			/>
			{displayDrawer && (
				<DrawerVehicleType
					onClose={onCloseDrawer}
					vehicleTypeId={vehicleType?.id}
					handlePeakSurchageSwitch={handlePeakSurchageSwitch}
					handleNightShiftSwitch={handleNightShiftSwitch}
					fs={fs}
					handleDlete={handleDlete}
				/>
			)}
			<FareSurchargeModal
				isModalOpen={isModalOpen}
				setIsModalOpen={setIsModalOpen}
				type={modalType}
				vehicleTypeId={selectedRow}
				fareSurchargeData={
					selectedRow
						? modalType === PEAK_SURCHARGE
							? PeakSurchage.peakSurchageList
							: NightShift.nightShiftList
						: []
				}
				handleCancel={handleCancel}
				onFsChange={handleFsChange}
			/>
		</Card>
	);
};

export default VehicleType;
