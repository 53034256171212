/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/no-array-index-key */

/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, forwardRef, useImperativeHandle, useEffect, useRef } from 'react';
import {
	Breadcrumb,
	Button,
	Col,
	Divider,
	Form,
	Input,
	Row,
	Select,
	Spin,
	Tour,
	message,
} from 'antd';
import { SaveOutlined, LoadingOutlined } from '@ant-design/icons';
import { style } from '../../Styles';
import { getSettings, resetSettingsData, updateSettings } from '../../redux/settingsSlice';
import { currencyItems, driverRequestTypeItems } from '../../Utils/Constants/utils';

const SettingsForm = forwardRef(
	(
		{
			initalValues,
			makingApiCall,
			Settings,
			setMakingApiCall,
			setInitialValues,
			dispatch,
			role,
		},
		ref,
	) => {
		const [form] = Form.useForm();
		// Watch all values
		const formValues = Form.useWatch([], form);
		const saveButtonRef = useRef(null);
		const [open, setOpen] = useState(false);

		const saveSettings = (values) => {
			setMakingApiCall(true);
			dispatch(updateSettings(values))
				.unwrap()
				.then((res) => {
					message.success('Settings updated successfully');
					setInitialValues(res);
				})
				.catch(() => {
					message.error('Something went wrong. Please try again later.');
				})
				.finally(() => {
					setMakingApiCall(false);
				});
		};

		const steps = [
			{
				title: 'Hello',
				description: 'Im here to help you understand the different settings fields.',
				cover: (
					<img
						alt='tour.png'
						src={`${process.env.PUBLIC_URL}/images/tour.svg`}
						height={150}
					/>
				),
				target: null,
			},
			{
				title: 'Preferred Currency',
				description: 'This field is used to set the currency for your account.',
				target: () => document.getElementById('currencyFieldRef'),
			},
			{
				title: 'Start Button KM range (KM)',
				description:
					'This field is used to set the range when the driver should be in the pickup location to start the trip.',
				target: () => document.getElementById('startButtonFieldRef'),
			},
			{
				title: 'Radius Vicinity (KM)',
				description:
					'This field is used to set the range where the app should search for nearby drivers.',
				target: () => document.getElementById('vicinityRef'),
			},
			{
				title: 'Global Cancellation Fee',
				description:
					'This field is used to set the global cancellation fee if it is missing in the vehicle type.',
				target: () => document.getElementById('cancellationFeeRef'),
			},
			{
				title: 'Global Cancellation Time (SECONDS)',
				description:
					'This field is used to set the global cancellation time im seconds if it is missing in the vehicle type.',
				target: () => document.getElementById('autoDeclineRef'),
			},
			{
				title: 'Auto decline (SECONDS)',
				description:
					'This field is used to set the auto decline time in seconds if the driver does not accept the trip.',
				target: () => document.getElementById('cancellationTimeRef'),
			},
			{
				title: 'Driver Last Location (SECONDS)',
				description:
					'This field is used to set the time in seconds when the app should check the remaining time and distance to the pickup location.',
				target: () => document.getElementById('driverLastLocationRef'),
			},
			{
				title: 'Driver Timeout (SECONDS)',
				description:
					' This field is used to set the time in seconds if there is no vehicle available in the vicinity radius to timeout the request.',
				target: () => document.getElementById('driverTimeoutRef'),
			},
			{
				title: 'Save Button',
				description: 'Finally, click on this button to save the settings changes.',
				target: () => saveButtonRef.current,
			},
		];
		useImperativeHandle(ref, () => ({
			openTour() {
				setOpen(true);
			},
			closeTour() {
				setOpen(false);
			},
		}));

		const onSave = (values) => {
			saveSettings(values);
		};

		const onSaveFailed = (errorInfo) => {
			console.log('Failed:', errorInfo);
		};

		const onDirty = () => {
			console.log('dirty');
		};
		useEffect(() => {
			console.log('Settings', Settings?.settingsData);
		}, []);
		return (
			<Spin
				size='large'
				indicator={<LoadingOutlined style={{ fontSize: 54 }} spin />}
				tip={
					<div>
						<h2>Loading...</h2>
					</div>
				}
				spinning={makingApiCall}>
				<div
					style={{
						borderRadius: '17px',
						background: '#ffffff',
						marginTop: '1em',
					}}>
					<div
						style={{
							padding: '0 2em 0 2em',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
						}}>
						<Breadcrumb
							style={{ margin: '0 3em 0 0', fontSize: '1.1em' }}
							items={[
								{
									title: 'Settings',
								},
							]}
						/>
						{/*  <Button
              type="default"
              onClick={() => setOpen(true)}
              icon={<CaretRightOutlined />}
            >
              Start Tour
            </Button> */}
					</div>

					<Row style={{ overflowX: 'auto', height: '65vh' }}>
						<Col span={24}>
							<Form
								form={form}
								name='Settings Form'
								layout='vertical'
								initialValues={initalValues}
								onFinish={onSave}
								onFinishFailed={onSaveFailed}
								autoComplete='off'
								onValuesChange={() => onDirty()}>
								<Row>
									<Col span={8}>
										<Form.Item
											style={{ padding: '2em 2em 1em 2em', width: '80%' }}
											label='Preferred Currency'
											tooltip='This field is used to set the currency for your account.'
											name='currency'
											rules={[
												{
													required: true,
													message: 'Please input preferred currency',
												},
											]}>
											<Select
												id='currencyFieldRef'
												size='medium'
												showArrow
												showSearch
												optionFilterProp='filterBy'
												allowClear
												optionLabelProp='label'
												popupMatchSelectWidth={false}
												getPopupContainer={(trigger) => trigger.parentNode}
												// disable the select if there is a value of "currency"
												disabled={
													Settings?.settingsData?.currency ||
													!role?.includes('SUPER_ADMIN')
												}>
												{currencyItems.map((currency) => (
													<Select.Option
														key={currency.label}
														label={`${currency.label} (${currency.value})`}
														value={currency.value}>
														{`${currency.label} (${currency.value})`}
													</Select.Option>
												))}
											</Select>
										</Form.Item>

										<Form.Item
											style={{ padding: '0 2em 1em 2em', width: '80%' }}
											label='Radius Vicinity (KM)'
											tooltip='This field is used to set the range where the app should search for nearby drivers.'
											name='radiusVicinity'
											rules={[
												{
													required: true,
													message: 'Please input driver vicinity',
												},
												{
													type: 'number',
													min: 0,
													transform: (value) => parseFloat(value),
													message:
														'Please enter a number greater than or equal to 0',
												},
											]}>
											<Input
												type='number'
												id='vicinityRef'
												disabled={!role?.includes('SUPER_ADMIN')}
											/>
										</Form.Item>

										<Form.Item
											style={{ padding: '0 2em 1em 2em', width: '80%' }}
											label='Auto decline (SECONDS)'
											tooltip='This field is used to set the auto decline time in seconds if the driver does not accept the trip.'
											name='autoDeclineSeconds'
											rules={[
												{
													required: true,
													message: 'Please input driver auto decline',
												},
												{
													type: 'integer',
													min: 0,
													transform: (value) => parseFloat(value),
													message:
														'Please enter a non decimal number greater than or equal to 0',
												},
											]}>
											<Input
												type='number'
												id='autoDeclineRef'
												disabled={!role?.includes('SUPER_ADMIN')}
											/>
										</Form.Item>

										<Form.Item
											style={{ padding: '0 2em 1em 2em', width: '80%' }}
											label='Show Trial On Map'
											tooltip='This field is used to determine whether to display the trail on the map in both the driver and rider apps.'
											name='showTrialOnMap'
											rules={[
												{
													required: true,
													message: 'Please select show trial on map',
												},
											]}>
											<Select
												id='show_trial_on_map_Ref'
												size='medium'
												showArrow
												showSearch
												optionFilterProp='filterBy'
												allowClear
												optionLabelProp='label'
												getPopupContainer={(trigger) => trigger.parentNode}
												disabled={!role?.includes('SUPER_ADMIN')}>
												<Select.Option key='true' label='Yes' value>
													Yes
												</Select.Option>

												<Select.Option key='fals' label='No' value={false}>
													No
												</Select.Option>
											</Select>
										</Form.Item>

										<Form.Item
											style={{ padding: '0 2em 1em 2em', width: '80%' }}
											label='Allow Waypoints Only'
											tooltip='This field is used to determine whether to allow the rider to select any location or from pre-defined locations (waypoints).'
											name='allowWaypointsOnly'
											rules={[
												{
													required: true,
													message: 'Please select allow only waypoints',
												},
											]}>
											<Select
												id='allow_waypoints_only_Ref'
												size='medium'
												showArrow
												showSearch
												optionFilterProp='filterBy'
												allowClear
												optionLabelProp='label'
												getPopupContainer={(trigger) => trigger.parentNode}
												disabled={!role?.includes('SUPER_ADMIN')}>
												<Select.Option key='true' label='Yes' value>
													Yes
												</Select.Option>

												<Select.Option key='fals' label='No' value={false}>
													No
												</Select.Option>
											</Select>
										</Form.Item>

										<Form.Item
											style={{ padding: '0 2em 1em 2em', width: '80%' }}
											label='Allow Booking Without Driver Documents'
											tooltip='This field is used to determine whether to allow the rider to book without the driver having uploaded the required documents.'
											name='allowBookingWithoutDriverDocuments'
											rules={[
												{
													required: true,
													message:
														'Please select allow booking without driver documents',
												},
											]}>
											<Select
												id='allow_booking_without_driver_documents_Ref'
												size='medium'
												showArrow
												showSearch
												optionFilterProp='filterBy'
												allowClear
												optionLabelProp='label'
												getPopupContainer={(trigger) => trigger.parentNode}
												disabled={!role?.includes('SUPER_ADMIN')}>
												<Select.Option key='true' label='Yes' value>
													Yes
												</Select.Option>

												<Select.Option key='fals' label='No' value={false}>
													No
												</Select.Option>
											</Select>
										</Form.Item>

										<Form.Item
											style={{ padding: '0 2em 1em 2em', width: '80%' }}
											label='Allow rider to book in a restricted area'
											tooltip='Allow the rider to book if his current location is in a restricted area.'
											name='allowRiderToBookInRestrictedArea'
											rules={[
												{
													required: true,
													message:
														'Please select allow rider to book in a restricted area',
												},
											]}>
											<Select
												id='allow_rider_to_book_in_restricted_area_Ref'
												size='medium'
												showArrow
												showSearch
												optionFilterProp='filterBy'
												allowClear
												optionLabelProp='label'
												getPopupContainer={(trigger) => trigger.parentNode}
												disabled={!role?.includes('SUPER_ADMIN')}>
												<Select.Option key='true' label='Yes' value>
													Yes
												</Select.Option>

												<Select.Option key='fals' label='No' value={false}>
													No
												</Select.Option>
											</Select>
										</Form.Item>
									</Col>
									<Col span={8}>
										<Form.Item
											style={{ padding: '2em 2em 1em 2em', width: '80%' }}
											label='Global Cancellation Fee'
											tooltip='This field is used to set the global cancellation fee if it is missing in the vehicle type.'
											name='cancellationFee'
											rules={[
												{
													required: true,
													message: 'Please input global cancellation fee',
												},
												{
													type: 'number',
													min: 0,
													transform: (value) => parseFloat(value),
													message:
														'Please enter a number greater than or equal to 0',
												},
											]}>
											<Input
												type='number'
												id='cancellationFeeRef'
												disabled={!role?.includes('SUPER_ADMIN')}
											/>
										</Form.Item>

										<Form.Item
											style={{ padding: '0 2em 1em 2em', width: '80%' }}
											label='Global Cancellation time (SECONDS)'
											tooltip='This field is used to set the global cancellation time im seconds if it is missing in the vehicle type.'
											name='cancellationTimeInSeconds'
											rules={[
												{
													required: true,
													message:
														'Please input global cancellation Time',
												},
												{
													type: 'integer',
													min: 0,
													transform: (value) => parseFloat(value),
													message:
														'Please enter a non decimal number greater than or equal to 0',
												},
											]}>
											<Input
												type='number'
												id='cancellationTimeRef'
												disabled={!role?.includes('SUPER_ADMIN')}
											/>
										</Form.Item>

										<Form.Item
											style={{ padding: '0 2em 1em 2em', width: '80%' }}
											label='Looking For Driver Timeout (SECONDS)'
											tooltip='This field is used to set the time in seconds if there is no vehicle available in the vicinity radius to timeout the request.'
											name='lookingForDriverTimeoutInSeconds'
											rules={[
												{
													required: true,
													message:
														'Please input looking for driver Timeout',
												},
												{
													type: 'integer',
													min: 0,
													transform: (value) => parseFloat(value),
													message:
														'Please enter a non decimal number greater than or equal to 0',
												},
											]}>
											<Input
												type='number'
												id='driverTimeoutRef'
												disabled={!role?.includes('SUPER_ADMIN')}
											/>
										</Form.Item>

										<Form.Item
											style={{ padding: '0 2em 1em 2em', width: '80%' }}
											label='Show Rider Price'
											tooltip='This field is used to determine whether to display the price on the rider app.'
											name='showPriceRider'
											rules={[
												{
													required: true,
													message: 'Please select show rider price',
												},
											]}>
											<Select
												id='show_rider_price_Ref'
												size='medium'
												showArrow
												showSearch
												optionFilterProp='filterBy'
												allowClear
												optionLabelProp='label'
												getPopupContainer={(trigger) => trigger.parentNode}
												disabled={!role?.includes('SUPER_ADMIN')}>
												<Select.Option key='true' label='Yes' value>
													Yes
												</Select.Option>

												<Select.Option key='fals' label='No' value={false}>
													No
												</Select.Option>
											</Select>
										</Form.Item>

										<Form.Item
											style={{ padding: '0 2em 1em 2em', width: '80%' }}
											label='Auto accept (SECONDS)'
											tooltip='This field is used to set a timer for the driver to be able to accept the booking. After this period the booking will be auto-declined.'
											name='autoAcceptSeconds'
											rules={[
												{
													required: true,
													message: 'Please input driver auto accept',
												},
												{
													type: 'integer',
													min: 0,
													transform: (value) => parseFloat(value),
													message:
														'Please enter a non decimal number greater than or equal to 0',
												},
											]}>
											<Input
												type='number'
												id='autoAcceptRef'
												disabled={!role?.includes('SUPER_ADMIN')}
											/>
										</Form.Item>
										<Form.Item
											style={{ padding: '0 2em 1em 2em', width: '80%' }}
											label='Taxi mode'
											tooltip='This field is used to determine the taxi mode to allow for the taxi service. You can choose from private, shared or both.'
											name='taxiMode'
											rules={[
												{
													required: true,
													message: 'Please select taxi mode',
												},
											]}>
											<Select
												id='taxi_mode_Ref'
												size='medium'
												showArrow
												optionLabelProp='label'
												getPopupContainer={(trigger) => trigger.parentNode}
												disabled={!role?.includes('SUPER_ADMIN')}>
												<Select.Option
													key='shared'
													label='SHARED'
													value='SHARED'>
													Shared
												</Select.Option>

												<Select.Option
													key='private'
													label='PRIVATE'
													value='PRIVATE'>
													Private
												</Select.Option>
												<Select.Option
													key='shared_private'
													label='SHARED & PRIVATE'
													value='SHARED_PRIVATE'>
													Shared & Private
												</Select.Option>
											</Select>
										</Form.Item>

										<Form.Item
											style={{ padding: '0 2em 1em 2em', width: '80%' }}
											label='Allow rider to review driver'
											tooltip='This field is used to determine whether to prompt the rider to give a review after the trip is completed.'
											name='riderPromptedForReview'
											rules={[
												{
													required: true,
													message:
														'Please select allow rider to review driver',
												},
											]}>
											<Select
												id='rider_prompted_for_review_Ref'
												size='medium'
												showArrow
												showSearch
												optionFilterProp='filterBy'
												allowClear
												optionLabelProp='label'
												getPopupContainer={(trigger) => trigger.parentNode}
												disabled={!role?.includes('SUPER_ADMIN')}>
												<Select.Option key='true' label='Yes' value>
													Yes
												</Select.Option>

												<Select.Option key='fals' label='No' value={false}>
													No
												</Select.Option>
											</Select>
										</Form.Item>
									</Col>
									<Col span={8}>
										<Form.Item
											style={{ padding: '2em 2em 1em 2em', width: '80%' }}
											label='Start Button KM range (KM)'
											tooltip='This field is used to set the range when the driver should be in the pickup location to start the trip.'
											name='startButtonKmRange'
											rules={[
												{
													required: true,
													message: 'Please input start button KM Range',
												},
												{
													type: 'integer',
													min: 0,
													transform: (value) => parseFloat(value),
													message:
														'Please enter a non decimal number greater than or equal to 0',
												},
											]}>
											<Input
												type='number'
												id='startButtonRef'
												disabled={!role?.includes('SUPER_ADMIN')}
											/>
										</Form.Item>
										<Form.Item
											style={{ padding: '0 2em 1em 2em', width: '80%' }}
											label='Driver Last Location (SECONDS)'
											tooltip='This field is used to set the time in seconds when the app should check the remaining time and distance to the pickup location.'
											name='lastLocationUpdateInSeconds'
											rules={[
												{
													required: true,
													message:
														'Please input driver last location update',
												},
												{
													type: 'integer',
													min: 0,
													transform: (value) => parseFloat(value),
													message:
														'Please enter a non decimal number greater than or equal to 0',
												},
											]}>
											<Input
												type='number'
												id='driverLastLocationRef'
												disabled={!role?.includes('SUPER_ADMIN')}
											/>
										</Form.Item>
										<Form.Item
											style={{ padding: '0 2em 1em 2em', width: '80%' }}
											label='Booking request type'
											tooltip='This field is used to determine which driver should be requested first'
											name='bookingRequestType'
											rules={[
												{
													required: true,
													message: 'Please select driver request type',
												},
											]}>
											<Select
												id='reauestTypeRef'
												size='medium'
												showArrow
												showSearch
												optionFilterProp='filterBy'
												allowClear
												optionLabelProp='label'
												popupMatchSelectWidth={false}
												getPopupContainer={(trigger) => trigger.parentNode}
												// disable the select if there is a value of "currency"
												disabled={!role?.includes('SUPER_ADMIN')}>
												{driverRequestTypeItems.map((item) => (
													<Select.Option
														key={item.label}
														label={`${item.label}`}
														value={item.value}>
														{`${item.label}`}
													</Select.Option>
												))}
											</Select>
										</Form.Item>

										<Form.Item
											style={{ padding: '0 2em 1em 2em', width: '80%' }}
											label='Show Driver Price'
											tooltip='This field is used to determine whether to display the price on the driver app.'
											name='showPriceDriver'
											rules={[
												{
													required: true,
													message: 'Please select show driver price',
												},
											]}>
											<Select
												id='show_driver_price_Ref'
												size='medium'
												showArrow
												showSearch
												optionFilterProp='filterBy'
												allowClear
												optionLabelProp='label'
												getPopupContainer={(trigger) => trigger.parentNode}
												disabled={!role?.includes('SUPER_ADMIN')}>
												<Select.Option key='true' label='Yes' value>
													Yes
												</Select.Option>

												<Select.Option key='fals' label='No' value={false}>
													No
												</Select.Option>
											</Select>
										</Form.Item>
										<Form.Item
											style={{ padding: '0 2em 1em 2em', width: '80%' }}
											label='Require valid card details for bookings'
											tooltip='This field is used to determine whether to allow the rider to book without entering credit card details.  If set to No, the rider should not be able to book unless valid credit card details are inputted.'
											name='allowBookingWithCard'
											rules={[
												{
													required: true,
													message:
														'Please select allow booking without card',
												},
											]}>
											<Select
												id='allow_booking_without_card_Ref'
												size='medium'
												showArrow
												showSearch
												optionFilterProp='filterBy'
												allowClear
												optionLabelProp='label'
												getPopupContainer={(trigger) => trigger.parentNode}
												disabled={
													!role?.includes('SUPER_ADMIN') ||
													formValues?.paymentMethod === 'CREDIT_CARD_ONLY'
												}>
												<Select.Option key='true' label='Yes' value>
													Yes
												</Select.Option>

												<Select.Option key='fals' label='No' value={false}>
													No
												</Select.Option>
											</Select>
										</Form.Item>

										<Form.Item
											style={{ padding: '0 2em 1em 2em', width: '80%' }}
											label='Payment Method'
											tooltip='This field is used to determine the payment method to be used in the rider app. You can choose that rider can pay with credit card and cash or only credit card.'
											name='paymentMethod'
											rules={[
												{
													required: true,
													message: 'Please select payment method',
												},
											]}>
											<Select
												id='payment_method_Ref'
												size='medium'
												showArrow
												optionLabelProp='label'
												getPopupContainer={(trigger) => trigger.parentNode}
												onChange={(value) => {
													if (value === 'CREDIT_CARD_ONLY') {
														form.setFieldsValue({
															allowBookingWithCard: true,
														});
													}
												}}
												disabled={!role?.includes('SUPER_ADMIN')}>
												<Select.Option
													key='cedit_card_and_cash'
													label='Credit Card & Cash'
													value='CREDIT_CARD_AND_CASH'>
													Credit Card & Cash
												</Select.Option>
												<Select.Option
													key='Credit_card_only'
													label='Credit Card Only'
													value='CREDIT_CARD_ONLY'>
													Credit Card Only
												</Select.Option>
											</Select>
										</Form.Item>
									</Col>
								</Row>
								<Row
									style={{
										display: 'flex',
										justifyContent: 'end',
										alignItems: 'center',
										padding: '0 2em 1em 2em',
									}}>
									{role?.includes('SUPER_ADMIN') && (
										<Button
											type='primary'
											htmlType='submit'
											loading={makingApiCall}
											style={{ width: '8em' }}
											icon={<SaveOutlined />}
											ref={saveButtonRef}>
											{makingApiCall ? ' Saving...' : ' Save'}
										</Button>
									)}
								</Row>
							</Form>
						</Col>
					</Row>
				</div>
				<Tour open={open} onClose={() => setOpen(false)} steps={steps} />
			</Spin>
		);
	},
);

export default SettingsForm;
