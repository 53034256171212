/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-useless-fragment */
import { Card, Tooltip, Select, Spin, Button } from 'antd';
import React from 'react';
import { InfoCircleOutlined, LoadingOutlined, ReloadOutlined } from '@ant-design/icons';
import { Chart, Interval } from 'bizcharts';

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { registeredUsers } from '../../redux/dashboardSlice';
import NoData from './NoData';

const { Option } = Select;

const RegistredUsersChart = () => {
	const [selectedOption, setSelectedOption] = useState('1W');
	const handleSelectChange = (value) => {
		setSelectedOption(value);
		fetchRegisteredUsers(value);
	};
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);

	const { dashboard } = useSelector((state) => state.dashboard);

	const reload = () => {
		fetchRegisteredUsers(selectedOption);
	};
	const fetchRegisteredUsers = async (value) => {
		setLoading(true);
		dispatch(registeredUsers(value))
			.then()
			.catch()
			.finally(() => {
				setLoading(false);
			});
	};
	return (
		<Spin
			size='large'
			indicator={<LoadingOutlined style={{ fontSize: 54 }} spin />}
			tip={
				<div>
					<h2>Loading...</h2>
				</div>
			}
			spinning={loading}>
			<Card
				type='inner'
				title='Registred Rider/Driver Statistics'
				extra={
					<div>
						<Tooltip title='This widget displays site statistics including the count of registred drivers and riders'>
							<InfoCircleOutlined />
						</Tooltip>
						<Button
							style={{
								marginLeft: '1em',
							}}
							type='default'
							size='small'
							onClick={() => reload()}>
							<ReloadOutlined />
						</Button>
						<Select
							defaultValue={selectedOption}
							style={{ marginLeft: 16 }}
							onChange={handleSelectChange}>
							<Option value='1W'>Last Week</Option>
							<Option value='1M'>Last Month</Option>
							<Option value='3M'>Last 3 Months</Option>
							<Option value='1Y'>Last Year</Option>
						</Select>
					</div>
				}
				bordered={false}
				style={{
					width: '100%',
					height: '30em',
				}}>
				{!dashboard?.registeredUsers?.some((item) => item.value !== 0) ? (
					<NoData height={300} />
				) : (
					<Chart
						height={300}
						autoFit
						data={dashboard?.registeredUsers || []}
						interactions={['active-region']}
						padding='auto'>
						<Interval
							position='period*value'
							color='name'
							adjust={[
								{
									type: 'dodge',
									marginRatio: 0,
								},
							]}
						/>
						<Tooltip shared />
					</Chart>
				)}
			</Card>
		</Spin>
	);
};

export default RegistredUsersChart;
