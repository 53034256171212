/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
import React from 'react';
import { Button, Col, Divider, Form, Input, Row, Select, Spin, message } from 'antd';
import { SaveOutlined, LoadingOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { updateCarPoolingSetting } from '../../redux/carPoolingSettingSlice';

const CarPoolingSettingForm = ({ makingApiCall, setMakingApiCall, dispatch, role }) => {
	const { CarPoolingSetting } = useSelector((state) => state.carPoolingSetting);
	const { Brand } = useSelector((state) => state.brand);
	const saveSettings = (values) => {
		setMakingApiCall(true);
		dispatch(updateCarPoolingSetting(values))
			.unwrap()
			.then(() => {
				message.success('Settings updated successfully');
			})
			.catch(() => {
				message.error('Something went wrong. Please try again later');
			})
			.finally(() => {
				setMakingApiCall(false);
			});
	};

	const onSave = (values) => {
		saveSettings(values);
	};

	const onSaveFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};

	const onDirty = () => {
		console.log('dirty');
	};

	return (
		<Spin
			size='large'
			indicator={<LoadingOutlined style={{ fontSize: 54 }} spin />}
			tip={
				<div>
					<h2>Loading...</h2>
				</div>
			}
			spinning={makingApiCall}>
			<div
				style={{
					borderRadius: '17px',
					background: '#ffffff',
					marginTop: '1em',
				}}>
				{/*  <Button
              type="default"
              onClick={() => setOpen(true)}
              icon={<CaretRightOutlined />}
            >
              Start Tour
            </Button> */}

				<Row style={{ overflowX: 'auto', height: '70vh' }}>
					<Col span={24}>
						<Form
							name='Car Pooling Settings Form'
							layout='vertical'
							initialValues={CarPoolingSetting?.settingsData}
							onFinish={onSave}
							onFinishFailed={onSaveFailed}
							autoComplete='off'
							onValuesChange={() => onDirty()}>
							<Row>
								<Col span={10} style={{ padding: '0 5% 0 5%' }}>
									<div style={{ textAlign: 'center', paddingBottom: '5%' }}>
										<h3>Threshold</h3>
									</div>
									<Form.Item
										style={{ padding: '0 2em 1em 2em', width: '80%' }}
										label='Threshold In Minutes'
										tooltip='This field is used to set the range where the app should search for nearby drivers.'
										name='thresholdMinutes'
										rules={[
											{
												required: true,
												message: 'Please input threshold in minutes',
											},
											{
												type: 'number',
												min: 0,
												transform: (value) => parseFloat(value),
												message:
													'Please enter a number greater than or equal to 0',
											},
										]}>
										<Input
											type='number'
											disabled={!role?.includes('SUPER_ADMIN')}
										/>
									</Form.Item>
									<Form.Item
										style={{ padding: '0 2em 1em 2em', width: '80%' }}
										label='Threshold In Meters'
										tooltip='This field is used to set the range where the app should search for nearby drivers.'
										name='thresholdMeters'
										rules={[
											{
												required: true,
												message: 'Please input threshold in meters',
											},
											{
												type: 'number',
												min: 0,
												transform: (value) => parseFloat(value),
												message:
													'Please enter a number greater than or equal to 0',
											},
										]}>
										<Input
											type='number'
											disabled={!role?.includes('SUPER_ADMIN')}
										/>
									</Form.Item>
									<Form.Item
										style={{ padding: '0 2em 1em 2em', width: '80%' }}
										label='Rider walking limit in meters'
										tooltip='This field is used to set the maximum amount of meters the rider can walk to reach the pickup point.'
										name='riderWalkingLimitMeters'
										rules={[
											{
												required: true,
												message:
													'Please input rider walking limit in meters',
											},
											{
												type: 'number',
												min: 0,
												transform: (value) => parseFloat(value),
												message:
													'Please enter a number greater than or equal to 0',
											},
										]}>
										<Input
											type='number'
											disabled={!role?.includes('SUPER_ADMIN')}
										/>
									</Form.Item>
									<Form.Item
										style={{ padding: '0 2em 1em 2em', width: '80%' }}
										label='Threshold in meters enabled '
										tooltip='This field is used to enable or disable the threshold in meters.'
										name='threshold_enabled_meters'
										rules={[
											{
												required: true,
												message:
													'Please select threshold enabled in meters',
											},
										]}>
										<Select
											id='threshold_enabled_meters'
											size='medium'
											showArrow
											showSearch
											optionFilterProp='children'
											allowClear
											optionLabelProp='label'
											getPopupContainer={(trigger) => trigger.parentNode}
											disabled={!role?.includes('SUPER_ADMIN')}>
											<Select.Option key='true' label='True' value>
												True
											</Select.Option>

											<Select.Option key='false' label='False' value={false}>
												False
											</Select.Option>
										</Select>
									</Form.Item>

									<Form.Item
										style={{ padding: '0 2em 1em 2em', width: '80%' }}
										label='Threshold in minutes enabled'
										tooltip='This field is used to enable or disable the threshold in minutes.'
										name='threshold_enabled_minutes'
										rules={[
											{
												required: true,
												message:
													'Please select threshold enabled in minutes',
											},
										]}>
										<Select
											id='threshold_enabled_minutes'
											size='medium'
											showArrow
											showSearch
											optionFilterProp='children'
											allowClear
											optionLabelProp='label'
											getPopupContainer={(trigger) => trigger.parentNode}
											disabled={!role?.includes('SUPER_ADMIN')}>
											<Select.Option key='true' label='True' value>
												True
											</Select.Option>

											<Select.Option key='false' label='False' value={false}>
												False
											</Select.Option>
										</Select>
									</Form.Item>
								</Col>

								<Col span={4} style={{ display: 'flex', justifyContent: 'center' }}>
									{/* Add a horizontal divider between the two columns */}
									<Divider
										type='vertical'
										style={{
											height: '100%',
											width: '1.3px',
											backgroundColor:
												Brand?.brandData?.webPrimary || '#ffa500',
										}}
										// devider should be strong when the screen is large
									/>
								</Col>
								<Col span={10} style={{ padding: '0 5% 0 5%' }}>
									<div style={{ textAlign: 'center', paddingBottom: '5%' }}>
										<h3>Pricing</h3>
									</div>
									<Form.Item
										style={{ padding: '0 2em 1em 2em', width: '80%' }}
										label='Car pooling base price'
										tooltip='This field is used to set the base price for the car pooling.'
										name='basePrice'
										rules={[
											{
												required: true,
												message: 'Please input car pooling base price',
											},
											{
												type: 'number',
												min: 0,
												transform: (value) => parseFloat(value),
												message:
													'Please enter a number greater than or equal to 0',
											},
										]}>
										<Input
											type='number'
											disabled={!role?.includes('SUPER_ADMIN')}
										/>
									</Form.Item>
									<Form.Item
										style={{ padding: '0 2em 1em 2em', width: '80%' }}
										label='First rider price per KM'
										tooltip='This field is used to set the price per KM for the first rider.'
										name='firstRiderPricePerKm'
										rules={[
											{
												required: true,
												message: 'Please input first rider price per KM',
											},
											{
												type: 'number',
												min: 0,
												transform: (value) => parseFloat(value),
												message:
													'Please enter a number greater than or equal to 0',
											},
										]}>
										<Input
											type='number'
											disabled={!role?.includes('SUPER_ADMIN')}
										/>
									</Form.Item>
									<Form.Item
										style={{ padding: '0 2em 1em 2em', width: '80%' }}
										label='Additional riders price per KM'
										tooltip='This field is used to set the price per KM for the additional riders.'
										name='additionalRidersPricePerKm'
										rules={[
											{
												required: true,
												message:
													'Please input additional riders price per KM',
											},
											{
												type: 'number',
												min: 0,
												transform: (value) => parseFloat(value),
												message:
													'Please enter a number greater than or equal to 0',
											},
										]}>
										<Input
											type='number'
											disabled={!role?.includes('SUPER_ADMIN')}
										/>
									</Form.Item>
									<Form.Item
										style={{ padding: '0 2em 1em 2em', width: '80%' }}
										label='Additional riders fixed price'
										tooltip='This field is used to set the fixed price for the additional riders.'
										name='additionalRidersFixedPrice'
										rules={[
											{
												required: true,
												message:
													'Please input additional riders fixed price',
											},
											{
												type: 'number',
												min: 0,
												transform: (value) => parseFloat(value),
												message:
													'Please enter a number greater than or equal to 0',
											},
										]}>
										<Input
											type='number'
											disabled={!role?.includes('SUPER_ADMIN')}
										/>
									</Form.Item>
									<Form.Item
										style={{ padding: '0 2em 1em 2em', width: '80%' }}
										label='Additional riders pricing method'
										tooltip='This field is used to set the pricing option for the additional riders in shared rides.'
										name='pricingOption'
										rules={[
											{
												required: true,
												message: 'Please select pricing option',
											},
										]}>
										<Select
											id='show_driver_price_Ref'
											size='medium'
											showArrow
											showSearch
											optionFilterProp='filterBy'
											allowClear
											optionLabelProp='label'
											getPopupContainer={(trigger) => trigger.parentNode}
											disabled={!role?.includes('SUPER_ADMIN')}>
											<Select.Option
												key='PER_KM'
												label='Per KM'
												value='PER_KM'>
												Per KM
											</Select.Option>

											<Select.Option key='FIXED' label='Fixed' value='FIXED'>
												Fixed
											</Select.Option>
										</Select>
									</Form.Item>
								</Col>
							</Row>
							<Row
								style={{
									display: 'flex',
									justifyContent: 'end',
									alignItems: 'center',
									padding: '0 2em 1em 2em',
								}}>
								{role?.includes('SUPER_ADMIN') && (
									<Button
										type='primary'
										htmlType='submit'
										loading={makingApiCall}
										style={{ width: '8em' }}
										icon={<SaveOutlined />}>
										{makingApiCall ? ' Saving...' : ' Save'}
									</Button>
								)}
							</Row>
						</Form>
					</Col>
				</Row>
			</div>
		</Spin>
	);
};

export default CarPoolingSettingForm;
