/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-nested-ternary */

import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Card, Input, Switch, message, Rate } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { ReloadOutlined, MinusOutlined } from '@ant-design/icons';
import DrawerDriver from './DrawerDriver';
import {
	changeDriverAllowBookingDecline,
	changeDriverStatus,
	deleteDriver,
	getAllDrivers,
} from '../../redux/driverSlice';
import { style } from '../../Styles';
import CustomTableComponents from '../Global/CustomTableComponents';
import CustomAvatar from '../Global/CustomAvatar/CustomAvatar';
import ImageComponent from '../Global/ImageComponent/ImageComponent';
import { getAllFleetOperators } from '../../redux/fleetOperatorSlice';
import ActionButtonWithChangeStatus from '../Global/ActionButtonWithChangeStatus';

const Driver = () => {
	const columns = [
		{
			title: 'Profile Picture',
			key: 'profilePicture',
			render: (record) => {
				return record.profilePicture ? (
					<ImageComponent size={40} imageId={record?.profilePicture} />
				) : (
					<CustomAvatar name={`${record.name} ${record.surname}`} type='initials' />
				);
			},
			width: 130,
			show: true,
		},
		{
			title: 'Name',
			key: 'name',
			render: (record) => {
				return <span>{`${record.name} ${record.surname}`}</span>;
			},
			sorter: (a, b) => a.name.length - b.name.length,
			show: true,
		},
		{
			title: 'Email',
			dataIndex: 'email',
			sorter: {
				compare: (a, b) => a.email - b.email,
				multiple: 4,
			},
			show: true,
		},
		{
			title: 'Phone',
			key: 'phone',
			render: (record) => {
				return <span>{`+(${record.phoneCountryCode}) ${record.phone}`}</span>;
			},
			sorter: {
				compare: (a, b) => a.phone - b.phone,
				multiple: 3,
			},
			show: true,
		},
		{
			title: 'Country',
			key: 'country',
			render: (record) => {
				return record.country ? <span>{`${record.country} `}</span> : <MinusOutlined />;
			},
			sorter: {
				compare: (a, b) => a.country - b.country,
				multiple: 1,
			},
			show: true,
		},
		{
			title: 'Rating',
			key: 'rating',
			render: (record) => {
				return record.rating ? (
					<Rate allowHalf disabled defaultValue={record?.rating} />
				) : (
					<Rate allowHalf disabled defaultValue={0} />
				);
			},
			sorter: {
				compare: (a, b) => a?.rating - b?.rating,
				multiple: 1,
			},
			show: true,
		},
		{
			title: 'Address',
			key: 'address',
			render: (record) => {
				return record.address ? <span>{`${record.address} `}</span> : <MinusOutlined />;
			},
			sorter: {
				compare: (a, b) => a.address - b.address,
				multiple: 1,
			},
			show: true,
		},

		{
			title: 'Allow booking decline',
			key: 'autoAccept',
			render: (record) => {
				// a toggle button to change the auto accept status
				return (
					<span>
						<Switch
							checked={record.allowBookingDecline}
							onClick={(value) => handleToggleAllowBookingDecline(value, record)}
						/>
					</span>
				);
			},
			show: true,
		},

		{
			title: 'Status',
			key: 'status',
			render: (record) => {
				return (
					<span style={style.dataContainer}>
						{record.active ? (
							<div style={style.statusActif}>ACTIVE</div>
						) : (
							<div style={style.statusInactif}>INACTIVE</div>
						)}
					</span>
				);
			},

			sorter: {
				compare: (a, b) => a.status - b.status,
				multiple: 1,
			},
			width: 90,
			show: true,
		},

		{
			title: 'Actions',
			key: 'actions',
			render: (text, record) => (
				<ActionButtonWithChangeStatus
					record={record}
					onDelete={() => handleDeleteDriver(record.id, () => {})}
					onEdit={() => handleSelectedDriver(record)}
					onRowClick={() => handleSelectedDriver(record)}
					onChangeStatus={() => handleChangeStatus(record)}
				/>
			),

			show: true,
		},
	];

	const handleToggleAllowBookingDecline = (value, record) => {
		setMakingApiCall(true);
		dispatch(changeDriverAllowBookingDecline({ driverId: record.id, status: value }))
			.unwrap()
			.then(() => {
				message.success('Allow booking decline status updated successfully');
			})
			.catch((e) => {
				console.log('error', e);
				message.error('Error updating driver');
			})
			.finally(() => {
				setMakingApiCall(false);
			});
	};
	const fetchAllFlletOperators = () => {
		dispatch(getAllFleetOperators())
			.unwrap()
			.then(() => {})
			.catch(() => {
				message.error('Error getting fleet operators');
			});
	};

	const handleDeleteDriver = (id, callback) => {
		setMakingApiCall(true);
		dispatch(
			deleteDriver({
				driverId: id,
			}),
		)
			.unwrap()
			.then(() => {
				message.success('Driver deleted successfully');
				callback();
			})
			.catch(() => {})
			.finally(() => {
				onClose();
				setMakingApiCall(false);
			});
	};

	const handleChangeStatus = (record) => {
		setMakingApiCall(true);
		dispatch(
			changeDriverStatus({
				userId: record.id,
				active: !record.active,
			}),
		)
			.unwrap()
			.then(() => {
				message.success('Driver status updated successfully');
			})
			.catch((e) => {
				if (e.message === 'DRIVER_SHOULD_UPLOAD_DOCUMENTS_TO_BE_ACTIVATED') {
					message.error(
						'The driver should upload the required documents to be activated',
					);
				}
				message.error('Error updating driver status');
			})
			.finally(() => {
				setMakingApiCall(false);
			});
	};
	const [filter, setFilter] = useState('');

	const [open, setOpen] = useState(false);
	const [driver, setDriver] = useState(null);
	const [isMakingApiCall, setMakingApiCall] = useState(true);

	const showDrawer = () => {
		setOpen(true);
	};
	const onClose = () => {
		setOpen(false);
		setDriver(null);
	};

	const dispatch = useDispatch();

	const { Drivers } = useSelector((state) => state.drivers);
	const fetchAll = () => {
		dispatch(getAllDrivers())
			.unwrap()
			.then()
			.catch(() => {
				message.error('Error fetching drivers');
			})
			.finally(() => {
				setMakingApiCall(false);
			});
	};
	useEffect(() => {
		fetchAll();
		fetchAllFlletOperators();
	}, [dispatch]);

	const handleSelectedDriver = (selectedDriver) => {
		setDriver(selectedDriver);
		showDrawer();
	};

	let driversArray = Object.values(Drivers.driversList);
	driversArray = driversArray.map((item) => {
		return { ...item, key: item.id };
	});

	const filtredData = driversArray.filter((item) => {
		return (
			item.id.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
			item.name.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
			item.email.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
			item.country.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
			item.phone.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1
		);
	});

	return (
		<>
			<Card
				style={{
					margin: 16,
					maxHeight: '90%', // Set maximum width
					height: 'auto', // Allow width to be dynamic
				}}>
				<div style={style.inlineContent}>
					<div
						style={{
							display: 'flex',
							alignContent: 'center',
							alignItems: 'center',
							float: 'left',
							marginBottom: 8,
						}}>
						<Breadcrumb
							style={{ margin: '0 3em 0 0', fontSize: '1.1em' }}
							items={[
								{
									title: 'Drivers',
								},
							]}
						/>
						<Button
							type='default'
							size='small'
							// style={{ margin: '0 8px 8px 0' }}
							onClick={() => fetchAll()}>
							<ReloadOutlined />
						</Button>
					</div>
					<div
						style={{
							...style.inlineContent,
							float: 'right',
							marginBottom: 8,
						}}>
						<Input.Search
							placeholder='Search'
							style={{ margin: '0 8px 8px 0' }}
							value={filter}
							onChange={(e) => setFilter(e.target.value)}
						/>

						<Button
							type='default'
							style={{ float: 'right', marginBottom: 8 }}
							onClick={showDrawer}>
							Add Driver
						</Button>
					</div>
				</div>
				<CustomTableComponents
					isMakingApiCall={isMakingApiCall}
					columns={columns.filter((column) => column.show)}
					dataArray={filtredData}
					handleDoubleClickRow={(record) => handleSelectedDriver(record)}
					handleSelectedRow={() => {}}
				/>
			</Card>
			{open && (
				<DrawerDriver
					onClose={onClose}
					open={open}
					driverId={driver?.id}
					handleDeleteDriver={handleDeleteDriver}
				/>
			)}
		</>
	);
};

export default Driver;
