/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Drawer, Spin, message } from 'antd';
import {
	createBundle,
	getBundleById,
	resetSelectedBundle,
	updateBundle,
} from '../../redux/bundleSlice';
import ShowConfirmClose from '../Modals/ShowConfirmClose';
import BundleForm from './BundleForm';

const BundleDrawer = ({ onClose, bundleId, dispatch }) => {
	const [unsavedChanges, setUnsavedChanges] = useState(false);
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const { bundles, selectedBundle } = useSelector((state) => state.bundles);
	const [bundle, setBundle] = useState(null);

	const handleClose = useCallback(
		(hasUnsavedChanges) => {
			dispatch(resetSelectedBundle());
			if (hasUnsavedChanges && !confirmationOpen) {
				setConfirmationOpen(true); // Mark that the confirmation modal is open
				ShowConfirmClose(() => {
					setConfirmationOpen(false); // Reset when the confirmation modal is closed

					onClose();
				});
			} else {
				onClose();
			}
		},
		[onClose, confirmationOpen],
	);

	const handleDirty = useCallback(() => {
		setUnsavedChanges(true);
	}, []);

	const saveBundle = async (values) => {
		// value.allowedDays is a list of days of the week , i want to convert it to a string separated by comma
		values.allowedDays = values.allowedDays.join(',');
		const payload = bundleId ? { ...values, id: bundleId } : { ...values, id: null };
		const action = bundleId ? updateBundle(payload) : createBundle(payload);
		dispatch(action)
			.unwrap()
			.then(() => {
				message.success('Bundle saved successfully');
				setUnsavedChanges(false);
			})
			.catch((e) => {
				if (e.message === 'BUNDLE_ALREADY_EXISTS') {
					message.error('“Could not save the bundle. It already already exists.');
				} else {
					message.error('Error occurred while saving bundle');
				}
			})
			.finally(() => {
				onClose();
			});
	};

	const onSaveFailed = (error) => {
		console.log('Failed:', error);
	};

	useEffect(() => {
		const abortController = new AbortController();
		if (bundleId) {
			dispatch(getBundleById(bundleId))
				.unwrap()
				.then((data) => {
					setBundle(data);
				})
				.catch((error) => console.log(error));
		}
		return () => abortController.abort();
	}, [bundleId, dispatch]);
	return (
		<Drawer
			width={500}
			title={!selectedBundle?.bundle ? 'Add Bundle' : 'Update Bundle'}
			placement='right'
			onClose={() => handleClose(unsavedChanges)}
			open>
			<Spin
				size='large'
				spinning={selectedBundle?.loading}
				style={{ marginTop: 64 }}
				delay={500}>
				<BundleForm
					bundle={bundle}
					onSave={saveBundle}
					onSaveFailed={onSaveFailed}
					onCancel={handleClose}
					onDirty={handleDirty}
					unsavedChanges={unsavedChanges}
					setUnsavedChanges={setUnsavedChanges}
					bundleId={bundleId}
				/>
			</Spin>
		</Drawer>
	);
};

export default BundleDrawer;
