/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/default */
/* eslint-disable import/namespace */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
import { Badge, Card, Col, Modal, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CloseOutlined } from '@ant-design/icons';
import BookingMap from './BookingMap';
import DriverRiderCard from './DriverRiderCard';
import AddressCard from './AddressCard';
import PriceBreakdown from './PriceBreakdown';
import ReviewCard from './ReviewCard';
import { formatDateAndTime } from '../../Utils/date-utils';
import { getSpanStyle } from '../../Utils/style-depending-on-status';
import { getBookingById } from '../../redux/bookingSlice';

const DetailsModal = ({ closeModal, isModalOpen, isLoaded, role, bookingId }) => {
	const [makingApiCall] = useState(false);
	const dispatch = useDispatch();
	const { googleMaps } = useSelector((state) => state.googleMapsKey);
	const { selectedBooking } = useSelector((state) => state.booking);
	const fetchBookingDetailsById = () => {
		if (bookingId) {
			dispatch(getBookingById(bookingId)).unwrap();
		}
	};

	useEffect(() => {
		fetchBookingDetailsById();
	}, [bookingId, dispatch]);

	return (
		<Modal
			width={800}
			title={
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}>
					<div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
							}}>
							<h5
								style={{
									marginRight: '0.5em',
									marginBottom: '0',
									marginTop: '0',
								}}>{`${selectedBooking?.booking?.id}. Booking Details`}</h5>
							<h5
								style={{
									margin: '0',
									fontSize: '0.8em',
									color: '#9E9E9E',
								}}>
								{formatDateAndTime(selectedBooking?.booking?.created)}
							</h5>
						</div>
						{/* Tax component goes here */}
					</div>
					<div
						style={{
							width: '48%',
							height: '50px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'flex-end',
						}}>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'end',
								justifyContent: 'flex-end',
							}}>
							<Badge
								className='site-badge-count-109'
								count={selectedBooking?.booking?.bookingStatus.name}
								style={{
									backgroundColor: getSpanStyle(
										selectedBooking?.booking?.bookingStatus.name,
									)?.backgroundColor,
									color: getSpanStyle(
										selectedBooking?.booking?.bookingStatus.name,
									)?.color,
									borderColor: getSpanStyle(
										selectedBooking?.booking?.bookingStatus.name,
									)?.borderColor,
									fontWeight: 'bold',
									// marginRight: '0.5em',
								}}
							/>
							{selectedBooking?.booking?.bookingStatus.name === 'CANCELLED' && (
								<h5
									style={{
										margin: '0',
										fontSize: '0.8em',
										color: '#9E9E9E',
									}}>
									{
										selectedBooking?.booking?.cancellationHistory
											?.cancellationReason?.reason
									}
								</h5>
							)}
						</div>

						<CloseOutlined
							style={{
								marginLeft: '0.9em',
								color: 'white', // Set the color to white
								backgroundColor: '#ff3232', // Set the background color to red
								borderRadius: '20%', // Make it a circle with border radius
								cursor: 'pointer', // Add a pointer cursor for better UX
								transition: 'color 0.3s, transform 0.3s', // Add smooth transitions
							}}
							onClick={closeModal}
							onMouseEnter={(e) => {
								e.target.style.transform = 'scale(1.2)'; // Scale up on hover
							}}
							onMouseLeave={(e) => {
								e.target.style.transform = 'scale(1)'; // Scale back down on hover out
							}}
						/>
					</div>
				</div>
			}
			open={isModalOpen}
			onOk={closeModal}
			onCancel={closeModal}
			closable={false}
			/* footer={[
        <Button key="back" onClick={closeModal}>
          Close
        </Button>,
      ]} */
			footer={null}>
			<Spin
				spinning={
					makingApiCall && !googleMaps?.googleMapsKey?.key && selectedBooking?.loading
				}
				tip='Loading...'>
				<Card>
					<Row gutter={[16, 16]}>
						<Col xs={24} sm={24} md={12} lg={12} xl={12}>
							{isLoaded && googleMaps?.googleMapsKey?.key && (
								<BookingMap
									pickup={selectedBooking?.booking?.pickUp}
									dropOff={selectedBooking?.booking?.dropOff}
								/>
							)}
						</Col>

						<Col xs={24} sm={24} md={12} lg={12} xl={12}>
							<Row gutter={[16, 16]}>
								<Col span={24}>
									<DriverRiderCard
										assigned={selectedBooking?.booking?.driver}
										data={selectedBooking?.booking?.driver}
										type='Driver'
										role={role}
									/>
								</Col>

								<Col span={24}>
									<DriverRiderCard
										assigned
										data={selectedBooking?.booking?.rider}
										type='Rider'
										role={role}
									/>
								</Col>

								<Col span={24}>
									<AddressCard
										distance={selectedBooking?.booking?.distance}
										dropOffName={selectedBooking?.booking?.dropOffAddress}
										pickUpName={selectedBooking?.booking?.pickupAddress}
										numberOfSeats={selectedBooking?.booking?.numberOfSeats}
										pickupWaypoint={selectedBooking?.booking?.pickupWaypoint}
										dropoffWaypoint={selectedBooking?.booking?.dropoffWaypoint}
									/>
								</Col>
							</Row>
						</Col>

						<Col xs={24} sm={24} md={24} lg={24} xl={24}>
							<Row gutter={[16, 16]}>
								<Col xs={24} sm={24} md={12} lg={12} xl={12}>
									<ReviewCard
										assigned={!!selectedBooking?.booking?.review}
										review={
											selectedBooking?.booking?.review
												? selectedBooking?.booking?.review[0]
												: null
										}
									/>
								</Col>

								<Col xs={24} sm={24} md={12} lg={12} xl={12}>
									<PriceBreakdown
										priceBreakdown={selectedBooking?.booking?.priceBreakdown}
										paymentType={
											selectedBooking?.booking?.paymentType
												? selectedBooking?.booking?.paymentType
												: 'CASH'
										}
									/>
								</Col>
							</Row>
						</Col>
					</Row>
				</Card>
			</Spin>
		</Modal>
	);
};

export default DetailsModal;
