/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
import { Badge, Col, Rate, Row, Tooltip } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { style } from '../../Styles';
import CustomAvatar from '../Global/CustomAvatar/CustomAvatar';
import DriverNotAssigned from '../Booking/DriverNotAssigned';
import { mailSvg, phoneSvg, walletSvg } from '../../Utils/svgExport';
import ImageComponent from '../Global/ImageComponent/ImageComponent';

const DriverCard = ({ data, type, assigned, totalEarning }) => {
	// Round to 2 decimal places and convert to Euro
	const roundedEarning = Number(totalEarning).toFixed(2);
	const { Settings } = useSelector((state) => state.settings);

	const currencyRender = () => {
		return Settings?.settingsData?.currency || '€';
	};
	return (
		<div style={{ ...style.boxShadow, height: '230px', marginTop: '1em' }}>
			{assigned ? (
				<>
					<Row
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							padding: '0 20px 0 20px',
						}}>
						<Col
							span={16}
							style={{
								alignItems: 'center',
								display: 'flex',
								justifyContent: 'start',
							}}>
							{data?.profilePicture ? (
								<ImageComponent size={30} imageId={data?.profilePicture} />
							) : (
								<CustomAvatar
									name={`${data?.name} ${data?.surname}`}
									type='initials'
								/>
							)}
							<Tooltip title={`${data?.name} ${data?.surname}`}>
								<h3
									style={{
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										whiteSpace: 'nowrap',
										maxWidth: '250px',
									}}>
									{`${data?.name} ${data?.surname}`}
								</h3>
							</Tooltip>
						</Col>
						<Col
							span={8}
							style={{
								alignItems: 'center',
								justifyContent: 'end',
								display: 'flex',
							}}>
							<Badge
								className='site-badge-count-109'
								count={type}
								style={{ backgroundColor: '#ffa500' }}
							/>
						</Col>
					</Row>
					<Row>
						{data?.email && (
							<Col
								span={24}
								style={{
									paddingLeft: '1.5em',
									display: 'flex',
									alignItems: 'center',
									justifyContent: ' start',
									height: 40,
								}}>
								{mailSvg()}
								<Tooltip title={data?.email}>
									<p
										style={{
											marginLeft: '0.4em',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											whiteSpace: 'nowrap',
											maxWidth: '80%',
										}}>
										{data?.email}
									</p>
								</Tooltip>
							</Col>
						)}
						{data?.phone && data?.phoneCountryCode && (
							<Col
								span={24}
								style={{
									paddingLeft: '1.5em',
									display: 'flex',
									alignItems: 'center',
									justifyContent: ' start',
									height: 40,
								}}>
								{phoneSvg()}
								<p
									style={{
										marginLeft: '0.4em',
									}}>{`${data?.phoneCountryCode} ${data?.phone}`}</p>
							</Col>
						)}

						<Col
							span={24}
							style={{
								paddingLeft: '1.5em',
								display: 'flex',
								alignItems: 'center',
								justifyContent: ' start',
								height: 40,
							}}>
							{walletSvg()}
							<p>{`Total earning: ${roundedEarning} ${currencyRender()}`}</p>
						</Col>

						{data.rating ? (
							<Col
								span={24}
								style={{
									paddingLeft: '1.5em',
									display: 'flex',
									alignItems: 'center',
									justifyContent: ' start',
								}}>
								<Rate allowHalf disabled defaultValue={data?.rating} />
							</Col>
						) : (
							<Col
								span={24}
								style={{
									paddingLeft: '1.5em',
									display: 'flex',
									alignItems: 'center',
									justifyContent: ' start',
								}}>
								<Rate allowHalf disabled defaultValue={0} />
							</Col>
						)}
					</Row>
				</>
			) : (
				<div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
					<DriverNotAssigned />
				</div>
			)}
		</div>
	);
};

export default DriverCard;
