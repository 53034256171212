/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import { Drawer, message, Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	addVehicleType,
	getVehicleTypeById,
	updateVehicleType,
} from '../../redux/vehicleTypeSlice';
import VehicletypeForm from './VehicleTypeForm';
import ShowConfirmClose from '../Modals/ShowConfirmClose';

const DrawerVehicleType = ({
	onClose,
	vehicleTypeId,
	handlePeakSurchageSwitch,
	handleNightShiftSwitch,
	fs,
	handleDlete,
}) => {
	const [makingApiCall, setMakingApiCall] = useState(false);
	const dispatch = useDispatch();
	const [unsavedChanges, setUnsavedChanges] = useState(false);
	const [vehicleType, setVehicleType] = useState(undefined);
	const [fareSurcharge, setFareSurcharge] = useState([]);

	const handleDirty = useCallback(() => {
		setUnsavedChanges(true);
	}, []);

	useEffect(() => {
		const tmp = fareSurcharge;
		setFareSurcharge([...tmp, ...fs]);
	}, [fs]);

	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const handleClose = useCallback(
		(hasUnsavedChanges) => {
			if (hasUnsavedChanges && !confirmationOpen) {
				setConfirmationOpen(true); // Mark that the confirmation modal is open
				ShowConfirmClose(() => {
					setConfirmationOpen(false); // Reset when the confirmation modal is closed
					onClose();
				});
			} else {
				onClose();
			}
		},
		[onClose, confirmationOpen],
	);

	const onSave = (values) => {
		if (!vehicleTypeId) addNewVehicleType(values);
		else updateVehicleType_(values);
	};

	const onSaveFailed = () => {};

	const addNewVehicleType = (values) => {
		let data = values;
		if (data.peakSurcharge === undefined) {
			data = { ...data, peakSurcharge: false };
		}
		if (data.nightShift === undefined) {
			data = { ...data, nightShift: false };
		}
		data = {
			...data,
			fareSurcharge,
		};
		setMakingApiCall(true);
		dispatch(
			addVehicleType({
				vehicleType: data,
			}),
		)
			.unwrap()
			.then(() => {
				message.success('Vehicle Type saved successfully');
				setUnsavedChanges(false);
			})
			.catch((e) => {
				if (e.message === 'VEHICLE_TYPE_EXIST') {
					message.error('Could not save Vehicle Type, it is already exist');
				} else {
					message.error('Error while saving Vehicle Type, please try again later');
				}
			})
			.finally(() => {
				onClose();
				setMakingApiCall(false);
			});
	};
	const updateVehicleType_ = (values) => {
		let data = values;
		if (data.peakSurcharge === undefined) {
			data = { ...data, peakSurcharge: false };
		}
		if (data.nightShift === undefined) {
			data = { ...data, nightShift: false };
		}
		data = { ...data, id: vehicleTypeId };
		setMakingApiCall(true);
		dispatch(
			updateVehicleType({
				vehicleType: data,
			}),
		)
			.unwrap()
			.then(() => {
				message.success('Vehicle Type updated successfully');
				setUnsavedChanges(false);
			})
			.catch((e) => {
				if (e.message === 'VEHICLE_TYPE_EXIST') {
					message.error('Could not save Vehicle Type, it is already exist');
				} else {
					message.error('Error while saving Vehicle Type, please try again later');
				}
			})
			.finally(() => {
				onClose();
				setMakingApiCall(false);
			});
	};
	useEffect(() => {
		const abortController = new AbortController();

		if (vehicleTypeId) {
			setMakingApiCall(true);
			const calls = [
				dispatch(
					getVehicleTypeById({
						vehicleTypeId,
					}),
				),
			];
			Promise.all(calls)
				.then((res) => {
					if (vehicleTypeId) {
						setVehicleType(res[0].payload);
					}
				})
				.catch(() => {
					onClose();
				})
				.finally(() => {
					setMakingApiCall(false);
				});
		}
		return () => abortController.abort();
	}, [vehicleTypeId, dispatch]);

	return (
		<Drawer
			width={500}
			title={!vehicleTypeId ? 'Add Vehicle Type' : 'Update Vehicle Type'}
			placement='right'
			onClose={() => handleClose(unsavedChanges)}
			open
			getContainer={() => document.getElementById('drawer-container')}
			style={{ zIndex: 900, overflow: 'hidden' }}
			maskStyle={{ zIndex: 900, overflow: 'hidden' }}
			className='ant-drawer-my-drawer-class'>
			<Spin size='large' spinning={makingApiCall} style={{ marginTop: 64 }} delay={500}>
				{!makingApiCall && (
					<VehicletypeForm
						vehicleType={vehicleType}
						onSave={onSave}
						onSaveFailed={onSaveFailed}
						onDelete={handleDlete}
						onCancel={handleClose}
						onDirty={handleDirty}
						unsavedChanges={unsavedChanges}
						handlePeakSurchageSwitch={handlePeakSurchageSwitch}
						handleNightShiftSwitch={handleNightShiftSwitch}
						setUnsavedChanges={setUnsavedChanges}
					/>
				)}
			</Spin>
		</Drawer>
	);
};

export default DrawerVehicleType;
