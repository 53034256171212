/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
import { Button, Divider, Form, Input, Select } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

const validateMessages = {
	types: {
		email: '${label} is not a valid email',
	},
};
const LocationWiseFareForm = ({
	locationWiseFare,
	unsavedChanges,
	onDirty,
	onSave,

	onSaveFailed,
	onClose,
}) => {
	const { VehicleType } = useSelector((state) => state.vehiclesType);
	const { Location } = useSelector((state) => state.locations);
	const { Settings } = useSelector((state) => state.settings);

	const filtredLocationList = Location.locationsList;

	const filtredVehicleTypes = VehicleType.vehicleTypeList.filter(
		(vehicleType) => !vehicleType.deleted,
	);
	return (
		<Form
			name='locationWiseFare'
			layout='vertical'
			initialValues={locationWiseFare}
			validateMessages={validateMessages}
			onFinish={onSave}
			onFinishFailed={onSaveFailed}
			autoComplete='off'
			onValuesChange={() => onDirty()}>
			<Form.Item
				label='Source Location Name'
				name='sourceLocationId'
				rules={[{ required: true, message: 'Please select a source location' }]}>
				<Select
					size='medium'
					showArrow
					showSearch
					optionFilterProp='filterBy'
					allowClear
					optionLabelProp='label'
					popupMatchSelectWidth={false}>
					{filtredLocationList.map((location) => {
						return (
							<Select.Option
								key={location.id}
								filterBy={location.locationName}
								label={<span>{location.locationName}</span>}
								value={location.id}>
								<span>{location.locationName}</span>
							</Select.Option>
						);
					})}
				</Select>
			</Form.Item>

			<Form.Item
				label='Destination Location Name'
				name='destinationLocationId'
				rules={[
					{
						required: true,
						message: 'Please select a destination location',
					},
				]}>
				<Select
					size='medium'
					showArrow
					showSearch
					optionFilterProp='filterBy'
					allowClear
					optionLabelProp='label'
					popupMatchSelectWidth={false}>
					{filtredLocationList.map((location) => {
						return (
							<Select.Option
								key={location.id}
								filterBy={location.locationName}
								label={<span>{location.locationName}</span>}
								value={location.id}>
								<span>{location.locationName}</span>
							</Select.Option>
						);
					})}
				</Select>
			</Form.Item>

			<Form.Item
				label='Vehicle Type'
				name='vehicleTypeId'
				rules={[
					{
						required: true,
						message: 'Please select a vehicle type',
					},
				]}>
				<Select
					size='medium'
					showArrow
					showSearch
					optionFilterProp='filterBy'
					allowClear
					optionLabelProp='label'
					popupMatchSelectWidth={false}>
					{filtredVehicleTypes.map((vehicleType) => {
						return (
							<Select.Option
								key={vehicleType.id}
								filterBy={vehicleType.vehicleType}
								label={<span>{vehicleType.vehicleType}</span>}
								value={vehicleType.id}>
								<span>{vehicleType.vehicleType}</span>
							</Select.Option>
						);
					})}
				</Select>
			</Form.Item>

			<Form.Item
				label={`Flat Fare (${Settings?.settingsData?.currency || '€'})`}
				name='flatFare'
				rules={[
					{ required: true, message: 'Please fill the flat fare' },
					{
						type: 'number',
						min: 0,
						transform: (value) => parseFloat(value),
						message: 'Please enter a number greater than or equal to 0',
					},
				]}>
				<Input type='number' step='0.01' />
			</Form.Item>

			<Divider />
			<Form.Item style={{ float: 'right' }}>
				<Button
					type='default'
					style={{ marginBottom: 32, marginRight: 8 }}
					onClick={onClose}>
					Cancel
				</Button>
				<Button
					type='primary'
					style={{ marginBottom: 32 }}
					htmlType='submit'
					disabled={!unsavedChanges}>
					{locationWiseFare ? 'Update' : 'Save'}
				</Button>
			</Form.Item>
		</Form>
	);
};

export default LocationWiseFareForm;
