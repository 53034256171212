/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
/* eslint-disable import/no-extraneous-dependencies */
import { Breadcrumb, Button, Card, Input, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReloadOutlined } from '@ant-design/icons';
import ActionButton from '../Global/ActionsButton';
import { style } from '../../Styles';
import { deleteKmRangeFare, getAllKmRangeFares } from '../../redux/KmRangeFareSlice';
import CustomTableComponents from '../Global/CustomTableComponents';

import KmRangeFareDrawer from './KmRangeFareDrawer';

const KmRangeFare = ({ role }) => {
	const [filter, setFilter] = useState('');
	const { Settings } = useSelector((state) => state.settings);
	const cuurencyRender = () => {
		return <span>{Settings?.settingsData?.currency || '€'}</span>;
	};
	const handleDelete = (id) => {
		setMakingApiCall(true);
		const data = {
			id,
		};
		dispatch(deleteKmRangeFare(data))
			.then(() => {
				message.success('KM Range Fare deleted successfully');
				dispatch(getAllKmRangeFares());
			})
			.catch(() => {
				message.error('Error deleting KM Range Fare');
			})
			.finally(() => {
				setMakingApiCall(false);
			});
	};
	const onEdit = (record) => {
		onRowClick(record);
	};

	const Columns = [
		{
			title: 'ID',
			dataIndex: 'id',
			defaultSortOrder: 'ascend',
			sorter: (a, b) => a.id - b.id,
			width: 100,
		},
		{
			title: 'From Km',
			key: 'fromKm',
			render: (record) => {
				return <span>{`${record?.fromKm} `}</span>;
			},
			defaultSortOrder: 'ascend',
			sorter: (a, b) => a.fromKm - b.fromKm,
		},
		{
			title: 'To Km',
			key: 'toKm',
			render: (record) => {
				return <span>{`${record?.toKm} `}</span>;
			},
			defaultSortOrder: 'ascend',
			sorter: (a, b) => a.toKm - b.toKm,
		},
		{
			title: 'Price',
			key: 'price',
			render: (record) => {
				return (
					<span>
						{cuurencyRender() || '€'} {`${record?.price} `}
					</span>
				);
			},
			defaultSortOrder: 'ascend',
			sorter: (a, b) => a.price - b.price,
		},
		{
			title: 'Actions',
			key: 'actions',
			render: (text, record) => (
				<ActionButton
					record={record}
					onDelete={() => handleDelete(record?.id)}
					onEdit={() => onEdit(record)}
					recordName={`${record?.id}`}
				/>
			),
		
			disabled: !role?.includes('SUPER_ADMIN'),
		},
	];

	const [makingApiCall, setMakingApiCall] = useState(false);
	const dispatch = useDispatch();
	const { KmRangeFare } = useSelector((state) => state.kmRangeFare);
	const [kmRangeFare, setKmRangeFare] = useState(null);
	const [showDrwer, setShowDrwer] = useState(false);

	const handleOpenDrawer = () => {
		setShowDrwer(true);
	};
	const handleCloseDrawer = () => {
		setKmRangeFare(null);
		setShowDrwer(false);
	};

	const fetchAllKmRangeFares = async () => {
		setMakingApiCall(true);
		dispatch(getAllKmRangeFares())
			.unwrap()
			.then(() => {})
			.catch(() => {
				message.error('Error fetching KM Range Fares');
			})
			.finally(() => {
				setMakingApiCall(false);
			});
	};

	useEffect(() => {
		fetchAllKmRangeFares();
	}, []);

	const onRowClick = (record) => {
		setKmRangeFare(record);
		handleOpenDrawer();
	};

	const kmRangeFaresArray = Object.values(KmRangeFare.kmRangeFareList);
	const filtredData = kmRangeFaresArray.filter((item) => {
		return (
			item.id.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
			item.price.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
			item.fromKm.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
			item.toKm.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1
		);
	});
	return (
		<Card
			style={{
				margin: 16,
				maxHeight: '90%', // Set maximum width
				height: 'auto', // Allow width to be dynamic
			}}>
			<div style={style.inlineContent}>
				<div
					style={{
						display: 'flex',
						alignContent: 'center',
						alignItems: 'center',
						float: 'left',
						marginBottom: 8,
					}}>
					<Breadcrumb
						style={{ margin: '0 3em 0 0', fontSize: '1.1em' }}
						items={[
							{
								title: 'Locations',
							},
							{
								title: 'KM Range Fare',
							},
						]}
					/>
					<Button
						type='default'
						size='small'
						// style={{ margin: '0 8px 8px 0' }}
						onClick={() => fetchAllKmRangeFares()}>
						<ReloadOutlined />
					</Button>
				</div>

				<div
					style={{
						...style.inlineContent,
						float: 'right',
						marginBottom: 8,
					}}>
					<Input.Search
						placeholder='Search'
						style={{ margin: '0 8px 8px 0' }}
						value={filter}
						onChange={(e) => setFilter(e.target.value)}
					/>
					{role?.includes('SUPER_ADMIN') && (
						<Button
							type='default'
							style={{ float: 'right', marginBottom: 8 }}
							onClick={handleOpenDrawer}>
							Add KM Range Fare
						</Button>
					)}
				</div>
			</div>
			<CustomTableComponents
				isMakingApiCall={makingApiCall}
				columns={Columns.filter((item) => {
					return !item.disabled;
				})}
				dataArray={filtredData}
				handleDoubleClickRow={() => (role?.includes('SUPER_ADMIN') ? onRowClick() : {})}
				handleSelectedRow={() => {}}
			/>
			{showDrwer && (
				<KmRangeFareDrawer
					visible={showDrwer}
					handleClose={handleCloseDrawer}
					kmRangeFareId={kmRangeFare?.id}
				/>
			)}
		</Card>
	);
};

export default KmRangeFare;
