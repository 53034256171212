import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import reportWebVitals from './reportWebVitals';
// eslint-disable-next-line import/namespace
import { persistor, store } from './redux/store';
// import setupInterceptors from './axios/setupInterceptors';
import AxiosInterceptor from './axios/interceptorComponent';
import TenantFeaturesContextProvider from './Context/TenantFeatureContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<Router>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<TenantFeaturesContextProvider>
					<AxiosInterceptor />
					<App />
				</TenantFeaturesContextProvider>
			</PersistGate>
		</Provider>
	</Router>,
);
// setupInterceptors(store);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
