import { style } from '../Styles';

export const getSpanStyle = (status) => {
	switch (status) {
		case 'COMPLETED':
			return style.statusCompleted;
		case 'CANCELLED':
			return style.statusCancelled;
		case 'PENDING':
			return style.statusPending;
		case 'IN_PROGRESS':
			return style.statusInProgress;
		case 'ACCEPTED':
			return style.statusAccepted;
		case 'REQUESTED':
			return style.statusRequested;
		case 'TIMED_OUT':
			return style.statusTimedOut;
		case 'REFUNDED':
			return style.statusRefunded;
		case 'STARTED':
			return style.statusInProgress;
		default:
			return null;
	}
};

export const refundStatusStyle = (status) => {
	switch (status) {
		case 'REFUNDED':
			return style.statusCompleted;
		case 'FAILED':
			return style.statusCancelled;
		case 'PENDING':
			return style.statusPending;
		default:
			return null;
	}
};
