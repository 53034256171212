/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */

import React, { useCallback, useEffect, useState } from 'react';
import { Drawer, Spin, message } from 'antd';
import { useDispatch } from 'react-redux';
import ShowConfirmClose from '../Modals/ShowConfirmClose';
import { createModel, getAllModels, getModelById, updateModel } from '../../redux/modelSlice';
import ModelForm from './ModelForm';
import { getAllMakes } from '../../redux/makeSlice';

const DrawerModel = ({ onClose, modelId }) => {
	const [unsavedChanges, setUnsavedChanges] = useState(false);
	const [makingApiCall, setMakingApiCall] = useState(false);
	const [model, setModel] = useState(null);
	const [confirmationOpen, setConfirmationOpen] = useState(false);

	const dispatch = useDispatch();

	const handleClose = useCallback(
		(hasUnsavedChanges) => {
			setModel(null);
			if (hasUnsavedChanges && !confirmationOpen) {
				setConfirmationOpen(true); // Mark that the confirmation modal is open
				ShowConfirmClose(() => {
					setConfirmationOpen(false); // Reset when the confirmation modal is closed

					onClose();
				});
			} else {
				onClose();
			}
		},
		[onClose, confirmationOpen],
	);

	const handleDirty = useCallback(() => {
		setUnsavedChanges(true);
	}, []);

	const saveModel = async (values) => {
		const action = modelId
			? updateModel({
					...values,
					id: modelId,
			  })
			: createModel(values);
		dispatch(action)
			.unwrap()
			.then(() => {
				message.success('Model saved successfully');
				setUnsavedChanges(false);
			})
			.catch((e) => {
				if (e.message === 'MODEL_ALREADY_EXIST') {
					message.error('Could not save model, it is already exist');
				} else {
					message.error(e.message);
				}
			})
			.finally(() => {
				onClose();
				setMakingApiCall(false);
			});
	};

	const onSaveFailed = (error) => {
		console.log('Failed:', error);
	};

	useEffect(() => {
		// make use of promise.all to distribute the api calls
		Promise.all([dispatch(getAllMakes()), dispatch(getAllModels())])
			.then(() => {
				// console.log(res);
			})
			.catch(() => {
				message.error('Error while fetching makes');
			});
	}, [dispatch]);

	useEffect(() => {
		const abortController = new AbortController();

		if (modelId) {
			setMakingApiCall(true);
			const calls = [dispatch(getModelById(modelId))];
			Promise.all(calls)
				.then((res) => {
					setModel(res[0].payload);
				})
				.catch(() => {
					onClose();
				})
				.finally(() => {
					setMakingApiCall(false);
				});
		}
		return () => abortController.abort();
	}, [modelId, dispatch]);
	return (
		<Drawer
			width={500}
			title={!model ? 'Add Model' : 'Update Model'}
			placement='right'
			onClose={() => handleClose(unsavedChanges)}
			open>
			<Spin size='large' spinning={makingApiCall} style={{ marginTop: 64 }} delay={500}>
				{!makingApiCall && (
					<ModelForm
						model={model}
						onSave={saveModel}
						onSaveFailed={onSaveFailed}
						onCancel={handleClose}
						onDirty={handleDirty}
						unsavedChanges={unsavedChanges}
						setUnsavedChanges={setUnsavedChanges}
					/>
				)}
			</Spin>
		</Drawer>
	);
};

export default DrawerModel;
