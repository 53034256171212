/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Breadcrumb, Button, Input, message } from 'antd';
import { ReloadOutlined, MinusOutlined } from '@ant-design/icons';
import { changeUserStatus, deleteUser, getAllUsers } from '../../redux/userSlice';

import { style } from '../../Styles';
import CustomTableComponents from '../Global/CustomTableComponents';
import DrawerUser from './DrawerUser';
import ActionButtonWithChangeStatus from '../Global/ActionButtonWithChangeStatus';
import ImageComponent from '../Global/ImageComponent/ImageComponent';
import CustomAvatar from '../Global/CustomAvatar/CustomAvatar';
import { getAllFleetOperators } from '../../redux/fleetOperatorSlice';
import { formatEnumValue } from '../../Utils/ArrayToObject';

const Users = () => {
	const columns = [
		{
			title: 'Profile Picture',
			key: 'profilePicture',
			render: (record) => {
				return record.profilePicture ? (
					<ImageComponent size={40} imageId={record?.profilePicture} />
				) : (
					<CustomAvatar name={`${record.name} ${record.surname}`} type='initials' />
				);
			},
			width: 150,
		},
		{
			title: 'Name',
			key: 'name',
			render: (record) => {
				return <span>{`${record.name} ${record.surname}`}</span>;
			},
			sorter: (a, b) => a.name.length - b.name.length,
		},
		{
			title: 'Email',
			dataIndex: 'email',
			sorter: {
				compare: (a, b) => a.email - b.email,
				multiple: 4,
			},
		},
		{
			title: 'Role',
			key: 'role',
			render: (record) => {
				return record.role?.length > 0 ? (
					<span>
						{record.role?.map((item) => {
							return (
								<span key={item} style={style.tags}>
									{formatEnumValue(item)}
								</span>
							);
						})}
					</span>
				) : (
					<MinusOutlined />
				);
			},
			sorter: {
				compare: (a, b) => a.role - b.role,
				multiple: 1,
			},
		},
		{
			title: 'Phone',
			key: 'phone',
			render: (record) => {
				return <span>{`+(${record.phoneCountryCode}) ${record.phone}`}</span>;
			},
			sorter: {
				compare: (a, b) => a.phone - b.phone,
				multiple: 3,
			},
		},
		{
			title: 'Country',
			key: 'address',
			render: (record) => {
				return (
					<span>{`${record.address || ''} ${record.state || ''} ${record.country || ''} ${
						record.zipCode || ''
					}`}</span>
				);
			},
			sorter: {
				compare: (a, b) => a.status - b.status,
				multiple: 1,
			},
		},
		{
			title: 'Status',
			key: 'status',
			render: (record) => {
				return (
					<span style={style.dataContainer}>
						{record.active ? (
							<div style={style.statusActif}>ACTIVE</div>
						) : (
							<div style={style.statusInactif}>INACTIVE</div>
						)}
					</span>
				);
			},
			sorter: {
				compare: (a, b) => a.status - b.status,
				multiple: 1,
			},
		},
		{
			title: 'Actions',
			key: 'actions',
			render: (text, record) => (
				<ActionButtonWithChangeStatus
					record={record}
					onDelete={() => handleDeleteUser(record.id, () => {})}
					onRowClick={() => handleSelectedUser(record)}
					onChangeStatus={() => handleChangeStatus(record)}
				/>
			),
		},
	];

	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);

	const [filter, setFilter] = useState('');
	const { Users } = useSelector((state) => state.users);
	const [user, setUser] = useState(null);
	const [isMakingApiCall, setMakingApiCall] = useState(false);
	const fetchAllFlletOperators = () => {
		dispatch(getAllFleetOperators())
			.unwrap()
			.then(() => {})
			.catch(() => {
				message.error('Error getting fleet operators');
			});
	};
	const fetchAllUsers = () => {
		setMakingApiCall(true);
		dispatch(getAllUsers())
			.then(() => {})
			.catch(() => {})
			.finally(() => {
				setMakingApiCall(false);
			});
	};
	useEffect(() => {
		fetchAllUsers();
		fetchAllFlletOperators();
	}, [dispatch]);

	const handleChangeStatus = (record) => {
		setMakingApiCall(true);
		dispatch(
			changeUserStatus({
				userId: record.id,
				active: !record.active,
			}),
		)
			.unwrap()
			.then(() => {
				message.success('User status updated successfully');
			})
			.catch(() => {
				message.error('Error updating user status');
			})
			.finally(() => {
				setMakingApiCall(false);
			});
	};

	const handleDeleteUser = (id, callback) => {
		setMakingApiCall(true);
		dispatch(
			deleteUser({
				userId: id,
			}),
		)
			.unwrap()
			.then(() => {
				message.success('User deleted successfully');
				callback();
			})
			.catch(() => {})
			.finally(() => {
				onClose();
				setMakingApiCall(false);
			});
	};

	const showDrawer = () => {
		setOpen(true);
	};
	const onClose = () => {
		setOpen(false);
		setUser(null);
	};

	const handleSelectedUser = (selectedUser) => {
		setUser(selectedUser);
		showDrawer();
	};

	let userArray = Object.values(Users.usersList);
	userArray = userArray.map((item) => {
		return { ...item, key: item.id };
	});

	const filtredData = userArray.filter((item) => {
		return (
			item?.id?.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
			item?.name?.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
			item?.email?.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
			item?.country?.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
			item?.phone?.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1
		);
	});
	return (
		<>
			<div style={style.inlineContent}>
				<div
					style={{
						display: 'flex',
						alignContent: 'center',
						alignItems: 'center',
						float: 'left',
						marginBottom: 8,
					}}>
					<Breadcrumb
						style={{ margin: '0 3em 0 0', fontSize: '1.1em' }}
						items={[
							{
								title: 'Users',
							},
						]}
					/>
					<Button
						type='default'
						size='small'
						// style={{ margin: '0 8px 8px 0' }}
						onClick={() => fetchAllUsers()}>
						<ReloadOutlined />
					</Button>
				</div>
				<div
					style={{
						...style.inlineContent,
						float: 'right',
						marginBottom: 8,
					}}>
					<Input.Search
						placeholder='Search'
						style={{ margin: '0 8px 8px 0' }}
						value={filter}
						onChange={(e) => setFilter(e.target.value)}
					/>

					<Button
						type='default'
						style={{ float: 'right', marginBottom: 8 }}
						onClick={showDrawer}>
						Add User
					</Button>
				</div>
			</div>
			<CustomTableComponents
				isMakingApiCall={isMakingApiCall}
				columns={columns}
				dataArray={filtredData}
				handleDoubleClickRow={(record) => handleSelectedUser(record)}
				handleSelectedRow={() => {}}
			/>

			{open && (
				<DrawerUser
					onClose={onClose}
					open={open}
					userId={user?.id}
					handleDeleteUser={handleDeleteUser}
				/>
			)}
		</>
	);
};

export default Users;
