/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
import { Col, Row } from 'antd';
import React from 'react';
import { MinusOutlined } from '@ant-design/icons';
import { style } from '../../Styles';
import { navigateSvg, personSvg } from '../../Utils/svgExport';

const spanTruncate = {
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
};
const PricingCard = ({
	distance,
	pickUpName,
	dropOffName,
	pickupWaypoint,
	dropoffWaypoint,
	numberOfSeats,
}) => {
	return (
		<div
			style={{
				...style.boxShadow,
				height: pickupWaypoint && dropoffWaypoint ? '160px' : '110px',
				marginTop: '1em',
			}}>
			<Row style={{ margin: '0 0 0 0.6em' }}>
				{!pickupWaypoint ? (
					<Col
						span={24}
						style={{
							paddingLeft: '15px',
							marginBottom: '10px',
							marginTop: '10px',
							display: 'flex',
							alignItems: 'space-between',
						}}>
						<div
							style={{
								textAlign: 'center',
								borderRadius: '50%',
								backgroundColor: '#e7483c',
								color: 'white',
								width: '20px',
								minWidth: '20px',
								fontWeight: 'bold',
								fontSize: '12px',
								marginRight: '10px',
							}}>
							A
						</div>
						<span style={spanTruncate}> {pickUpName || '(-)'}</span>
					</Col>
				) : (
					<>
						<Col
							span={24}
							style={{
								paddingLeft: '15px',
								marginBottom: '10px',
								marginTop: '10px',
								display: 'flex',
								alignItems: 'space-between',
							}}>
							<div
								style={{
									textAlign: 'center',
									borderRadius: '50%',
									backgroundColor: '#e7483c',
									color: 'white',
									width: '20px',
									minWidth: '20px',
									fontWeight: 'bold',
									fontSize: '12px',
									marginRight: '10px',
								}}>
								A
							</div>
							<span style={spanTruncate}> Pickup</span>
						</Col>
						<br />
						<span
							style={{
								...spanTruncate,
								marginBottom: '0.2em',
								marginLeft: '3em',
								fontWeight: 'bold',
							}}>
							[{pickupWaypoint?.locationName}]
						</span>
					</>
				)}

				{!pickupWaypoint ? (
					<Col
						span={24}
						style={{
							paddingLeft: '15px',
							marginBottom: '10px',
							display: 'inherit',
						}}>
						<div
							style={{
								textAlign: 'center',
								borderRadius: '50%',
								backgroundColor: '#e7483c',
								color: 'white',
								width: '20px',
								minWidth: '20px',
								fontWeight: 'bold',
								fontSize: '12px',
								marginRight: '10px',
							}}>
							B
						</div>
						<span style={spanTruncate}>{dropOffName || <MinusOutlined />}</span>
					</Col>
				) : (
					<>
						<Col
							span={24}
							style={{
								paddingLeft: '15px',
								marginBottom: '10px',
								display: 'inherit',
							}}>
							<div
								style={{
									textAlign: 'center',
									borderRadius: '50%',
									backgroundColor: '#e7483c',
									color: 'white',
									width: '20px',
									minWidth: '20px',
									fontWeight: 'bold',
									fontSize: '12px',
									marginRight: '10px',
								}}>
								B
							</div>
							<span style={spanTruncate}>Dropoff</span>
						</Col>
						<br />
						<span
							style={{
								...spanTruncate,
								marginLeft: '3em',
								marginBottom: '0.2em',
								fontWeight: 'bold',
							}}>
							[{dropoffWaypoint?.locationName}]
						</span>
					</>
				)}
			</Row>
			<Row style={{ margin: '0 0.9em 0 1.5em' }}>
				<Col
					span={12}
					style={{
						textAlign: 'center',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'start',
					}}>
					{navigateSvg()}
					<span>{distance ? `${distance} km` : <MinusOutlined />} </span>
				</Col>
				<Col
					span={12}
					style={{
						textAlign: 'center',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'end',
					}}>
					{personSvg()}
					<span>
						{numberOfSeats ? (
							`${numberOfSeats} ${numberOfSeats === 1 ? 'person' : 'persons'}`
						) : (
							<MinusOutlined />
						)}
					</span>
				</Col>
			</Row>
		</div>
	);
};

export default PricingCard;
