/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */

import { Badge, Col, Row, Tooltip } from 'antd';
import React from 'react';
import { style } from '../../Styles';
import CustomAvatar from '../Global/CustomAvatar/CustomAvatar';
import DriverNotAssigned from './DriverNotAssigned';
import { mailSvg, phoneSvg } from '../../Utils/svgExport';
import ImageComponent from '../Global/ImageComponent/ImageComponent';

const spanTruncate = {
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
};

const DriverRiderCard = ({ data, type, assigned, role }) => {
	const fullName =
		type === 'Driver'
			? `${data?.name} ${data?.surname}`
			: role?.includes('SUPER_ADMIN')
			? `${data?.name} ${data?.surname}`
			: `${data?.name}`;
	return (
		<div style={{ ...style.boxShadow, height: '130px', marginTop: '1em' }}>
			{assigned ? (
				<>
					<Row
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							padding: '0 20px 0 20px',
						}}>
						<Col span={16} style={{ alignItems: 'center', display: 'flex' }}>
							{data?.profilePicture ? (
								<ImageComponent size={40} imageId={data?.profilePicture} />
							) : (
								<CustomAvatar
									name={`${data?.name} ${data?.surname}`}
									type='initials'
								/>
							)}
							<Tooltip title={`${fullName}`}>
								<h3
									style={{
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										whiteSpace: 'nowrap',
										maxWidth: '250px',
									}}>
									{`${fullName}`}
								</h3>
							</Tooltip>
						</Col>
						<Col
							span={8}
							style={{
								alignItems: 'center',
								justifyContent: 'end',
								display: 'flex',
							}}>
							<Badge
								className='site-badge-count-109'
								count={type}
								style={{ backgroundColor: '#ffa500' }}
							/>
						</Col>
					</Row>
					<Row>
						{data?.email && (
							<Col
								span={24}
								style={{
									...spanTruncate,
									paddingLeft: '1.5em',
									display: 'flex',
									alignItems: 'center',
									justifyContent: ' start',

									height: 20,
								}}>
								{mailSvg()}
								<p
									style={{
										...spanTruncate,
										marginLeft: '0.4em',
										minWidth: '50px',
									}}>
									{data?.email}
								</p>
							</Col>
						)}
						{data?.phone && data?.phoneCountryCode && (
							<Col
								span={24}
								style={{
									paddingLeft: '1.5em',
									display: 'flex',
									alignItems: 'center',
									justifyContent: ' start',
								}}>
								{phoneSvg()}
								<p
									style={{
										marginLeft: '0.4em',
									}}>{`${data?.phoneCountryCode} ${data?.phone}`}</p>
							</Col>
						)}
					</Row>
				</>
			) : (
				<DriverNotAssigned />
			)}
		</div>
	);
};

export default DriverRiderCard;
