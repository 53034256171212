let DEFAULT = 'local';

if (process.env.NODE_ENV === 'production') {
	DEFAULT = 'production';
}

const env = {
	production: {
		baseUri: '/api/v1/web/',
	},
	local: {
		baseUri: process.env.REACT_APP_API_URL_LOCAL,
	},
};

export default env[DEFAULT];
